import axios from 'axios';
// import { logout } from '../store/slices/authSlice';

const axiosInstance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: process.env.REACT_APP_REACT_SERVER_URL
});
/* eslint no-unused-vars: 0 */
let store;

export const injectStore = (_store) => {
  store = _store;
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    config.headers['x-access-token'] = localStorage.getItem('token');
    config.headers.UserKey = localStorage.getItem('UserKey');
    return config;
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // store.dispatch(logout());
      // eslint-disable-next-line no-undef
      window.location.href = process.env.REACT_APP_BASE_URL;
      return Promise.reject(error.response);
    }
    return Promise.reject(error.response);
  },
);

export default axiosInstance;
