import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { theme, useStyles } from "./AFOEReportStyles";

// import LightTheme from "../../../themes/Light.json";
// import DarkTheme from "../../..//themes/Dark.json";
import PowerBiService from '../powerBi.auth';

const PowerBIReport = ({ data }) => {
  const {
    PBIBaseURL: BaseURL,
    // PBIDatasetId: DataSetID,
    PBIGroupId: GroupID,
    PBIReportId: ReportID,
    PBIReportURL: ReportURL,
  } = data;
  // useEffect(() => {
  //   getAccessToken();
  // }, []);

  // For Language Change
  // const classes = useStyles(theme);

  // const [Lang, setLang] = useState("English");
  const [report, setReport] = useState();
  const [reportRender, setReportRender] = useState(false);

  const [authToken, setAuthToken] = useState('');
  const bear = 'Bearer ';

  const reportID = ReportID;
  const groupID = GroupID;
  const reportURL = ReportURL;
  const base = BaseURL;

  const [sampleReportConfig, setReportConfig] = useState({
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.All,
    accessToken: undefined,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      localeSettings: {
        language: 'en',
        formatLocale: 'en-gb',
      },
    },
    viewMode: models.ViewMode.View,
  });

  const getEmbedToken = (auth) => {
    let URL = '';
    URL = base.concat(groupID, '/reports/', reportID, '/GenerateToken');
    return axios
      .post(
        URL,
        {
          accessLevel: 'View',
          allowSaveAs: 'false',
        },
        {
          headers: {
            Authorization: authToken === '' ? auth : authToken,
            'Content-type': 'application/json',
          },
        },
      )
      .then((response) => {
        setReportConfig({
          ...sampleReportConfig,
          embedUrl: reportURL,
          accessToken: response.data.token,
        });
      })
      .catch((err) => { console.log(err, report, reportRender); });
  };

  const getAccessToken = () => {
    // Calling API
    PowerBiService.getAccessToken()
      .then((response) => {
        if (response.data !== undefined) {
          setAuthToken(bear.concat(response.data));
          getEmbedToken(bear.concat(response.data));
        }
      })
      .catch((error) => { console.log(error); });
  };
  useEffect(() => {
    getAccessToken();
  }, []);
  const eventHandlersMap = new Map([
    [
      'loaded',
      /* eslint  func-names:0 */
      function () {
        // //
      },
    ],
    [
      'rendered',
      function (event, currentReport) {
        /// /
        setReportRender(true);
        currentReport.getPages().then((pages) => {
          const activePage = pages.filter((page) => page.isActive)[0];

          activePage.getVisuals().then((visuals) => {
            const slicers = visuals.filter((v) => v.type === 'slicer');
            console.log(slicers);
            // plantHook.storeSlicerValue(slicers, false)
            // machineModelHook.storeSlicerValue(slicers, false)
            // machineNameHook.storeSlicerValue(slicers, false)
          });
        });
      },
    ], // Event handler for err
    [
      'error',
      function (event) {
        if (event) {
          console.log();
        }
      },
    ], // Event handler for Save As
    [
      'saved',
      function (event) {
        if (event) {
          console.log();
        }
      },
    ],
  ]);

  return (
    <PowerBIEmbed
      embedConfig={sampleReportConfig}
      eventHandlers={eventHandlersMap}
      cssClassName="powerBI_report_container"
      getEmbeddedComponent={(embedObject) => {
        setReport(embedObject);
      }}
    />
  );
};

export default PowerBIReport;
