import React from 'react';
import Styles from './popup.module.css';
/* eslint jsx-a11y/click-events-have-key-events:0 */
/* eslint jsx-a11y/no-static-element-interactions:0 */
/* eslint react/prop-types:0 */

function Showdata({ closePopUPdata, data }) {
  const {
    popup,
    popupBody,
    popupHeader,
    popupClose,
    popupData,
    popupOk,
    popupTitle,
    BGlayer,
  } = Styles;

  return (
    <>
      <div className={`${popup}`}>
        <div className={`${popupHeader}`}>
          <span
            className={`${popupClose}`}
            onClick={() => closePopUPdata((prev) => !prev)}
          >
            &#x2715;
          </span>
        </div>
        <div className={`${popupBody}`}>
          <span className={`${popupTitle}`}>{isNaN(data) ? 'Info' : 'Average Pick Up Weight'}</span>
          <span className={`${popupData}`}>{data}</span>
          <span
            onClick={() => closePopUPdata((prev) => !prev)}
            className={`${popupOk}`}
          >
            Ok
          </span>
        </div>
      </div>
      <div
        className={`${BGlayer}`}
        onClick={() => closePopUPdata((prev) => !prev)}
      />
    </>
  );
}

export default Showdata;
