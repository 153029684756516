import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import reports from './ImportReport';
import PowerBIReport from './PowerBI';
import AppRoutes from '../../config/appicationRoutes.config.json';
import ExcelImport from './SeniorManagement/ExcelImport';

const DefaultComponent = () => <div>Report is in progress</div>;
const Report = () => {
  const { Reports } = useSelector((state) => state.user);
  const params = new URLSearchParams(window.location.search); // id=123
  const ReportID = params.get('reportType') || null;

  const history = useHistory();
  if (ReportID == null) {
    history.replace(AppRoutes.REPORTS);
    // return;
  }
  const ReportType = Reports[ReportID] ? Reports[ReportID].ReportType : null;
  let Component = '';

  // IF
  if (ReportID === null || Reports[ReportID] === undefined) {
    Component = DefaultComponent;
  } else if (ReportType === 'PowerBI') {
    Component = PowerBIReport;
  } else {
    Component = reports[ReportID] ? reports[ReportID] : DefaultComponent;
  }
  const ExtraTabContent = [];
  if (ReportID === 'SeniorManagementRpt') {
    const ExtraTab = {
      tabName: 'Import Excel',
      Component: () => <ExcelImport />,
      tabID: 'importexcel',
    };
    ExtraTabContent.push(ExtraTab);
  }
  return (
    <Component data={Reports[ReportID]} ExtraTabContent={ExtraTabContent} />
  );
};
export default Report;
