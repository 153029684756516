import React from 'react';
import AddEntry from './SeniorManagement/AddEntry';

const ReportAddEntryPopup = ({ reportCode, open, onClosed }) => {
  let Component = null;
  if (reportCode === 'SMDAFOERpt') {
    Component = AddEntry;
  }
  return (
    <>
      <Component open={open} onClosed={onClosed} />
    </>
  );
};

export default ReportAddEntryPopup;
