// import axios from 'axios';
// import { APIUrl } from "../../../configs/config";
import { APIUrl } from '../../../helpers/constants';
// import authHeader from '../../../config/auth.header';
import CallAPI from '../../../services/conditionMonitoring.service';
// const headers = authHeader();

// A mock function to mimic making an async request for data
export function getSchedulings() {
  const apiInfo = {
    url: `${APIUrl}/api/getScheduling`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(

  //     `${APIUrl}/api/getScheduling`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function saveSchedulings(body) {
  const apiInfo = {
    url: `${APIUrl}/api/saveScheduling`,
    method: 'POST',
    postData: body,
    isMultiPart: false,
    CreatedUserID: true,
  };
  return CallAPI(apiInfo).then((res) => console.log('Status updated', res));
  // return axios
  //   .post(`${APIUrl}/api/saveScheduling`, body, { headers })
  //   .then((res) => console.log('Status updated', res));
}
