// Author:- Sanket
// Reviewd By:- Aniket
// Engslish & Language strings of application

export const LangEstrings = {
  Language: 'English (Default)',
  ChangeLanguage: 'Change Language',
  // text for login form
  welcome: 'Welcome',
  emailId: 'Email ID',
  password: 'Password',
  forgot: ' Forgot password?',
  login: 'Login',

  // Logout Texts
  Logout: 'Logout',
  LogoutTxt1: ' You have been successfully logged out from ACG Smart Connected',
  LogoutTxt2: 'Thank you for using ACG Smart Connected',
  ClickHere: 'Click Here',
  ClickHereTxt: 'to log in again',
  Remoteassistance: 'Remote Assistance',

  // NoInernet Txt
  NoInternetTxt: 'This machine is currently Offline',

  // Sidebar texts
  Home: 'Home',
  Monitoring: 'Monitoring',
  AlertNotif: 'Alerts & Notifications',
  AlertsAndNotifications: 'Alerts & Notifications',
  SettingIcon: 'Settings',
  HelpIc: 'Help',
  Help: 'Help',
  AllNotif: 'All Notifications',
  AllNotifications: 'All Notifications',
  CustomAlert: 'Custom Alert',
  CustAlert: 'Custom Alert',
  ChangePass: 'Change Password',
  ChangePIN: 'Change PIN',
  AppSettings: 'Application Settings',
  AllParameters: 'Machine All Parameters',
  Downtime: 'Machine Downtime',
  Reports: 'Reports',
  OEEInsights: 'OEE Day wise Insights',
  Camera_pass_rate: 'Camera Pass Rate',
  RsdReport: 'RSD Report',
  // Report Page text
  MachineDowntime: 'Machine Downtime',
  MachineAllParameters: 'Machine All Parameters',
  AlarmAnalysis: 'Alarm Analysis',
  AssetHealthDiagnostics: 'Asset Health Diagnostics',
  Af100zroParameterTrends: 'AF100ZRO Parameter Trends',
  TimeSeriesAnalysis: 'Time Series Analysis',
  OeeInsights: 'OEE Day wise Insights',
  AfoeReport: 'AFOE Report',
  BatchPerformance: 'Batch Performance',
  DataAdequacy: 'Data Adequacy',
  EcrReport: 'ECR',
  GelRoomReport: 'GelRoom Report',
  HcmdowntimeCopy: 'HCM Downtime',
  ProcessCapability: 'Process Capability',
  ShiftReport: 'Production Shift Report',
  SetPoint: 'Set Point Changes',
  Gelreport: 'Reports',
  Avsu: 'AVSU',
  WaterTrend: 'Water Trend',
  MelterMixture: 'Melter Mixture',
  FeedTank: 'Feed Tank',
  MachinePerformanceReport: 'Machine Performance Report',
  ConditionMonitoringReport: 'Condition Monitoring Report',

  // Report Page text
  Admin: 'Admin',
  MasterForms: 'Master Forms',
  UserManagement: 'User Management',

  // Settins Page
  ChangePassword: 'Change Password',
  ChangePin: 'Change Pin',
  ApplicationSettings: 'Application Settings',
  AssetManagement: 'Asset Management',

  // text for map side fiter
  MapLang: 'en',
  LiveMap: 'Live Map Tracking',
  MachineList: 'Machine List',
  Country: 'Country',
  States: 'States',
  MachineModel: 'Machine Model',
  Plants: 'Plants',
  Orgnizations: 'Orgnizations',
  Reset: 'Reset',
  TypeHere: 'Type Here',
  AllMach: 'All Machines',
  CriticalMach: 'Critical Machines',
  AMCdueMach: 'AMC Due Machines',

  // text for machine side filter
  // Plants: "Plants",
  // MachineModel: "Machine Model",
  LineNo: 'Line Number',
  Serial: 'Line / Serial Number',
  // Reset: "Reset",
  // TypeHere: "Type Here...",

  All: 'All',
  Criticalt: 'Critical',
  AMCdue: 'AMC due',
  machineList: 'Machine List',

  // Machine list card texts
  MachineOffline: 'Machine is offline',
  Since: 'Since ',
  CriticalAlarmt: 'Active Alarm: ',
  Mode: 'Mode',
  MachineFor: 'Machine List For:',

  // machine card tooltip
  CommisionOn: 'Commissioned On',
  PlantName: 'Plant Name',
  CriticalAlarm: 'Active Alarm:',
  AvgMachineSpeedCPM: 'Avg Machine Speed (cpm)/day',

  AvgMachineSpeedSPM: 'Avg Machine Speed (spm)/day',

  TimeSeriesAnalysisZydus: 'Time Series Analysis',

  // for overview side filter
  // Plants: "Plants",
  // MachineModel: "Machine Model",
  // LineNo: "Line Number",
  // Serial: "Serial Number",
  SerialShort: 'Serial No.',
  // Reset: "Reset",
  // TypeHere: "Type Here...",

  // overview texts
  monitoring: 'Monitoring',
  overview: 'Overview',
  Overview: 'overview',
  MachineSetting: 'Machine Parameters',
  Kpeei: 'Time Series',
  Alarms: 'Machine Alarms',
  Events: 'Events',
  LiveBatch: 'Batch',
  CustomRepo: 'Time Series Analysis',
  Beta: 'Under Beta Testing',
  Prev: 'Previous',
  Next: 'Next',

  stat: 'Status',
  Machineview: 'Machine View',
  TotalProdCount: 'Total Production Count',

  // AppSettings Texts
  Setting: 'Settings',
  Settings: 'Settings',
  AppSetting: 'Application Settings',
  ChangingTheme: 'Change Theme',
  Black: 'Dark (Default)',
  White: 'Light',
  ChangingLang: 'Change Language',
  ChangingFont: 'Change Font Size',
  Medium: 'Medium (Default)',

  // Overview Status Marquee text
  Stopped: 'Stopped',
  Running: 'Running',
  NotAvailable: 'NA',
  OffStatus: 'OfflineStatus',
  NAStatus: 'NAStatus',
  MachineMode: 'Machine Mode',

  ConnectivityStatus: 'Connectivity Status',
  Online: 'Online',
  Offline: 'Offline',
  MachineStatus: 'Machine Status',
  ComingSoon: 'Coming Soon',
  AvgMachineSpeed: 'Avg Machine Speed/day',
  AvgRunning: 'Avg Running hr/day',
  AvgDowntime: 'Avg Downtime hr/day',
  AvgAsset: 'Avg Asset Utilization/day',
  AvgBreakdown: 'Avg Breakdown hr/day',
  AvgAlarm: 'Avg Alarm Count/day',
  MachineStatust: 'Machine Status:',

  // Machine Setting Texts
  ConfigPara: 'Configure Parameters',

  // kpi
  ConfigKpi: 'Configure TSD',

  // kpi tool tip
  AbsoluteRelative: 'Absolute/Relative',
  Absolute: 'Absolute:',
  Relative: 'Relative:',
  AbsoluteText: '  Upper limit > Parameter KPI > Lower limit',
  RelativeText:
      '  (Upper limit + Set value) > Parameter KPI > (set Value - Lower limit)',

  // Alarm Setting
  AlarmSetting: 'Alarm Settings',
  Active: 'Active',
  History: 'History',
  AlarmName: 'Alarm Name',
  TriggerTime: 'Triggered Time',
  ResetTime: 'Reset Time',
  Duration: 'Duration',
  ActiveDuration: 'Active Duration',
  EventTimestamp: 'Event Timestamp',
  Severity: 'Severity',
  NoRecord: 'No Record Available',
  TriggeredTime: 'Triggered Since:',
  NoAlarm: 'No active alarm available',

  // Condition Monitoring
  ConditionName: 'Condition Name',
  MachineSection: 'Machine Section',
  FailureMode: 'Failure Mode',
  CommunicationType: 'Communication Type',
  Activate: 'Activate',
  Action: 'Actions',
  Edit: 'Edit',

  // Alarm tooltip
  // Severity: "Severity",
  Critical: 'Critical:',
  CriticalTxt: 'Affects machine performance and product output.',
  Major: 'Major:',
  MajorTxt: 'Operator action required.',
  Minor: 'Minor:',
  MinorTxt: 'No action required/only information.',

  // Event texts
  EventSetting: 'Event Settings',
  EventName: 'Event Name ',

  // LiveBatch texts
  LiveBatchStatus: 'Live Batch Status',
  MachineOffTxt:
      'Machine is currently offline. Live batch details won’t be available Meanwhile, You can continue checking other tabs of monitoring.',
  BatchNo: 'Batch No.',
  StartTime: 'Start Time',
  EndTime: 'End Time',
  TotalAlarm: 'Total Alarm Count',
  OEE: 'OEE',
  ProdTime: 'Availability',
  ProdRate: 'Performance',
  ProdQual: 'Quality',
  LiveProdCount: 'Live Batch Production Count',
  NoData: 'No data available',

  // AllNotification texts
  TimeStamp: 'Time Stamp',
  MachName: 'Machine Name',
  AlertName: 'Alert Name',
  Manage: 'Manage',

  // Custom Alert Table
  Condition: 'Condition',
  CreateDate: 'Creation Date',

  // Notification Pannel
  Notif: 'Notifications',
  ViewAll: 'View All',
  NoNotif: 'No notification available',
  ClearNotif: 'Clear All Notification',
  Dismiss: 'Dismiss',
  //   SelectStatus: 'Status',
  SelectDuration: 'Duration',
  Select: 'Select',

  // Password/PIN div texts
  ChangeHere: 'Change Password',
  ChangePINhere: 'Change PIN',
  OldPass: 'Old Password',
  OldPIN: 'Old PIN',
  EnterPass: 'Enter Old Password',
  EnterOldPIN: 'Enter Old PIN',
  NewPass: 'New Password',
  NewPIN: 'New PIN',
  EnterNewPass: 'Enter New Password',
  EnterNewPIN: 'Enter New PIN',
  ConfirmPass: 'Confirm Password',
  ConfirmPIN: 'Confirm PIN',
  EnterConfirmPass: 'Enter Confirm Password',
  EnterConfirmPIN: 'Enter Confirm PIN',
  Clear: 'Clear',
  Save: 'Save',

  // PIN modal texts
  EnterPIN: 'Enter PIN',
  PleaseEnter: ' Please enter 4 digit PIN to authenticate your access.',
  Enter: 'Enter',
  Cancel: 'Cancel',
  Ok: 'OK',

  // createalert modal
  CreateAlert: 'Create Alert',
  SelectPara: 'Select Parameter',
  EnterAlert: 'Enter Alert Name',
  AlertCondition: 'Alert Condition',
  SelectCondition: 'Select Condition',
  AlertTriggerInteval: 'Alert Trigger Interval',
  SelectInterval: 'Select Interval',
  InstantAlert: 'Instant Alert',
  Notify: 'Notify By',
  Limit: 'Limit',
  Value: 'Value',
  EnterTxt: 'Enter Text',
  PushNotif: 'Push Notification - Web',
  Email: 'Email',
  CustAlertSave: 'Custom Alert Saved',
  DataSaved: 'Data has been stored successfully',
  Or: 'Or',

  // report module
  // Side filter
  Date: 'Date',
  DateRange: 'Date Range',
  FileName: 'File Name',
  Download: 'Download',
  SrNo: 'Sr. No.',
  MachineStopTime: 'Downtime Start',
  MachineStartTime: 'Downtime End',
  TotalDowntime: 'Total Downtime ',
  DowntimeReason: 'Downtime Reason',
  AssociatedAlerts: 'Associated Alerts',

  // HCM Reports
  onlyDowntime: 'Downtime',
  HCMDowntimeReport: 'HCM Downtime Report',
  Trends: 'Trends',
  hcmoverview: 'Downtime Overview',
  downtimeAnalysis: 'Downtime Analysis',
  trippingAnalysis: 'Tripping Analysis',
  MachineNo: 'Machine No',
  MonthWise: 'Month wise',
  MonthlyTotal: 'Monthly Total',
  MonthlyAvg: 'Monthly Avg',
  StandredDowntime: 'Standred Downtime',
  DowntimeCode: 'Downtime Code',
  DowntimeVariance: 'Downtime Variance',
  FaultCategory: 'Fault Category',
  Dipping: 'Dipping OFF Count',
  HCMNO: 'HCM NO',
  Shift: 'Shift',
  DowntimeTrends: 'Downtime Trends',
  DowntimeTable: 'Downtime Table',
  RemoteassistanceContacts: 'Contacts',
  RemoteassistanceCallhistory: 'Calls History',
  Y_starl: 'Y-Stral',

  // remote add edit contact  modal
  AddNewContact: 'Add New Contact',
  EditNewContact: 'Edit New Contact',
  OEMServiceSPOC: 'OEM Service SPOC',
  CustomerSPOC: 'Customer SPOC',
  EnterFirstName: 'Enter First Name',
  EnterLastName: 'Enter Last Name',
  EnterRole: 'Enter Role',
  EnterEmail: 'Enter Email ID',
  PrimaryMobile: 'Enter Primary Mobile Number',
  AlternateMobile: 'Enter Alternate Mobile Number',
  EnterMachineModel: 'Enter Machine Model',
  EnterOrgnization: 'Enter Orgnization',
  EnterPlant: 'Enter Plant',
  EnterSerialNumber: 'Enter Serial Number',
  Add: 'Add',
  NoMapRecord: 'No machine model is mapped for this contact',

  // Execution Result
  ExecutionResult: 'Execution Result',
  Machine_ID: 'Machine ID',
  ConditionId: 'ID',
  ConditionDescription: 'Condition Description',
  FormulaDescription: 'Formula Description',
  FormulaSubstitutedValue: 'Formula Substitue Value',
  isResolved: 'Resolved?',
  Resolved: 'Resolved',
  Name: 'Name',
  EnterName: 'Enter Name',
  Comments: 'Comments',
  EnterComments: 'Enter Comments',
  IssueResolution: 'Issue Resolution',

  // filter
  From: 'From',
  To: 'To',
  Status: 'Status',
  SelectStatus: 'Select Status',
  SelectMachineModel: 'Select Machine Model',

  // alert and notification,
  EmailandalertStatus: 'Email and SMS Alert Status',
  AlertnotificationTitle: 'Alert/Notification Log',
  ID: 'ID',
  ExecutionId: 'Execution ID',
  AlertType: 'Alert Type',
  Description: 'Description',
  AlertText: 'Alert Text',
  NotificationText: 'Notification Text',
  DeliveryStatus: 'Delivery Status',
  EmailTo: 'To Email',
  EmailCC: 'CC Email',
  PhoneNumber: 'Phone Numbers',
  Alert: 'Alert',
  Notification: 'Notification',
  Message: 'Message',
  SMS: 'SMS',
  Subject: 'Subject',

  // REPORTS
  MPR_BQS: 'MPR BQS',
  BQS_OEE: 'BQS OEE',
  MPR_Protab700: 'MPR Protab 700',
  BQSMachineOverview: 'BQS Machine Overview',
  BQSBatchwiseOEE: 'BQS Batchwise OEE',
  MPR_BQS_V_2: 'MPR-BQS V.2',
  BQS_OEE_V_2_0: 'BQS-OEE V-2.0',
  Machine_Overview_V_2_00: 'Machine Overview V-2.00',
  Production_Overview_V_2_00: 'Production Overview V-2.00',
  Production_Analysis_V_2_00: 'Production Analysis V-2.00',
};

export const LangGstrings = {
  welcome: 'herzlich willkommen',
  emailId: 'Email ID',
  password: 'Passwort',
  forgot: 'Passwort vergessen?',
  login: 'Anmeldung',
};
