import axios from 'axios';
import config from '../../config/api.config.json';
/* eslint  no-alert:0 */

const handelSave = (formData) => {
  const d = new Date();
  const dformat = `${[d.getMonth() + 1, d.getDate(), d.getFullYear()].join('-')} ${
    [d.getHours(), d.getMinutes(), d.getSeconds()].join(':')}`;
  const parseData = {
    Plant: formData.Plant,
    MachineNo: formData.Machine,
    Size: formData.Size,
    HCMSpeed: formData.HCMSpeed,
    DBCalibartionViscosity: formData.CapDBCalibartionViscosity === '' ? 0 : formData.CapDBCalibartionViscosity,
    DBAcutalViscosity: formData.CapDBAcutalViscosity === '' ? 0 : formData.CapDBAcutalViscosity,
    DBAcutalTemp: formData.CapDBAcutalTemp === '' ? 0 : formData.CapDBAcutalTemp,
    LubiricationOfTime: formData.CapLubiricationOfTime === '' ? 0 : formData.CapLubiricationOfTime,
    Distance1: formData.Distance1 === '' ? 0 : formData.Distance1,
    Distance2: formData.Distance2 === '' ? 0 : formData.Distance2,
    Distance3: formData.Distance3 === '' ? 0 : formData.Distance3,
    Distance4: formData.Distance4 === '' ? 0 : formData.Distance4,
    Distance5: formData.Distance5 === '' ? 0 : formData.Distance5,
    Distance6: formData.Distance6 === '' ? 0 : formData.Distance6,
    Time1: formData.Time1 === '' ? 0 : formData.Time1,
    Time2: formData.Time2 === '' ? 0 : formData.Time2,
    Time3: formData.Time3 === '' ? 0 : formData.Time3,
    Time4: formData.Time4 === '' ? 0 : formData.Time4,
    Time5: formData.Time5 === '' ? 0 : formData.Time5,
    Time6: formData.Time6 === '' ? 0 : formData.Time6,
    Time7: formData.Time7 === '' ? 0 : formData.Time7,
    PredictionResult: formData.result === '' ? 0 : formData.result,
    Model: formData.model,
    timestamp: formData.Timestap === '' ? dformat : formData.Timestamp,
  };
  const payload = {
    method: 'post',
    url: config.API_IOMATRIX_SAVE,
    data: parseData,
  };
  console.log(payload);
  axios(payload).then((response) => {
    console.log(response);
    alert('Data Save Successfully');
  })
    .catch((error) => {
      alert('Error in  Data Saving Try after Sometime');
      console.log(error.message);
    });
};
export default handelSave;
