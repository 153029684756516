import React, {
  useState, useEffect, useContext, Fragment, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { LanguageContext } from '../../../../../LanguageContext';
import { fetchFailureModeImportAsync, getFMEAImportNotificationAsync } from '../../../../../redux/slices/ImportFMEAListingSlice';
import { downloadImportedFile, downloadTemplateFile } from './ImportFMEAListingAPI';
import { fetchMachineModelAsync } from '../../../../../redux/slices/failureModeAddSlice';
// import PaginationTab from '../../../../components/dashboard/common/PaginationTab';
// import CircularIndeterminate from '../../../../components/dashboard/common/CircularIndeterminate';
import PaginationTab from '../../../../../common/PaginationTab/PaginationTab';
import CircularIndeterminate from '../../../../../common/CircularIndeterminate';
import Addimportfmea from './addimportfmea';
import NotificationPanel from './notification/NotificationPanel';
// import { ReactComponent as Download } from '../../common/download.svg';
// import { ReactComponent as Upload } from '../../common/upload.svg';
// import Icon from '../../../../Dashboard/Sidebar/images/Icon';
import 'antd/dist/antd.css';
import 'antd/dist/antd.dark.css';
import '../../../../../styles/failureMode.css';
/* eslint no-nested-ternary:0 */
/* eslint max-len:0 */

const ImportFMEATable = () => {
  const dispatch = useDispatch();
  // const history = useHistory();

  const defaultCardCount = 15;
  const node = useRef();
  const [isNotificationActive, setNotificationActive] = useState(false);
  const [page, setPage] = useState(1);
  const [size] = useState(defaultCardCount);
  const [search, setSearch] = useState('');
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(1);
  const [showAddEntry, setShowAddEntry] = useState(false);
  const [Lang] = useContext(LanguageContext);

  // const required = (value) => (value ? undefined : 'Required');

  useEffect(() => {
    dispatch(fetchFailureModeImportAsync({ page, size, search }));
  }, [page, size, search]);

  const notificationList = useSelector((state) => state.failureModeImport.fmeanotifications);

  const count = useSelector((state) => state.failureModeImport.failureModeImports.Count);

  const listData = useSelector((state) => state.failureModeImport.failureModeImports.Details);

  useEffect(() => {
    dispatch(getFMEAImportNotificationAsync());
    dispatch(fetchMachineModelAsync());
  }, []);

  const machineModels = useSelector(
    (state) => state.failureModeEntry.machineModels,
  );
  // let start = 1, end = 5;
  useEffect(() => {
    const st = (page - 1) * size + 1;
    const en = st + (size - 1) > count ? count : st + (size - 1);
    // let en = st + size - 1;
    setStart(st);
    setEnd(en);
  }, [page, size, count]);

  const onSearchChange = debounce((e) => {
    setPage(1);
    setSearch(e.target.value);
  }, 500);

  const onSortHeaderClick = (fieldName) => {
    console.log(fieldName);
  };

  const onPrevious = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  const onNext = (pageNumber) => {
    setPage(pageNumber + 1);
  };

  const onDownloadClicked = (id) => {
    downloadImportedFile(id);
  };
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
    hour12: false,
  };
  // const dateFormatter = (date, payload) => {
  const dateFormatter = (date) => {
    // return moment(date).unix();
    const temp = new Date(date);
    return temp.toLocaleString('en-GB', options);
  };
  const handleNotificationPanel = () => {
    setNotificationActive(!isNotificationActive);
  };

  // const handleClickRef = (e) => {
  //     if (!node.current.contains(e.target)) {
  //         setNotificationActive(false);
  //     }
  // };

  // useEffect(() => {
  //     document.addEventListener("mousedown", handleClickRef);
  //     return () => {
  //         document.removeEventListener("mousedown", handleClickRef);
  //     };
  // }, []);

  const getNotificationRowDisplay = (notification) => `${notification.AlertNotificationLog_ID} : ${notification.CommunicationType === 'E' ? notification.MessageText_Email : notification.MessageText_SMS}`;
  const isLoading = false;
  return (
    <>
      <div className="alarmBar padTopOnly row dropRow failuremodulePage importfmea">
        <div className="input-group pull-right search-container">
          <svg className="searchIconGlobalSvg" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="searchP1" fillRule="evenodd" clipRule="evenodd" d="M6.125 2.82751C4.192 2.82751 2.625 4.39452 2.625 6.32751C2.625 8.26051 4.192 9.82751 6.125 9.82751C8.058 9.82751 9.625 8.26051 9.625 6.32751C9.625 4.39452 8.058 2.82751 6.125 2.82751ZM1.625 6.32751C1.625 3.84223 3.63972 1.82751 6.125 1.82751C8.61028 1.82751 10.625 3.84223 10.625 6.32751C10.625 8.8128 8.61028 10.8275 6.125 10.8275C3.63972 10.8275 1.625 8.8128 1.625 6.32751Z" fill="#b7b7b7" />
            <path className="searchP2" fillRule="evenodd" clipRule="evenodd" d="M8.5964 8.79896C8.79166 8.6037 9.10824 8.6037 9.3035 8.79896L11.4785 10.974C11.6738 11.1692 11.6738 11.4858 11.4785 11.6811C11.2832 11.8763 10.9667 11.8763 10.7714 11.6811L8.5964 9.50607C8.40114 9.3108 8.40114 8.99422 8.5964 8.79896Z" fill="#b7b7b7" />
          </svg>
          <input
            // className="form-control py-2"
            type="search"
            className="form-control py-2 customForm input-md search-box"
            placeholder="Search by File Name and Machine Model"
            onChange={(e) => onSearchChange(e.nativeEvent)}
          />
        </div>
        <div className="col-sm-4 importExcelBtn pull-right">
          <button type="button" className="pull-right btn btn-secondary add-entry-button" onClick={() => downloadTemplateFile()}>
            {/* <Icon name="DownloadIcon" /> */}
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5254 8.35254V10.3525C10.5254 10.6178 10.42 10.8721 10.2325 11.0596C10.045 11.2472 9.79061 11.3525 9.52539 11.3525H2.52539C2.26017 11.3525 2.00582 11.2472 1.81828 11.0596C1.63075 10.8721 1.52539 10.6178 1.52539 10.3525V8.35254" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M3.52539 5.85254L6.02539 8.35254L8.52539 5.85254" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M6.02539 8.35254V2.35254" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Download Template
          </button>
          <button type="button" className="pull-right btn btn-secondary add-entry-button" onClick={() => setShowAddEntry(true)}>
            {/* <Icon name="UploadIcon" /> */}
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 10V12.6667C14.5 13.0203 14.3595 13.3594 14.1095 13.6095C13.8594 13.8595 13.5203 14 13.1667 14H3.83333C3.47971 14 3.14057 13.8595 2.89052 13.6095C2.64048 13.3594 2.5 13.0203 2.5 12.6667V10" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11.8327 5.33333L8.49935 2L5.16602 5.33333" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8.5 2V10" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Upload Excel
          </button>
        </div>
        {false && (
          <div className={`dropdown notificationicon liItem1 ${isNotificationActive ? 'open' : ''} notification-icon`} ref={node}>
            <a className={`dropdown-toggle pointer ${isNotificationActive ? 'open' : ''}`} data-toggle="dropdown">
              <span
                onClick={handleNotificationPanel}
                className={`fa-stack fa-1x bellSvg ${notificationList.length !== 0 ? ' bluebellIcon' : ''}`}
              >
                {notificationList.length === 0 && isNotificationActive === false ? (
                  <div className="withoutCount bell">
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.36774 1.17095C8.49907 1.0449 8.67719 0.974088 8.86292 0.974088H9.13695C9.32268 0.974088 9.5008 1.0449 9.63213 1.17095C9.76346 1.297 9.83724 1.46795 9.83724 1.64621V2.08455H9.64081C9.42537 2.06776 9.20854 2.06119 8.99085 2.06507C8.8105 2.06519 8.63168 2.07176 8.45467 2.08455H8.16263V1.64621C8.16263 1.46795 8.23641 1.29699 8.36774 1.17095ZM7.14771 2.29791V1.64621C7.14771 1.20961 7.32842 0.790888 7.65008 0.482164C7.97175 0.173439 8.40802 0 8.86292 0H9.13695C9.59185 0 10.0281 0.17344 10.3498 0.482164C10.6714 0.790888 10.8522 1.20961 10.8522 1.64621V2.28961C11.9525 2.57887 12.9869 3.14004 13.8794 3.9371C14.5068 4.48362 15.0123 5.18012 15.364 5.97062C15.7161 6.76222 15.9066 7.63151 15.9239 8.51413L15.924 8.52328L15.9239 13.9383L17.7139 16.9929C17.8022 17.1437 17.8006 17.3282 17.7096 17.4775C17.6186 17.6269 17.4518 17.7187 17.2715 17.7187H12.1599C11.9977 18.3383 11.6482 18.8985 11.1501 19.3134C10.5498 19.8133 9.79634 20 8.99998 20C8.18299 20 7.45017 19.8133 6.84987 19.3134C6.35171 18.8985 6.00229 18.3383 5.84002 17.7187H0.728406C0.548308 17.7187 0.381702 17.6271 0.290623 17.4779C0.199543 17.3288 0.197637 17.1445 0.285614 16.9937L2.06755 13.9388V8.52328C2.06755 5.53957 4.22955 3.05001 7.14771 2.29791ZM8.49341 3.05864C5.45413 3.29539 3.08246 5.66088 3.08246 8.52328V14.0658C3.08246 14.1491 3.0602 14.2311 3.0178 14.3038L1.59407 16.7446H6.2547L6.26288 16.7445H11.7371L11.7453 16.7446H16.4039L14.9742 14.3046C14.9315 14.2317 14.909 14.1495 14.909 14.0658V8.52796C14.8936 7.76796 14.7291 7.02432 14.4305 6.35309C14.1314 5.68052 13.7071 5.10195 13.1952 4.6566L13.1901 4.65218C12.1411 3.71448 10.8899 3.16826 9.59731 3.05864H8.49341ZM7.51501 18.5777C7.24305 18.3512 7.0317 18.0549 6.90116 17.7187H11.0988C10.9683 18.0549 10.7569 18.3512 10.4849 18.5777C10.0757 18.9184 9.4698 19.0263 8.94911 19.0263C8.91529 19.0231 8.98294 19.0231 8.94911 19.0263C8.42843 19.0263 7.92422 18.9184 7.51501 18.5777Z"
                        fill="#8F8F8F"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="withCount bell">
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.14942 2.29791V1.64621C7.14942 1.20961 7.33013 0.790888 7.65179 0.482164C7.97346 0.173439 8.40973 0 8.86463 0H9.13866C9.59356 0 10.0298 0.17344 10.3515 0.482164C10.6732 0.790888 10.8539 1.20961 10.8539 1.64621V2.28961C11.9542 2.57887 12.9886 3.14004 13.8811 3.9371C14.5085 4.48362 15.014 5.18012 15.3657 5.97062C15.7178 6.76222 15.9083 7.63151 15.9256 8.51413L15.9257 8.52328L15.9257 13.9383L17.7156 16.9929C17.8039 17.1437 17.8023 17.3282 17.7113 17.4775C17.6203 17.6269 17.4535 17.7187 17.2733 17.7187H12.1616C11.9994 18.3383 11.65 18.8985 11.1518 19.3134C10.5515 19.8133 9.79805 20 9.00169 20C8.1847 20 7.45188 19.8133 6.85158 19.3134C6.35342 18.8985 6.004 18.3383 5.84173 17.7187H0.730115C0.550017 17.7187 0.383411 17.6271 0.292332 17.4779C0.201252 17.3288 0.199346 17.1445 0.287323 16.9937L2.06925 13.9388V8.52328C2.06925 5.53957 4.23126 3.05001 7.14942 2.29791Z"
                        fill="#5D97F6"
                      />
                    </svg>
                  </div>
                )}
                {notificationList.length > 0
                  && isNotificationActive === false ? (
                    <span className="alertNumber">
                      {notificationList.length > 99
                        ? '99+'
                        : notificationList.length}
                    </span>
                  ) : null}
              </span>
              <NotificationPanel
                className="notification panel"
                handleNotification={handleNotificationPanel}
                notificationList={notificationList}
                getDisplayLabel={getNotificationRowDisplay}
              />
            </a>
          </div>
        )}
      </div>
      <div className="alertDivsBg excelimport">
        <div className="alertDivs">
          <div className="headerTable">
            <span className="row-0" onClick={() => onSortHeaderClick('File_Name')}>File Name</span>
            <span className="row-1" onClick={() => onSortHeaderClick('MachineModel_Name')}>
              {Lang.MachineModel}
            </span>
            {/* <span className="row-2" onClick={() => onSortHeaderClick("Uploaded_By")}>Uploaded By</span> */}
            <span className="row-2" onClick={() => onSortHeaderClick('Uploaded_On')}>Uploaded On</span>
            <span className="row-3" onClick={() => onSortHeaderClick('LastProceed On')}>Last Processed On</span>
            <span className="row-4" onClick={() => onSortHeaderClick('Status')}>Status</span>
            <span className="row-5">Download File</span>

          </div>
          {!isLoading && listData && listData.length > 0 && (
            <div
              className="pannelWrap overflowScrolls common-scroll"
              id="pannelWrap"
            >
              <div className="bs-example">
                <div className="panel-group" id="accordion1">
                  {
                    listData.map((item) => (
                      <div className="panel panel-default alarmRows" key={item.FailureNotification_ID}>
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <div>
                              <span className="row-0">{item.FileName}</span>
                              <span className="row-1">{machineModels.find((e) => e.MachineModel_ID === item.MachineModel_ID) ? machineModels.find((e) => e.MachineModel_ID === item.MachineModel_ID).MachineModel_Name : ''}</span>
                              {/* <span className="row-2">{item.CreatedUser_ID}</span> */}
                              <span className="row-2">{dateFormatter(item.UploadedOn)}</span>
                              <span className="row-3">{dateFormatter(item.LastProcessedOn)}</span>
                              <span className="row-4">{item.Status === 'Y' ? 'Yet To Start' : item.Status === 'I' ? 'In Progress' : item.Status === 'C' ? 'Completed' : item.Status === 'E' ? 'Completed with Errors' : item.Status === 'F' ? 'Interrupted' : ''}</span>
                              <span className="row-5">
                                {' '}
                                <a href="#" onClick={() => { if (item.Status !== 'I' && item.Status !== 'Y' && item.Status !== 'F') onDownloadClicked(item.FailureModeImport_ID); }} className={item.Status === 'I' || item.Status === 'Y' ? 'disabled' : ''} disabled={item.Status !== 'C' && item.Status !== 'E'}> Download File</a>
                              </span>
                            </div>
                          </h4>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          )}

          {isLoading && (
            <div className="pannelWrap overflowScrolls" id="pannelWrap">
              <div
                className="loaderHistoryAlarms"
                style={{ marginTop: '100px' }}
              >
                <CircularIndeterminate />
              </div>
            </div>
          )}

          {!isLoading && (listData === undefined || listData.length === 0) && (
            <div className="noHistoryAlarms">{Lang.NoRecord}</div>
          )}
        </div>
        {listData && (count > defaultCardCount)
          && (
            <div
              className={`
            alarm-pagination
            ${count <= defaultCardCount
                ? 'cardBelowNine'
                : 'float-container'
                }`}
            >
              <div className="panellefttext">
                <div className="shownotext">
                  {` ${start} to ${end} of ${count} `}
                </div>
              </div>
              <div className="panelrighttext">
                <PaginationTab
                  Previous={onPrevious}
                  Next={onNext}
                  page={page}
                  maxPage={Math.ceil(count / size)}
                />
              </div>
            </div>
          )}
      </div>
      {showAddEntry && (
        <Addimportfmea
          onSaved={() => {
            setPage(1);
            setShowAddEntry(false);
            setTimeout(() => {
              setPage(2);
              setTimeout(() => { setPage(1); }, 500);
            }, 1000);
          }}
          onClosed={() => { setShowAddEntry(false); }}
        />
      )}
    </>
  );
};

export default ImportFMEATable;
