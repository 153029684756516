import React from 'react';
import './ftr.css';
import Styles from './FTRUpload.module.css';
/* eslint  react/prop-types:0 */
/* eslint jsx-a11y/label-has-associated-control:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */

function Estimated({ fomdata }) {
  const {
    detailTitle,
    ftrInput,
    ftrTable,
  } = Styles;
  //
  return (
    <div>
      <label htmlFor="table" className={`${detailTitle}`}>Estimated Setting</label>
      <table id="table" className={`${ftrTable}`}>
        <thead>
          <tr>
            <th />
            <th>Viscosity Ink</th>
            <th>Printing GS</th>
            <th>Printing OS</th>
            <th>Blade Pressure</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cap:</td>
            <td>
              <input
                type="text"
                className={`${ftrInput}`}
                disabled
                name="previscosityInkcolor"
                value={fomdata.previscosityInkcolor}
              />
            </td>
            <td>
              <input
                type="number"
                className={`${ftrInput}`}
                disabled
                name="preaprintinghead1gscap"
                value={fomdata.preaprintinghead1gscap}
              />
            </td>
            <td>
              <input
                type="text"
                className={`${ftrInput}`}
                disabled
                name="preaprintinghead1oscap"
                value={fomdata.preaprintinghead1oscap}
              />
            </td>
            <td>
              <input
                type="text"
                className={`${ftrInput}`}
                disabled
                name="preabladeprecap"
                value={fomdata.preabladeprecap}
              />
            </td>
            <td />
          </tr>
          <tr>
            <td>Body :</td>
            <td>
              <input
                type="text"
                className={`${ftrInput}`}
                disabled
                name="preaviscosityInkbody"
                value={fomdata.preaviscosityInkbody}
              />
            </td>
            <td>
              <input
                type="number"
                className={`${ftrInput}`}
                disabled
                name="preaprintinghead1gsbody"
                value={fomdata.preaprintinghead1gsbody}
              />
            </td>
            <td>
              <input
                type="number"
                className={`${ftrInput}`}
                disabled
                name="preaprintinghead1osbody"
                value={fomdata.preaprintinghead1osbody}
              />
            </td>
            <td>
              <input
                type="text"
                className={`${ftrInput}`}
                disabled
                name="preabladeprebody"
                value={fomdata.preabladeprebody}
              />
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Estimated;
