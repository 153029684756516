import React, { useEffect } from 'react';
import './PaginationTab.css';
import { Tooltip } from '@material-ui/core';
// import { ThemeContext } from '../../../ThemeContext';
import { useStyles } from '../popupStyles';
/* eslint react/destructuring-assignment: 0 */
const PaginationTab = (props) => {
  // const { currentTheme, setCurrentTheme } = useContext(ThemeContext);
  const currentTheme = 'dark';
  const popupStyles = useStyles({ currentTheme });
  useEffect(() => {}, [props.page, props.maxPage]);

  return (
    <>
      <div className="comanPagination">
        <div className="pagination-tab text-center">
          <ul className="pagination">
            <li
              className={`${
                props.page > 1 ? 'selected' : 'staticLi'
              } firstPage`}
            >
              <a href="#" aria-label="First">
                <div className="alarmToolTip">
                  <Tooltip
                    classes={{
                      tooltip: popupStyles.tooltipPopper,
                      arrow: popupStyles.arrow,
                      tooltipArrow: popupStyles.tooltipArrow,
                    }}
                    title={(
                      <div className={popupStyles.tooltipDiv}>
                        First
                      </div>
                    )}
                    placement="top"
                    arrow
                  >
                    <span
                      className="firstPage navArrow"
                      onClick={() => {
                        if (props.page > 1) props.Previous(2);
                      }}
                    >
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.3359 3L7.33594 6L10.3359 9"
                          stroke="#8F8F8F"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.33594 3L3.33594 6L6.33594 9"
                          stroke="#8F8F8F"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Tooltip>
                  {/* <span className="pageToolTipText">First</span> */}
                </div>
              </a>
            </li>
            <li className={`${props.page > 1 ? 'selected' : 'staticLi'}`}>
              <a href="#" aria-label="Previous">
                <div className="alarmToolTip">
                  <Tooltip
                    classes={{
                      tooltip: popupStyles.tooltipPopper,
                      arrow: popupStyles.arrow,
                      tooltipArrow: popupStyles.tooltipArrow,
                    }}
                    title={(
                      <div className={popupStyles.tooltipDiv}>
                        Previous
                      </div>
                    )}
                    placement="top"
                    arrow
                  >
                    <span
                      className="previous navArrow"
                      onClick={() => {
                        if (props.page > 1) props.Previous(props.page);
                      }}
                    >
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.33594 3L5.33594 6L8.33594 9"
                          stroke="#8F8F8F"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Tooltip>
                  {/* <span className="pageToolTipText">Previous</span> */}
                </div>
              </a>
            </li>
            <li className="staticLi">
              <span className="active">{props.page}</span>
              <span>of</span>
              <span>{props.maxPage}</span>
            </li>
            <li
              className={`${
                props.page < props.maxPage ? 'selected' : 'staticLi'
              }`}
            >
              <a href="#" aria-label="Next">
                <div className="alarmToolTip">
                  <Tooltip
                    classes={{
                      tooltip: popupStyles.tooltipPopper,
                      arrow: popupStyles.arrow,
                      tooltipArrow: popupStyles.tooltipArrow,
                    }}
                    title={(
                      <div className={popupStyles.tooltipDiv}>
                        Next
                      </div>
                    )}
                    placement="top"
                    arrow
                  >
                    <span
                      className="next navArrow"
                      onClick={() => {
                        if (props.page < props.maxPage) props.Next(props.page);
                      }}
                    >
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.49219 9L8.49219 6L5.49219 3"
                          stroke="#8F8F8F"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Tooltip>
                  {/* <span className="pageToolTipText">Next</span> */}
                </div>
              </a>
            </li>
            <li
              className={`${
                props.page < props.maxPage ? 'selected' : 'staticLi'
              }`}
            >
              <a href="#" aria-label="Last">
                <div className="alarmToolTip">
                  <Tooltip
                    classes={{
                      tooltip: popupStyles.tooltipPopper,
                      arrow: popupStyles.arrow,
                      tooltipArrow: popupStyles.tooltipArrow,
                    }}
                    title={(
                      <div className={popupStyles.tooltipDiv}>
                        Last
                      </div>
                    )}
                    placement="top"
                    arrow
                  >
                    <span
                      className="lastPage navArrow"
                      onClick={() => {
                        if (props.page < props.maxPage) props.Next(props.maxPage - 1);
                      }}
                    >
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.49219 9L6.49219 6L3.49219 3"
                          stroke="#8F8F8F"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.49219 9L10.4922 6L7.49219 3"
                          stroke="#8F8F8F"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Tooltip>
                  {/* <span className="pageToolTipText">Last</span> */}
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PaginationTab;
