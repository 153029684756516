import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAlertNotificationList, fetchAlertNotificationById } from '../../components/ConditionMonitoring/alerts-notifications/alertnotificationAPI';

const initialState = {
  alertNotificationList: [],
  alertNotificationListStatus: 'idle',
};

export const fetchAlertNotificationListAsync = createAsyncThunk(
  'alertNotificationLog/getAlertNotificationList',
  async ({
    search, page, size, name, from, to, comType, comMode,
  }) => {
    if (comType) {
      comType = comType === 'Alert' ? 'A' : 'N';
    }
    if (comMode) {
      comMode = comMode === 'Email' ? 'E' : 'S';
    }
    const result = await getAlertNotificationList(search, page, size, name, from, to, comType, comMode);
    return result;
  },
);

export const fetchAlertNotificationByIdAsync = createAsyncThunk(
  'alertNotificationLog/getAlertNotificationById',
  async ({ id }) => {
    const result = await fetchAlertNotificationById(id);
    return result;
  },
);

export const alertNotificationList = createSlice({
  name: 'alertNotificationList',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlertNotificationListAsync.pending, (state) => {
        state.alertNotificationListStatus = 'loading';
      })
      .addCase(fetchAlertNotificationListAsync.fulfilled, (state, action) => {
        state.alertNotificationList = action.payload;
        state.alertNotificationListStatus = 'idle';
      });
  },
});

export default alertNotificationList.reducer;
