import React, { useState, useContext } from 'react';
import {
  Checkbox, ListItemIcon, ListItemText, Select, MenuItem, Dialog, Radio,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
/* eslint max-len:0 */
/* eslint no-unused-expressions:0 */
/* eslint consistent-return:0 */
/* eslint no-unused-vars:0 */
/* eslint no-shadow:0 */

import { LanguageContext } from '../../../../LanguageContext';
import DropdownFilter from '../DropdownFilter';
// import DropdownFilter from '../../../Reports/RSD/DropdownFilter';
import { mobileNoValRegex, nameValRegex, emailValidateRegex } from '../../../../helpers/constants';
import '../../Condition-Monitoring/condition-monitoring.css';
import './EditAlertModal.css';

const EditGroupDetailsModal = ({
  groupDetails, onSaved, onClosed, needsUpdate, roles, selecRoles,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('');
  const [errorTxt, setErrorTxtValue] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [errorFields, setErrorFields] = useState([]);

  // const rolesArr = roles.map((e) => e.Role_Description);
  const rolesKeyArr = roles.map((e) => e.Role_ID);
  const [Lang] = useContext(LanguageContext);
  if (needsUpdate.value) {
    // setSelectedRoles(roles.filter((e) => selecRoles.indexOf(e.Role_ID) !== -1));
    setSelectedRoles(roles.filter((e) => groupDetails?.Role_ID === e.Role_ID));
    setName(groupDetails.Name);
    setEmail(groupDetails.EmailID);
    setPhoneNumber(groupDetails.PhoneNumber);
    console.log('type', groupDetails.Type);
    if (groupDetails.Type === 'U') setType('Contact');
    else {
      setType('Role');
      let roleIndex = rolesKeyArr.indexOf(groupDetails.Role_ID);
      roleIndex = roleIndex === -1 ? 0 : roleIndex;
      console.log(roleIndex);
      // setRole(roleIndex + 1);
    }
    needsUpdate.value = false;
  }

  const onNameChanged = (e) => {
    setName(e.target.value);
  };
  const onEmailChanged = (e) => {
    setEmail(e.target.value);
  };

  const onTypeChanged = (value) => {
    setErrorFields([]);
    setErrorTxtValue('');
    setType(value);
  };

  const validatePhonenumber = (inputtxt) => String(inputtxt).toLowerCase().match(mobileNoValRegex);

  const validateName = (inputTxt) => String(inputTxt).toLowerCase().match(nameValRegex);

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(emailValidateRegex);

  const onSaveBtnClicked = async () => {
    const errorFields = [];
    let errorString = '';
    if (type === 'Role') {
      if (selectedRoles.length === 0) {
        errorFields.push('role');
        setErrorFields(errorFields);
        setErrorTxtValue(errorString);
        return;
      }
      const res = onSaved(groupDetails, 'R', selectedRoles);
      if (!res.Result) setErrorTxtValue(res.Message);
      return;
    }
    if (!name || !validateName(name)) {
      errorFields.push('name');
      errorString = 'Please enter valid name';
    }
    if (!email) {
      errorFields.push('email');
      // errorString = "Email is Mandatory";
    } else if (!validateEmail(email)) {
      errorFields.push('email');
      errorString = 'Please enter valid email address.';
    } else if (phoneNumber && !validatePhonenumber(phoneNumber)) {
      errorFields.push('phoneNumber');
      errorString = 'Please enter valid phone number.';
    }
    setErrorFields(errorFields);
    setErrorTxtValue(errorString);
    if (errorString || errorFields.length > 0) {
      return;
    }
    const res = await onSaved(groupDetails, 'U', name, email, phoneNumber);
    if (!res.Result) setErrorTxtValue(res.Message);
  };

  const handleChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const Types = ['Contact', 'Role'];
  const typeDropDownValues = Types.map((val) => ({ Name: val }));
  if (groupDetails.Type && !type) {
    setType(groupDetails.Type === 'U' ? 'Contact' : 'Role');
    console.log('groupDetails-if', groupDetails.Type);
  } else if (Types.indexOf(type) === -1) {
    console.log('indexxx-else', Types.indexOf(type));
    setType(Types[0]);
    return;
  }
  const style = { modelBox: { height: 'auto', width: '40rem' } };

  const onRolesChanged = (e) => {
    const ids = e.target.value;
    // const selected = roles.filter((n) => ids.find((id) => +n.Role_ID === +id));
    const selected = roles.filter((n) => +n.Role_ID === +ids);
    if (selected.length > 0 && errorFields.indexOf('role') !== -1) setErrorFields([]);
    if (JSON.stringify(selected) !== JSON.stringify(selectedRoles)) setSelectedRoles(selected);
  };
  return (
    <Dialog open disableEnforceFocus>
      <div className="modelboxopen" ref={(e) => { e && e.style.setProperty('z-index', 1000, 'important'); }}>
        <div className="modelboxopenInnner" style={style.modelBox} id="notification-dialog">
          <div className="wrapContent">
            <div className="containerIner2">
              <div className="popupCloseButton bbt">
                <h3 className="titleDiv">Contact Details</h3>
                <div
                  className="closepopup pointer"
                  onClick={onClosed}
                >
                  &#x2715;
                </div>
              </div>
              <div className="contentPopup">
                <div className="innerContentPopup">
                  <div className="descriptionCreate notification-group conditionMonitoring">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group formgroup">
                          {/* <div className="newPin">{Lang.PleaseEnter}</div> */}
                          <div className="font14">
                            <div className="comboDiv input-control">
                              <label className="font12">Type</label>
                              <DropdownFilter
                                filterOptions={typeDropDownValues}
                                setFilter={() => { }}
                                currentState={Types.indexOf(type) + 1}
                                setFilterVal={onTypeChanged}
                              />
                              {console.log('roleType', Types.indexOf(type) + 1)}
                            </div>
                            {console.log('type000', type, 'typeof', typeof type, 'typess[0]', Types[0], 'typrOf', typeof Types[0])}
                            {type !== Types[0]
                              ? (
                                <div className="input-control noti-role">
                                  <label className="font12">Role</label>
                                  <span className="redStar">*</span>
                                  {roles && roles.length > 0
                                    ? (
                                      <div id="edit-notification-group-role-field">
                                        <Form
                                          onSubmit={() => { }}
                                          // render={({ handleSubmit, form }) => (
                                          render={({ form }) => (
                                            <Field
                                              name="To"
                                              className="form-control"
                                          // component={Select}
                                          // validate={required}
                                              placeholder="Select"
                                            >
                                              {(props) => (
                                                <Select
                                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                                  {...props.input}
                                                  className={`rol form-control ${props.input.value === '' ? 'placeholder' : ''} ${(errorFields.indexOf('role') !== -1 ? ' error-field' : '')}`}
                                              // disabled={disable}
                                                  // multiple
                                                  value={selectedRoles.map((e) => e.Role_ID)}
                                                  onChange={onRolesChanged}
                                                  renderValue={() => selectedRoles.map((s) => s.Role_Description).join(', ')}
                                                >
                                                  {roles && (roles.length && roles.filter((el) => (selecRoles || []).indexOf(el.Role_ID) === -1)).map((m) => (
                                                    <MenuItem key={m.Role_ID} value={m.Role_ID}>
                                                      <ListItemIcon>
                                                        {/* <Checkbox checked={selectedRoles.map((e) => e.Role_ID).indexOf(m.Role_ID) > -1} /> */}
                                                        <Radio
                                                          checked={selectedRoles.map((e) => e.Role_ID).indexOf(m.Role_ID) > -1}
                                                          onChange={handleChange}
                                                          value={m.Role_ID}
                                                          name="radio-buttons"
                                                          className="role-radio"
                                                        />
                                                      </ListItemIcon>
                                                      <ListItemText primary={m.Role_Description} />
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                              )}
                                            </Field>
                                          )}
                                        />

                                        {/* <FormControl >
                                      <Select
                                        className={`form-control`}
                                        //disabled={disable}
                                        multiple
                                        value={[]}
                                        onChange={() => { }}
                                        menuportaltarget={document.body}
                                        style={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        renderValue={() => "aasd"}
                                      >
                                        {roles &&
                                          roles.map((m) => (
                                            <MenuItem key={m.Role_ID} value={m.Role_ID}>
                                              <ListItemIcon>
                                                <Checkbox checked={1 > -1} />
                                              </ListItemIcon>
                                              <ListItemText primary={m.Role_Description} />
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl> */}
                                      </div>
                                    )

                                    : <label className="font12">No Roles available.</label>}

                                </div>
                              )
                              : (
                                <div id="edit-notification-group-fields">
                                  <div className="row-md-16">
                                    <div className="form-group">
                                      <label className="font12">Name</label>
                                      <span className="redStar">*</span>
                                      <input
                                        id="textbox1"
                                        name="textbox1"
                                        type="text"
                                        placeholder="Enter Name"
                                        onChange={onNameChanged}
                                        value={name}
                                        className={`form-control customForm input-md${errorFields.indexOf('name') !== -1 ? ' error-field' : ''}`}
                                      />
                                    </div>
                                  </div>
                                  <div className="row-md-16">
                                    <div className="form-group">
                                      <label className="font12">Email ID</label>
                                      <span className="redStar">*</span>
                                      <input
                                        id="textbox1"
                                        name="textbox1"
                                        type="text"
                                        placeholder="Enter Email ID"
                                        onChange={onEmailChanged}
                                        value={email}
                                        className={`form-control customForm input-md${errorFields.indexOf('email') !== -1 ? ' error-field' : ''}`}
                                      />
                                    </div>
                                  </div>
                                  <div className="row-md-16">
                                    <div className="form-group">
                                      <label className="font12">Phone Number</label>
                                      <input
                                        id="textbox1"
                                        name="textbox1"
                                        type="text"
                                        placeholder="Enter Phone Number"
                                        onChange={handleChange}
                                        value={phoneNumber}
                                        className={`form-control customForm input-md${errorFields.indexOf('phoneNumber') !== -1 ? ' error-field' : ''}`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="enterpins placeColor">
                            <div className="pinErrorTxt">{errorTxt}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footerPopupWithBtn">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        type="button"
                        id="cancelBtn"
                        name="cancel"
                        className="btn btn-default cancelBtn"
                        value="1"
                        onClick={onClosed}
                      >
                        {Lang.Cancel}
                      </button>
                      <button
                        type="button"
                        id="saveBtn"
                        name="Save"
                        className="btn btn-default saveBtn"
                        value="1"
                        onClick={onSaveBtnClicked}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditGroupDetailsModal;
