import './style.css';

const Popup = ({ handleClose, className, children }) => (
  <>
    <div className={`popup ${className}`}>
      <div className="popup__head">
        <span className="popup_title">Edit Energy Meter</span>
        <span className="popup__close" onClick={handleClose}>X</span>
      </div>
      <div className="popup__body">
        {children}
      </div>
    </div>
    <div className="bg-layer" onClick={handleClose} />
  </>
);
export default Popup;
