import API from './API.config.json';

const {
  LoadMachine,
  LoadMachineModel,
  LoadMachineOnboard,
  LoadMachineParameters,
  LoadMachModParameters,
  LoadPlant,
  LoadPlantLineNo,
  LoadRemoteCallFavoriteUser,
  LoadRemoteCallUser,
  LoadRemoteCallUserCalledHistory,
  LoadRemoteCallUserMapping,
  LoadRole,
  LoadRoleReportMapping,
  LoadUser,
  LoadUserAccess,
  LoadCustomer,
  LoadModule,
  LoadReports,
  LoadLocation,
  LoadMenus,
  CustMenuMapping,
  CustReportMapping,
  LoadRoleMenuMapping,
  LoadEMPlant,
  LoadEMPlantGroup,
  LoadEMPlantSubGroup,
  LoadEmailFlow,
  LoadEmailFlowMapping,
} = API;

const SelectMenus = [
  {
    Value: 'User',
    Label: 'User',
    API: LoadUser,
    AllowMenu: true,
  },
  {
    Value: 'Menus',
    Label: 'Menus',
    API: LoadMenus,
    AllowMenu: true,
  },
  {
    Value: 'Reports',
    Label: 'Reports',
    API: LoadReports,
    AllowMenu: true,
  },
  {
    Value: 'Plants',
    Label: 'Plants',
    API: LoadPlant,
    AllowMenu: true,
  },
  {
    Value: 'LoadCustomer',
    Label: 'Customer',
    API: LoadCustomer,
    AllowMenu: true,
  },
  {
    Value: 'LoadLocation',
    Label: 'Locations',
    API: LoadLocation,
    AllowMenu: true,
  },
  {
    Value: 'LoadMachine',
    Label: 'Machines',
    API: LoadMachine,
    AllowMenu: true,
  },
  {
    Value: 'LoadModule',
    Label: 'Moduels',
    API: LoadModule,
    AllowMenu: true,
  },
  {
    Value: 'LoadMachineModel',
    Label: 'Machine Models',
    API: LoadMachineModel,
    AllowMenu: true,
  },
  {
    Value: 'LoadMachineOnboard',
    Label: 'Machine Onboard',
    API: LoadMachineOnboard,
    AllowMenu: false,
  },
  {
    Value: 'LoadMachineParameters',
    Label: 'Machine Parameters',
    API: LoadMachineParameters,
    AllowMenu: false,
  },
  {
    Value: 'LoadMachModParameters',
    Label: 'Machine Mod Parameters',
    API: LoadMachModParameters,
    AllowMenu: false,
  },
  {
    Value: 'LoadPlantLineNo',
    Label: 'Plant Line No.',
    API: LoadPlantLineNo,
    AllowMenu: true,
  },
  {
    Value: 'LoadRemoteCallFavoriteUser',
    Label: 'LoadRemoteCallFavoriteUser',
    API: LoadRemoteCallFavoriteUser,
    AllowMenu: false,
  },
  {
    Value: 'LoadRemoteCallUser',
    Label: 'LoadRemoteCallUser',
    API: LoadRemoteCallUser,
    AllowMenu: false,
  },
  {
    Value: 'LoadRemoteCallUserCalledHistory',
    Label: 'LoadRemoteCallUserCalledHistory',
    API: LoadRemoteCallUserCalledHistory,
    AllowMenu: false,
  },
  {
    Value: 'LoadRemoteCallUserMapping',
    Label: 'LoadRemoteCallUserMapping',
    API: LoadRemoteCallUserMapping,
    AllowMenu: false,
  },
  {
    Value: 'LoadRole',
    Label: 'Roles',
    API: LoadRole,
    AllowMenu: true,
  },
  {
    Value: 'CustMenuMapping',
    Label: 'Customer Menu Mapping',
    API: CustMenuMapping,
    AllowMenu: true,
  },
  {
    Value: 'CustReportMapping',
    Label: 'Customer Report Mapping',
    API: CustReportMapping,
    AllowMenu: true,
  },
  {
    Value: 'LoadRoleReportMapping',
    Label: 'Role Report Mapping',
    API: LoadRoleReportMapping,
    AllowMenu: true,
  },
  {
    Value: 'LoadRoleMenuMapping',
    Label: 'Role Menu Mapping',
    API: LoadRoleMenuMapping,
    AllowMenu: true,
  },
  {
    Value: 'LoadUserAccess',
    Label: 'User Access',
    API: LoadUserAccess,
    AllowMenu: true,
  },
  {
    Value: 'LoadEMPlant',
    Label: 'EM Plant',
    API: LoadEMPlant,
    AllowMenu: true,
  },
  {
    Value: 'LoadEMPlantGroup',
    Label: 'EM Plant Group',
    API: LoadEMPlantGroup,
    AllowMenu: true,
  },
  {
    Value: 'LoadEMPlantSubGroup',
    Label: 'EM Plant Sub Group',
    API: LoadEMPlantSubGroup,
    AllowMenu: true,
  },
  {
    Value: 'LoadEmailFlows',
    Label: 'Email Flows',
    API: LoadEmailFlow,
    AllowMenu: true,
  },
  {
    Value: 'LoadEmailFlowsMapping',
    Label: 'Email Flows Mapping',
    API: LoadEmailFlowMapping,
    AllowMenu: true,
  },
];
export default SelectMenus;
