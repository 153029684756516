import React, { useState, useEffect } from 'react';
// import ReportSlideData from "./overview/ReportSlide";
import ImportReport from '../ImportReport';
import PowerBIReport from '../PowerBI';
/* eslint no-unused-vars: 0 */
import userManagementService from '../../../services/userManagement.service';
import ReportAddEntryButton from '../ReportAddEntryButton';

const { getInnerReports } = userManagementService;
const ReportTabs = ({ data, ExtraTabContent }) => {
  const { ReportKey } = data;
  const [reports, setReports] = useState([]);
  const [active, setActive] = useState('');
  useEffect(() => {
    getInnerReports({ ReportKey })
      .then((res) => {
        if (res.status === 200) {
          const { Reports } = res.data;
          setReports(Reports);
          setActive(Reports[0].ReportCode);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const [showpin, setShowPin] = useState(false);
  // const [showSetting, setSetting] = useState(false);
  // console.log(reports);
  const addEntryBtnFound = (reports || []).find((item) => ['SMDAFOERpt'].includes(item.ReportCode));
  return (
    <>
      { (addEntryBtnFound && addEntryBtnFound.ReportCode === active)
      && <ReportAddEntryButton name="Loss Entry" reportCode={addEntryBtnFound.ReportCode} />}
      <div className="tab-container">
        <ul className="tab-button-container">
          {reports.map((current, key) => {
            const { ReportName, ReportCode } = current;
            return (
              <li
                key={key}
                className={`tab-button ${ReportCode === active ? 'active' : ''}`}
              >
                <a
                  data-toggle="tab"
                  data-tab="overview"
                  onClick={() => {
                    setActive(ReportCode);
                  // setSelectedTab(e.target.dataset.tab);
                  }}
                >
                  {ReportName}
                </a>
              </li>
            );
          })}

          {ExtraTabContent.map((current, key) => {
            const { tabName: ReportName, tabID: ReportCode } = current;
            return (
              <li
                key={key}
                className={`tab-button ${ReportCode === active ? 'active' : ''}`}
              >
                <a
                  data-toggle="tab"
                  data-tab="overview"
                  onClick={() => {
                    setActive(ReportCode);
                  // setSelectedTab(e.target.dataset.tab);
                  }}
                >
                  {ReportName}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="tab-content PowerBI-tab-content">
          {reports.map((current, key) => {
            const { ReportCode, ReportType } = current;
            {
              let Component = null;
              if (ReportType === 'PowerBI') {
                Component = PowerBIReport;
              } else {
                Component = ImportReport[ReportCode];
              }
              return (
                <div className={`tab-pane ${ReportCode === active ? 'active' : ''}`} key={key}>
                  <Component data={current} />
                </div>
              );
            }
          })}
          {ExtraTabContent.map((current, key) => {
            const { tabID: ReportCode, Component } = current;
            return (
              <div className={`tab-pane ${ReportCode === active ? 'active' : ''}`} key={key}>
                <Component />
              </div>
            );
          })}
          {/* {reports.length > 0 ? <PowerBIReport data={{...selectedReport}} /> : "...loadingg"} */}
        </div>
      </div>
    </>

  // <Fragment>
  //   {doesTabExists("overview") ? (
  //     <div className="wrapper">
  //       <div className="contentWrap monitoringcontentWrap">
  //         <div className="slick-carousel slickcarouselTop">
  //           {/* <ReportSlideData
  //             visible={visible}
  //             setVisible={setVisible}
  //             visibilityAccess={props.visibilityAccess}
  //             selectedTabs={tab ? tab : selectedTab}
  //             setSelectedTabs={setSelectedTab}
  //             setButtonClicked={setButtonClicked}
  //             buttonClicked={buttonClicked}
  //             {...props}
  //           /> */}
  //         </div>
  //       </div>
  //     </div>
  //   ) : (
  //     "server error"
  //     // <ServerError />
  //   )}
  // </Fragment>
  );
};
export default ReportTabs;
