import React, {
  useEffect, useState,
} from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import '../../../styles/dashboard.css';
import './cmDashboard.css';
import MUITooltip from '@material-ui/core/Tooltip';
import PaginationTab from '../../../common/PaginationTab/PaginationTab';
import { fetchFailureResolutionDetailsAsync } from '../../../redux/slices/cmDashboardSlice';
/* eslint max-len:0 */
export default function FailureResolution({ showFRDetails, setShowFRDetails, fromStart }) {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const defaultCardCount = 15;
  const size = defaultCardCount;
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const dateFormatter = (item) => moment(item).format('YYYY-MM-DD HH:mm:ss.SSS');

  const getCommunicationType = (item) => {
    let result = '';
    if (item.Communication_SMS) {
      result = 'SMS';
    }
    if (item.Communication_Email) {
      if (result.length > 0) result += ', ';
      result += 'Email';
    }
    return result;
  };

  useEffect(() => {
    dispatch(fetchFailureResolutionDetailsAsync({
      page, size, from: dateFormatter(fromStart), to: dateFormatter(new Date()),
    }));
  }, [page, size, fromStart]);

  const failureResolutionDetails = useSelector(
    (state) => state.cmDashboardOutput.failureResolutionDetails.rows,
  );

  const executionResults = useSelector(
    (state) => state.cmDashboardOutput.failureResolutionDetails,
  );

  const { count } = executionResults;

  const handleFRCancel = () => {
    setShowFRDetails(false);
  };

  useEffect(() => {
    const st = (page - 1) * size + 1;
    const en = st + (size - 1) > count ? count : st + (size - 1);
    setStart(st);
    setEnd(en);
  }, [page, size, count]);

  const onPrevious = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  const onNext = (pageNumber) => {
    setPage(pageNumber + 1);
  };

  return (
    <>

      <div
        className={`modelboxopen2 ${showFRDetails ? '' : 'hidden'
        }`}
      >
        <div className="modelboxopenInnner conditionMonitoring resolutiontimefailureconditions" id="modelboxopenInnner6">
          <div className="wrapContent">
            <div className="containerIner2" />
            <div className="contentPopup">
              <div className="popupCloseButton bbt">
                <h3 className="titleDiv">Resolution Time - Failure Conditions</h3>
                <a
                  className="closepopup"
                  onClick={handleFRCancel}
                >
                  &#x2715;
                </a>
              </div>
              <div className="innerContentPopup">
                <div className="descriptionCreate">
                  <div>
                    <div className="col-md-12 mb-8">
                      <div>
                        <div className="enterpins placeColor">
                          <div className="font14">
                            <>

                              <div className="cm-dashboard cm-table">
                                <div className="scrollTab">
                                  <div className="headerTable">

                                    <span className="row2 font10">Failure Condition</span>

                                    <span className="row4 font10">Machine Model</span>
                                    <span className="row5 font10">Machine ID </span>

                                    <span className="row6 font10">Customer </span>

                                    <span className="row7 font10">Alert Type </span>

                                    <MUITooltip
                                      className="conditional-monitoring"
                                      title="Communication Type"
                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                      arrow
                                    >
                                      <span className="row8 font10">Communication Type</span>
                                    </MUITooltip>

                                    <span className="row9 font10">Timestamp</span>

                                    <span className="row10 font10">Raised On</span>

                                    <span className="row11 font10">Resolved On</span>

                                    <span className="row3 font10">Status</span>

                                    <span className="row12 font10">Aging</span>

                                  </div>

                                  {failureResolutionDetails && failureResolutionDetails.length > 0 && (
                                    <div
                                      className="pannelWrap eventOverflowScroll common-scroll"
                                      id="pannelWrap"
                                    >
                                      <div className="bs-example">
                                        <div className="panel-group" id="accordion">
                                          {
                                                                                        failureResolutionDetails.map((item, index) => (
                                                                                          <div className="panel panel-default alarmRows" key={item.Condition_ID + index}>
                                                                                            <div className="panel-heading">
                                                                                              <h4 className="panel-title">

                                                                                                <MUITooltip
                                                                                                  className="conditional-monitoring"
                                                                                                  title={item.ConditionDescription}
                                                                                                  PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                                                                  arrow
                                                                                                >
                                                                                                  <span className="row2 font10">{item.ConditionDescription}</span>
                                                                                                </MUITooltip>
                                                                                                <MUITooltip
                                                                                                  className="conditional-monitoring"
                                                                                                  title={item.Machine_Model}
                                                                                                  PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                                                                  arrow
                                                                                                >
                                                                                                  <span className="row4 font10">{item.Machine_Model}</span>
                                                                                                </MUITooltip>

                                                                                                <span className="row5 font10">{item.Machine_ID}</span>

                                                                                                <MUITooltip
                                                                                                  className="conditional-monitoring"
                                                                                                  title={item.Customer}
                                                                                                  PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                                                                  arrow
                                                                                                >
                                                                                                  <span className="row6 font10">{item.Customer}</span>
                                                                                                </MUITooltip>

                                                                                                <span className="row7 font10">{item.Alert_Type === 'A' ? 'Alert' : 'Notification'}</span>

                                                                                                <MUITooltip
                                                                                                  className="conditional-monitoring"
                                                                                                  title={getCommunicationType(item)}
                                                                                                  PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                                                                  arrow
                                                                                                >
                                                                                                  <span className="row8 font10">{getCommunicationType(item)}</span>
                                                                                                </MUITooltip>
                                                                                                <MUITooltip
                                                                                                  className="conditional-monitoring"
                                                                                                  title={dateFormatter(item.TimeStamp)}
                                                                                                  PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                                                                  arrow
                                                                                                >
                                                                                                  <span className="row9 font10">{dateFormatter(item.TimeStamp)}</span>
                                                                                                </MUITooltip>
                                                                                                <MUITooltip
                                                                                                  className="conditional-monitoring"
                                                                                                  title={dateFormatter(item.TimeStamp)}
                                                                                                  PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                                                                  arrow
                                                                                                >
                                                                                                  <span className="row10 font10">{dateFormatter(item.TimeStamp)}</span>
                                                                                                </MUITooltip>
                                                                                                <MUITooltip
                                                                                                  className="conditional-monitoring"
                                                                                                  title={dateFormatter(item.ResolvedOnTimeStamp)}
                                                                                                  PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                                                                  arrow
                                                                                                >
                                                                                                  <span className="row11 font10">{dateFormatter(item.ResolvedOnTimeStamp)}</span>
                                                                                                </MUITooltip>
                                                                                                <span className="row3 font10">{item.IsResolved ? 'Resolved' : 'Not Resolved'}</span>
                                                                                                <span className="row12 font10">{item.Duration}</span>
                                                                                              </h4>
                                                                                            </div>
                                                                                          </div>
                                                                                        ))
                                                                                    }
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {(failureResolutionDetails === undefined || failureResolutionDetails.length === 0)
                                                                && <div className="noHistoryAlarms">No Record</div>}
                              <div
                                className={`
                                                                        alarm-pagination
                                                                        ${count <= defaultCardCount
                                  ? 'cardBelowNine'
                                  : ''
                                                                    }`}
                              >
                                <div className="panellefttext">
                                  <div className="shownotext">
                                    {` ${start} to ${end} of ${count} `}
                                  </div>
                                </div>
                                <div className="panelrighttext">
                                  <PaginationTab
                                    Previous={onPrevious}
                                    Next={onNext}
                                    page={page}
                                    maxPage={Math.ceil(count / size)}
                                  />
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
