import React from 'react';
import Styles from './style.module.css';

function Footer() {
  return (
    <footer className={`${Styles.footer}`} id="footer">
      ACG ©
      {' '}
      {new Date().getFullYear()}
      {' '}
      All rights reserved.
    </footer>
  );
}
export default Footer;
