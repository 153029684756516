/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useState, useCallback,
} from 'react';
// import { useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  PieChart, Pie,
  Tooltip,
  Legend,
} from 'recharts';
import moment from 'moment';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import MUITooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import '../../../styles/dashboard.css';
import './cmDashboard.css';
// import { ReactComponent as Zoom, ReactComponent as Viewall } from '../../Reports/ECR/svgs/viewgrid';
// import { ReactComponent as Viewall } from '../../Reports/ECR/svgs/viewgrid.svg';
/* eslint react/jsx-no-undef:0 */
/* eslint no-shadow:0 */
import { ReactComponent as Zoom } from '../../../images/expand.svg';
import { ReactComponent as Viewall } from '../../../images/viewgrid.svg';
import { ReactComponent as AlertIcon } from '../../../images/alert-circle.svg';
import {

  fetchExecutionResultAsync,
  fetchConditionWiseAsync,
  fetchModelWiseAsync,
  fetchSeverityWiseAsync,
  fetchCustomerWiseAsync,
  fetchFailureResolutionAsync,
  fetchMaintenanceResolutionAsync,
  fetchFailureAgingAsync,
  fetchMaintenanceAgingAsync,
} from '../../../redux/slices/cmDashboardSlice';
import FailureCondition from './FailureCondition';
import CustomerDetails from './CustomerDetails';
import MachineFamilyDetails from './MachineFamilyDetails';
import SeverityDetails from './SeverityDetails';
import FailureAgingDetails from './FailureAgingDetails';
import MaintenanceAgingDetails from './MaintenanceAgingDetails';
import FailureResolution from './FailureResolution';
import MaintenanceResolution from './MaintenanceResolution';

/* eslint max-len:0 */

// const validate = () => { };

export default function CMDashboard() {
  const dispatch = useDispatch();
  // const { option } = useParams();
  const [visible, setVisible] = useState(false);
  const [zoomComponent, setZoomComponent] = useState();
  const [showDetails, setShowDetails] = useState(false);
  // const [modalText, setModalText] = useState('');
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [showModelDetails, setShowModelDetails] = useState(false);
  const [showSeverityDetails, setShowSeverityDetails] = useState(false);
  const [showFagingDetails, setShowFagingDetails] = useState(false);
  const [showMagingDetails, setShowMagingDetails] = useState(false);
  const [showFRDetails, setShowFRDetails] = useState(false);
  const [showMRDetails, setShowMRDetails] = useState(false);
  // const [fCValue, setFCValue] = useState('');
  const [, setFCValue] = useState('');

  // const [page, setPage] = useState(1);
  // const defaultCardCount = 15;
  // const size = defaultCardCount;
  // const [start, setStart] = useState();
  // const [end, setEnd] = useState();

  const [title, setTitle] = useState('');

  const dateFormatter = (item) => moment(item).format('YYYY-MM-DD HH:mm:ss.SSS');
  // const getCommunicationType = (item) => {
  //   let result = '';
  //   if (item.Communication_SMS) {
  //     result = 'SMS';
  //   }
  //   if (item.Communication_Email) {
  //     if (result.length > 0) result += ', ';
  //     result += 'Email';
  //   }
  //   return result;
  // };

  const datesTypes = [
    { dID: 1, dRange: '1 Day' },
    { dID: 2, dRange: 'Last Week' },
    { dID: 3, dRange: 'Last Month' },
  ];

  const [fromStart, setFromStart] = useState();

  useEffect(() => {
    const tempDate = new Date();
    tempDate.setDate(tempDate.getDate() - 30);
    setFromStart(tempDate);
  }, []);

  useEffect(() => {
    if (fromStart) {
      dispatch(fetchExecutionResultAsync({ from: dateFormatter(fromStart), to: dateFormatter(new Date()) }));
      dispatch(fetchConditionWiseAsync({ from: dateFormatter(fromStart), to: dateFormatter(new Date()) }));
      dispatch(fetchModelWiseAsync({ from: dateFormatter(fromStart), to: dateFormatter(new Date()) }));
      dispatch(fetchSeverityWiseAsync({ from: dateFormatter(fromStart), to: dateFormatter(new Date()) }));
      dispatch(fetchCustomerWiseAsync({ from: dateFormatter(fromStart), to: dateFormatter(new Date()) }));
      dispatch(fetchFailureResolutionAsync({ from: dateFormatter(fromStart), to: dateFormatter(new Date()) }));
      dispatch(fetchMaintenanceResolutionAsync({ from: dateFormatter(fromStart), to: dateFormatter(new Date()) }));
      dispatch(fetchFailureAgingAsync({ from: dateFormatter(fromStart), to: dateFormatter(new Date()) }));
      dispatch(fetchMaintenanceAgingAsync({ from: dateFormatter(fromStart), to: dateFormatter(new Date()) }));
    }
  }, [fromStart]);

  const onFilterChange = async (values) => {
    const tempDate = new Date();
    if (values === 1) {
      tempDate.setDate(tempDate.getDate() - 1);
    } else if (values === 2) {
      tempDate.setDate(tempDate.getDate() - 7);
    } else {
      tempDate.setDate(tempDate.getDate() - 30);
    }

    setFromStart(tempDate);
  };

  const executionResults = useSelector(
    (state) => state.cmDashboardOutput.executionResults,
  );

  const { count } = executionResults;
  const { failure } = executionResults;
  const { maintenance } = executionResults;
  const { general } = executionResults;
  const { resolved } = executionResults;
  const { alerts } = executionResults;
  const { notifications } = executionResults;

  const conditionWiseResults = useSelector(
    (state) => state.cmDashboardOutput.conditionWiseResults,
  );

  let modelWiseResults = useSelector(
    (state) => state.cmDashboardOutput.modelWiseResults,
  );

  const fillColors = ['#5D97F6', '#0954CD', '#D57878', '#C4D55F', '#FB965D'];
  const severityColors = ['#D57878', '#FB965D', '#D5A05F', '#C4D55F', '#8BCD9D'];

  modelWiseResults = modelWiseResults.map((v, i) => ({
    ...v, fill: fillColors[i % fillColors.length],
  }));

  // modelWiseResults.forEach(((m,i) => m.fill = fillColors[i % fillColors.length]));

  const severityWiseResults = useSelector(
    (state) => state.cmDashboardOutput.severityWiseResults,
  );

  // severityWiseResults = severityWiseResults.map((v, i) => ({
  //     ...v, fill: severityColors[i % severityColors.length]
  // }));

  const customerWiseResults = useSelector(
    (state) => state.cmDashboardOutput.customerWiseResults,
  );

  const failureResolutions = useSelector(
    (state) => state.cmDashboardOutput.failureResolutions,
  );

  const maintenanceResolutions = useSelector(
    (state) => state.cmDashboardOutput.maintenanceResolutions,
  );

  const failureAging = useSelector(
    (state) => state.cmDashboardOutput.failureAging,
  );

  const maintenanceAging = useSelector(
    (state) => state.cmDashboardOutput.maintenanceAging,
  );

  // const onSubmit = async (values) => { };
  const onSubmit = async () => { };

  const handleCancel = () => {
    setVisible(false);
  };

  const makeZoom = (component, header) => {
    setVisible(true);
    setZoomComponent(component);
    setTitle(header);
  };

  const showAll = () => {
    setShowDetails(true);
  };

  const showAllCustomer = () => {
    setShowCustomerDetails(true);
  };

  const showAllModel = () => {
    setShowModelDetails(true);
  };

  const showAllSeverity = () => {
    setShowSeverityDetails(true);
  };

  const showAllFailureAging = () => {
    setShowFagingDetails(true);
  };

  const showAllMaintenanceAging = () => {
    setShowMagingDetails(true);
  };

  const showAllFR = () => {
    setShowFRDetails(true);
  };

  const showAllMR = () => {
    setShowMRDetails(true);
  };

  // const CustomTooltip = ({ active, payload, label }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="recharts-tooltip-wrapper">
          <div className="recharts-default-tooltip">
            <p className="label">{`${payload[0].payload.machine_model} : ${payload[0].payload.MachineFamily}`}</p>
          </div>
        </div>
      );
    }
    return null;
  };
  console.log('visible', visible);
  const TopFailure = useCallback(({ height }) => (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={conditionWiseResults} layout="vertical">
        <XAxis type="number" allowDecimals={false} />
        <YAxis dataKey="Condition_Description" type="category" style={{ fontSize: '0.8rem', fontFamily: 'Arial' }} interval={0} tickFormatter={(x) => (x)} />
        <Legend iconType="square" iconSize={10} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="Condition"
          fill="#5D97F6"
          maxBarSize={15}
        />
      </BarChart>
    </ResponsiveContainer>
  ), [conditionWiseResults]);

  const CustomerFailureCount = useCallback(({ height }) => (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={customerWiseResults} layout="vertical">
        <XAxis type="number" allowDecimals={false} />
        <YAxis
          dataKey="Organization_Name"
          type="category"
          style={{ fontSize: '0.8rem', fontFamily: 'Arial' }}
          interval={0}
          tickFormatter={(x) => (x)}
        />
        <Legend iconType="square" iconSize={10} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="Customer"
          fill="#5D97F6"
          maxBarSize={15}
        />
      </BarChart>
    </ResponsiveContainer>
  ), [customerWiseResults]);

  const FailureCountMachineFamily = useCallback(({ height, innerRadius, outerRadius }) => (
    <ResponsiveContainer width="100%" height={height}>
      <PieChart>
        <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
        <Pie
          data={modelWiseResults}
          stroke="none"
          // outerRadius={100}
          // width="100%"
          innerRadius={innerRadius || 60}
          outerRadius={outerRadius || 80}
          dataKey="MachineFamily"
          label={({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            value,
            index,
          }) => {
            const RADIAN = Math.PI / 180;
            const radius = 25 + innerRadius + (outerRadius - innerRadius);
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
              <text
                x={x}
                y={y}
                fontSize="12px"
                fill="#E0E0E0"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
              >
                {modelWiseResults[index].machine_model}
                {' '}
                {((value / (modelWiseResults.reduce((t, x) => t + x.MachineFamily, 0))) * 100).toFixed(0)}
                %
              </text>
            );
          }}
        />

      </PieChart>
    </ResponsiveContainer>
  ), [modelWiseResults]);

  const FailureCountSeverity = useCallback(({ height }) => (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={severityWiseResults.map((v, i) => ({
        ...v, fill: severityColors[i % severityColors.length],
      }))}
      >

        <XAxis dataKey="Scale" interval={0} />
        <YAxis allowDecimals={false} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="Severity"
          fill="#5D97F6"
          maxBarSize={15}
        />
      </BarChart>
    </ResponsiveContainer>
  ), [severityWiseResults]);

  const FailureConditionsAging = useCallback(({ height, labelLength }) => (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={failureAging} layout="vertical">
        <XAxis type="number" allowDecimals={false} />
        <YAxis dataKey="ConditionDescription" type="category" interval={0} tickFormatter={(x) => (x.length > labelLength ? `${x.substring(0, labelLength - 1)}...` : x)} />
        <Legend iconType="square" iconSize={10} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="Duration"
          fill="#5D97F6"
          maxBarSize={15}
        />
      </BarChart>
    </ResponsiveContainer>
  ), [failureAging]);

  const MaintenanceConditionsAging = useCallback(({ height, labelLength }) => (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={maintenanceAging} layout="vertical">
        <XAxis type="number" allowDecimals={false} />
        <YAxis dataKey="ConditionDescription" type="category" interval={0} tickFormatter={(x) => (x.length > labelLength ? `${x.substring(0, labelLength - 1)}...` : x)} />
        <Legend iconType="square" iconSize={10} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="Duration"
          fill="#5D97F6"
          maxBarSize={15}
        />
      </BarChart>
    </ResponsiveContainer>
  ), [maintenanceAging]);

  const ResolutionTimeFailureConditions = useCallback(({ height }) => (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={failureResolutions}>
        <XAxis dataKey="DayDuration" interval={0} />
        <YAxis allowDecimals={false} />
        <Legend iconType="square" iconSize={10} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="Count"
          fill="#5D97F6"
          maxBarSize={15}
        />
      </BarChart>
    </ResponsiveContainer>
  ), [failureResolutions]);

  const ResolutionTimeMainenanceConditions = useCallback(({ height }) => (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={maintenanceResolutions}>
        <XAxis dataKey="DayDuration" interval={0} />
        <YAxis allowDecimals={false} />
        <Legend iconType="square" iconSize={10} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="Count"
          fill="#5D97F6"
          maxBarSize={15}
        />
      </BarChart>
    </ResponsiveContainer>
  ), [maintenanceResolutions]);

  return (
    <>

      <>
        <div className="wrapper overflowScrolls conditionMonitoring cm-dashboard">
          <div className="contentWrap conditionMonitoring">

            <Form
              onSubmit={onSubmit}
              // render={({ handleSubmit, form }) => (
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} id="failure-mode-form">
                  <div>
                    <div className="tabArea">
                      <ul id="navList" className="nav nav-tabs tabsName">
                        <li className="active">
                          <a data-toggle="tab">Dashboard</a>
                        </li>
                      </ul>
                    </div>
                    <div className="form-group col-md-12">
                      <div className="input-control">
                        <div className="input-group datefilter">
                          <div>
                            <Field
                              name="DateRange"
                              className="form-control conditionMonitoring"
                              placeholder="Select"
                            >
                              {(props) => (
                                // <select
                                //     {...props.input}
                                // >
                                //     <option value="">Select Date Range</option>
                                //     {dateTypes &&
                                //         dateTypes.map((m) => (
                                //             <option value={m} key={m}>
                                //                 {m}
                                //             </option>
                                //         ))}
                                // </select>

                                <Autocomplete
                                  {...props.input}
                                  value={(datesTypes && datesTypes.find((m) => m.dID === props.input.value)) || ''}
                                  getOptionSelected={(option, value) => option.dRange === value.dRange}
                                  onChange={(event, value) => {
                                    props.input.onChange(value ? value.dID : '');
                                    setFCValue(value && value.dID);
                                  }}
                                  options={datesTypes}
                                  getOptionLabel={(option) => option.dRange || ''}
                                  PopperProps={{ className: 'conditionMonitoring' }}
                                  renderInput={(params) => <TextField {...params} placeholder="Last month" variant="outlined" />}
                                />
                              )}
                            </Field>
                            <OnChange name="DateRange">

                              {onFilterChange}
                            </OnChange>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />

            <div className="dashboard-panel">
              <div className="dashboard-panel-card">
                <div className="tinycard">
                  <div className="title">
                    Total Conditions Met
                  </div>
                  <div className="value">
                    {count}
                  </div>
                </div>
              </div>

              <div className="dashboard-panel-card">
                <div className="tinycard">
                  <div className="title">
                    Active Conditions
                  </div>
                  <div className="value">
                    {resolved}
                  </div>
                </div>
              </div>

              <div className="dashboard-panel-card">
                <div className="tinycard">
                  <div className="title">
                    Failure Conditions
                  </div>
                  <div className="value">
                    {failure}
                  </div>
                </div>
              </div>

              <div className="dashboard-panel-card">
                <div className="tinycard">
                  <div className="title">
                    Maintenance Conditions
                  </div>
                  <div className="value">
                    {maintenance}
                  </div>
                </div>
              </div>

              <div className="dashboard-panel-card">
                <div className="tinycard">
                  <div className="title">
                    General Conditions
                  </div>
                  <div className="value">
                    {general}
                  </div>
                </div>
              </div>

              <div className="dashboard-panel-card">
                <div className="tinycard">
                  <div className="title">
                    Total Alerts
                  </div>
                  <div className="value">
                    {alerts}
                  </div>
                </div>
              </div>

              <div className="dashboard-panel-card">
                <div className="tinycard">
                  <div className="title">
                    Total Notifications
                  </div>
                  <div className="value">
                    {notifications}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mr-1 chart-scroll">

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4 dashboard-card">

                    <div className="card">

                      <label className="font10">
                        Top 10 Failure Conditions
                        <MUITooltip
                          className="conditional-monitoring"
                          title="The top 10 failure conditions along with the number of times of failure is displayed in this chart"
                          PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                          arrow
                        >
                          <AlertIcon />
                        </MUITooltip>
                      </label>
                      {conditionWiseResults && conditionWiseResults.length > 0
                        && (
                          <>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="Enlarged View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => makeZoom(<TopFailure height={450} labelLength={15} />, 'Top 10 Failure Conditions')}><Zoom /></label>
                            </MUITooltip>

                            <MUITooltip
                              className="conditional-monitoring"
                              title="List View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => showAll()}><Viewall /></label>
                            </MUITooltip>

                            <TopFailure height={250} labelLength={8} />
                          </>
                        )}

                      {(conditionWiseResults === undefined || conditionWiseResults.length === 0)
                        && <div className="font10 noRecords">No Record Available</div>}
                    </div>
                  </div>

                  <div className="col-md-4 dashboard-card">
                    <div className="card">
                      <label className="font10">
                        Failure Count - Customer Wise
                        <MUITooltip
                          className="conditional-monitoring"
                          title="The top 10 customers and the number of failures for each of the customer is displayed in this chart"
                          PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                          arrow
                        >
                          <AlertIcon />
                        </MUITooltip>
                      </label>
                      {customerWiseResults && customerWiseResults.length > 0
                        && (
                          <>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="Enlarged View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => makeZoom(<CustomerFailureCount height={450} labelLength={15} />, 'Failure Count - Customer Wise')}><Zoom /></label>
                            </MUITooltip>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="List View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => showAllCustomer()}><Viewall /></label>
                            </MUITooltip>
                            <CustomerFailureCount height={250} labelLength={8} />
                          </>
                        )}
                      {(customerWiseResults === undefined || customerWiseResults.length === 0)
                        && <div className="font10 noRecords">No Record Available</div>}
                    </div>
                  </div>

                  <div className="col-md-4 dashboard-card">
                    <div className="card">
                      <label className="font10">
                        Failure Count - Machine Model Wise
                        <MUITooltip
                          className="conditional-monitoring"
                          title="The top 10 machine models and the number of failures for each of the machine model is displayed in this chart"
                          PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                          arrow
                        >
                          <AlertIcon />
                        </MUITooltip>
                      </label>

                      {modelWiseResults && modelWiseResults.length > 0
                        && (
                          <>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="Enlarged View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => makeZoom(<FailureCountMachineFamily height={450} innerRadius={120} outerRadius={160} />, 'Failure Count - Machine Model Wise')}><Zoom /></label>
                            </MUITooltip>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="List View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => showAllModel()}><Viewall /></label>
                            </MUITooltip>
                            <FailureCountMachineFamily height={250} />
                          </>
                        )}
                      {(modelWiseResults === undefined || modelWiseResults.length === 0)
                        && <div className="font10 noRecords">No Record Available</div>}
                    </div>
                  </div>
                </div>
                {' '}

              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4 dashboard-card">
                    <div className="card">
                      <label className="font10">
                        Failure Count - Severity
                        <MUITooltip
                          className="conditional-monitoring"
                          title="The top 10 failures based on the five severity levels available in the failure modes is displayed in this chart"
                          PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                          arrow
                        >
                          <AlertIcon />
                        </MUITooltip>
                      </label>

                      {severityWiseResults && severityWiseResults.some((s) => s.Severity > 0)
                        && (
                          <>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="Enlarged View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => makeZoom(<FailureCountSeverity height={450} />, 'Failure Count - Severity Wise')}><Zoom /></label>
                            </MUITooltip>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="List View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => showAllSeverity()}><Viewall /></label>
                            </MUITooltip>
                            <FailureCountSeverity height={250} />
                          </>
                        )}
                      {(severityWiseResults === undefined || severityWiseResults.length === 0 || !severityWiseResults.some((s) => s.Severity > 0))
                        && <div className="font10 noRecords">No Record Available</div>}
                    </div>
                  </div>

                  <div className="col-md-4 dashboard-card">
                    <div className="card">
                      <label className="font10">
                        Failure Condition - Aging
                        <MUITooltip
                          className="conditional-monitoring"
                          title="The failure conditions count based on the date in which it occurred to the date it is pending until resolved is displayed in this chart"
                          PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                          arrow
                        >
                          <AlertIcon />
                        </MUITooltip>
                      </label>

                      {failureAging && failureAging.length > 0
                        && (
                          <>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="Enlarged View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => makeZoom(<FailureConditionsAging height={450} labelLength={15} />, 'Failure Condition - Aging')}><Zoom /></label>
                            </MUITooltip>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="List View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => showAllFailureAging()}><Viewall /></label>
                            </MUITooltip>
                            <FailureConditionsAging height={250} labelLength={8} />
                          </>
                        )}
                      {(failureAging === undefined || failureAging.length === 0)
                        && <div className="font10 noRecords">No Record Available</div>}
                    </div>
                  </div>

                  <div className="col-md-4 dashboard-card">
                    <div className="card">
                      <label className="font10">
                        Maintenance Condition - Aging
                        <MUITooltip
                          className="conditional-monitoring"
                          title="The maintenance conditions count based on the date in which it occurred to the date it is pending until resolved is displayed in this chart"
                          PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                          arrow
                        >
                          <AlertIcon />
                        </MUITooltip>
                      </label>

                      {maintenanceAging && maintenanceAging.length > 0
                        && (
                          <>

                            <MUITooltip
                              className="conditional-monitoring"
                              title="Enlarged View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => makeZoom(<MaintenanceConditionsAging height={450} labelLength={15} />, 'Maintenance Condition - Aging')}><Zoom /></label>
                            </MUITooltip>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="List View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => showAllMaintenanceAging()}><Viewall /></label>
                            </MUITooltip>
                            <MaintenanceConditionsAging height={250} labelLength={8} />
                          </>
                        )}
                      {(maintenanceAging === undefined || maintenanceAging.length === 0)
                        && <div className="font10 noRecords">No Record Available</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 dashboard-card">
                    <div className="card">
                      <label className="font10">
                        Resolution Time - Failure Conditions
                        <MUITooltip
                          className="conditional-monitoring"
                          title="The failure conditions count will be displayed based on the time taken for it to be resolved is displayed to the user"
                          PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                          arrow
                        >
                          <AlertIcon />
                        </MUITooltip>
                      </label>

                      {failureResolutions && failureResolutions.some((f) => f.Count > 0)
                        && (
                          <>

                            <MUITooltip
                              className="conditional-monitoring"
                              title="Enlarged View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => makeZoom(<ResolutionTimeFailureConditions height={450} />, 'Resolution Time - Failure Conditions')}><Zoom /></label>
                            </MUITooltip>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="List View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => showAllFR()}><Viewall /></label>
                            </MUITooltip>
                            <ResolutionTimeFailureConditions height={250} />
                          </>
                        )}
                      {(failureResolutions === undefined || failureResolutions.length === 0 || !failureResolutions.some((f) => f.Count > 0))
                        && <div className="font10 noRecords">No Record Available</div>}
                    </div>
                  </div>

                  <div className="col-md-6 dashboard-card">
                    <div className="card">
                      <label className="font10">
                        Resolution Time - Maintenance Conditions
                        <MUITooltip
                          className="conditional-monitoring"
                          title="The maintenance conditions count will be displayed based on the time taken for it to be resolved is displayed to the user"
                          PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                          arrow
                        >
                          <AlertIcon />
                        </MUITooltip>
                      </label>
                      {maintenanceResolutions && maintenanceResolutions.some((m) => m.Count > 0)
                        && (
                          <>

                            <MUITooltip
                              className="conditional-monitoring"
                              title="Enlarged View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => makeZoom(<ResolutionTimeMainenanceConditions height={450} />, 'Resolution Time - Maintenance Conditions')}><Zoom /></label>
                            </MUITooltip>
                            <MUITooltip
                              className="conditional-monitoring"
                              title="List View"
                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                              arrow
                            >
                              <label className="font10 pull-right" onClick={() => showAllMR()}><Viewall /></label>
                            </MUITooltip>
                            <ResolutionTimeMainenanceConditions height={250} />
                          </>
                        )}
                      {(maintenanceResolutions === undefined || maintenanceResolutions.length === 0 || !maintenanceResolutions.some((m) => m.Count > 0))
                        && <div className="font10 noRecords">No Record Available</div>}
                    </div>
                  </div>
                </div>
                {' '}

              </div>
            </div>

          </div>
        </div>
      </>

      <div
        className={`modelboxopen2 cmmodel ${visible ? '' : 'hidden'
        }`}
      >
        <div className="modelboxopenInnner conditionMonitoring" id="modelboxopenInnner6">
          <div className="wrapContent">
            <div className="containerIner2" />
            <div className="contentPopup">
              <div className="popupCloseButton bbt">
                <h3 className="titleDiv">{title}</h3>
                <a
                  className="closepopup"
                  onClick={handleCancel}
                >
                  &#x2715;
                </a>
              </div>
              <div className="innerContentPopup">
                <div className="descriptionCreate">
                  <div>
                    <div className="col-md-12 mb-8">
                      <div>
                        <div className="enterpins placeColor">
                          <div className="font14">
                            {zoomComponent}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDetails && <FailureCondition showDetails={showDetails} setShowDetails={setShowDetails} fromStart={fromStart} />}

      {showCustomerDetails && <CustomerDetails showCustomerDetails={showCustomerDetails} setShowCustomerDetails={setShowCustomerDetails} fromStart={fromStart} />}

      {showModelDetails && <MachineFamilyDetails showModelDetails={showModelDetails} setShowModelDetails={setShowModelDetails} fromStart={fromStart} />}

      {showSeverityDetails && <SeverityDetails showSeverityDetails={showSeverityDetails} setShowSeverityDetails={setShowSeverityDetails} fromStart={fromStart} />}

      {showFagingDetails && <FailureAgingDetails showFagingDetails={showFagingDetails} setShowFagingDetails={setShowFagingDetails} fromStart={fromStart} />}

      {showMagingDetails && <MaintenanceAgingDetails showMagingDetails={showMagingDetails} setShowMagingDetails={setShowMagingDetails} fromStart={fromStart} />}

      {showFRDetails && <FailureResolution showFRDetails={showFRDetails} setShowFRDetails={setShowFRDetails} fromStart={fromStart} />}

      {showMRDetails && <MaintenanceResolution showMRDetails={showMRDetails} setShowMRDetails={setShowMRDetails} fromStart={fromStart} />}

    </>
  );
}
