/* eslint no-unused-vars:0 */

import { useState } from 'react';
import { message } from 'antd';
import Icon from '../Icon';
import './style.css';
import Popup from '../../../common/Popup/Popup';
import EditForm from '../EditForm/EditForm';
import Switch from '../../../common/Switch/Switch';
// const { convertToDate } = utils;
// const
// const defaultData = [
//   {
//     PlantName: 'ACPL, Pithampur',
//     Type: 'Gelatine',
//     GroupName: 'HCM',
//     SubGroupName: 'HCM-101',
//     MachineSerialNo: 'INPI_ETP_EM_1',
//     Factor: 0.5,
//     Addition: 'Y',
//     Substraction: null,
//     ValidFrom: '2022-12-23',
//     ValidTo: '2099-12-01',
//     IsActive: 'Y',
//     ManualEntryKey: null,
//     PlantKey: 5,
//     PlantTypeKey: 2,
//     EMGroupKey: 1,
//     EMSubGroupKey: 1,
//     MachineKey: 293,
//   },
// ];
const SkipColumn = {
  Addition: 'Addition',
  Substraction: 'Substraction',
  isNew: 'isNew',
  // isAddition: 'isAddition',
  // IsActive: 'IsActive',
  ManualEntryKey: 'ManualEntryKey',
  PlantKey: 'PlantKey',
  PlantTypeKey: 'PlantTypeKey',
  EMGroupKey: 'EMGroupKey',
  EMSubGroupKey: 'EMSubGroupKey',
  MachineKey: 'MachineKey',
  isUpdate: 'isUpdate',
  ID: 'ID',
  LoggedUserKey: 'LoggedUserKey',
};
const TableHead = ({ data }) => {
  const rowKeys = Object.keys(data);
  return (
    <tr>
      {rowKeys.map((value) => {
        if (!SkipColumn[value]) {
          return <th>{value}</th>;
        } if (value === 'Addition') {
          return <th>Addition/Substraction</th>;
        }
      })}
      <th>Edit</th>
    </tr>
  );
};

const TableBody = ({ data, handleOpenPopup }) => data.map((row) => {
  // if (row.IsActive === 'N') {
  //   return '';
  // }
  const rowKeys = Object.keys(row);
  const OpenPopup = () => {
    handleOpenPopup(row);
  };
  /* eslint no-nested-ternary: 0 */
  return (
    <tr className={`${row.isNew ? 'new-row' : row.isUpdate ? 'updated-row' : ''}`}>
      {rowKeys.map((colName) => {
        if (!SkipColumn[colName]) {
          return <td>{row[colName]}</td>;
        } if (colName === 'Addition') {
          const isAddition = row[colName] === 'Y';
          return (
            <td>
              {isAddition ? 'ADD' : 'SUB'}
              {/* <Switch isOn={isOn} isDisable /> */}
            </td>
          );
        }
      })}
      <td><Icon name="edit" onClick={OpenPopup} /></td>
    </tr>
  );
});

const index = ({ data = [], onTableSubmit }) => {
  // data = defaultData;
  console.log('-----------------', data);
  // const [tableData, setTableData] = useState(data);
  const [showPopup, setShowPopup] = useState(false);
  const [editData, setEditData] = useState([]);
  const handleClose = () => {
    setShowPopup(false);
  };
  const handleOpenPopup = (rowdata) => {
    setEditData(rowdata);
    setShowPopup(true);
  };
  const onSubmit = (updatedData) => {
    // const {
    //   Factor, ValidFrom, ValidTo, Addition, Remark,
    // } = updatedData;
    // if (!Remark) {
    //   message.error('All fields are required');
    //   return false;
    // }
    onTableSubmit(updatedData);
    handleClose();
  };
  // console.log('tableData-out', data);
  if (data.length > 0) {
    // const TableHead = ;

    return (
      <>
        <div className="LogTableContainer">
          {/* <div className="EM__title">Grouping Selection Log Table</div> */}
          <div className="LogTable__body">
            <table className="LogTable__dataTable">
              <thead>
                <TableHead data={data[0]} />
              </thead>
              <tbody>
                <TableBody data={data} handleOpenPopup={handleOpenPopup} />
              </tbody>
            </table>
          </div>
        </div>
        {showPopup
          ? (
            <Popup handleClose={handleClose} className="EnergyMeter__popup">
              <EditForm data={editData} onSubmit={onSubmit} />
            </Popup>
          )

          : ''}
      </>
    );
  }
  return <div className="noEnrgyBlk">No Energy Meters Found</div>;
};
export default index;
