/* eslint-disable */
import axiosInstance from "../utils/api";

const getPerfLineChart = async (value) => {
    let config = {
        headers: {
            'Content-Type': 'application/json'
        },
    };

    return axiosInstance.post(`api/dm/parameter-performance/line-chart`, value, config)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error;
        });
}
export default getPerfLineChart;