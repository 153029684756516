import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Styles from './style.module.css';
import { resetState } from '../../redux/slices/userSlice';
// import axios from "./../../helper/axios";
import config from '../../config/api.config.json';
/* eslint jsx-a11y/label-has-associated-control:0 */
import {
  PASSWORD_RULES,
  PLEASE_ENTER_NEWPASSWORD,
  PLEASE_ENTER_CONFIRMPASSWORD,
  NEWPASSWORD_CONFIRM_PASSWORD_SAME,
  PLEASE_ENTER_CURRENT_PASSWORD,
  CURRENT_NEW_PASSWORD_SHOULD_NOT_SAME,
} from '../../helpers/constants';
import AppRoutes from '../../config/appicationRoutes.config.json';

function ChangePassword() {
  const { RESET_SUCCESS } = AppRoutes;
  const initialPAssword = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  const [password, setPassword] = useState(initialPAssword);
  const [hasPasswordChanged, setHasPasswordChanged] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSetPassword = (e) => {
    const { passwordStatus } = e.target.dataset;
    setPassword({
      ...password,
      [passwordStatus]: e.target.value,
    });
  };

  const handleReset = () => {
    setPassword(initialPAssword);
    setError('');
    setHasPasswordChanged('');
  };

  const Logout = () => {
    // setIsShowPanel(false)
    localStorage.clear();
    // <Redirect to="/" />
    dispatch(resetState());
    // history.push('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /* eslint no-useless-escape : 0 */
    const { currentPassword, newPassword, confirmPassword } = password;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#\^(){}|\[\]])[A-Za-z\d@$!%*?&#\^(){}|\[\]]{8,}$/;

    if (currentPassword === '') {
      setError(PLEASE_ENTER_CURRENT_PASSWORD);
      setHasPasswordChanged('');
    } else if (newPassword === '') {
      setError(PLEASE_ENTER_NEWPASSWORD);
      setHasPasswordChanged('');
    } else if (confirmPassword === '') {
      setError(PLEASE_ENTER_CONFIRMPASSWORD);
      setHasPasswordChanged('');
    } else if (currentPassword === newPassword) {
      setError(CURRENT_NEW_PASSWORD_SHOULD_NOT_SAME);
      setHasPasswordChanged('');
    } else if (!passwordRegex.test(newPassword)) {
      setError(PASSWORD_RULES);
      setHasPasswordChanged('');
    } else if (newPassword !== confirmPassword) {
      setError(NEWPASSWORD_CONFIRM_PASSWORD_SAME);
      setHasPasswordChanged('');
    } else {
      setError('');
      setHasPasswordChanged('');
      const email = localStorage.getItem('email');
      const token = localStorage.getItem('token');
      axios
        .post(config.CHANGE_PASSWORD, {
          email, password, token,
        })
        .then(async (res) => {
          console.log(res);
          if (res?.data?.status === 1) {
            setHasPasswordChanged(res?.data?.data);
            setPassword(initialPAssword);
            await Logout();
            history.push(RESET_SUCCESS);
          } else {
            setError(res?.data?.data);
          }
        })
        .catch((err) => {
          console.log('errr', err);
          if (err.response && err.response.data.password === 'invalid') {
            setError('You entered wrong password');
          } else {
            setError('Something went wrong, Password not changed');
          }
        });
    }
  };
  return (
    <div className={`${Styles.changePassword}`}>
      {error ? (
        <div className={`${Styles.msg} ${Styles.error}`}>{error}</div>
      ) : (
        ''
      )}
      {hasPasswordChanged ? (
        <div className={`${Styles.msg} ${Styles.success}`}>
          {hasPasswordChanged}
        </div>
      ) : (
        ''
      )}
      <form action="" onReset={handleReset} onSubmit={handleSubmit}>
        <div className={`${Styles.formFields}`}>
          <div className={`${Styles.formGroup}`}>
            <label htmlFor="current_password">Current Password</label>
            <input
              placeholder="enter old password"
              type="password"
              value={password.currentPassword}
              onChange={handleSetPassword}
              data-password-status="currentPassword"
              id="current_password"
            />
          </div>
          <div className={`${Styles.formGroup}`}>
            <label htmlFor="newPassword">New Password</label>
            <input
              placeholder="enter New password"
              type="password"
              value={password.newPassword}
              onChange={handleSetPassword}
              data-password-status="newPassword"
              id="newPassword"
            />
          </div>
          <div className={`${Styles.formGroup}`}>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              placeholder="enter confirm password"
              type="password"
              value={password.confirmPassword}
              onChange={handleSetPassword}
              data-password-status="confirmPassword"
              id="confirmPassword"
            />
          </div>
        </div>
        <div className={`${Styles.submitControll}`}>
          <input type="submit" value="save" className={`${Styles.btnBlue}`} />
          <input type="reset" value="clear" className={`${Styles.btnGray}`} />
        </div>
      </form>
    </div>
  );
}
export default memo(ChangePassword);
