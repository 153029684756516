import axios from 'axios';
// import { getItem } from './getLocalStorageItem';
import apiConfig from '../config/api.config.json';

export default async function CallAPI(apiInfo) {
  try {
    const {
      method, url, postData: data, isMultiPart,
    } = apiInfo;
    const token = localStorage.getItem('token'); // await getItem('token') // get token
    const UserKey = localStorage.getItem('UserKey');
    let apiData = {};
    let headers = { 'x-access-token': token, UserKey };
    if (isMultiPart) {
      headers = { ...headers, 'Content-Type': 'multipart/form-data' };
    }

    apiData = { ...apiData, method, url };
    if (data) { // set payload
      apiData = { ...apiData, data };
    }
    if (![apiConfig.API_LOGIN].includes(url)) { // token excemption routes
      if (token) { // set token in headers
        apiData = {
          ...apiData,
          headers,
        };
      }
    }
    const response = await axios(apiData);
    if (response) { return response; }
    return false;
  } catch (err) {
    console.log(`api-err ${apiInfo?.url} failed due to`, err);
    return false;
  }
}
