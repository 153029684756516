import React, { useEffect, useState } from 'react';
import {
  Table, TableHead, TableBody, TableCell, TableRow,
} from '@mui/material';

const DynamicTable = ({
  data, inputColumns, TableClass, handleChange, skipColumns,
}) => {
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setRefresh(!refresh);
  }, [data]);

  const inputHandleChange = (e, index, key) => {
    const regexp = /(^[0-9]{0,2})(\.[0-9]{0,2})?$/;
    if (e.target.value.match(regexp)) {
      handleChange(e, index, key);
      return true;
    }
    return false;
  };
  return (
    <>
      {
            data && data.length
              ? (
                <Table className={TableClass}>
                  <TableHead>
                    <TableRow>
                      {
                                Object.keys(data[0]).map((key) => (
                                  !skipColumns.includes(key)
                                  && <TableCell key={key}>{key.split('_').join(' ')}</TableCell>
                                ))
                            }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                            data.map((row, index) => (
                              <TableRow>
                                {
                                        Object.keys(row).map((key) => (
                                          inputColumns.includes(key)
                                            ? <TableCell><input type="text" value={row[key] ? row[key] : ''} onChange={(e) => inputHandleChange(e, index, key)} /></TableCell>
                                            : !skipColumns.includes(key) && <TableCell>{row[key]}</TableCell>
                                        ))
                                    }
                              </TableRow>
                            ))
                        }
                  </TableBody>
                </Table>
              )
              : null
        }
    </>
  );
};

export default DynamicTable;
