import React, {
  useEffect, useState, useContext, Fragment,
} from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import MUITooltip from '@material-ui/core/Tooltip';
import { LanguageContext } from '../../../LanguageContext';
import '../../../styles/dashboard.css';
import './cm-reports.css';
// import PaginationTab from '../../common/PaginationTab';
import PaginationTab from '../../../common/PaginationTab/PaginationTab';
// import { fetchExecutionResultAsync } from './conditionMonitoringSlice';

export default function CMTable({ page, setPage }) {
  // const dispatch = useDispatch();

  const defaultCardCount = 15;
  const size = defaultCardCount;
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const executionResults = useSelector(
    (state) => state.conditionMonitoringReport.executionResults.rows,
  );

  const count = useSelector(
    (state) => state.conditionMonitoringReport.executionResults.count,
  );

  const getCommunicationType = (item) => {
    let result = '';
    if (item.Communication_SMS) {
      result = 'SMS';
    }
    if (item.Communication_Email) {
      if (result.length > 0) result += ', ';
      result += 'Email';
    }
    return result;
  };

  const dateFormatter = (item) => moment(item).format('DD MMM YYYY HH:mm:ss');

  const [Lang] = useContext(LanguageContext);

  useEffect(() => {
    const st = (page - 1) * size + 1;
    const en = st + (size - 1) > count ? count : st + (size - 1);
    setStart(st);
    setEnd(en);
  }, [page, size, count]);

  const onPrevious = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  const onNext = (pageNumber) => {
    setPage(pageNumber + 1);
  };

  return (
    <>
      <div className="cm-reports-bg">
        <div className="cm-reports cm-table">
          <div className="scrollTab">
            <div className="headerTable">
              <span className="row2 font10">Condition ID</span>
              <span className="row3 font10">Condition Type</span>
              <span className="row4 font10">Machine Model</span>
              <span className="row5 font10">Machine ID </span>
              <span className="row6 font10">Severity </span>
              <span className="row7 font10">Customer </span>
              <span className="row8 font10">Condition Description</span>
              <span className="row9 font10">Formula Description</span>
              {/* <span className="row10 font10">Actual Formula</span> */}
              <span className="row11 font10"> Timestamp</span>
              <span className="row12 font10">Alert Type</span>
              <span className="row13 font10">Communication Type</span>
              <span className="row14 font10">Condition Status</span>
              <span className="row15 font10">Resolving Time</span>

            </div>

            {executionResults && executionResults.length > 0 && (
              <div
                className="pannelWrap eventOverflowScroll common-scroll"
                id="pannelWrap"
              >
                <div className="bs-example">
                  <div className="panel-group" id="accordion">
                    {
                                            executionResults.map((item, index) => (
                                              <div className="panel panel-default alarmRows" key={item.Condition_ID + index}>
                                                <div className="panel-heading">
                                                  <h4 className="panel-title">

                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.Condition_ID}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row2 font10">{item.Condition_ID}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.Condition_Type}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row3 font10">{item.Condition_Type}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.Machine_Model}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row4 font10">{item.Machine_Model}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.Machine_ID}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row5 font10">{item.Machine_ID}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.Severity}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row6 font10">{item.Severity}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.Customer}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row7 font10">{item.Customer}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.Condition_Description}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row8 font10">{item.Condition_Description}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.Formula_Description}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row9 font10">{item.Formula_Description}</span>
                                                    </MUITooltip>
                                                    {/* <MUITooltip className="conditional-monitoring" title={item.Actual_Formula}
                                                                PopperProps={{ className: "MuiTooltip-popper MuiTooltip-popperInteractive toolstips" }}
                                                                arrow={true}
                                                            >
                                                                <span className="row10 font10">{item.Actual_Formula}</span>
                                                            </MUITooltip> */}
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={dateFormatter(item.TimeStamp)}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row11 font10">{dateFormatter(item.TimeStamp)}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.Alert_Type === 'A' ? 'Alert' : 'Notification'}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row12 font10">{item.Alert_Type === 'A' ? 'Alert' : 'Notification'}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={getCommunicationType(item)}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row13 font10">{getCommunicationType(item)}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.IsResolved ? 'Resolved' : 'Not Resolved'}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      <span className="row14 font10">{item.IsResolved ? 'Resolved' : 'Not Resolved'}</span>
                                                    </MUITooltip>
                                                    <MUITooltip
                                                      className="conditional-monitoring"
                                                      title={item.ResolvedOnTimeStamp ? dateFormatter(item.ResolvedOnTimeStamp) : '-'}
                                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                      arrow
                                                    >
                                                      {/* <span className="row15 font10">{dateFormatter(item.ResolvedOnTimeStamp)}</span> */}
                                                      <span className="row15 font10">{item.ResolvedOnTimeStamp ? dateFormatter(item.ResolvedOnTimeStamp) : '-'}</span>
                                                    </MUITooltip>

                                                  </h4>
                                                </div>
                                              </div>
                                            ))
                                        }

                  </div>
                </div>

              </div>
            )}
            {(count === undefined || count === 0)
                            // <div className="font10 noRecords">No Records Available</div>
                            && <div className="noHistoryAlarms">{Lang.NoRecord}</div>}
          </div>

        </div>

        {count > size
                    && (
                    <div
                      className={`
                                                                        alarm-pagination float-container
                                                                        ${count <= defaultCardCount
                        ? 'cardBelowNine'
                        : ''
                            }`}
                    >
                      <div className="panellefttext">
                        <div className="shownotext">
                          {` ${start} to ${end} of ${count} `}
                        </div>
                      </div>
                      <div className="panelrighttext">
                        <PaginationTab
                          Previous={onPrevious}
                          Next={onNext}
                          page={page}
                          maxPage={Math.ceil(count / size)}
                        />
                      </div>
                    </div>
                    )}
      </div>

    </>
  );
}
