import React from 'react';
// import { useHistory } from 'react-router-dom';
// import Logo from '../login/logo';
import Logo from '../Login/images/Logo';
// import Footer from '../dashboard/footer/footer';
// import Footer from '../Footer/Footer';
import styles from './linkExpire.module.css';
// import './setuppin.setting.css';

const LinkExpired = () => {
  // const history = useHistory();
  // console.log('history', history, history.location);

  // const gotoLogin = () => {
  //   history.push('/');
  // };
  console.log('d');
  return (
    <div className={styles.fgs_mainBlk}>
      <div className="text-center centerbox">
        <div className={styles.linkExLogo}>
          <Logo />
        </div>
        <form className="form-signin">
          {/* <div className="closeLogins">
            <a className="crossIcon" onClick={gotoLogin}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.25 6.67871L12.25 12.6787L6.25 18.6787"
                  stroke="#8F8F8F"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.25 6.67871L18.25 18.6787"
                  stroke="#8F8F8F"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </div> */}
          <div className="forgetFormBg">
            <div className="pad20">
              <div className="text-center checkEmail">
                {/* The link is no longer valid. */}
              </div>
              {/* <div className="text-center rightSign">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 1.00024L1 17.0002"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 1.00024L17 17.0002"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div> */}
              <div className={`text-center ${styles.txt}`}>
                We have e-mailed your password reset link!
              </div>
              {/* <button onClick={() => gotoLogin()} type="button" className="btn btn-primary btn-block loginBtn">
                Back to Login
              </button> */}
            </div>
          </div>
        </form>
      </div>
      {/* <Footer /> */}
    </div>
  );
};
export default LinkExpired;
