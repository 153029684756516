import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  DataFetched: false,
  Menus: {},
  Reports: {},
  ReportCodes: [],
  DefaultRoute: '/',
  RoleName: null,
};
/* eslint  no-param-reassign:0 */
export const userManagement = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setMenuAndReport: (state, action) => {
      const {
        Menus, Reports, ReportCodes, RoleName,
      } = action.payload;
      state.Menus = {
        ...Menus,
        // ConditionMonitoring: {
        //   MenuCode: 'ConditionMonitoring',
        //   MenuHeaderName: 'Condition Monitoring',
        //   MenuName: 'Condition Monitoring',
        //   MenuOrderId: 5,
        //   SubMenu: {
        //     ConditionMonitoringDashboard: {
        //       MenuCode: 'ConditionMonitoringDashboard',
        //       MenuHeaderName: 'Condition Monitoring Dashboard',
        //       MenuName: 'Condition Monitoring Dashboard',
        //       MenuOrderId: 1,
        //     },
        //     NotificationGroup: {
        //       MenuCode: 'NotificationGroup',
        //       MenuHeaderName: 'Notification Group',
        //       MenuName: 'Notification Group',
        //       MenuOrderId: 2,
        //     },
        //     NotificationTemplate: {
        //       MenuCode: 'NotificationTemplate',
        //       MenuHeaderName: 'Notification Template',
        //       MenuName: 'Notification Template',
        //       MenuOrderId: 3,
        //     },
        //     ConditionMasters: {
        //       MenuCode: 'Condition Monitoring',
        //       MenuHeaderName: 'Condition Monitoring',
        //       MenuName: 'Condition Monitoring',
        //       MenuOrderId: 4,
        //     },
        //     ExecutionResult: {
        //       MenuCode: 'ExecutionResult',
        //       MenuHeaderName: 'Execution Result',
        //       MenuName: 'Execution Result',
        //       MenuOrderId: 5,
        //     },
        //     AlertNotificationLog: {
        //       MenuCode: 'AlertNotificationLog',
        //       MenuHeaderName: 'Alert/Notification Log',
        //       MenuName: 'Alert/Notification Log',
        //       MenuOrderId: 6,
        //     },
        //   },
        // },
        // FmeaRepository: {
        //   MenuCode: 'FmeaRepository',
        //   MenuHeaderName: 'FMEA Repository',
        //   MenuName: 'FMEA Repository',
        //   MenuOrderId: 6,
        // },
      };
      state.Reports = Reports;
      state.ReportCodes = ReportCodes;
      state.DataFetched = true;
      state.RoleName = RoleName;
    },
    setDefaultRoute: (state, action) => {
      state.DefaultRoute = action.payload;
    },
    resetState: (state) => {
      state.DataFetched = false;
      state.Menus = {};
      state.Reports = {};
      state.ReportCodes = [];
      state.DefaultRoute = '/';
      state.RoleName = null;
    },
  },
});
export const { setMenuAndReport, resetState, setDefaultRoute } = userManagement.actions;

export default userManagement.reducer;
