/* eslint-disable */
import React from "react";
import visavail from "visavail";

import "./styles.css";
import "visavail/visavail.min.css";
import "visavail/visavail.min.js";

class VisavailChart extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = { alarm_bar: {} };

    this.state.alarm_bar["dataset"] = [
      {
        measure: "Balance",
        interval_s: 3 * 30.5 * 24 * 60 * 60,
        data: this.props.Data.map((e) => {
          let status = e.DippingStatus.split(" ");
          return [e.StartTime, status[1] == "OFF" ? 0 : 1,e.EndTime, e.DowntimeReason];
        }),
      },
    ];

    this.state.alarm_bar["options"] = {
      
      id_div_container: "alarm_bar_container",
      id_div_graph: "alarm_bar_div",
      date_in_utc: false,
      // width: 690,
      is_date_only_format: true,
      defined_blocks: true,
      margin: {
        left: 0,
        right: 0,
      },

      line_spacing: 10,
      icon: {
        class_has_data: "fas fa-fw fa-check",
        class_has_no_data: "fas fa-fw fa-exclamation-circle",
      },
      custom_time_format: {
        format_millisecond: ".%L",
        format_second: ":%S",
        format_minute: "%H:%M",
        format_hour: "%H:%M",
        format_day: "%a %d",
        format_week: "%b %d",
        format_month: "%B",
        format_year: "%Y",
      },
      tooltip: {
        height: 18,
        width: 30,
        position: "overlay",
        left_spacing: 15,
        only_first_date: false,
        date_plus_time: true,
      },
      zoom: {
        enabled: true,
      },
      responsive: {
        enabled: true,
      },
      show_y_title: false,
      title: {
        enabled: false,
      },
      sub_title: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      graph: {
        height: 10,
        width: 100,
      },
    };
  }

  renderChart() {
    this.state.alarm_bar["chart"] = visavail.generate(
      this.state.alarm_bar.options,
      this.state.alarm_bar.dataset
    );
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    
    this.state.alarm_bar["dataset"] = [
      {
        measure: "Balance",
        interval_s: 3 * 30.5 * 24 * 60 * 60,
        data: this.props.Data.map((e) => {
          let status = e.DippingStatus.split(" ");
          return [e.StartTime, status[1] == "OFF" ? 0 : 1, e.EndTime];
        }),
      },
    ];

    this.state.alarm_bar.chart.destroy()
    this.renderChart();
    this.state.alarm_bar.chart.updateGraph(this.state.alarm_bar.dataset);
  }

  render() {
    return (
      <div
        className="visavail"
        id="alarm_bar_container"
        key={this?.props?.Data?.[0]?.MachineKey}
      >
        <div id="alarm_bar_div"></div>
      </div>
    );
  }
}

export default VisavailChart;
