import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formData: [],
  shiftReportData: {
    isLoad: false,
    data: [],
    filters: {
      PlantKey: null,
      ShiftKey: null,
      PhaseKey: null,
      LineKey: null,
      DateKey: null,
    },
  },
};

export const shiftReport = createSlice({
  name: 'shiftReport',
  initialState,
  reducers: {
    setShiftReportFilters: (state, action) => {
      state.filters = action.payload;
    },
    setShiftReportFormData: (state, action) => {
      state.formData = action.payload;
      // console.log('state', state, action);
    },
    setShiftReportData: (state, action) => {
      console.log('action.payload,shift report data', action.payload);
      state.shiftReportData = { ...action.payload };
    },
  },
});

export const { setShiftReportFormData, setShiftReportData, setShiftReportFilters } = shiftReport.actions;

export default shiftReport.reducer;
