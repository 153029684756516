import { useEffect, useMemo, useState } from 'react';

// IMPORTING COMPONENTS
import { message } from 'antd';
import UpdateData from './components/UpdateData';
import AdminPanelHead from './components/AdminPanelHead';
import Table from './components/Table';

// IMPORT SERVICES
import userManagementService from './services/userManagement.service';

// IMPORTING MENUS DROPDOWNS VALUE
import SelectMenus from './config/selectMenu.config';
// IMPORTING CSS
import Style from './style.module.css';
/* eslint camelcase:0 */
/* eslint guard-for-in:0 */

const {
  AdminPanelContainer,
  AdminPanel__body,
  noRecord,
} = Style;

// FUNCTION USE TO CALL API
const { CallAPI } = userManagementService;

const SelectMenuItems = [];
const APIForMenus = {};

// ADDING ITEMS FOR SELECT DROPDOWN
for (let i = 0; i < SelectMenus.length; i += 1) {
  const {
    Value, Label, AllowMenu, API,
  } = SelectMenus[i];
  if (AllowMenu === true) {
    const obj = {
      label: Label,
      value: Value,
    };
    SelectMenuItems.push(obj);
    APIForMenus[Value] = API;
  }
}

const defaultSelectedValue = SelectMenuItems[0] ? SelectMenuItems[0] : {
  label: 'Select Table',
  value: 'Select Table',
};
const defaultAPI = APIForMenus[SelectMenuItems[0].value];

const AdminPanel = () => {
  const [defaultData, setDefaultData] = useState({});
  const [selectedTable, setSelectedTable] = useState(defaultSelectedValue);
  const [selectedAPI, setSelectedAPI] = useState(defaultAPI);
  const [isEdit, setIsEdit] = useState(false);
  // const [canAdd, setCanAdd] = useState(false);
  const [operation, setOperation] = useState('INSERT');
  const [dataToUpdate, SetDataToUpdate] = useState(defaultData);
  // const { TableData } = useSelector((state) => state.user);
  const [OriginalData, setOriginalData] = useState([]);
  const [TableData, setTableData] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const columns = useMemo(
    () => {
      const tableColumns = [];
      for (const current in TableData[0]) {
        tableColumns.push({
          Header: current,
          accessor: current,
        });
      }
      return tableColumns;
    },
    [TableData],
  );

  const handleUpdate = (data, Command = 'INSERT') => {
    SetDataToUpdate(data);
    // setCanAdd(canAddValue);
    setIsEdit(true);
    setOperation(Command);
  };

  const handleSelect = (data) => {
    setSelectedTable(data);
    if (data.value !== 'LoadRoleReportMapping') {
      setPageNumber(0);
      setSearchValue('');
    }
  };

  // function UpdateAdminPanel(API, request) {
  function UpdateAdminPanel(API) {
    const LoggedUserKey = localStorage.getItem('UserKey');
    const DefaultLabelValues = {};
    CallAPI(API, { LoggedUserKey }).then((res) => {
      const { status } = res;
      const { data } = res;
      if (status === 200) {
        for (const x in data[0]) {
          DefaultLabelValues[x] = '';
        }

        const filterData = [];
        if (searchValue !== '') {
          for (let i = 0; i < data.length; i += 1) {
            const current = data[i];
            let obj = {};
            for (const x in current) {
              if (current[x] != null && `${current[x]}`.toUpperCase().includes(searchValue)) {
                obj = current;
                filterData.push(obj);
                break;
              }
            }
          }
        }
        if (filterData.length) {
          setTableData(filterData);
        } else {
          setTableData(data);
        }
        setOriginalData(data);
        setDefaultData(DefaultLabelValues);
      } else {
        setTableData({});
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  useEffect(() => {
    const { value = '' } = defaultSelectedValue;
    setSelectedTable(defaultSelectedValue);
    const API = APIForMenus[value];
    UpdateAdminPanel(API);
  }, []);

  useEffect(() => {
    const { value = '' } = selectedTable || {};
    const API = APIForMenus[value];
    if (API) {
      setSelectedAPI(API);
      UpdateAdminPanel(API);
    }
    setIsEdit(false);
    // setCanAdd(false);
    setOperation('INSERT');
    // setDefaultData({});
  }, [selectedTable]);

  const handlesubmitForm = () => {
    const { value = '' } = selectedTable;
    const API = APIForMenus[value];
    UpdateAdminPanel(API);
  };

  const handleCheck = (ColumnName, ColumnValue, RoleReportMapKey, pageIndex) => {
    const LoggedUserKey = localStorage.getItem('UserKey');
    const API = '/api/updateReportAccess';
    setPageNumber(pageIndex);
    handleSelect({ label: 'Role Report Mapping', value: 'LoadRoleReportMapping' });

    CallAPI(API, {
      LoggedUserKey, ColumnName, ColumnValue, RoleReportMapKey,
    }).then((res) => {
      const { status } = res;
      if (status === 200) {
        handleSelect({ label: 'Role Report Mapping', value: 'LoadRoleReportMapping' });
        message.success(`${ColumnName} ${ColumnValue ? 'given' : 'revoked'} Successfully`);
      } else {
        message.error('something went wrong');
      }
    }).catch((e) => {
      console.log(e);
    });
  };
  return (
    <>
      <div className={`${AdminPanelContainer}`}>
        <AdminPanelHead
          defaultSelectedValue={defaultSelectedValue}
          SelectMenuItems={SelectMenuItems}
          selectedTable={selectedTable}
          handleSelect={handleSelect}
          handleUpdate={handleUpdate}
          defaultData={defaultData}
          setTableData={setTableData}
          OriginalData={OriginalData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <div className={`${AdminPanel__body}`}>
          {TableData.length > 0
            ? (
              // ONLY IF DATA EXIST
              <>
                {isEdit === true
                  ? (
                    <UpdateData
                      dataToUpdate={dataToUpdate}
                      setIsEdit={setIsEdit}
                      // canAdd={canAdd}
                      operation={operation}
                      API={selectedAPI}
                      selectedTable={selectedTable}
                      handlesubmitForm={handlesubmitForm}
                    />
                  )
                  : (
                    <Table columns={columns} data={TableData} handleUpdate={handleUpdate} handleCheck={handleCheck} selectedTable={selectedTable?.value} pageNumber={pageNumber} />
                  )}
              </>
            )

            : <div className={`${noRecord}`}>There is No Record</div>}
        </div>
      </div>
    </>
  );
};
export default AdminPanel;
