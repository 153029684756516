import React from 'react';
import './index.css';
import ShiftFilters from './ShiftFilters';
import ShiftTable from './ShiftTable';

function Addentry({ closePopUP }) {
  return (
    <div className="shift_popup">
      <div className="shift_popup__head">
        <h3 className="shift_popup_title">
          Production Shift Report - Add Entry
        </h3>
        <a
          className="shift_close_btn"
          onClick={() => closePopUP((prev) => !prev)}
        >
          &#x2715;
        </a>
      </div>
      <div>
        <ShiftFilters />
      </div>
      <div className="table-content">
        <ShiftTable />
      </div>
    </div>
  );
}

export default Addentry;
