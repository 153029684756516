import React from 'react';
import FTRUpload from './FTRUpload';
// import Aux from "./Auxs";
function Container() {
  return (
    <FTRUpload />
  );
}

export default Container;
