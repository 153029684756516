/* eslint-disable */
import React, { useState, useEffect } from "react";
// import {  useParams } from 'react-router-dom';
import { useHistory, useParams } from "react-router-dom";
import LiHtml from "../../common/horizontalMenu/horizontalMenu";
import "../../styles/failureMode.css";
import ServoMotor from "./servo-motor/ServoMotor.jsx";
import ServoMotorParameter from "./servo-motor-parameter/ServoMotorParameter.jsx";
import { handleURLRouteParameter } from "../../services/handleRoute";
// import ConfigureThreshold from './configure-threshold/ConfigureThreshold';

const DMTab = () => {
  const history = useHistory();
  const { tabType } = useParams();
  const selectedTab = tabType || "servoMotorParameter";
  const [dmState, SetDmState] = useState({
    selectedTab,
  });

  const tabFuntions = (tabvalue) => {
    history.replace(handleURLRouteParameter("settings", tabvalue));
    SetDmState((prevState) => ({ ...prevState, selectedTab: tabvalue }));
  };

  useEffect(() => {
    tabFuntions(tabType || "servoMotor");
  }, [selectedTab]);

  return (
    <>
      <div className="tabArea">
        <ul className="nav nav-tabs tabsName">
          <LiHtml
            tabValue="servoMotor"
            selectedTab={dmState.selectedTab}
            onClickFun={() => tabFuntions("servoMotor")}
            tabName="Servo Motor"
          />
          <LiHtml
            tabValue="servoMotorParameter"
            selectedTab={dmState.selectedTab}
            onClickFun={() => tabFuntions("servoMotorParameter")}
            tabName="Servo Motor Parameter"
          />
          {/* <LiHtml
            tabValue="configureThreshold"
            selectedTab={dmState.selectedTab}
            onClickFun={() => tabFuntions('configureThreshold')}
            tabName="Configure Threshold"
          /> */}
        </ul>
        <div className="tab-content">
          {dmState.selectedTab === "servoMotor" && (
            <div
              id="FailureModeListing"
              className={`tab-pane fade ${
                dmState.selectedTab === "servoMotor" ? "in active" : ""
              }`}
              style={{ padding: "10px" }}
            >
              <ServoMotor />
            </div>
          )}

          {dmState.selectedTab === "servoMotorParameter" && (
            <div
              id="FailureModeListing"
              className={`tab-pane fade ${
                dmState.selectedTab === "servoMotorParameter" ? "in active" : ""
              }`}
              style={{ padding: "10px", overflow: "hidden" }}
            >
              <ServoMotorParameter />
            </div>
          )}

          {/* {dmState.selectedTab === 'configureThreshold' && (
          <div
            id="FailureModeListing"
            className={`tab-pane fade ${dmState.selectedTab === 'configureThreshold'
              ? 'in active'
              : ''
            }`}
          >
            <ConfigureThreshold />
          </div>
          )} */}
        </div>
      </div>
    </>
  );
};
export default DMTab;

/**
 * Configure Threshold
 */
