import Select from 'react-select';

const customStyles = {
  // menu: (provided, state) => {
  menu: (provided) => ({ ...provided }),
  container: (provided) => ({
    ...provided,
  }),

  // singleValue: (provided, state) => {
  singleValue: (provided) => {
    const color = 'white';
    return { ...provided, color };
  },
};
const index = ({ options, onChange, value }) => (
  <Select
    className="dropdown_rsd"
    // name="plant"
    onChange={onChange}
    // defaultValue={false}
    options={options}
    value={value}
    // noOptionsMessage={() => 'Select Proper Plant'}
    styles={customStyles}
  />
);

export default index;
