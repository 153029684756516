import React, {
  Fragment, useState, useEffect,
} from 'react';
// import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
// import { ThemeContext } from '../../../../ThemeContext';
// import { MachineContext } from '../../../../context/userMachineList';
// import { handleURLRouteParameter } from '../../../../services/handleRoutes';
// import UserService from '../../../../services/auth/user.auth';
// import { LanguageContext } from '../../../../LanguageContext';
// import {
//   staticData,
//   apiName,
//   logName,
// } from '../../../../config/configuration';
// import { AuditLogContext } from '../../../../context/auditLogList';
import CMTrends from './CMTrends';
import CMTable from './CMTable';
import CMFilter from './CMFilter';
/* eslint no-unused-vars:0 */
const ReportSlideData = ({
  // visible,
  // setVisible,
  // machineData,
  // visibilityAccess,
  selectedTabs,
  setSelectedTabs,
  // activePage,
  // setActivePage,
}) => {
  // const history = useHistory();
  console.log(selectedTabs, 'selectedTab...');
  // for language change

  useEffect(() => {
    // setActivePage("gelreport");
  }, []);
  // const [Lang, ,] = useContext(LanguageContext);
  const [filterVisible, setFilterVisible] = useState(true);
  // const { currentTheme } = useContext(ThemeContext);
  // const [state, dispatch] = useContext(MachineContext);
  // const [currentRole, setCurrentRole] = useState('');
  // const [auditLogList, dispatchAuditLog] = useContext(AuditLogContext);
  const [page, setPage] = useState(1);

  return (
    <>
      <div>
        <div className="breadcrumsCls">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {/* <li className="breadcrumb-item active">{Lang.monitoring}</li>
              <li className="breadcrumb-item active">{"Condition Monitoring"}</li>
              <li className="breadcrumb-item active" aria-current="page">
                {selectedTabs}
              </li>

              <ReactTooltip
                id="custom-color-no-arrow"
                place="bottom"
                className="custom-color-no-arrow"
                arrowColor={currentTheme === "dark" ? "#000" : "#fff"}
                html={true}
                effect="solid"
              /> */}
            </ol>
          </nav>
        </div>

        <div className="slide-content">
          <div className="tabArea">
            <div className="mobileTabScroll">
              {/* search bar for mobile view */}
              <div
                className="DglobalSearch DglobalSearch-mobile SearchMobileMonitoring"
                id="mobileSearch"
              />
              <ul className="nav nav-tabs tabsName navscroll">
                <li
                  className={`pointer ${selectedTabs === 'Condition-Monitoring-Trends' ? 'active' : ''
                  }`}
                >
                  <a
                    id="Condition-Monitoring-Trends"
                    data-toggle="tab"
                    onClick={(e) => {
                      //   history.replace(
                      //     handleURLRouteParameter("cmreport", e.target.id)+"?reportType=condition-monitoring-report"
                      //   );
                      setSelectedTabs(e.target.id);
                    }}
                  >
                    Condition Monitoring Trends
                  </a>

                  <ReactTooltip
                    id="custom-color-no-arrow4"
                    place="bottom"
                    className="custom-color-no-arrow4"
                    // arrowColor={currentTheme === 'dark' ? '#000' : '#fff'}
                    html
                    effect="solid"
                  />
                </li>
                <li
                  id="avsuLi"
                  className={`pointer ${selectedTabs === 'Condition Monitoring Table' ? 'active' : ''
                  }`}
                >
                  <a
                    id="Condition Monitoring Table"
                    data-toggle="tab"
                    onClick={(e) => {
                      //   history.replace(
                      //     handleURLRouteParameter("cmreport", e.target.id)+"?reportType=condition-monitoring-report"
                      //   );
                      setSelectedTabs(e.target.id);
                    }}
                  >
                    Condition Monitoring Table
                  </a>
                </li>

                {/* last updated date time over tabs */}
              </ul>
            </div>

            <div className="tab-content">
              <div className="conditionalmonitoringreport">
                <div className="contentPart">
                  <div className={`${filterVisible ? '' : 't'}`}>

                    <CMFilter setFilterVisible={setFilterVisible} filterVisible={filterVisible} page={page} />

                  </div>

                  <div
                    id="Condition-Monitoring_Trends"
                // prettier-ignore
                    className={`${filterVisible ? 'not_cmtrends' : 'cmtrends'} tab-pane fade ${selectedTabs === 'Condition-Monitoring-Trends' ? 'in active' : ''}`}
                  >
                    <CMTrends />
                  </div>

                  <div
                    id="Condition-Monitoring_Table"
                // prettier-ignore
                    className={`${filterVisible ? 'not_cmtable' : 'cmtable'} tab-pane fade ${selectedTabs === 'Condition Monitoring Table' ? 'in active' : ''}`}
                  >
                    <CMTable page={page} setPage={setPage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportSlideData;
