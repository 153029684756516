/* eslint-disable class-methods-use-this */
import axios from 'axios';
import config from '../config/api.config.json';
import AppRoutes from '../config/appicationRoutes.config.json';
import { getSubMenuRoute } from '../helpers/constants';
import authHeader from '../config/auth.header';

class UserManagement {
  login(request) {
    return axios
      .post(config.API_LOGIN, request)
      .then((response) => ({
        status: response.status,
        data: response.data.data,
      }))
      .catch((error) => {
        console.log(error);
        return {
          status: error.response.status,
          data: error,
        };
      });
  }

  getUserData(request) {
    const headers = {
      headers: authHeader(),
    };
    console.log(headers);
    return axios
      .post(config.GET_USER_DATA, request, headers)
      .then((response) => ({
        status: response.status,
        data: response.data,
      }))
      .catch((error) => ({
        status: error.response.status,
        data: error,
      }));
  }

  getDefaultRoute(menu) {
    // // GETTING ROUTES
    const {
      FTR, FTR_UPLOAD, IOMATRIX, REPORTS, WELCOME, FailureModeDetails: FailureModeDetailsRoute,
    } = AppRoutes;

    let defaultRoute = WELCOME;
    if (menu.Reports) {
      defaultRoute = REPORTS;
    } else if (menu.FTR) {
      defaultRoute = FTR;
    } else if (menu.FTRUpload) {
      defaultRoute = FTR_UPLOAD;
    } else if (menu.IOMatrix) {
      defaultRoute = IOMATRIX;
    } else if (menu.COND) {
      defaultRoute = getSubMenuRoute(menu?.COND?.SubMenu[0].MenuCode);
    } else if (menu.FMEA) {
      defaultRoute = FailureModeDetailsRoute;
    }
    return defaultRoute;
  }

  getInnerReports(request) {
    const headers = {
      headers: authHeader(),
    };
    return axios
      .post(config.GET_INNER_REPORTS, request, headers)
      .then((response) => ({
        status: response.status,
        data: response.data,
      }))
      .catch((error) => ({
        status: error.response.status,
        data: error,
      }));
  }

  forgetLink(request) {
    const headers = {
      headers: authHeader(),
    };
    return axios
      .post(config.API_FORGETEMAILPASSWORD, { email: request }, headers)
      .then((response) => ({
        status: response.status,
        data: response.data,
      }))
      .catch((error) => ({
        status: error.response.status,
        data: error,
      }));
  }

  setPasswordApi(data) {
    const headers = {
      headers: authHeader(),
    };
    return axios
      .post(config.API_SETPASSWORD, data, headers)
      .then((response) => ({
        status: response.status,
        data: response.data,
      }))
      .catch((error) => ({
        status: error.response.status,
        data: error,
      }));
  }

  forgotPasswordApi(data) {
    const headers = {
      headers: authHeader(),
    };
    return axios
      .post(config.API_RESETPASSWORDFROMLINK, data, headers)
      .then((response) => ({
        status: response.status,
        data: response.data,
      }))
      .catch((error) => ({
        status: error.response.status,
        data: error,
      }));
  }

  logout(request) {
    const headers = {
      headers: authHeader(),
    };
    return axios
      .post(config.API_LOGOUT, request, headers)
      .then((response) => ({
        status: response.status,
        data: response.data.data,
      }))
      .catch((error) => {
        console.log(error);
        return {
          status: error.response.status,
          data: error,
        };
      });
  }
}

export default new UserManagement();
