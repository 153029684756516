// import { useState } from 'react';
import { useState } from 'react';
import { message } from 'antd';
import moment from 'moment';
import EMSelect from '../EnergyMeter/EMSelect';
// import Switch from '../../../common/Switch/Switch';
// import DatePicker from '../../../common/DatePicker/Datepicker';
import './style.css';

const options = [{
  label: 'Addition',
  value: 'Addition',
},
{
  label: 'Subtraction',
  value: 'Subtraction',
}];
const EditForm = ({ data, onSubmit }) => {
  // console.log(data);
  const [formData, setFormData] = useState({ ...data });
  // const formData = data;
  const [selected, setSelected] = useState(data.Addition === 'Y' ? options[0] : options[1]);
  // const isOn = data.Addition === 'Y';
  // console.log('formData-edit', formData);
  const handleOnChange = (event) => {
    const { value, name } = event.target;
    // if (name === 'Factor' && !event.target.validity.valid) {
    //   return false;
    // }
    if (name === 'ValidTo') {
      if (new Date(formData.ValidFrom).getTime() >= new Date(value).getTime()) {
        message.info('ValidTo date should be graterthan validFrom date');
        return false;
      }
    }
    if (name === 'ValidFrom') {
      if (new Date(formData.ValidTo).getTime() <= new Date(value).getTime()) {
        message.info('ValidFrom date should be lessthan validTo date');
        return false;
      }
    }
    setFormData((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleSelectChange = (e) => {
    const { value } = e.target;
    const { label } = e.target.dataset;
    // console.log('handleSelectChange', label, value);
    setSelected({ value, label });
    setFormData((prev) => ({
      ...prev,
      Addition: value === 'Addition' ? 'Y' : 'N',
    }));
  };
  return (
    <form>
      <div className="formBody">
        <div className="Form__field">
          <label>Factor</label>
          <span className="redStar">*</span>
          {/* pattern="[0-2]" */}
          <input type="text" name="Factor" value={formData.Factor} data-field="Factor" onChange={handleOnChange} />
        </div>
        <div className="Form__field">
          <label>Addition/Substraction</label>
          <span className="redStar">*</span>
          {/* <Switch isOn={isOn} className="Form__field_switch" /> */}
          <EMSelect title={selected.label} options={options} onChange={handleSelectChange} />
          {/* <input type="text" value={formData.Addition} data-field="Addition" onChange={handleOnChange} /> */}
        </div>
        <div className="Form__field">
          <label>ValidFrom</label>
          <span className="redStar">*</span>
          <input type="date" name="ValidFrom" value={formData.ValidFrom !== '' ? moment(formData.ValidFrom).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')} onChange={handleOnChange} />
          {/* <DatePicker value={formData.ValidFrom} /> */}
        </div>
        <div className="Form__field">
          <label>ValidTo</label>
          <span className="redStar">*</span>
          <input type="date" name="ValidTo" value={formData.ValidTo !== '' ? moment(formData.ValidTo).format('YYYY-MM-DD') : moment('2099-12-31').format('YYYY-MM-DD')} onChange={handleOnChange} />
          {/* <DatePicker value={formData.ValidTo} /> */}
        </div>
        <div className="Form__field">
          <label>Remarks</label>
          <span className="redStar">*</span>
          <textarea name="Remark" rows="4" cols="50" placeholder="Enter Remarks" value={formData.Remark} onChange={handleOnChange} />
          {/* <DatePicker value={formData.ValidTo} /> */}
        </div>
      </div>
      <div className="formFooter">
        <input type="button" className="btn-primary" value="Update" onClick={() => onSubmit(formData)} />
      </div>
    </form>
  );
};

export default EditForm;
