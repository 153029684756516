import axios from 'axios';
import authHeader from '../config/auth.header';

function CallAPI(API, request = {}) {
  const headers = {
    headers: authHeader(),
  };
  return axios
    .post(API, request, headers)
    .then((response) => ({
      status: response.status,
      data: response.data.data,
    }))
    .catch((error) => {
      console.log(error);
      return {
        status: error,
        data: error,
      };
    });
}

export default CallAPI;
