/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import { Form, Field } from 'react-final-form';
/* eslint  no-unused-vars:0 */
// import { MomentUtils } from '@date-io/moment/build';
import DateFnsUtils from '@date-io/date-fns';
// import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { LanguageContext } from '../../../LanguageContext';
// import '../../../../src/components/dashboard/monitoring/kpi/kpi.css';
import '../../../styles/kpi.css';
import {
  fetchMachineNameAsync,
} from '../../../redux/slices/executionResultSlice';

const validate = () => { };

export default function AlertNotificationFilter({ filterVisible, setFilterVisible, onSubmited }) {
  const dispatch = useDispatch();

  const [isFilterActive, setIsFilterActive] = useState(false);

  const communicationMode = [{ commText: 'Email' }, { commText: 'Sms' }];
  const communicationType = [{ comTypeText: 'Alert' }, { comTypeText: 'Notification' }];

  const [model, setModel] = useState('');
  const [comMode, setComMode] = useState('');
  const [comType, setComType] = useState('');
  // const [endDate, setEndDate] = useState('');
  // var dispatch = useDispatch();
  const [Lang] = useContext(LanguageContext);
  const formRef = useRef(null);

  useEffect(() => {
    dispatch(fetchMachineNameAsync());
  }, []);

  const onSubmit = async (values) => {
    if (values.machine_id || values.From_Date || values.To_Date || values.comMode || values.comType) {
      console.log(values);
      setIsFilterActive(true);
      onSubmited(values);
    } else {
      setIsFilterActive(false);
      onSubmited({});
    }
  };

  useEffect(() => {
    const { values } = formRef.current.getState();
    onSubmit(values);
  }, []);

  const machineNames = useSelector(
    (state) => state.executionResult.machineNames,
  );

  const fromStart = new Date();
  fromStart.setDate(fromStart.getDate() - 30);
  const toEnd = new Date();

  return (
    <>
      <div
        className={`filterInnerDiv ${filterVisible ? 'active' : ''} 
            ${isFilterActive ? 'filter-is-on' : ''}`}
        onClick={() => setFilterVisible(!filterVisible)}
      >
        <span
          className="filterIcon pointer"
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.418 2.5H2.41797L7.21797 8.80667V13.1667L9.61797 14.5V8.80667L14.418 2.5Z"
              stroke="#8F8F8F"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="cm-filter conditionMonitoring">
        <div className={`filterDiv ${filterVisible ? '' : 'removedWidth'}`}>
          <div className="exResultOverflowScroll">
            <Form
              mutators={{
                setReset: (args, state, utils) => {
                  utils.changeValue(state, 'From_Date', () => fromStart);
                  utils.changeValue(state, 'To_Date', () => toEnd);
                  utils.changeValue(state, 'machine_id', () => '');
                  utils.changeValue(state, 'comMode', () => '');
                  utils.changeValue(state, 'comType', () => '');
                  setModel('');
                  setComMode('');
                  setComType('');
                  setIsFilterActive(false);
                },
              }}
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{ From_Date: moment(fromStart).format('YYYY-MM-DDTHH:mm'), To_Date: moment(toEnd).format('YYYY-MM-DDTHH:mm') }}
              keepDirtyOnReinitialize
              // render={({ handleSubmit, form, reset }) => {
              render={({ handleSubmit, form }) => {
                formRef.current = form;
                return (
                  <div className="slick-carousel slickcarouselTop">

                    <form onSubmit={handleSubmit} id="failure-mode-form">

                      <div>
                        <span
                          className="resetBtn resetpost pointer"
                          onClick={() => {
                            form.mutators.setReset();
                            onSubmit({});
                          }}
                        >
                          {Lang.Reset}
                        </span>

                        <div>
                          <div className="form-group mui-date-picker">
                            <div className="input-control">
                              <label className="font10">From</label>
                              <div className="input-group">
                                <Field
                                  name="From_Date"
                                  className="form-control"
                                >
                                  {(props) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <DateTimePicker
                                        disableFuture
                                        variant="inline"
                                        format="dd-MM-yyyy HH:mm"
                                        margin="normal"
                                        id="from"
                                        autoOk
                                        maxDate={form.getFieldState('To_Date') && form.getFieldState('To_Date').value}
                                        value={props.input.value}
                                        onChange={(value) => {
                                          props.input.onChange(value);
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="form-group mui-date-picker">
                            <div className="input-control">
                              <label className="font10">To</label>
                              <div className="input-group">
                                <Field
                                  name="To_Date"
                                  className="form-control"
                                >
                                  {(props) => (
                                    <DateTimePicker
                                      disableFuture
                                      variant="inline"
                                      format="dd-MM-yyyy HH:mm"
                                      margin="normal"
                                      id="to"
                                      autoOk
                                      minDate={form.getFieldState('From_Date') && form.getFieldState('From_Date').value}
                                      value={props.input.value}
                                      onChange={(value) => {
                                        props.input.onChange(value);
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-control">
                              <label className="font10">
                                {Lang.MachineModel}
                              </label>
                              <div className="input-group">
                                <div>
                                  <Field
                                    name="machine_id"
                                    className="form-control"
                                    placeholder="Select"
                                  >
                                    {(props) => (
                                      <Autocomplete
                                        value={(machineNames && machineNames.find((m) => m.machine_id === (props.input.value || model))) || ''}
                                        getOptionSelected={(option, value) => option.machine_id === value.machine_id}
                                        onChange={(event, value) => {
                                          setModel(value ? value.machine_id : '');
                                          props.input.onChange(value ? value.machine_id : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={machineNames}
                                        getOptionLabel={(option) => option.machine_id || ''}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Machine Model" variant="outlined" />}
                                        disablePortal
                                      />
                                    )}
                                  </Field>
                                  <span id="machinemodel" className="caret" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-control">
                              <label className="font10">Communication Type</label>
                              <div className="input-group">
                                <div>
                                  <Field
                                    name="comType"
                                    className="form-control"
                                    placeholder="Select"
                                  >
                                    {(props) => (
                                      <Autocomplete
                                        value={(communicationType && communicationType.find((m) => m.comTypeText === (props.input.value || comType))) || ''}
                                        getOptionSelected={(option, value) => option.comTypeText === value.comTypeText}
                                        onChange={(event, value) => {
                                          setComType(value ? value.comTypeText : '');
                                          props.input.onChange(value ? value.comTypeText : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={communicationType}
                                        getOptionLabel={(option) => option.comTypeText || ''}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Communication Type" variant="outlined" />}
                                        disablePortal
                                      />
                                    )}
                                  </Field>
                                  <span id="comType" className="caret" />
                                </div>

                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-control">
                              <label className="font10">Communication Mode</label>
                              <div className="input-group">
                                <div>
                                  <Field
                                    name="comMode"
                                    className="form-control"
                                    placeholder="Select"
                                  >
                                    {(props) => (
                                      <Autocomplete
                                        value={(communicationMode && communicationMode.find((m) => m.commText === (props.input.value || comMode))) || ''}
                                        getOptionSelected={(option, value) => option.commText === value.commText}
                                        onChange={(event, value) => {
                                          setComMode(value ? value.commText : '');
                                          props.input.onChange(value ? value.commText : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={communicationMode}
                                        getOptionLabel={(option) => option.commText || ''}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Communication Mode" variant="outlined" />}
                                        disablePortal
                                      />
                                    )}
                                  </Field>
                                  <span id="comMode" className="caret" />
                                </div>

                              </div>
                            </div>
                          </div>

                          <Button
                            className="comnEditDel MuiButton-containedPrimary editGroupBtn floatLeft"
                            type="submit"
                          >
                            GO
                          </Button>
                        </div>

                      </div>

                    </form>
                  </div>
                );
              }}
            />

          </div>

        </div>
      </div>
    </>
  );
}
