const reports = {};

reports.ECRRpt = require('./ECR/ECR').default;
reports.ECRRpt_new = require('./ECR/ECR').default;

reports.GelroomRpt = require('./ReportTabs/index').default;
reports.RSDRpt = require('./RSD/index').default;
reports.DownTimeRpt = require('./ReportTabs/index').default;
reports.ConditionMonitoringRpt = require('./condition-monitoring/ConditionMonitoring').default;
// reports.SeniorManagementRpt = require('./SeniorManagement/index').default;
reports.SeniorManagementRpt = require('./ReportTabs/index').default;
reports.ShiftPerformanceRpt = require('./ShiftReport/index').default;

export default reports;
