import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMaintenanceConditions, saveActiveStatus } from '../../components/ConditionMonitoring/maintenance-condition/maintenanceViewApi';

const initialState = {
  maintenanceConditions: {},
  status: 'idle',
};

export const getMaintenanceConditionsAsync = createAsyncThunk(
  'maintenanceConditions/getMaintenanceConditions',
  async ({
    page, size, search, sortName, sortDirection, activeStatus,
  }) => {
    const response = await getMaintenanceConditions(page, size, search, sortName, sortDirection, activeStatus);
    return response;
  },
);

export const saveActiveStatusAsync = createAsyncThunk(
  'maintenanceConditions/saveActiveStatus',
  async ({ id, status }) => {
    const request = await saveActiveStatus(id, status);
    return request;
  },
);

export const maintenanceConditionsSlice = createSlice({
  name: 'maintenanceConditions',
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMaintenanceConditionsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMaintenanceConditionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.maintenanceConditions = action.payload;
      })

      .addCase(saveActiveStatusAsync.pending, (state, action) => {
        state.status = 'loading';
        const { id, status } = action.meta.arg;
        const maintenanceCondition = state.maintenanceConditions.rows.find((r) => r.MaintenanceCondition_ID === id);
        maintenanceCondition.IsActive = status;
      })
      .addCase(saveActiveStatusAsync.fulfilled, (state) => {
        state.status = 'idle';
      });
  },
});

export default maintenanceConditionsSlice.reducer;
