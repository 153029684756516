/* eslint-disable */
import React from "react";

import "../../../../styles/failureMode.css";

const Select = ({
  errors,
  touched,
  handleChange,
  values,
  title,
  name,
  inputType,
  options,
  disable,
}) => (
  <select
    name={name}
    className="selct"
    placeholder="DataCategory"
    value={values[name]}
    onChange={handleChange}
    disabled={disable ?? false}
  >
    <option value="">Select any option</option>
    {options?.map((e) => (
      <option value={e.value}>{e.label}</option>
    ))}
  </select>
);

export default Select;
