/*eslint-disable*/
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import DMFilterReducer from "./slices/filterDMSlice";
import notificationGroupSlice from "./slices/notificationGroupSlice";
import failureModeListingReducer from "./slices/failureModeListingSlice";
import failureModeDetailsReducer from "./slices/failureModeDetailsSlice";
import failureModeEntryReducer from "./slices/failureModeAddSlice";
import failureIndicatorsEntryReducer from "./slices/failureIndicatorsViewSlice";
import failureIndicatorsAddReducer from "./slices/failureIndicatorsAddSlice";
import potentialCausesAddReducer from "./slices/potentialCauseAddSlice";
import causualParametersAddReducer from "./slices/causualParametersAddSlice";
import recommendedActionsEntryReducer from "./slices/recommendedActionSlice";
// import MachineList from "../store/reducers/index";
import generalConditionsViewReducer from "./slices/generalConditionViewSlice";
import generalConditionAddReducer from "./slices/generalConditionAddSlice";
// import notificationGroupReducer from './features/notification-group/notificationGroupSlice';
import notificationGroupDetailsReducer from './slices/notificationGroupDetailsSlice';
import schedulingReducer from './slices/schedulingSlice';
import notificationTemplateReducer from './slices/notificationTemplateSlice';
import failureNotificationViewReducer from './slices/failureNotificationViewSlice';
import failureNotificationAddReducer from './slices/failureNotificationAddSlice';
import maintenanceViewReducer from './slices/maintenanceViewSlice';
import maintenanceAddReducer from './slices/maintenanceAddSlice';
import conditionMonitoringReducer from './slices/conditionMonitoringSlice';
import cmDashboardReducer from './slices/cmDashboardSlice';
import failureModeImportReducer from './slices/ImportFMEAListingSlice';
import executionResultReducer from './slices/executionResultSlice';
import alertNotificationReducer from './slices/alertnotificationsSlice';
import shiftReportReducer from './slices/shiftReportSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    notificationGroup: notificationGroupSlice,
    cmDashboardOutput: cmDashboardReducer,
    notificationGroupDetails: notificationGroupDetailsReducer,
    notificationTemplate: notificationTemplateReducer,

    failureModeListing: failureModeListingReducer,
    failureModeDetails: failureModeDetailsReducer,
    failureModeEntry: failureModeEntryReducer,
    failureIndicatorsEntry: failureIndicatorsEntryReducer,
    failureIndicatorsAdd: failureIndicatorsAddReducer,
    potentialCausesAdd: potentialCausesAddReducer,
    causualParametersAdd: causualParametersAddReducer,
    recommendedActionsEntry: recommendedActionsEntryReducer,
    // mac: MachineList,
    generalConditionsView: generalConditionsViewReducer,
    generalConditionAdd: generalConditionAddReducer,
    // notificationGroup: notificationGroupReducer,
    // notificationGroupDetails: notificationGroupDetails,
    scheduling: schedulingReducer,
    failureNotificationsView: failureNotificationViewReducer,
    failureNotificationAdd: failureNotificationAddReducer,
    maintenanceView: maintenanceViewReducer,
    maintenanceAdd: maintenanceAddReducer,
    conditionMonitoringReport: conditionMonitoringReducer,
    failureModeImport: failureModeImportReducer,
    executionResult: executionResultReducer,
    alertnotfications: alertNotificationReducer,
    shiftReport: shiftReportReducer,
    DMFilter: DMFilterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
