import { useCallback, useEffect, useState } from 'react';

// IMPORT COMPONENTS
import Select from './Select';
import Input from './Input';

// IMPORT SERVICES
import userManagement from '../../services/userManagement.service';

// IMPORTING CONFIG
// import UserConfig from '../../config/Form/user.config.json';

// IMPORTING HELPER
import getFormScema from '../../helper/getFormSchema';

// IMPORTING CSS
import Style from './style.module.css';

// const userArr = Array.from(UserConfig);
const {
  UpdateDataContainer,
  formActions,
  formControls,
  formRow,
  inputControl,
  inputText,
  labelControl,
} = Style;
const { CallAPI } = userManagement;
const operations = {
  INSERT: 'Save',
  UPDATE: 'Update',
  DELETE: 'Delete',
};
const index = ({
  dataToUpdate, setIsEdit, API, selectedTable, handlesubmitForm, operation,
}) => {
  const isDisable = operation === 'DELETE';
  const FormScema = getFormScema(selectedTable.value);
  const submitValue = operations[operation];
  const [formData, setFormData] = useState(dataToUpdate);
  // const Data = Object.keys(dataToUpdate);

  // HANDLING INPUT FEILDS
  const handleOnChange = (e) => {
    const { keyname } = e.target.dataset;
    const { value } = e.target;
    setFormData({
      ...formData,
      [keyname]: value,
    });
  };

  // HANDLE RESET FORM
  const handleReset = useCallback(() => {
    setIsEdit(false);
  }, []);

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form Validation
    for (let i = 0; i < FormScema.length; i += 1) {
      const { ColumnName, Required } = FormScema[i];
      if (Required && Required === true && formData[ColumnName] === '') {
        alert(`Pleae Enter ${ColumnName}`);
        return;
      }
    }
    // const Command = canAdd ? 'INSERT' : 'UPDATE';
    const Command = operation;
    const LoggedUserKey = localStorage.getItem('UserKey');
    const request = { ...formData, LoggedUserKey };
    request.Command = Command;

    // for (const x in request) {
    //   if (request[x] === null) {
    //     request[x] = '';
    //   }
    // }
    const result = await CallAPI(API, request);
    const { status, data } = result;
    let msg = 'Something Went Wrong';
    if (status === 200) {
      setIsEdit(false);
      msg = data[0] ? data[0].SPResult : msg;
      // Updateing Admin Panel Data
      handlesubmitForm();
    }
    /* eslint no-alert:off */
    alert(msg);
  };

  // UPDATING FORM FIELDS WHEN SELECT CHANGE
  useEffect(() => {
    setFormData(dataToUpdate);
  }, [dataToUpdate]);

  return (
    <form className={`${UpdateDataContainer}`} onReset={handleReset} onSubmit={handleSubmit}>
      <div className={`${formControls}`}>
        {FormScema.map((current, key) => (
          <div className={`${formRow}`} key={key}>
            <div className={`${labelControl}`}>
              <label>
                {current.ColumnName}
                {' '}
                <span>{current.Required === true ? <span className="star-sign">*</span> : ''}</span>
              </label>
            </div>
            <div className={`${inputControl}`}>
              {current.Type === 'select'
                ? (
                  <Select
                    value={formData[current.ColumnName] != null ? formData[current.ColumnName] : ''}
                    formData={formData}
                    setFormData={setFormData}
                    current={current.ColumnName}
                    API={current.API}
                    data={current}
                    disable={isDisable}
                  />
                )
                : (
                  <Input
                    formData={formData}
                    current={current}
                    handleOnChange={handleOnChange}
                    inputText={inputText}
                    disabled={isDisable}
                  />
                  // <input
                  //   value={formData[current.ColumnName] != null ? formData[current.ColumnName] : ''}
                  //   data-keyname={current.ColumnName}
                  //   onChange={handleOnChange}
                  //   className={`${inputText}`}
                  //   required={current.required}
                  //   type={current.Type}
                  // />
                )}
            </div>
          </div>
        ))}

      </div>
      <div className={`${formActions}`}>
        <input type="submit" value={submitValue} className={`btn ${operation === 'DELETE' ? 'btn-danger' : 'btn-primary'}`} />
        <input type="reset" value="Cancel" className="btn btn-clear" />
      </div>
    </form>
  );
};
export default index;

// Data.map((current, key) => (
//   <div className={`${formRow}`} key={key}>
//     <div className={`${labelControl}`}>
//       <label>{current}</label>
//     </div>
//     <div className={`${inputControl}`}>
//       {selectedTable && selectedTable.value !== 'LoadRole' && current === 'RoleName'
//         ? (
//           <Select
//             value={formData[current] != null ? formData[current] : ''}
//             formData={formData}
//             setFormData={setFormData}
//             current={current}
//           />
//         )
//         : (
//           <input
//             value={formData[current] != null ? formData[current] : ''}
//             data-keyname={current}
//             onChange={handleOnChange}
//             className={`${inputText}`}
//             required={selectedTable && selectedTable.value === 'User' && current === 'EmailId'}
//           />
//         )}
//     </div>
//   </div>
