const customSelect = {
  placeholder: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  control: (props, state) => ({
    ...props,
    width: '200px',
    border: 'none',
    boxShadow: state.isFocused && 'none',
    backgroundColor: '#1f1f1f',
    fontSize: '12px',
    outline: 'none',
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  singleValue: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  input: (props) => ({
    ...props,
    color: '#e0e0e0',
  }),
  menu: (props) => ({
    ...props,
    backgroundColor: '#1f1f1f',
  }),
  option: (props, state) => ({
    ...props,
    backgroundColor: state.isFocused ? '#5D97F6' : '',
    color: state.isFocused ? '#121212' : '#e0e0e0',
    fontSize: '12px',
  }),
};
export default customSelect;
