import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  fetchMachineModel, fetchMachineSection, fetchMachineFunction,
  editMaintenanceCondition, saveMaintenanceCondition, fetchNotificationGroup,
  fetchNotificationTemplate, fetchMachineParameters, fetchMachineModelName,
} from '../../components/ConditionMonitoring/maintenance-condition/maintenanceAddApi';
// import { saveRecommendedActions, deleteRecommendedActions } from '../..//recommendedActionApi';
import { saveRecommendedActions, deleteRecommendedActions } from '../../components/ConditionMonitoring/recommended-actions/recommendedActionApi';
// import { Success_Message, Error_Message } from "../../../configs/config";
import { SuccessMessage, DuplicateRecord } from '../../helpers/constants';

const initialState = {
  machineModels: [],
  machineModelStatus: 'idle',
  machineModelNames: [],
  machineModelNameStatus: 'idle',
  machineSections: [],
  machineSectionStatus: 'idle',
  machineFunctions: [],
  machineFunctionStatus: 'idle',
  machineParameters: [],
  machineParametersStatus: 'idle',
  notificationGroups: [],
  notificationGroupStatus: 'idle',
  notificationTemplates: [],
  notificationTemplateStatus: 'idle',
  maintenanceConditionStatus: 'idle',
  editMaintenanceConditions: {},
  editMaintenanceConditionsStatus: 'idle',
  recommendedActionsStatus: 'idle',
  recordSaved: false,
};

export const saveMaintenanceConditionAsync = createAsyncThunk(
  'maintenanceConditionAdd/saveMaintenanceConditionDetails',
  // async (body, thunkApi) => {
  async (body) => {
    const response = await saveMaintenanceCondition(body);
    return response;
  },
);

export const editMaintenanceConditionAsync = createAsyncThunk(
  'maintenanceConditionAdd/editMaintenanceConditions',
  async ({ id }) => {
    const response = await editMaintenanceCondition(id);
    return response;
  },
);

export const fetchMachineModelAsync = createAsyncThunk(
  'maintenanceConditionAdd/fetchMachineModel',
  async () => {
    const response = await fetchMachineModel();
    return response;
  },
);

export const fetchMachineModelNameAsync = createAsyncThunk(
  'maintenanceConditionAdd/fetchMachineModelName',
  async (modelId) => {
    const response = await fetchMachineModelName(modelId);
    return response;
  },
);

export const fetchMachineSectionAsync = createAsyncThunk(
  'maintenanceConditionAdd/fetchMachineSection',
  async (modelId) => {
    const response = await fetchMachineSection(modelId);
    return response;
  },
);

export const fetchMachineFunctionAsync = createAsyncThunk(
  'maintenanceConditionAdd/fetchMachineFunction',
  async (sectionId) => {
    const response = await fetchMachineFunction(sectionId);
    return response;
  },
);

export const fetchMachineParametersAsync = createAsyncThunk(
  'maintenanceConditionAdd/fetchMachineParameters',
  async ({ model, section, fun }) => {
    const response = await fetchMachineParameters(
      model,
      section,
      fun,
    );
    return response;
  },
);

export const fetchNotificationGroupAsync = createAsyncThunk(
  'maintenanceConditionAdd/fetchNotificationGroup',
  async () => {
    const response = await fetchNotificationGroup();
    return response;
  },
);

export const fetchNotificationTemplateAsync = createAsyncThunk(
  'maintenanceConditionAdd/fetchNotificationTemplate',
  async () => {
    const response = await fetchNotificationTemplate();
    return response;
  },
);

export const saveRecommendedActionsAsync = createAsyncThunk(
  'maintenanceConditionAdd/saveRecommendedActions',
  // async (recommendedAction, thunkApi) => {
  async (recommendedAction) => {
    if (recommendedAction.MaintenanceCondition_ID) {
      const response = await saveRecommendedActions(recommendedAction);
      // thunkApi.dispatch(setRecommendedAction(response));
      return response;
    }

    // thunkApi.dispatch(setRecommendedAction(recommendedAction));
    return recommendedAction;
  },
);

export const deleteRecommendedActionsAsync = createAsyncThunk(
  'maintenanceConditionAdd/deleteRecommendedActions',
  // async (recommendedActionId, thunkApi) => {
  async (recommendedActionId) => {
    const response = await deleteRecommendedActions(recommendedActionId);
    if (response === 'OK') {
      // thunkApi.dispatch(removeRecommendedAction(recommendedActionId));
    }
    return response;
  },
);

export const maintenanceConditionEntry = createSlice({
  name: 'maintenanceConditionAdd',
  initialState,
  reducers: {

    // clearSaveRecord: (state, action) => {
    clearSaveRecord: (state) => {
      state.recordSaved = false;
      state.editMaintenanceConditions = {};
    },

    clearEdit: (state) => {
      state.editMaintenanceConditions = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineModelAsync.pending, (state) => {
        state.machineModelStatus = 'loading';
      })
      .addCase(fetchMachineModelAsync.fulfilled, (state, action) => {
        state.machineModelStatus = 'idle';
        state.machineModels = action.payload;
      })

      .addCase(fetchMachineModelNameAsync.pending, (state) => {
        state.machineModelNameStatus = 'loading';
      })
      .addCase(fetchMachineModelNameAsync.fulfilled, (state, action) => {
        state.machineModelNameStatus = 'idle';
        const offset = state.editMaintenanceConditions.MaintenanceOffset;
        const machineList = action.payload;
        if (offset && machineList && machineList.length > 0) {
          machineList.forEach((m) => {
            const existingOffset = offset.find((o) => o.Machine_ID === m.Machine_ID);
            if (existingOffset) {
              m.OffsetValue = existingOffset.OffsetValue;
            }
          });
        }
        state.editMaintenanceConditions.MaintenanceOffset = machineList;
      })

      .addCase(fetchMachineSectionAsync.pending, (state) => {
        state.machineSectionStatus = 'loading';
        state.machineSections = [];
        state.machineFunctions = [];
      })
      .addCase(fetchMachineSectionAsync.fulfilled, (state, action) => {
        state.machineSectionStatus = 'idle';
        state.machineSections = action.payload;
      })

      .addCase(fetchMachineFunctionAsync.pending, (state) => {
        state.machineFunctionStatus = 'loading';
      })
      .addCase(fetchMachineFunctionAsync.fulfilled, (state, action) => {
        state.machineFunctionStatus = 'idle';
        state.machineFunctions = action.payload;
      })

      .addCase(fetchMachineParametersAsync.pending, (state) => {
        state.machineParametersStatus = 'loading';
      })
      .addCase(fetchMachineParametersAsync.fulfilled, (state, action) => {
        state.machineParametersStatus = 'idle';
        state.machineParameters = action.payload;
      })

      .addCase(fetchNotificationGroupAsync.pending, (state) => {
        state.notificationGroupStatus = 'loading';
      })
      .addCase(fetchNotificationGroupAsync.fulfilled, (state, action) => {
        state.notificationGroupStatus = 'idle';
        state.notificationGroups = action.payload;
      })

      .addCase(fetchNotificationTemplateAsync.pending, (state) => {
        state.notificationTemplateStatus = 'loading';
      })
      .addCase(fetchNotificationTemplateAsync.fulfilled, (state, action) => {
        state.notificationTemplateStatus = 'idle';
        state.notificationTemplates = action.payload;
      })

      .addCase(saveMaintenanceConditionAsync.pending, (state) => {
        state.maintenanceConditionStatus = 'loading';
      })
      // .addCase(saveMaintenanceConditionAsync.fulfilled, (state, action) => {
      .addCase(saveMaintenanceConditionAsync.fulfilled, (state) => {
        state.maintenanceConditionStatus = 'idle';
        state.recordSaved = true;
        // state.editMaintenanceConditions = {};
        setTimeout(() => {
          message.success(SuccessMessage);
        }, 100);
      })
      .addCase(saveMaintenanceConditionAsync.rejected, (state) => {
        state.maintenanceConditionStatus = 'idle';
        setTimeout(() => {
          message.error(DuplicateRecord);
        }, 100);
      })

      .addCase(editMaintenanceConditionAsync.pending, (state) => {
        state.editMaintenanceConditionsStatus = 'loading';
      })
      .addCase(editMaintenanceConditionAsync.fulfilled, (state, action) => {
        state.editMaintenanceConditionsStatus = 'idle';
        state.editMaintenanceConditions = action.payload;
      })
      .addCase(editMaintenanceConditionAsync.rejected, (state) => {
        state.editMaintenanceConditionsStatus = 'idle';
      })

      .addCase(saveRecommendedActionsAsync.pending, (state) => {
        state.recommendedActionsStatus = 'loading';
      })
      .addCase(saveRecommendedActionsAsync.fulfilled, (state, action) => {
        state.recommendedActionsStatus = 'idle';
        const existingRecommendedAction = state.editMaintenanceConditions.recommendedactions
        && state.editMaintenanceConditions.recommendedactions.findIndex(
          (i) => i.RecommendedAction_ID === action.payload.RecommendedAction_ID,
        );

        if (existingRecommendedAction >= 0) {
          state.editMaintenanceConditions.recommendedactions[existingRecommendedAction] = action.payload;
        } else if (state.editMaintenanceConditions.recommendedactions) {
          state.editMaintenanceConditions.recommendedactions.push(action.payload);
        } else {
          state.editMaintenanceConditions.recommendedactions = [action.payload];
        }
      })
      .addCase(saveRecommendedActionsAsync.rejected, (state) => {
        state.recommendedActionsStatus = 'idle';
      })

      .addCase(deleteRecommendedActionsAsync.pending, (state) => {
        state.recommendedActionsStatus = 'loading';
      })
      .addCase(deleteRecommendedActionsAsync.fulfilled, (state, action) => {
        state.recommendedActionsStatus = 'idle';
        const existingRecommendedAction = state.editMaintenanceConditions.recommendedactions.findIndex(
          (i) => i.RecommendedAction_ID === action.meta.arg,
        );
        if (existingRecommendedAction >= 0) {
          state.editMaintenanceConditions.recommendedactions.splice(
            existingRecommendedAction,
            1,
          );
        }
      })
      .addCase(deleteRecommendedActionsAsync.rejected, (state) => {
        state.recommendedActionsStatus = 'idle';
      });
  },
});

export const {
  clearSaveRecord, clearEdit,
} = maintenanceConditionEntry.actions;

export default maintenanceConditionEntry.reducer;
