import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { fetchMachineParameters, saveFailureIndicator } from '../../components/ConditionMonitoring/FMEA/failure-indicators/failureIndicatorsAddApi';

import { setFailureIndicator } from './failureModeDetailsSlice';
// import { Success_Message, Error_Message } from '../../configs/config';
import { SuccessMessage, ErrorMessage } from '../../helpers/constants';

const initialState = {
  machineParameters: [],
  machineParametersStatus: 'idle',
  failureIndicators: [],
  failureIndicatorsStatus: 'idle',
};

export const fetchMachineParametersAsync = createAsyncThunk(
  'failureIndicatorAdd/fetchMachineParameters',
  async ({ modelID, sectionID, functionID }) => {
    const response = await fetchMachineParameters(
      modelID,
      sectionID,
      functionID,
    );
    return response;
  },
);

export const saveFailureIndicatorAsync = createAsyncThunk(
  'saveFailureIndicator/saveFailureIndicators',
  async (body, thunkApi) => {
    const response = await saveFailureIndicator(body);
    thunkApi.dispatch(setFailureIndicator(response));
    return response;
  },
);

export const failureIndicatorsAdd = createSlice({
  name: 'failureIndicatorAdd',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineParametersAsync.pending, (state) => {
        state.machineParametersStatus = 'loading';
      })
      .addCase(fetchMachineParametersAsync.fulfilled, (state, action) => {
        state.machineParametersStatus = 'idle';
        state.machineParameters = action.payload;
      })

      .addCase(saveFailureIndicatorAsync.pending, (state) => {
        state.failureIndicatorsStatus = 'loading';
      })
      .addCase(saveFailureIndicatorAsync.fulfilled, (state, action) => {
        state.failureIndicatorsStatus = 'idle';
        state.failureIndicators = action.payload;
        setTimeout(() => {
          message.success(SuccessMessage);
        }, 100);
      })
      .addCase(saveFailureIndicatorAsync.rejected, (state) => {
        state.failureIndicatorsStatus = 'idle';
        setTimeout(() => {
          message.error(ErrorMessage);
        }, 100);
      });
  },
});

export default failureIndicatorsAdd.reducer;
