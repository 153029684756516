// import axios from 'axios';
// import { APIUrl } from "../../configs/config";
import { APIUrl } from '../../../helpers/constants';
// import authHeader from '../../../config/auth.header';
import CallAPI from '../../../services/conditionMonitoring.service';
// const headers = authHeader();

export function saveRecommendedActions(recommendedAction) {
  const apiInfo = {
    url: `${APIUrl}/api/saveRecommendedAction`,
    method: 'POST',
    postData: recommendedAction,
    isMultiPart: false,
    CreatedUserID: true,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .post(`${APIUrl}/api/saveRecommendedAction`, recommendedAction, { headers })

  //   .then((res) => res.data);
}

export function deleteRecommendedActions(recommendedActionId) {
  const apiInfo = {
    url: `${APIUrl}/api/deleteRecommendedAction?recommendedActionId=${recommendedActionId}`,
    method: 'DELETE',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .delete(
  //     `${APIUrl}/api/deleteRecommendedAction?recommendedActionId=${recommendedActionId}`, { headers },
  //   )
  //   .then((res) => res.data);
}
