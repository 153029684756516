/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { CircularProgress } from '@mui/material';
import DynamicTable from '../../../common/DynamicTable';
import './index.css';
import { setShiftReportFormData, setShiftReportData } from '../../../redux/slices/shiftReportSlice';
import { SAVE_SHIFT_REPORT_DATA } from '../../../config/api.config.json';
import CallCommonAPI from '../../../services/commonAPI.service';

function ShiftTable() {
  const dispatchAction = useDispatch();
  const { data: finalData, isLoad } = useSelector((state) => state?.shiftReport?.shiftReportData);
  const { filters } = useSelector((state) => state?.shiftReport);
  console.log('filters', filters);
  // let finalData = useSelector(state => console.log('===>state table', state?.shiftReport?.shiftReportData));

  const [tableData, setTableData] = useState();
  const inputColumns = ['FLOOR_LOSS_IN_KG',
    'ACRU_LOSS_IN_LACS_AUTO',
    'ACRU_LOSS_IN_LACS_MANUAL',
    'UNCUT_LOSS_IN_LACS',
    'ATS_LOSS_IN_KG',
    'PRINTING_LOSS_IN_KG',
    'CAMERA_LOSS_IN_KG',
    'WIP_IN_HALL',
    'WIP_IN_ATS',
    'WIP_IN_PRINTING',
    'WIP_IN_CAMERA'];
  const getTableData = (formData) => {
    console.log('formData', formData);
  };
  useEffect(() => {
    console.log('finalData', finalData);
    setTableData(finalData);
    dispatchAction(setShiftReportFormData(finalData));
  }, [finalData]);
  const handleChange = (e, index, key) => {
    const regexp = /(^[0-9]{0,1})(\.[0-9]{0,2})?$/;
    // console.log('0000', e.target.value.match(regexp));
    const tableDataChange = tableData.map((item, indexVal) => {
      if (indexVal === index) {
        item = { ...item, [key]: e.target.value };
        return item;
      }
      return item;
    });
    setTableData(tableDataChange);
    dispatchAction(setShiftReportFormData(tableDataChange));
    dispatchAction(setShiftReportData({ data: tableDataChange, isLoad: true }));
  };
  const saveShiftReport = async () => {
    if(!filters?.SuperVisor) {
      message.error('Please enter Supervisor name');
      return false;
    }
    const finalSaveData = finalData.map((item) => ({
      ...item,
      ...filters,
    //   PlantKey: '17',
    //   DateKey: '20230508',
    //   ShiftKey: 'All Day',
    //   LineKey: '',
    }));
    const apiInfo = {
      url: SAVE_SHIFT_REPORT_DATA,
      method: 'POST',
      postData: finalSaveData,
    };
    console.log('finalSaveData', finalSaveData);
    /* eslint no-unreachable: 0 */
    // return false;
    const saveResponse = await CallCommonAPI(apiInfo);
    if (saveResponse?.data?.status === 201) {
      message.success('Saved  Successfully');
    } else {
      message.error('Failed to save');
    }
  };

  //   const sendMailShiftReport = async () => {
  //     console.clear();
  //     console.log('finalData', finalData);
  //     const apiInfo = {
  //       url: SEND_MAIL_SHIFT_REPORT_DATA,
  //       method: 'POST',
  //       postData: {
  //         data: finalData,
  //         filters: {},
  //       },
  //     };
  //     const sendMailResponse = await CallCommonAPI(apiInfo);
  //     console.log('sendMailResponse', sendMailResponse);
  //   };
  const reset = () => {
    // reset inputs fields
    const tableDataReset = tableData.map((item) => ({
      ...item,
      ...{
        FLOOR_LOSS_IN_KG: '',
        ACRU_LOSS_IN_LACS_AUTO: '',
        ACRU_LOSS_IN_LACS_MANUAL: '',
        UNCUT_LOSS_IN_LACS: '',
        ATS_LOSS_IN_KG: '',
        PRINTING_LOSS_IN_KG: '',
        CAMERA_LOSS_IN_KG: '',
        WIP_IN_HALL: '',
        WIP_IN_ATS: '',
        WIP_IN_PRINTING: '',
        WIP_IN_CAMERA: '',
      },
    }));
    console.clear();
    console.log('reset dAta', tableDataReset);
    setTableData(tableDataReset);
    dispatchAction(setShiftReportFormData(tableDataReset));
    dispatchAction(setShiftReportData({ data: tableDataReset, isLoad: true }));
  };
  console.log('data============>finalData', finalData);
  /* eslint no-nested-ternary: 0 */
  return (
    <>
      <div>
        {isLoad
          ? (finalData && finalData.length)
            ? (
              <div>
                <div className="tablescroll">
                  <DynamicTable
                    data={finalData}
                    TableClass="dyntbl"
                    skipColumns ={['MachineKey']}
                    inputColumns={inputColumns}
                    getTableData={getTableData}
                    handleChange={handleChange}
                  />
                </div>
                <div className="shbtnBlk">
                  <button type="button" onClick={() => reset()}>Reset</button>
                  <button type="button" onClick={() => saveShiftReport()}>Save</button>
                  {/* <Button size="small" variant="outlined" onClick={() => sendMailShiftReport()}>Send Mail</Button> */}
                </div>
                {/* <div className='tablescroll'>
                                <DynamicTable data={finalData} TableClass={'dyntbl'} inputColumns={[]} />
                            </div> */}
              </div>
            )
            : <div className="sh-noData">No Data Available</div>
          : <div className="sh-loader"><CircularProgress /></div>}
      </div>
    </>
  );
}

export default ShiftTable;
