import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// IMPORTING COMPONENTS
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Sidebar from './Sidebar/Sidebar';

// importing the Services
import userManagement from '../../services/userManagement.service';

// IMPORTING ACTIONS
import {
  setDefaultRoute,
  resetState,
  setMenuAndReport,
} from '../../redux/slices/userSlice';

// import '../../styles/dashboard.css';
// import '../../styles/filter.css';
// import '../../styles/breadcrum.css';
import '../../styles/pagination.css';
import '../../styles/popup.css';
import '../../styles/profile.css';
import '../../styles/responsive.css';
// import '../../styles/events.css';

const { getUserData, getDefaultRoute } = userManagement;
/* eslint  react/prop-types:0 */

function Dashboard({ children }) {
  const history = useHistory();
  const [dashboardHeight, setDashboardHeight] = useState(null);
  const dispatch = useDispatch();
  const { DataFetched } = useSelector((state) => state.user);
  // dispatch(incremeant)

  // Checking for valid user else redirect to login page
  const isValidUser = (token) => {
    axios
      .post('/api/verifyToken', { token })
      .then(() => true)
      .catch((err) => {
        console.log(err);
        history.replace('/');
      });
  };

  function adjustDashboard() {
    const header = document.getElementById('header');
    const footer = document.getElementById('footer');
    // const dashboard__data = document.getElementById("dashboard__data");
    const DashboardContentHeight = window.innerHeight - (header.clientHeight + footer.clientHeight);
    setDashboardHeight(DashboardContentHeight);
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      isValidUser(token, '/');
    } else {
      history.replace('/');
    }

    // FETCH ONLY IF DATA IS NOT FETCHED ALREADY WHILE LOGIN
    if (DataFetched === false) {
      // FETCHING ACCESSABLE MENUS AND REPORTS FOR CURRENT USER
      const UserKey = localStorage.getItem('UserKey');
      const email = localStorage.getItem('email');
      getUserData({ UserKey, email })
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            const {
              Menus, Reports, ReportCodes, RoleName,
            } = data;
            const DefaultRoute = getDefaultRoute(Menus);
            // STORING DATA IN STORE
            dispatch(setMenuAndReport({
              Menus, Reports, ReportCodes, RoleName,
            }));
            // SETTING DEFAULT ROUTE
            dispatch(setDefaultRoute(DefaultRoute));
          } else {
            // WHEN ERR OCCUR
            dispatch(resetState());
            console.log(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    adjustDashboard();
    let x;
    const callOnetime = () => {
      clearTimeout(x);
      x = setTimeout(() => {
        adjustDashboard();
      }, 500);
    };
    window.addEventListener('resize', callOnetime);
    window.addEventListener('orientationchange', adjustDashboard);
    return () => {
      window.removeEventListener('resize', callOnetime);
      window.removeEventListener('orientationchange', adjustDashboard);
    };
  }, []);

  return (
    <div className="dashboard dark">
      <Sidebar />
      <div className="dashboard__content">
        <Header />
        <div
          className="dashboard__data"
          id="dashboard__data"
          style={{
            height: dashboardHeight,
          }}
        >
          <div className="dashboard__innerData">{ children }</div>
          {/* {renderMenu()} */}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Dashboard;
