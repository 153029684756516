import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// import moment from 'moment';
// import ReportSlideData from "../../reports/GelReport/overview/ReportSlide";
import ReportSlideData from './ReportSlide';
// import ServerError from '../../common/InternalPageError';

export default function ConditionMonitoring(props) {
  // const { menu, tab } = useParams();
  const { tab } = useParams();
  console.log('tab', tab);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Condition-Monitoring-Trends');

  function doesTabExists(Tab) {
    return (
      Tab === 'Condition-Monitoring-Trends'
          || Tab === 'Condition Monitoring Table'
    );
  }

  const [visible, setVisible] = useState(false);

  return (
    <>
      <>
        {doesTabExists('Condition-Monitoring-Trends') ? (
          <div className="wrapper cm-wrapper cm-report">
            <div className="contentWrap conditionMonitoring monitoringcontentWrap monitoringPage cm_report">
              <div className="slick-carousel slickcarouselTop">
                <ReportSlideData
                  visible={visible}
                  setVisible={setVisible}
                  visibilityAccess={props.visibilityAccess}
                  selectedTabs={tab || selectedTab}
                  setSelectedTabs={setSelectedTab}
                  setButtonClicked={setButtonClicked}
                  buttonClicked={buttonClicked}
                  /* eslint-disable react/jsx-props-no-spreading */
                  {...props}
                />
              </div>
            </div>
          </div>
        ) : (
          // <ServerError />
          null
        )}
      </>
    </>
  );
}
