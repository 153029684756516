import jsPDF from 'jspdf';
// import {getHTML} from "./service.js"
// define a generatePDF function that accepts a tickets argument
/* eslint  no-shadow:0 */
/* eslint  guard-for-in:0 */

function getHTML(formData) {
  const data = [];
  for (const x in formData) {
    const obj = {
      label: x,
      value: formData[x],
    };
    data.push(obj);
  }

  let list = '';
  data.forEach((x) => {
    if (
      x.label !== 'result'
      && x.label !== 'model'
      && x.label !== 'Timestamp'
    ) {
      const listHtml = `
      <tr>
      <td>${x.label}</td>
      <td>${x.value !== undefined ? x.value : ''}</td>
      </tr>`;
      list += listHtml;
    }
  });
  // `<font size="1" color="black">This is p one</font>`
  let html = `;
  <html><head><style>
   table{
    width:190px;
    margin:auto;
  border-collapse: collapse;

   }
   
    .modal_table td{
      font-size:6px;
      color:#000;
      border-bottom: 1px solid #000;
      padding:0 4px; 
      padding-bottom:2px; 
      border-right:1px solid #000;
    }
    .modal_table td:first-child{
      border-left:1px solid #000;
    }
    .modal_table tr:first-child td{
      border-top:1px solid #000;
    }
    .test{
      padding:10px;
    }
    h6{
      font-weight:700;
      font:size:4px;
      color:#000;
      text-align:center;
      margin:0;
      width:100%;
    }
    .value_date{
      margin-top:4px;
      margin-bottom:10px;
    width:190px;
    }
    .value_date td{
      color:#000;
      font-size:4px;
      font-size:5px;
      font-weight:700;
    }
    .value_date td:last-child{
      text-align:right;
    }
  </style></head><body><div class="test">
  <h6 class="title">Average&nbsp;Pickup&nbsp;Weight&nbsp;For&nbsp;Capsule&nbsp;${formData.model}</h6>
  <table class="value_date">
    <tr>
      <td>Predicted&nbspValue:&nbsp;${formData.result}</td>
      <td class="">Date&nbsp;&&nbsp;Time:&nbsp;${formData.Timestamp}</td>
    </tr>
  </table>
  <table class="modal_table"> <tbody>`;

  html += list;
  html += '</tbody></table></div></body></html>';
  return html;
}
const generatePDF = (formData) => {
  const filename = `Average_Pickup_Weight_${formData.model}_${formData.Timestamp}.pdf`;
  // initialize jsPDF
  // eslint-disable-next-line new-cap
  const doc = new jsPDF();
  const dochtml = getHTML(formData);
  doc.html(dochtml, {
    callback(doc) {
      doc.save(filename);
    },
  });
};
export default generatePDF;
