import { useState } from 'react';
import './style.css';

const index = ({ title = 'Groups', options = [], onChange = null }) => {
  const name = Math.random();
  const [expand, setExpand] = useState(false);
  const handleClick = () => {
    setExpand(!expand);
  };
  const handleOnChange = (e) => {
    console.log(e);
    setExpand(false);
    onChange(e);
  };
  return (
    <div className={`EM__Select ${expand === true ? 'expand' : ''}`}>
      <div className="EM__Select_btn" onClick={handleClick}>{title}</div>
      <ul className="EM_options">
        {options.length > 0
          ? options.map(({ label = '', value = '', percentage = '' }) => (
            <li key={value}>
              <label>
                <input type="radio" value={value} data-label={label} name={name} onChange={handleOnChange} />
                <div className="EM_options_content">
                  <span>{label}</span>
                  {percentage !== ''
                    ? <span className="EM__phasePercentage">{percentage}</span>
                    : ''}
                </div>
              </label>
            </li>
          ))
          : <li className="EM_no_record">No Options</li>}
      </ul>
    </div>
  );
};

// (
//   <select className="EM__Select minimal" onChange={onChange}>
//     {data.map((text) => (
//       <option>{text}</option>
//     ))}
//   </select>
// );
export default index;
