import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFailureNotifications, saveActivateFailureNotification } from '../../components/ConditionMonitoring/failure-notification/failureNotificationViewApi';

const initialState = {
  failureNotifications: {},
  status: 'idle',
};

export const getfailureNotificationsAsync = createAsyncThunk(
  'failureNofication/getFailureNotifications',
  async ({
    // page, size, search, sortName, sortDirection, activeStatus,
    page, size, search, sortName, sortDirection,
  }) => {
    const response = await getFailureNotifications(page, size, search, sortName, sortDirection);
    return response;
  },
);

export const saveActivateFailureNotificationAsync = createAsyncThunk(
  'failureNotifications/saveActivateFailureNotification',
  async ({ id, status }) => {
    const request = await saveActivateFailureNotification(id, status);
    return request;
  },
);

export const failureNotificationsSlice = createSlice({
  name: 'failureNotifications',
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder
      .addCase(getfailureNotificationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getfailureNotificationsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.failureNotifications = action.payload;
      })

      .addCase(saveActivateFailureNotificationAsync.pending, (state, action) => {
        state.status = 'loading';
        const { id, status } = action.meta.arg;
        const generalCondition = state.failureNotifications.rows.find((r) => r.FailureNotification_ID === id);
        generalCondition.IsActive = status;
      })
      .addCase(saveActivateFailureNotificationAsync.fulfilled, (state) => {
        state.status = 'idle';
      });
  },
});

export default failureNotificationsSlice.reducer;
