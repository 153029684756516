import React from 'react';
// import axios  from "axios";
import './rsc.css';
import MultipleFileUpload from './multipleUpload';
/* eslint react/prop-types:0 */
/* eslint jsx-a11y/label-has-associated-control:0 */
/* eslint  jsx-a11y/no-static-element-interactions:0 */
/* eslint jsx-a11y/click-events-have-key-events:0 */
/* eslint jsx-a11y/anchor-is-valid:0 */

function UploadFiles({ closePopUPdata }) {
  return (
    <div className="modelboxopen rsdAdd ">
      <div className="modelboxopenInnnerRsdPop" style={{ height: '200px' }} id="modelboxopenInnner43">
        <div className="wrapContent">
          <div className="containerIner2">
            <div className="popupCloseButton bbt">
              <h3 className="titleDiv">Upload Print Data Files</h3>
              <a
                className="closepopup pointer"
                onClick={() => closePopUPdata((prev) => !prev)}
              >
                &#x2715;
              </a>
            </div>
          </div>
          <div className="contentPopup">

            <MultipleFileUpload closePopUPdata={closePopUPdata} />
          </div>

        </div>
      </div>
    </div>
  );
}

export default UploadFiles;
