import ActiveIcon from './ActiveIcons';
import InActiveIcon from './InActiveIcons';
/* eslint  react/prop-types:0 */
function Icon({ name, active = false, enableHover = false }) {
  if (enableHover) {
    return (
      <>
        <div className="showOnHover">
          <ActiveIcon icon={name} />
        </div>
        <div className="hideOnHover">
          <InActiveIcon icon={name} />
        </div>
      </>
    );
  }

  return (
    <>
      {active
        ? <ActiveIcon icon={name} />
        : <InActiveIcon icon={name} />}
    </>
  );
}

export default Icon;
