import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import ReportTile from './ReportTile/index';
// IMPORT POSSIBLE ROUTES
import { REPORT } from '../../config/appicationRoutes.config.json';

const MasterContent = () => {
  const { Reports, ReportCodes } = useSelector((state) => state.user);
  // console.clear();
  const history = useHistory();

  const clickHandler = (ReportCode) => {
    const params = new URLSearchParams({ reportType: ReportCode });
    history.push({
      pathname: REPORT,
      search: params.toString(),
    });
  };

  return (
    <div className="Reports">
      {ReportCodes.map((currentCode) => {
        console.log('currentCode', Reports[currentCode]);
        const { ReportCode, ReportName, ViewAccess } = Reports[currentCode];
        return (
          <>
            { ViewAccess
            && (
            <ReportTile
              ReportCode={ReportCode}
              ReportName={ReportName}
              clickHandler={() => clickHandler(ReportCode)}
              key={ReportCode}
            />
            )}
          </>
        );
      })}
      {/* <ReportTile
        ReportCode="Test"
        ReportName="Test"
        clickHandler={() => clickHandler('Test')}
        key="Test"
      /> */}
    </div>
  );
};

export default MasterContent;
