import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Logo from './images/Logo';
import Styles from './style.module.css';
/* eslint no-shadow:0 */
// IMPORTING SERVICES
import userManagementService from '../../services/userManagement.service';

// IMPORTIG ACTIONS
import {
  setMenuAndReport,
  setDefaultRoute,
  resetState,
} from '../../redux/slices/userSlice';

// IMPORT POSSIBLE ROUTES
import AppRoutes from '../../config/appicationRoutes.config.json';
import {
  EMAIL_IS_REQUIRED,
  PASSWORD_IS_REQUIRED,
  EMAILID_NOT_REGISTERED_WITH_US,
  EMAIL_ID_AND_PASSWORD_IS_REQUIRED,
  PLEASE_CHECK_EMAILID_CONTACT_ADMIN,
} from '../../helpers/constants';

const {
  login, forgetLink, getUserData, getDefaultRoute,
} = userManagementService;

/* eslint no-shadow:0 */

function Login() {
  const { WELCOME, RESET_SUCCESS } = AppRoutes;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const handleForgetPassword = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@acg-world\.com$/;

    if (email === '') {
      setError(EMAIL_IS_REQUIRED);
    } else if (!emailRegex.test(email)) {
      setError(EMAILID_NOT_REGISTERED_WITH_US);
    } else {
      forgetLink(email).then((res) => {
        if (res.status !== 200) {
          setError(EMAILID_NOT_REGISTERED_WITH_US);
        } else {
          console.log('res.data', res.data.data);
          // setError(res?.data?.data);
          history.push(RESET_SUCCESS, { state: 'forgetPassword' });
          // setIsForget(true)
          // history.replace('/');
        }
      }).catch(() => setError(PLEASE_CHECK_EMAILID_CONTACT_ADMIN));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === '' && password === '') {
      setError(EMAIL_ID_AND_PASSWORD_IS_REQUIRED);
    } else if (email === '') {
      setError(EMAIL_IS_REQUIRED);
    } else if (password === '') {
      setError(PASSWORD_IS_REQUIRED);
    } else {
      login({ email, password })
        .then((res) => {
          const { status } = res;
          // EXECUTES WHEN ALL OK
          if (status === 200) {
            const {
              FirstName,
              LastName,
              EmailId: email,
              UserKey,
              token,
            } = res.data;

            localStorage.setItem('token', token);
            localStorage.setItem('email', email);
            localStorage.setItem('firstname', FirstName);
            localStorage.setItem('lastname', LastName);
            localStorage.setItem('UserKey', UserKey);

            // FETCHING ACCESSABLE MENUS AND REPORTS FOR CURRENT USER
            getUserData({ UserKey, email })
              .then((res) => {
                const { data, status } = res;
                if (status === 200) {
                  const {
                    Menus, Reports, ReportCodes, RoleName,
                  } = data;
                  const DefaultRoute = getDefaultRoute(Menus);

                  // STORING DATA IN STORE
                  dispatch(setMenuAndReport({
                    Menus, Reports, ReportCodes, RoleName,
                  }));

                  // SETTING DEFAULT ROUTE
                  dispatch(setDefaultRoute(DefaultRoute));
                  history.replace(DefaultRoute);
                } else {
                  // const { WELCOME } = AppRoutes;
                  // WHEN ERR OCCUR
                  dispatch(resetState());
                  history.replace(WELCOME);
                  console.log(data);
                }
              })
              .catch((err) => {
                console.log(err);
              });
            setError('');
          }

          // EXECUTES WHEN WRONG CRDENTIALS
          if (status === 401) {
            setError('Please Enter Valid Credential');
          }

          // EXECUTES WHEN INTERNAL SERVER ERROR
          if (status === 500) {
            setError('Internal Server Error');
          }
        })
        .catch((err) => {
          console.log(err);
          setError('Internal Server Error');
        });
    }
  };
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <div className={Styles.Login}>
      <div className={Styles.loginContainer}>
        <div className={Styles.logo}>
          <Logo />
        </div>
        <div className={`${Styles.loginBox}`}>
          <div className={`${Styles.login__header}`}>Welcome</div>
          <div className={`${Styles.login__body}`}>
            {error ? (
              <div className={`${Styles.login__error}`}>{error}</div>
            ) : (
              ''
            )}
            {/* className={`${Styles.msg} ${Styles.success}`} */}

            <form className={`${Styles.login__form}`} onSubmit={handleSubmit}>
              <div className={`${Styles.login__inputControl}`}>
                <div className={`${Styles.login__fieldGroup}`}>
                  <div className={`${Styles.login__label}`}>email id</div>
                  <input
                    type="email"
                    value={email}
                    placeholder="Enter email id"
                    className={`${Styles.login__input}`}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className={`${Styles.login__fieldGroup}`}>
                  <div className={`${Styles.login__label}`}>password</div>
                  <input
                    type="password"
                    value={password}
                    placeholder="Enter password"
                    className={`${Styles.login__input}`}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={`${Styles.login__submitControl}`}>
                <input type="submit" value="Login" />
              </div>
              <div className={`${Styles.forgetpass}`}>
                <a href="#" onClick={handleForgetPassword}>
                  Forgot Password
                </a>
                <span>&nbsp;</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
