import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchExecutionResult, fetchConditionWise, fetchModelWise, fetchSeverityWise,
  fetchCustomerWise, fetchFailureResolution, fetchMaintenanceResolution, fetchFailureAging,
  fetchMaintenanceAging, fetchExecutionDetails, fetchCustomerDetails, fetchModelWiseDetails,
  fetchSeverityWiseDetails, fetchFailureAgingDetails, fetchMaintenanceAgingDetails,
  fetchFailureResolutionDetails, fetchMaintenanceResolutionDetails,
} from '../../components/ConditionMonitoring/CmDashboard/cmDashboardApi';

const initialState = {

  executionResults: [],
  executionResultStatus: 'idle',
  executionDetails: [],
  executionDetailStatus: 'idle',
  conditionWiseResults: [],
  conditionWiseResultStatus: 'idle',
  modelWiseResults: [],
  modelWiseResultStatus: 'idle',
  modelWiseDetails: [],
  modelWiseDetailStatus: 'idle',
  severityWiseResults: [],
  severityWiseResultStatus: 'idle',
  severityWiseDetails: [],
  severityWiseDetailStatus: 'idle',
  customerWiseResults: [],
  customerWiseResultStatus: 'idle',
  customerWiseDetails: [],
  customerWiseDetailStatus: 'idle',
  failureResolutions: [],
  failureResolutionStatus: 'idle',
  failureResolutionDetails: [],
  failureResolutionDetailStatus: 'idle',
  maintenanceResolutions: [],
  maintenanceResolutionStatus: 'idle',
  maintenanceResolutionDetails: [],
  maintenanceResolutionDetailStatus: 'idle',
  failureAging: [],
  failureAgingStatus: 'idle',
  failureAgingDetails: [],
  failureAgingDetailStatus: 'idle',
  maintenanceAging: [],
  maintenanceAgingStatus: 'idle',
  maintenanceAgingDetails: [],
  maintenanceAgingDetailStatus: 'idle',
};

export const fetchExecutionResultAsync = createAsyncThunk(
  'cmDashboardOutput/fetchExecutionResult',
  async ({ from, to }) => {
    const response = await fetchExecutionResult(from, to);
    return response;
  },
);

export const fetchExecutionDetailsAsync = createAsyncThunk(
  'cmDashboardOutput/fetchExecutionDetails',
  async ({
    page, size, from, to,
  }) => {
    const response = await fetchExecutionDetails(page, size, from, to);
    return response;
  },
);

export const fetchConditionWiseAsync = createAsyncThunk(
  'cmDashboardOutput/fetchConditionWise',
  async ({ from, to }) => {
    const response = await fetchConditionWise(from, to);
    return response;
  },
);

export const fetchModelWiseAsync = createAsyncThunk(
  'cmDashboardOutput/fetchModelWise',
  async ({ from, to }) => {
    const response = await fetchModelWise(from, to);
    return response;
  },
);

export const fetchModelWiseDetailsAsync = createAsyncThunk(
  'cmDashboardOutput/fetchModelWiseDetails',
  async ({
    page, size, from, to,
  }) => {
    const response = await fetchModelWiseDetails(page, size, from, to);
    return response;
  },
);

export const fetchSeverityWiseAsync = createAsyncThunk(
  'cmDashboardOutput/fetchSeverityWise',
  async ({ from, to }) => {
    const response = await fetchSeverityWise(from, to);
    return response;
  },
);

export const fetchSeverityWiseDetailsAsync = createAsyncThunk(
  'cmDashboardOutput/fetchSeverityWiseDetails',
  async ({
    page, size, from, to,
  }) => {
    const response = await fetchSeverityWiseDetails(page, size, from, to);
    return response;
  },
);

export const fetchCustomerWiseAsync = createAsyncThunk(
  'cmDashboardOutput/fetchCustomerWise',
  async ({ from, to }) => {
    const response = await fetchCustomerWise(from, to);
    return response;
  },
);

export const fetchCustomerDetailsAsync = createAsyncThunk(
  'cmDashboardOutput/fetchCustomerDetails',
  async ({
    page, size, from, to,
  }) => {
    const response = await fetchCustomerDetails(page, size, from, to);
    return response;
  },
);

export const fetchFailureResolutionAsync = createAsyncThunk(
  'cmDashboardOutput/fetchFailureResolution',
  async ({ from, to }) => {
    const response = await fetchFailureResolution(from, to);
    return response;
  },
);

export const fetchMaintenanceResolutionAsync = createAsyncThunk(
  'cmDashboardOutput/fetchMaintenanceResolution',
  async ({ from, to }) => {
    const response = await fetchMaintenanceResolution(from, to);
    return response;
  },
);

export const fetchFailureResolutionDetailsAsync = createAsyncThunk(
  'cmDashboardOutput/fetchFailureResolutionDetails',
  async ({
    page, size, from, to,
  }) => {
    const response = await fetchFailureResolutionDetails(page, size, from, to);
    return response;
  },
);

export const fetchMaintenanceResolutionDetailsAsync = createAsyncThunk(
  'cmDashboardOutput/fetchMaintenanceResolutionDetails',
  async ({
    page, size, from, to,
  }) => {
    const response = await fetchMaintenanceResolutionDetails(page, size, from, to);
    return response;
  },
);

export const fetchFailureAgingAsync = createAsyncThunk(
  'cmDashboardOutput/fetchFailureAging',
  async ({ from, to }) => {
    const response = await fetchFailureAging(from, to);
    return response;
  },
);

export const fetchFailureAgingDetailsAsync = createAsyncThunk(
  'cmDashboardOutput/fetchFailureAgingDetails',
  async ({
    page, size, from, to,
  }) => {
    const response = await fetchFailureAgingDetails(page, size, from, to);
    return response;
  },
);

export const fetchMaintenanceAgingAsync = createAsyncThunk(
  'cmDashboardOutput/fetchMaintenanceAging',
  async ({ from, to }) => {
    const response = await fetchMaintenanceAging(from, to);
    return response;
  },
);

export const fetchMaintenanceAgingDetailsAsync = createAsyncThunk(
  'cmDashboardOutput/fetchMaintenanceAgingDetails',
  async ({
    page, size, from, to,
  }) => {
    const response = await fetchMaintenanceAgingDetails(page, size, from, to);
    return response;
  },
);

export const conditionMonitoringDashboard = createSlice({
  name: 'cmDashboardOutput',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder

      .addCase(fetchExecutionResultAsync.pending, (state) => {
        state.executionResultStatus = 'loading';
      })
      .addCase(fetchExecutionResultAsync.fulfilled, (state, action) => {
        state.executionResultStatus = 'idle';
        state.executionResults = action.payload;
      })

      .addCase(fetchExecutionDetailsAsync.pending, (state) => {
        state.executionDetailStatus = 'loading';
      })
      .addCase(fetchExecutionDetailsAsync.fulfilled, (state, action) => {
        state.executionDetailStatus = 'idle';
        state.executionDetails = action.payload;
      })

      .addCase(fetchConditionWiseAsync.pending, (state) => {
        state.conditionWiseResultStatus = 'loading';
      })
      .addCase(fetchConditionWiseAsync.fulfilled, (state, action) => {
        state.conditionWiseResultStatus = 'idle';
        state.conditionWiseResults = action.payload;
      })

      .addCase(fetchModelWiseAsync.pending, (state) => {
        state.modelWiseResultStatus = 'loading';
      })
      .addCase(fetchModelWiseAsync.fulfilled, (state, action) => {
        state.modelWiseResultStatus = 'idle';
        state.modelWiseResults = action.payload;
      })

      .addCase(fetchModelWiseDetailsAsync.pending, (state) => {
        state.modelWiseDetailStatus = 'loading';
      })
      .addCase(fetchModelWiseDetailsAsync.fulfilled, (state, action) => {
        state.modelWiseDetailStatus = 'idle';
        state.modelWiseDetails = action.payload;
      })

      .addCase(fetchSeverityWiseAsync.pending, (state) => {
        state.severityWiseResultStatus = 'loading';
      })
      .addCase(fetchSeverityWiseAsync.fulfilled, (state, action) => {
        state.severityWiseResultStatus = 'idle';
        state.severityWiseResults = action.payload;
      })

      .addCase(fetchSeverityWiseDetailsAsync.pending, (state) => {
        state.severityWiseDetailStatus = 'loading';
      })
      .addCase(fetchSeverityWiseDetailsAsync.fulfilled, (state, action) => {
        state.severityWiseDetailStatus = 'idle';
        state.severityWiseDetails = action.payload;
      })

      .addCase(fetchCustomerWiseAsync.pending, (state) => {
        state.customerWiseResultStatus = 'loading';
      })
      .addCase(fetchCustomerWiseAsync.fulfilled, (state, action) => {
        state.customerWiseResultStatus = 'idle';
        state.customerWiseResults = action.payload;
      })

      .addCase(fetchCustomerDetailsAsync.pending, (state) => {
        state.customerWiseDetailStatus = 'loading';
      })
      .addCase(fetchCustomerDetailsAsync.fulfilled, (state, action) => {
        state.customerWiseDetailStatus = 'idle';
        state.customerWiseDetails = action.payload;
      })

      .addCase(fetchFailureResolutionAsync.pending, (state) => {
        state.failureResolutionStatus = 'loading';
      })
      .addCase(fetchFailureResolutionAsync.fulfilled, (state, action) => {
        state.failureResolutionStatus = 'idle';
        state.failureResolutions = action.payload;
      })

      .addCase(fetchFailureResolutionDetailsAsync.pending, (state) => {
        state.failureResolutionDetailStatus = 'loading';
      })
      .addCase(fetchFailureResolutionDetailsAsync.fulfilled, (state, action) => {
        state.failureResolutionDetailStatus = 'idle';
        state.failureResolutionDetails = action.payload;
      })

      .addCase(fetchMaintenanceResolutionAsync.pending, (state) => {
        state.maintenanceResolutionStatus = 'loading';
      })
      .addCase(fetchMaintenanceResolutionAsync.fulfilled, (state, action) => {
        state.maintenanceResolutionStatus = 'idle';
        state.maintenanceResolutions = action.payload;
      })

      .addCase(fetchMaintenanceResolutionDetailsAsync.pending, (state) => {
        state.maintenanceResolutionDetailStatus = 'loading';
      })
      .addCase(fetchMaintenanceResolutionDetailsAsync.fulfilled, (state, action) => {
        state.maintenanceResolutionDetailStatus = 'idle';
        state.maintenanceResolutionDetails = action.payload;
      })

      .addCase(fetchFailureAgingAsync.pending, (state) => {
        state.failureAgingStatus = 'loading';
      })
      .addCase(fetchFailureAgingAsync.fulfilled, (state, action) => {
        state.failureAgingStatus = 'idle';
        state.failureAging = action.payload;
      })

      .addCase(fetchFailureAgingDetailsAsync.pending, (state) => {
        state.failureAgingDetailStatus = 'loading';
      })
      .addCase(fetchFailureAgingDetailsAsync.fulfilled, (state, action) => {
        state.failureAgingDetailStatus = 'idle';
        state.failureAgingDetails = action.payload;
      })

      .addCase(fetchMaintenanceAgingAsync.pending, (state) => {
        state.maintenanceAgingStatus = 'loading';
      })
      .addCase(fetchMaintenanceAgingAsync.fulfilled, (state, action) => {
        state.maintenanceAgingStatus = 'idle';
        state.maintenanceAging = action.payload;
      })

      .addCase(fetchMaintenanceAgingDetailsAsync.pending, (state) => {
        state.maintenanceAgingDetailStatus = 'loading';
      })
      .addCase(fetchMaintenanceAgingDetailsAsync.fulfilled, (state, action) => {
        state.maintenanceAgingDetailStatus = 'idle';
        state.maintenanceAgingDetails = action.payload;
      });
  },
});

export default conditionMonitoringDashboard.reducer;
