// import axios from 'axios';
// import { user_id, API_Url } from '../../configs/config';
import { userId, APIUrl } from '../../../../helpers/constants';
// import authHeader from '../../../../config/auth.header';
import CallAPI from '../../../../services/conditionMonitoring.service';
// const headers = authHeader();

// A mock function to mimic making an async request for data
export function fetchMachineParameters(modelID, sectionID, functionID) {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineParameters?machinemodelID=${modelID}&machinesectionID=${sectionID}&machinefunctionID=${functionID}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMachineParameters?machinemodelID=${modelID}&machinesectionID=${sectionID}&machinefunctionID=${functionID}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function saveFailureIndicator(failureIndicator) {
  failureIndicator.CreatedUser_ID = userId;
  failureIndicator.Indicator = '';

  const toLower = failureIndicator?.FailureIndicatorDSeries.toLowerCase();
  if (toLower.indexOf('gelatin') !== -1) {
    failureIndicator.FailureIndicatorDSeries = failureIndicator?.FailureIndicatorDSeries.replace(/gelatin/gi, 'GELATIN');
    failureIndicator.IndirectFormula = failureIndicator?.IndirectFormula.replace(/gelatin/gi, 'GELATIN');
  }
  if (failureIndicator?.FailureIndicatorDSeries.toLowerCase().indexOf('hpmc') !== -1) {
    failureIndicator.FailureIndicatorDSeries = failureIndicator?.FailureIndicatorDSeries.replace(/hpmc/gi, 'HPMC');
    failureIndicator.IndirectFormula = failureIndicator?.IndirectFormula.replace(/hpmc/gi, 'HPMC');
  }

  // failureIndicator.MachineParameter_ID = 1;
  const apiInfo = {
    url: `${APIUrl}/api/saveFailureIndicator`,
    method: 'POST',
    postData: failureIndicator,
    isMultiPart: false,
    CreatedUserID: true,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .post(`${APIUrl}/api/saveFailureIndicator`, failureIndicator, { headers })
  //   .then((res) => res.data);
}
