/* eslint-disable import/prefer-default-export */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import 'antd/dist/antd.dark.css';
import { fetchMachineParametersAsync, saveCausualParameterAsync } from '../../../../redux/slices/causualParametersAddSlice';
// import { saveCausualParameterAsync } from "./causualParametersAddSlice";
import '../../../../styles/failureMode.css';
import { FormulaField } from '../../../../common/FormulaField';

export const CausualParameterAdd = ({
  potentialCauseID,
  onCausalParameterClose,
}) => {
  const dispatch = useDispatch();

  const item = useSelector((state) => {
    const potentialCause = state.failureModeDetails.editFailureMode.potentialcauses && state.failureModeDetails.editFailureMode.potentialcauses
      .find((p) => p.PotentialCause_ID === state.failureModeDetails.editPotentialCauseId);
    if (potentialCause) {
      const causalParameter = potentialCause.causualparameters && potentialCause.causualparameters.find((cp) => cp.CausalParameter_ID === state.failureModeDetails.editCausalParameterId);
      return causalParameter;
    }
  });
  const machineParameter = useSelector((state) => state.causualParametersAdd.machineParameters);

  const spinning = useSelector(
    (state) => state.causualParametersAdd.machineParametersStatus === 'loading'
        || state.causualParametersAdd.causualParametersStatus === 'loading',
  );
  const editFailure = useSelector(
    (state) => state.failureModeDetails.editFailureMode,
  );

  const onSubmit = (values, formula, validFormula) => {
    // if (formula && validFormula){
    //   var newValues = {...values};
    //   newValues.CausalParameter_ID = item && item.CausalParameter_ID;
    //   newValues.PotentialCause_ID = potentialCauseID;
    //   newValues.IndirectFormula = formula;
    //   dispatch(saveCausualParameterAsync(newValues)).then(()=>onCausalParameterClose());
    // }
    let parameterDSeriesFormula = formula;
    if (validFormula && (formula !== undefined && formula !== '')) {
      const parameters = formula.match(/@{[^@{}]*}/gm);
      if (parameters.length > 0) {
        for (const p of parameters) {
          if (p) {
            const dbParam = machineParameter.find((mp) => mp.ParameterName === p.substring(2, p.length - 1));
            parameterDSeriesFormula = parameterDSeriesFormula.replace(p, dbParam.ParameterDSeriesName);
          }
        }
      }

      dispatch(saveCausualParameterAsync({
        ...values, CausalParameter_ID: item && item.CausalParameter_ID, PotentialCause_ID: potentialCauseID, IndirectFormula: formula, CausalParameterDSeries: parameterDSeriesFormula,
      }))
        .then(() => {
          onCausalParameterClose();
        });
    }
  };

  useEffect(() => {
    dispatch(fetchMachineParametersAsync({
      modelID: editFailure.MachineModel_ID,
      sectionID: editFailure.MachineSection_ID,
      functionID: editFailure.MachineFunction_ID,
    }));
  }, []);

  return (
    <FormulaField
      spinning={spinning}
      onClose={onCausalParameterClose}
      machineParameter={machineParameter}
      item={item}
      onSubmit={onSubmit}
    />
  );
};
