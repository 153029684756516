/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import {
  setEditPotentialCause,
  editFailureModesAsync,
  clearEdit,
} from '../../../../redux/slices/failureModeDetailsSlice';
import { PotentialCausesAdd } from '../potential-causes/PotentialCauseAdd';
import '../../../../styles/failureMode.css';
import { ReactComponent as Edit } from '../../../../images/editSpoc.svg';
// import Icon from '../../../Dashboard/Sidebar/images/Icon';

export function FailureModeDetails(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [showViewCausualParameters, setShowViewCausualParameters] = useState(false);
  const [addPotentialCauses, setAddPotentialCauses] = useState(false);
  /* eslint no-unused-vars:0 */
  /* eslint no-shadow:0 */
  /* eslint max-len:0 */
  const { option } = useParams();
  console.log('opion', option);
  useEffect(() => {
    if (option) {
      dispatch(editFailureModesAsync({ id: option }));
    }

    // useEffect(() => {
    //   var id = 1;
    //   if (id) {
    //     dispatch(editFailureModesAsync({ id }));
    //   }

    return () => {
      dispatch(clearEdit());
    };
  }, [option]);

  const modeDetails = useSelector(
    (state) => state.failureModeDetails.editFailureMode,
  );

  const onEditPotentialCauseClick = (potentialCauseId) => {
    dispatch(setEditPotentialCause(potentialCauseId));
    setAddPotentialCauses(true);
  };

  const onPotentialCauseClose = () => {
    setAddPotentialCauses(false);
    dispatch(setEditPotentialCause());
  };

  const failureModeId = useSelector(
    (state) => state?.failureModeDetails?.editFailureMode?.FailureMode_ID || 0,
  );

  const editPotentialCause = useSelector((state) => {
    const id = state.failureModeDetails.editPotentialCauseId;
    if (id) {
      if (
        state.failureModeDetails.editFailureMode.potentialcauses
        && state.failureModeDetails.editFailureMode.potentialcauses.length > 0
      ) {
        return state.failureModeDetails.editFailureMode.potentialcauses.find(
          (p) => p.PotentialCause_ID === id,
        );
      }
    }
  });

  return (
    <>
      <div className="wrapper failuremodulePage cm-wrapper">
        <div className="contentWrap monitoringcontentWrap monitoringPage detailPage">
          <div className=" fm-container">
            <div className="row">
              <div className="col-sm-6">
                <div className="grid-header">
                  <span className="grid-label">
                    <svg
                      onClick={() => history.push('/FMEA/FailureModeListing')}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.9999 8.00014C14.9999 7.86753 14.9472 7.74036 14.8535 7.64659C14.7597 7.55282 14.6325 7.50014 14.4999 7.50014L2.70692 7.50014L5.85392 4.35414C5.90041 4.30766 5.93728 4.25247 5.96244 4.19173C5.9876 4.13099 6.00055 4.06589 6.00055 4.00014C6.00055 3.9344 5.9876 3.8693 5.96244 3.80856C5.93728 3.74782 5.90041 3.69263 5.85392 3.64614C5.80743 3.59966 5.75224 3.56278 5.6915 3.53762C5.63076 3.51246 5.56566 3.49951 5.49992 3.49951C5.43417 3.49951 5.36907 3.51246 5.30833 3.53762C5.24759 3.56278 5.1924 3.59966 5.14592 3.64614L1.14592 7.64614C1.09935 7.69259 1.06241 7.74776 1.0372 7.80851C1.012 7.86925 0.999023 7.93438 0.999023 8.00014C0.999023 8.06591 1.012 8.13103 1.0372 8.19178C1.06241 8.25252 1.09935 8.3077 1.14592 8.35414L5.14592 12.3541C5.1924 12.4006 5.24759 12.4375 5.30833 12.4627C5.36907 12.4878 5.43417 12.5008 5.49992 12.5008C5.56566 12.5008 5.63076 12.4878 5.6915 12.4627C5.75224 12.4375 5.80743 12.4006 5.85392 12.3541C5.90041 12.3077 5.93728 12.2525 5.96244 12.1917C5.9876 12.131 6.00055 12.0659 6.00055 12.0001C6.00055 11.9344 5.9876 11.8693 5.96244 11.8086C5.93728 11.7478 5.90041 11.6926 5.85392 11.6461L2.70692 8.50014H14.4999C14.6325 8.50014 14.7597 8.44747 14.8535 8.3537C14.9472 8.25993 14.9999 8.13275 14.9999 8.00014Z" fill="white" />
                    </svg>
                    Failure Mode
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <button
                  type="button"
                  className="pull-right btn btn-secondary add-entry-button"
                  onClick={() => {
                    setAddPotentialCauses(true);
                  }}
                >
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.99951 0.666504V11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.666504 6H11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                  Add Cause
                </button>

              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-5">
                    <div className="details-container">
                      <span className="sub-grid-label">{modeDetails.Description}</span>
                    </div>

                    <div className="details-container">
                      <div>
                        <span className="fa fa-minus-square" />
                        <span className="sub-grid-label ml-8">Failure Indicator</span>
                      </div>

                      {modeDetails.failureindicators
                        && modeDetails.failureindicators.length > 0 && (
                          <div className="tree-trunk">
                            {modeDetails.failureindicators
                              && modeDetails.failureindicators.map((item) => (
                                <div key={item.FailureIndicator_ID}>
                                  <span className="tree-branch" />
                                  <span className="tree-branch-content detail-content">
                                    {item.IndirectFormula}
                                  </span>
                                </div>
                              ))}
                          </div>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-7">
                    <div
                      className="details-container"
                    >
                      <span className="sub-grid-label">Potential Effect of Failure</span>
                      <p className="detail-content">{modeDetails.PotentialEffect}</p>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <div className="potential-cause-title-box">
                      <span className="grid-label">Potential Cause</span>
                    </div>

                    <div className="row potential-cause-grid-content">
                      {modeDetails.potentialcauses
                        && modeDetails.potentialcauses.map((item, i) => (
                          <>
                            <div
                              key={item.PotentialCause_ID}
                              className="col-md-6"
                            >
                              <div className="potential-cause-container">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="potential-cause-title pull-left sub-grid-label">
                                      {item.Description}
                                    </div>
                                  </div>
                                  <div className="col-sm-6 text-right">
                                    <label
                                      className="edit"
                                      onClick={() => {
                                        onEditPotentialCauseClick(
                                          item.PotentialCause_ID,
                                        );
                                      }}
                                    >
                                      {/* <Icon name="EditIcon" /> */}
                                      <Edit />
                                    </label>

                                    <span className="rpn-box">
                                      RPN :
                                      {item.RPN || 'NA'}
                                    </span>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-12 form-group">
                                    <span className="badge badge-secondary status-badge potential-badge">
                                      Occurrence Frequency -
                                      {' '}
                                      {item.occurencecriterium?.Scale || 'NA'}
                                    </span>
                                    <span className="badge badge-secondary status-badge potential-badge">
                                      Ease of detection -
                                      {' '}
                                      {item.detectioncriterium?.Scale || 'NA'}
                                    </span>
                                  </div>
                                </div>

                                <div>
                                  <span className="fa fa-minus-square" />
                                  <span className="sub-grid-label ml-8">Causal Parameter</span>
                                </div>
                                {item.causualparameters
                                  && item.causualparameters.length > 0 && (
                                    <div className="tree-trunk">
                                      {item.causualparameters
                                        && item.causualparameters.map((item) => (
                                          <div key={item.CausalParameter_ID}>
                                            <span className="tree-branch" />
                                            <span className="tree-branch-content detail-content">
                                              {item.IndirectFormula}
                                            </span>
                                          </div>
                                        ))}
                                    </div>
                                )}

                                <div className="clearfix form-group" />

                                <div className="recommended recommended-action-list">
                                  {item.recommendedactions && item.recommendedactions.length > 0
                                    && <span className="sub-grid-label">Recommended Action</span>}

                                  <div className="clearfix" />
                                  {item.recommendedactions
                                    && item.recommendedactions.length > 0 && (
                                      <div className="recommended">
                                        {item.recommendedactions
                                          && item.recommendedactions.map((item) => (
                                            <div className="card" key={item.RecommendedAction_ID}>

                                              {item.Description}

                                            </div>
                                          ))}
                                      </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {i % 2 === 1 && <div className="clearfix" />}
                          </>
                        ))}
                      {/* {modeDetails.potentialcauses &&
                modeDetails.potentialcauses.length % 2 == 1 && (
                  <div
                    key={0}
                    className="col-md-6 potential-cause-container"
                  ></div>
                )}
              <div className="clearfix"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {addPotentialCauses && (
              <Drawer
                title="Add Potential Causes"
                placement="right"
                height="100%"
                closable
                visible={addPotentialCauses}
                closeIcon={<div className="closepopup pointer"> &#x2715;</div>}
                onClose={onPotentialCauseClose}
                className="failuremodulePage"
                push={{ distance: 378 }}
              >
                <PotentialCausesAdd
                  failureModeID={failureModeId}
                  potentialCause={editPotentialCause}
                  onPotentialCauseClose={onPotentialCauseClose}
                />
              </Drawer>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
