/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import authHeader from '../../../config/auth.header';
import config from '../../../config/api.config.json';
import './rsc.css';
import DataComponent from './Table';
import GeneratePdf  from './GeneratePdf';


/* eslint  react/prop-types:0 */
function Monthlydata({
  plantFilter, calenderFilter, yearFilter, weekRanges, onSave,
}) {
  const year = yearFilter.value;
  const [loadPDF, setLoadPDF] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const getYearlyList = async () => {
    await axios
      .get(
        config.GET_YEARLY_DATA,
        {
          params: {
            plantId: plantFilter.label,
            year: yearFilter.value,
          },
          headers:  authHeader()
        },

        
      )
      .then((response) => {
        const dataset = response.data.mess;
        if (dataset && dataset.length > 0) {
          setMachineData(response.data.machineData);
          setTableData(dataset);
        } else {
          setTableData([]);
        }
      })
      .catch((error) => {
        console.log(error);
        //   setPlantsList([]);
      });
  };
  useEffect(() => {
    getYearlyList();
  }, []);
  useEffect(() => {
    getYearlyList();
  }, [plantFilter, calenderFilter, yearFilter, weekRanges, onSave]);

  return (
    <>      
      <button type="button" size="small" className="download-table-xls-button" onClick={() => {setLoadPDF(!loadPDF)}  } variant="contained"> Download as PDF  </button>
      {loadPDF && 
      <GeneratePdf machineData={machineData} tableData={tableData} year={year} revertState = { () => {setLoadPDF(false)} } />
      }
      
      <div className="tableFixHead rsdyearTbl">
        {tableData && tableData.length ?
          <DataComponent data={tableData} year={year} Month={calenderFilter.label} />
          : null
        }
      </div>
      {/** his is table edit */}
    </>
  );
}

export default Monthlydata;
