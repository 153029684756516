import React, { useState } from 'react';
import ReportAddEntryPopup from './ReportAddEntryPopup';

const ReportAddEntryButton = ({ name, reportCode }) => {
  const [open, setOpen] = useState(false);
  const onClosed = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="report_add_entry_btn">
        <button
          type="button"
          className="btn-primary"
          onClick={() => setOpen((prev) => !prev)}
        >
          {name}
        </button>
      </div>
      {open && <ReportAddEntryPopup reportCode={reportCode} open={open} onClosed={onClosed} />}
    </>
  );
};
export default ReportAddEntryButton;
