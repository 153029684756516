const ReportTile = ({ clickHandler, ReportCode, ReportName }) => (
  <div
    className="card_tile"
    onClick={clickHandler}
  >
    <span className="logo">
      <img
        src={`../Report_logos/${ReportCode}.svg`}
        onError={(e) => {
          e.target.src = '../Report_logos/Default.svg';
        }}
        alt="not available"
      />
    </span>
    <span className="title">{ReportName}</span>
  </div>
);
export default ReportTile;
