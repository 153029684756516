import LoadMachine from '../config/Form/LoadMachine.config.json';
import LoadPlantLineNo from '../config/Form/LoadPlantLineNo.config.json';
import LoadRole from '../config/Form/LoadRole.config.json';
import LoadUserAccess from '../config/Form/LoadUserAccess.config.json';
import Plants from '../config/Form/Plants.config.json';
import User from '../config/Form/User.config.json';
import LoadCustomer from '../config/Form/LoadCustomer.config.json';
import LoadModule from '../config/Form/LoadModule.config.json';
import Menus from '../config/Form/Menus.config.json';
import Reports from '../config/Form/Reports.config.json';
import LoadLocation from '../config/Form/LoadLocation.config.json';
import CustMenuMapping from '../config/Form/CustMenuMapping.config.json';
import CustReportMapping from '../config/Form/CustReportMapping.config.json';
import LoadRoleMenuMapping from '../config/Form/LoadRoleMenuMapping.config.json';
import LoadRoleReportMapping from '../config/Form/LoadRoleReportMapping.config.json';
import LoadEMPlant from '../config/Form/EMPlant.config.json';
import LoadEMPlantGroup from '../config/Form/EMPlantGroup.config.json';
import LoadEMPlantSubGroup from '../config/Form/EMPlantSubGroup.config.json';
import LoadEmailFlows from '../config/Form/EmailFlow.config.json';
import LoadEmailFlowsMapping from '../config/Form/EmailUserFlowMapping.config.json';

function getFormScema(x) {
  console.log(x);
  switch (x) {
    case 'LoadMachine': return LoadMachine;
    case 'LoadPlantLineNo': return LoadPlantLineNo;
    case 'LoadRole': return LoadRole;
    case 'LoadRoleReportMapping': return LoadRoleReportMapping;
    case 'LoadUserAccess': return LoadUserAccess;
    case 'Plants': return Plants;
    case 'User': return User;
    case 'LoadCustomer': return LoadCustomer;
    case 'LoadModule': return LoadModule;
    case 'Menus': return Menus;
    case 'Reports': return Reports;
    case 'LoadLocation': return LoadLocation;
    case 'CustMenuMapping': return CustMenuMapping;
    case 'CustReportMapping': return CustReportMapping;
    case 'LoadRoleMenuMapping': return LoadRoleMenuMapping;
    case 'LoadEMPlant': return LoadEMPlant;
    case 'LoadEMPlantGroup': return LoadEMPlantGroup;
    case 'LoadEMPlantSubGroup': return LoadEMPlantSubGroup;
    case 'LoadEmailFlows': return LoadEmailFlows;
    case 'LoadEmailFlowsMapping': return LoadEmailFlowsMapping;
    default: return [];
  }
}

export default getFormScema;
