import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { fetchFailureModeImport, uploadFailureModeFile, getFMEAImportNotification } from '../../components/ConditionMonitoring/FMEA/failure-mode-listing/Import-FMEA/ImportFMEAListingAPI';

const initialState = {
  failureModeImports: {},
  fmeanotifications: [],
  status: 'idle',
};

export const fetchFailureModeImportAsync = createAsyncThunk(
  'failureModeImport/fetchFailureModeImport',
  async ({ page, size, search }) => {
    const response = fetchFailureModeImport(page, size, search);
    return response;
  },
);

export const uploadFailureModeFileAsync = createAsyncThunk(
  'failureModeImport/uploadFailureModeFile',
  async (formData) => {
    const response = uploadFailureModeFile(formData);
    return response;
  },
);
export const getFMEAImportNotificationAsync = createAsyncThunk(
  'failureModeImport/getFMEAImportNotification',
  async () => {
    const response = getFMEAImportNotification();
    return response;
  },
);

export const failureModeImportSlice = createSlice({
  name: 'failureModeImport',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFMEAImportNotificationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFMEAImportNotificationAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.fmeanotifications = action.payload;
      })
      .addCase(fetchFailureModeImportAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFailureModeImportAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.failureModeImports = action.payload;
      })
      .addCase(uploadFailureModeFileAsync.pending, (state) => {
        state.status = 'loading';
      })
      // .addCase(uploadFailureModeFileAsync.fulfilled, (state, action) => {
      .addCase(uploadFailureModeFileAsync.fulfilled, (state) => {
        state.status = 'idle';
        message.success('File Uploaded.');
      })
      // .addCase(uploadFailureModeFileAsync.rejected, (state, action) => {
      .addCase(uploadFailureModeFileAsync.rejected, (state) => {
        state.status = 'idle';
        message.error('Invalid Excel sheet/format.');
      });
  },
});

export default failureModeImportSlice.reducer;
