// import axios from 'axios';
// import { APIUrl } from "../../../configs/config";
import { APIUrl } from '../../../helpers/constants';
// import authHeader from '../../../config/auth.header';
import CallAPI from '../../../services/conditionMonitoring.service';

// const headers = {
//   headers: authHeader(),
// };
// A mock function to mimic making an async request for data
export function getGeneralConditions(page = 1, size = 10, search, sortName, sortDirection, activeStatus) {
  const apiInfo = {
    url: `${APIUrl}/api/getGeneralConditions?page=${page}&size=${size}&search=${search}
      &sortName=${sortName}&sortDirection=${sortDirection}&activeStatus=${activeStatus}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(

  //     `${APIUrl}/api/getGeneralConditions?page=${page}&size=${size}&search=${search}
  //     &sortName=${sortName}&sortDirection=${sortDirection}`, headers,
  //   )
  //   .then((res) => res.data);
}

export function saveActiveStatus(id, status) {
  const apiInfo = {
    url: `${APIUrl}/api/saveActivateGeneralCondition`,
    method: 'POST',
    postData: { GeneralCondition_ID: id, IsActive: status },
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .post(`${APIUrl}/api/saveActivateGeneralCondition`, {
  //     GeneralCondition_ID: id,
  //     IsActive: status,
  //   }, headers);
  // .then((res) => console.log("Status updated"));
}
