import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Select from 'react-select';
import { message } from 'antd';
import { Form } from 'react-final-form';
import moment from 'moment';
/* eslint no-unused-vars:0 */
import { DatePicker } from '@material-ui/pickers';
import CallAPI from '../../../services/seniorManagement.service';
import apiConfig from '../../../config/api.config.json';

const AddEntry = ({ open, onClosed }) => {
  const [errorTxt] = useState('');
  const [errorFields, setErrorFields] = useState([]);
  const [date, setDate] = useState(new Date());
  const [atsLoss, setAtsLoss] = useState('');
  const [printLoss, setPrintLoss] = useState('');
  const [floorLoss, setFloorLoss] = useState('');
  const [acruLoss, setAcruLoss] = useState('');
  const [plantFilter, setPlantFilter] = useState({});
  const [PlantOptions, setPlantOptions] = useState([]);

  const getPlants = async () => {
    const apiInfo = {
      url: `${apiConfig.GET_PLANTS}`,
      method: 'GET',
      isMultiPart: false,
    };
    const result = await CallAPI(apiInfo);
    if (result.status === 200) {
      const response = result?.data?.data;
      setPlantOptions(response);
      setPlantFilter(response[0]);
    } else {
      setPlantOptions([]);
    }
  };
  useEffect(() => {
    getPlants();
  }, []);
  const validate = () => { };
  const onSaveBtnClicked = async () => {
    try {
      const errorsFields = [];
      if (!atsLoss || atsLoss >= 10) {
        errorsFields.push('atsLoss');
      }
      if (!printLoss || printLoss >= 10) {
        errorsFields.push('printLoss');
      }
      if (!acruLoss || acruLoss >= 10) {
        errorsFields.push('acruLoss');
      }
      if (!floorLoss || floorLoss >= 10) {
        errorsFields.push('floorLoss');
      }
      if (!plantFilter.value) {
        errorFields.push('plant');
      }
      setErrorFields(errorsFields);
      if (errorsFields.length === 0) {
        const data = {
          DateKey: moment(date).format().split('T')[0].split('-').join(''),
          PlantKey: plantFilter.value,
          ATSLoss: atsLoss,
          FlorLoss: floorLoss,
          PrintingLoss: printLoss,
          ACRULoss: acruLoss,
        };
        const apiInfo = {
          method: 'POST',
          url: apiConfig.SAVE_LOSS_DATA,
          postData: data,
          isMultiPart: false,
        };
        const response = await CallAPI(apiInfo);
        if (response.data.status) {
          message.success('Details saved successfully');
        } else {
          message.error('Details failed to save');
        }
        onClosed();
      }
    } catch (err) {
      message.error('Details failed to save');
    }
  };
  const handleChange = (event) => {
    const { value, name } = event.target;
    const regexp = /(^[0-9]{0,1})(\.[0-9]{0,2})?$/;
    if (name === 'atsLoss') {
      if (value.match(regexp)) setAtsLoss(value);
      else setAtsLoss('');
    } else if (name === 'printLoss') {
      if (value.match(regexp)) setPrintLoss(value);
      else setPrintLoss('');
    } else if (name === 'acruLoss') {
      if (value.match(regexp)) setAcruLoss(value);
      else setAcruLoss('');
    } else if (name === 'floorLoss') {
      if (value.match(regexp)) setFloorLoss(value);
      else setFloorLoss('');
    }
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };
  return (
    <Dialog open={open} fullWidth="lg" sx={{ backgroundColor: '#383232' }}>
      <div className="sm-modal-inner">
        <DialogTitle>
          Losses Manual Entry Form
          <div
            className="closepopup pointer"
            onClick={onClosed}
          >
            &#x2715;
          </div>
        </DialogTitle>
        <DialogContent>
          <Form
            onSubmit={onSaveBtnClicked}
            validate={validate}
            keepDirtyOnReinitialize
            initialValues={{ To_Date: moment().format('YYYY-MM-DDTHH:mm') }}
                    // render={({ handleSubmit, form, reset }) => {
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="contentPopup notification-template-editor sm-popup">
                  <div className="innerContentPopup">
                    <div className="descriptionCreate">
                      <div className="row">
                        <div className="">
                          <div className="form-group formgroup">
                            <div className="font14">
                              <div id="">
                                <div className="col-md-6">
                                  <div className="row-md-16">
                                    <div className="form-group mui-date-picker">
                                      <label className="font12">Plant</label>
                                      <span className="redStar">*</span>
                                      <Select
                                        // id="plantKey"
                                        className="dropdown_rsd"
                                      // className={`dropdown_rsd input-md${errorFields.indexOf('plant') !== -1 ? ' error-field' : ''}`}
                                        name="plant"
                                        onChange={(e) => setPlantFilter(e)}
                                        // defaultValue={false}
                                        options={PlantOptions}
                                        value={plantFilter}
                                        noOptionsMessage={() => 'Select Proper Plant'}
                                        styles={customStyles}
                                      />
                                    </div>
                                  </div>
                                  <div className="row-md-16">
                                    <div className="form-group">
                                      <label className="font12">ATS Loss</label>
                                      <span className="redStar">*</span>
                                      <input
                                        id="textbox1"
                                        name="atsLoss"
                                        type="number"
                                        placeholder="Enter ATS Loss in Lacs"
                                        onChange={(e) => handleChange(e)}
                                        value={atsLoss}
                                        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                        className={`form-control customForm input-md${errorFields.indexOf('atsLoss') !== -1 ? ' error-field' : ''}`}
                                      />
                                      <span className="err-txt">{errorFields.indexOf('atsLoss') !== -1 ? 'ATS Loss must be in between 0 to 9.99' : ''}</span>
                                    </div>
                                  </div>
                                  <div className="row-md-16">
                                    <div className="form-group">
                                      <label className="font12">Printing Loss</label>
                                      <span className="redStar">*</span>
                                      <input
                                        id="textbox1"
                                        name="printLoss"
                                        type="number"
                                        placeholder="Enter Printing Loss in Lacs"
                                        onChange={(e) => handleChange(e)}
                                        value={printLoss}
                                        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                        className={`form-control customForm input-md${errorFields.indexOf('printLoss') !== -1 ? ' error-field' : ''}`}
                                      />
                                      <span className="err-txt">{errorFields.indexOf('printLoss') !== -1 ? 'Print Loss must be in between 0 to 9.99' : ''}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row-md-16">
                                    <div className="form-group mui-date-picker">
                                      <label className="font12">Date</label>
                                      <span className="redStar">*</span>
                                      <DatePicker
                                        value={date}
                                        maxDate={new Date()}
                                        autoOk
                                        ampm={false}
                                        onChange={setDate}
                                        className="form-control customForm input-md date-picker"
                                        format="dd-MM-yyyy"
                                      />
                                    </div>
                                  </div>
                                  <div className="row-md-16">
                                    <div className="form-group">
                                      <label className="font12">Floor Loss</label>
                                      <span className="redStar">*</span>
                                      <input
                                        id="textbox1"
                                        name="floorLoss"
                                        type="number"
                                        placeholder="Enter Floor Loss in Lacs"
                                        onChange={(e) => handleChange(e)}
                                        value={floorLoss}
                                        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                        className={`form-control customForm input-md${errorFields.indexOf('floorLoss') !== -1 ? ' error-field' : ''}`}
                                      />
                                      <span className="err-txt">{errorFields.indexOf('floorLoss') !== -1 ? 'Floor Loss must be in between 0 to 9.99' : ''}</span>
                                    </div>
                                  </div>
                                  <div className="row-md-16">
                                    <div className="form-group">
                                      <label className="font12">ACRU Loss</label>
                                      <span className="redStar">*</span>
                                      <input
                                        id="textbox1"
                                        name="acruLoss"
                                        type="number"
                                        placeholder="Enter ACRU Loss in Lacs"
                                        onChange={(e) => handleChange(e)}
                                        value={acruLoss}
                                        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                        className={`form-control customForm input-md${errorFields.indexOf('acruLoss') !== -1 ? ' error-field' : ''}`}
                                      />
                                      <span className="err-txt">{errorFields.indexOf('acruLoss') !== -1 ? 'ACRU Loss must be in between 0 to 9.99' : ''}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="enterpins placeColor">
                              <div className="pinErrorTxt">{errorTxt}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footerPopupWithBtn">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <button
                          type="button"
                          id="cancelBtn"
                          name="cancel"
                          className="btn btn-default cancelBtn"
                          value="2"
                          onClick={onClosed}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          id="saveBtn"
                          name="Save"
                          className="btn btn-default saveBtn"
                          htmlType="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
};
export default AddEntry;
