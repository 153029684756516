import JWTDecode from 'jwt-decode';

export function getUserPreference() {
  const userToken = localStorage.getItem('user');
  if (userToken != null) {
    const userData = JWTDecode(userToken);
    return userData.userProfile;
  }
  return '';
}

export function getUserId() {
  const firstname = localStorage.getItem('firstname');
  const lastname = localStorage.getItem('lastname');
  const name = `${firstname} ${lastname}`;
  return name;
  // const userToken = localStorage.getItem("user");
  // if(userToken){
  //   const userData = JWTDecode(userToken);
  //   return userData.id;
  // }else{

  //   return "";
  // }
}

export function getUserOrg() {
  const userToken = localStorage.getItem('user');
  if (userToken) {
    const userData = JWTDecode(userToken);

    return userData.userProfile.organization;
  }

  return '';
}
export function getUserMachineData() {
  const machineData = localStorage.getItem('machineConfigureNOC');
  return JSON.parse(machineData);
}
export function getUserMachineDataNoc() {
  const machineData = localStorage.getItem('machineData');
  return JSON.parse(machineData);
}

/* common function for userdata */
export function getUserPreferenceData() {
  const userToken = localStorage.getItem('user');
  if (userToken != null) {
    const userData = JWTDecode(userToken);
    localStorage.setItem('userDetails', JSON.stringify(userData));
    return userData;
  }
  return '';
}
