import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchFailureModes, saveActiveStatus } from '../../components/ConditionMonitoring/FMEA/failure-mode-listing/failureModeListingAPI';

const initialState = {
  failureModes: {},
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchFailureModesAsync = createAsyncThunk(
  'failureModes/fetchFailureModes',
  async ({
    page, size, search, values, model, sortName, sortDirection,
  }) => {
    const response = await fetchFailureModes(page, size, search, values, model, sortName, sortDirection);
    return response;
  },
);

export const saveActiveStatusAsync = createAsyncThunk(
  'failureModes/saveActiveStatus',
  async ({ id, status }) => {
    const request = await saveActiveStatus(id, status);
    return request;
  },
);

export const failureModesSlice = createSlice({
  name: 'failureModes',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchFailureModesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFailureModesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.failureModes = action.payload;
      })

      .addCase(saveActiveStatusAsync.pending, (state, action) => {
        state.status = 'loading';
        const { id, status } = action.meta.arg;
        const failureMode = state.failureModes.rows.find((r) => r.FailureMode_ID === +id);
        failureMode.IsActive = status;
      })
      // .addCase(saveActiveStatusAsync.fulfilled, (state, action) => {
      .addCase(saveActiveStatusAsync.fulfilled, (state) => {
        state.status = 'idle';
        // state.failureModes = action.payload;
      })
      // .addCase(saveActiveStatusAsync.rejected, (state, action) => {
      .addCase(saveActiveStatusAsync.rejected, (state) => {
        state.status = 'idle';
      });
  },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default failureModesSlice.reducer;
