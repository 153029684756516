import React from 'react';

const index = ({
  formData, current, handleOnChange, inputText, disabled,
}) => (
  <input
    value={formData[current.ColumnName] != null ? formData[current.ColumnName] : ''}
    data-keyname={current.ColumnName}
    onChange={handleOnChange}
    className={`${inputText}`}
    required={current.Required}
    type={current.Type}
    disabled={disabled}
  />
);
export default index;
