/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { CgMaximizeAlt } from "react-icons/cg";
import CircularProgress from '@mui/material/CircularProgress';

import getBreakDownPareto from "../../../API/getBreakDownPareto.js";
import ExpandPareto from "./Modal/ExpandPareto.jsx";
import NoData from "../../Reusable/NoData.jsx";

import "./DownTimePareto.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DownTimePareto({ datas }) {
  const [paretoData, setParetoData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const getBreakDown = async (e) => {
    let temp = { ...e, TopNAll: "TOPN" };
    let res = await getBreakDownPareto(temp);
    setParetoData(res?.data?.data || []);
    setLoading(false);
  };

  useEffect(() => {
    if (datas.flag != "new") {
      setLoading(true);
      getBreakDown(datas);
    }
  }, [datas]);

  let labels = paretoData?.map((e, i) => {
    if (e.DowntimeReason.length > 7) {
      let shortString = e.DowntimeReason.substring(0, 10);
      return shortString + "...";
    }
    return e.DowntimeReason;
    // This code is for breaks in name
    // let str = e.DowntimeReason;
    // let parts = str.split(/(\S+\s*\s*\S+)/);
    // parts = parts.filter(function (str) {
    //   return /\S/.test(str);
    // });
    // return parts
  });
  const data = {
    labels,
    datasets: [
      {
        label: "Left Side",
        data: paretoData?.map((e) => {
          return e.Minutes;
        }),
        backgroundColor: "#4B84A5",
      },
    ],
  };
  const options = {
    devicePixelRatio: 4,
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (e) {
            return paretoData?.[e[0].dataIndex].DowntimeReason;
          },
          label: function (text) {
            let label = " " + text.formattedValue + " mins";
            return label;
          },
        },
        backgroundColor: "black",
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
          font: {
            size: 12,
            family: "Arial, Sans Serif",
          },
          color: "#FFFFFF",
        },
        grid: { display: false },
        title: {
          display: true,
          text: "Breakdown",
          color: "#FFFFFF",
          font: {
            size: 12,
            family: "Arial, Sans Serif",
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 12,
            family: "Arial, Sans Serif",
          },
          color: "#FFFFFF",
        },
        grid: {
          color: "rgba(255, 255, 255, 0.12)",
          borderWidth: 1,
          borderDash: [5, 2],
        },
        title: {
          display: true,
          text: "Time (mins)",
          color: "#FFFFFF",
          font: {
            size: 12,
            family: "Montserrat,Arial, Sans Serif",
          },
        },
      },
    },
  };

  return (
    <div>
      <div className="pareto-head">
        <div className="pareto-head-text" style={{fontSize:"14px"}}>Downtime Pareto</div>
        {loading ? null : (
          <div style={{ cursor: "pointer", marginRight:'-10px', marginTop:'-5px' }}>
            {!!paretoData.length && (
              <CgMaximizeAlt size={17} onClick={() => setOpen(true)} />
            )}
          </div>
        )}
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems:"center"
          }}
        >
        <CircularProgress size={15}/>
        </div>
      ) : (
        <div className="pareto-box">
          {paretoData.length ? (
            <Bar
              options={options}
              // height={400}
              data={data}
              // width={700} //remove this line if you want to decrease the chart width
              style={{ padding: "13px" }}
            />
          ) : (
            <NoData />
          )}
        </div>
      )}

      {open && <ExpandPareto setOpen={setOpen} open={open} datas={datas} />}
    </div>
  );
}

export default DownTimePareto;
