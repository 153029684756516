import Select from 'react-select';

// IMPORTING CSS
import Style from './style.module.css';
/* eslint camelcase:0 */
const {
  AdminPanel__head,
  add_btn,
  dropdown_rsd,
  input_search,
  left,
  right,
} = Style;
const customStyles = {
  // menu: (provided, state) => {
  menu: (provided) => ({ ...provided }),
  container: (provided) => ({
    ...provided,
  }),

  // singleValue: (provided, state) => {
  singleValue: (provided) => {
    const color = 'white';
    return { ...provided, color };
  },
};
// let ref;

const TableHead = ({
  SelectMenuItems, defaultSelectedValue, selectedTable, handleSelect, handleUpdate, defaultData, setTableData, OriginalData,
  setSearchValue,
}) => {
  const handleSearch = (e) => {
    // clearTimeout(ref);
    // ref = setTimeout(() => {
    // }, 500);
    const searchValue = `${e.target.value}`.trim().toUpperCase();
    setSearchValue(searchValue);
    if (searchValue !== '') {
      const filterData = [];
      for (let i = 0; i < OriginalData.length; i += 1) {
        const current = OriginalData[i];
        let obj = {};
        for (const x in current) {
          if (current[x] != null && `${current[x]}`.toUpperCase().includes(searchValue)) {
            obj = current;
            filterData.push(obj);
            break;
          }
        }
      }
      setTableData(filterData);
    } else {
      setTableData(OriginalData);
    }
  };
  return (
    <div className={`${AdminPanel__head}`}>
      <div className={`${left}`}>
        <Select
          className={`dropdown_rsd ${dropdown_rsd}`}
          defaultValue={defaultSelectedValue}
          options={SelectMenuItems}
          value={selectedTable}
          onChange={handleSelect}
          noOptionsMessage={() => 'Select Table'}
          styles={customStyles}
          isSearchable
        />
      </div>
      <div className={`${right}`}>
        <input type="search" className={`${input_search}`} placeholder="Search" onChange={handleSearch} />
        <button
          type="button"
          className={`btn-primary ${add_btn}`}
          onClick={() => {
            handleUpdate(defaultData, 'INSERT');
          }}
        >
          Create New

        </button>
      </div>
    </div>
  );
};

export default TableHead;
