import React from 'react';
import { useHistory } from 'react-router-dom';

const EmailSend = () => {
  const history = useHistory();
  const gotoLogin = () => {
    history.push('/');
  };
  return (
    <div className="bgImgLogin">
      <div className="text-center centerbox">
        {/* <Logo /> */}
        <form className="form-signin">
          <div className="closeLogins">
            <a className="crossIcon" onClick={gotoLogin}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.25 6.67871L12.25 12.6787L6.25 18.6787"
                  stroke="#8F8F8F"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.25 6.67871L18.25 18.6787"
                  stroke="#8F8F8F"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </div>
          <div className="forgetFormBg">
            <div className="welcm text-center">Forgot password?</div>
            <div className="hr mTB20" />
            <div className="pad20">
              <div className="text-center checkEmail">Check your E-mail</div>
              <div className="text-center rightSign">
                <svg
                  width="49"
                  height="48"
                  viewBox="0 0 49 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.581055 24C0.581055 10.8372 11.2516 0.166626 24.4144 0.166626C37.5772 0.166626 48.2477 10.8372 48.2477 24C48.2477 37.1627 37.5772 47.8333 24.4144 47.8333C11.2516 47.8333 0.581055 37.1627 0.581055 24Z"
                    fill="#419853"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.9209 15.4263C36.767 16.2724 36.767 17.6443 35.9209 18.4904L22.4722 32.5737C21.626 33.4199 20.2542 33.4199 19.408 32.5737L12.908 26.0737C12.0619 25.2276 12.0619 23.8557 12.908 23.0096C13.7542 22.1635 15.126 22.1635 15.9722 23.0096L20.9401 27.9775L32.8568 15.4263C33.7029 14.5801 35.0748 14.5801 35.9209 15.4263Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="text-center confirmMsg">
                We have sent you a reset password link on your registered email
                address
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EmailSend;
