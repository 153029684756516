import { Form } from 'react-final-form';
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import 'antd/dist/antd.dark.css';
import { Button, Spin } from 'antd';
import { Mention, MentionsInput } from 'react-mentions';
import { compileExpression } from 'filtrex';
import { defaultStyle, defaultMentionStyle } from '../components/ConditionMonitoring/mentions';
import '../styles/failureMode.css';
// import { ReactComponent as AlertIcon } from "../../../../src/components/dashboard/images/alert-circle.svg";
// import Icon from "../components/Dashboard/Sidebar/images/Icon";
const style = defaultStyle;
const inputEl = React.createRef();
/* eslint no-unused-vars:0 */
// eslint-disable-next-line import/prefer-default-export
export const FormulaField = ({
  onSubmit, machineParameter, onClose, item, spinning,
}) => {
  const [formula, setFormula] = useState(item && item.IndirectFormula);
  const [formulaTouched, setFormulaTouched] = useState(false);

  const [validFormula, setValidFormula] = useState(!!((item && item.IndirectFormula)));
  const onChange = (event, newValue, newPlainTextValue, mentions) => {
    setFormula(newValue);
    if (!newValue) {
      setValidFormula(false);
      return false;
    }
    // if (newPlainTextValue !== "") {
    const validTokens = ['AND', 'OR', 'NOT', '<', '>', '<=', '>=', '+', '-', '*', '/', '%', '==', '='];
    const params = newValue.match(/@{[^@{}]*}/gm);
    if (params === null || params === undefined || params.length === 0) {
      return false;
    }
    newPlainTextValue = newValue.replace(/@{[^@{}]*}/gm, 'dummy');
    const temp = newPlainTextValue.replace(/\(/gm, ' ').replace(/\)/gm, ' ').replace(/\n/gm, ' ');
    const tokens = temp.split(' ');
    const find = tokens.filter((ele) => validTokens.find((ele2) => ele === ele2));
    if (!find || find.length === 0) {
      return false;
    }
    // const validString = tokens.every((t) => {
    //   const isValidToken = validTokens.some((vt) => vt === t.toUpperCase());
    //   const isParam = machineParameter.some((mp) => mp.ParameterName.toUpperCase() === t.toUpperCase());
    //   if (isValidToken || isFinite(t) || isParam || t === 'dummy') {
    //     return true;
    //   }
    //   return false;
    // });

    // if (!validString) {
    //   setValidFormula(false);
    //   return;
    // }

    try {
      compileExpression(newPlainTextValue.toLowerCase());
      const unitFormula = newPlainTextValue.toLowerCase().split(/ and | or | not /);
      for (let f of unitFormula) {
        f = f.replace(/\(/gm, ' ').replace(/\)/gm, ' ').replace(/\n/gm, ' ');
        const check = f.split('').filter((el) => validTokens.find((el1) => (el === el1)));
        if (check.length === 0) {
          return false;
        }
        compileExpression(f);
      }
      setValidFormula(true);
      return true;
    } catch (ex) {
      setValidFormula(false);
      return false;
    }
    // }
  };

  const [result, setResult] = useState([]);

  useEffect(() => {
    setResult(
      machineParameter.map((m) => ({
        id: m.MachineParameter_ID,
        display: m.ParameterName,
        subText: m.ParameterUOM,
      })),
    );
  }, [machineParameter]);

  return (
    <Spin spinning={spinning}>
      <Form
        onSubmit={(v) => { setFormulaTouched(true); onSubmit(v, formula, validFormula); }}
        initialValues={item || {}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="fm">
            <div className="form-group formula-dropdown">
              <label htmlFor="exampleFormControlSelect1">
                {' '}
                Formula
                {' '}
                <div
                  className={validFormula ? 'valid-formula' : 'invalid-formula'}
                />
                <span className="redStar">*</span>
              </label>

              <MentionsInput
                value={formula}
                onChange={onChange}
                style={style}
                inputRef={inputEl}
                a11ySuggestionsListLabel="Suggested mentions"
                onBlur={() => { setFormulaTouched(true); }}
                className={` ${(formula === '' || formula === undefined) && formulaTouched ? 'error-field' : ''}`}
              >
                <Mention
                  markup="@{__display__}"
                  displayTransform={(id, display) => `${display}`}
                  // data={users}
                  data={(search, callback) => {
                    if (search === '') {
                      return result;
                    }
                    return result.filter(
                      (r) => r.display.toLowerCase().indexOf(search.toLowerCase()) >= 0,
                    );
                  }}
                  style={defaultMentionStyle}
                  renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay,
                    index,
                    focused,
                  ) => (

                    <div className={`user ${focused ? 'focused' : ''}`}>
                      <span>{highlightedDisplay}</span>
                      {' '}
                      <div style={{ float: 'right', fontSize: 11, marginTop: 5 }}>
                        (
                        {suggestion.subText}
                        )
                      </div>
                    </div>
                  )}
                />
              </MentionsInput>
            </div>

            <div className="pull-right">
              <Button
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </div>
            <div className="row">
              <div className="col-sm-12 fia-desc">
                <div className="fia-desc-head">Points to consider when configuring formula:</div>
                <br />
                <span>1. Type @ and select from a list of parameters relevant</span>
                <br />
                <span>2. Provide a space between operators and operands</span>
                <br />
                <span>Eg: Temperature &gt; 35</span>
                <br />
                <span>3. Nested formula can also be configured. Make sure to provide spaces.</span>
                <br />
                <span>Eg: (Temperature &lt; 0 or Temperature &gt; 15) and ((Pressure * 0.5) &gt; 20)</span>
                <br />
                <span>5. Product Type Should be either HPMC or GELATIN.</span>
                <br />
                <span>Eg: Product Type == 'HPMC' </span>
                <br />
                <span>6. Machine Type Should be either MINIHCM, HCM or HPLUS.</span>
                <br />
                <span>Eg: Machine Type == 'HPLUS' </span>
                <br />
                <span>7. Machine Serial No. Should be start with H and Line No eg.H101 </span>
                <br />
                <span>Eg:Machine Serial No == 'H101' </span>
                <br />
                {/* eslint react/no-unescaped-entities: 0 */}
                <span>8. First Add the Conditions, then Group them.</span>
                <br />
              </div>
            </div>
          </form>
        )}
      />
    </Spin>
  );
};
