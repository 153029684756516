/* eslint-disable */
import axiosInstance from "../utils/api";

export default function getPlantList()  {
    let config = {
        headers: {
          "Content-Type": "application/json",
        },
    };

    return axiosInstance
    .get(`/api/dm/plants`, config )
    .then(function (response) {
      return response;
    })
    .catch(function (err) {
        if (err.response) {
          return err;
        } else if (err.request) {
          return err;
        } else {
          return err.message;
        }
      });
  }
  