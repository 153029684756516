import React, { useState } from 'react';
import CapualModal from './capModal';
import BodyModal from './bodyModal';
import PredictModel from './PredictModel';
// /* eslint jsx-a11y/anchor-is-valid:0 */
// /* eslint  jsx-a11y/click-events-have-key-events:0 */
// /* eslint  jsx-a11y/click-events-have-key-events:0 */
// /* eslint jsx-a11y/no-static-element-interactions:0 */
function ReportSlide() {
  const [selectedTab, setSelectedTab] = useState('capsulModel');
  return (
    <div className="tab-container IOMatrix_container">
      <ul className="tab-button-container">
        <li
          className={`tab-button ${
            selectedTab === 'capsulModel' ? 'active' : ''
          }`}
        >
          <a
            data-toggle="tab"
            data-tab="capsulModel"
            onClick={(e) => {
              setSelectedTab(e.target.dataset.tab);
            }}
          >
            Cap Model
          </a>
        </li>
        <li
          id="bodyModel"
          className={`tab-button ${
            selectedTab === 'bodyModel' ? 'active' : ''
          }`}
        >
          <a
            data-tab="bodyModel"
            onClick={(e) => {
              setSelectedTab(e.target.dataset.tab);
            }}
          >
            {/* {Lang.MachineSetting} */}
            Body Model
          </a>
        </li>
        <li
          id="predictModal"
          className={`tab-button ${
            selectedTab === 'predictModal' ? 'active' : ''
          }`}
        >
          <a
            data-tab="predictModal"
            onClick={(e) => {
              setSelectedTab(e.target.dataset.tab);
            }}
            role="button"
            tabIndex={0}
          >
            {/* {Lang.MachineSetting} */}
            View Prediction
          </a>
        </li>
      </ul>
      <div className="tab-content">
        {selectedTab === 'capsulModel' ? (
          <div
            // prettier-ignore
            className={`tab-pane ${selectedTab === 'capsulModel' ? 'active' : ''}`}
          >
            <CapualModal />
          </div>
        ) : (
          ''
        )}

        {selectedTab === 'bodyModel' ? (
          <div
            // prettier-ignore
            className={`tab-pane ${selectedTab === 'bodyModel' ? 'active' : ''}`}
          >
            <BodyModal />
          </div>
        ) : (
          ''
        )}

        {selectedTab === 'predictModal' ? (
          <div
            // prettier-ignore
            className={`tab-pane ${selectedTab === 'predictModal' ? 'active' : ''}`}
          >
            <PredictModel />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default ReportSlide;
