/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import getItem from './getLocalStorageItem';
import apiConfig from '../config/api.config.json';

export async function callApi(apiInfo) {
  const LoggedUserKey = localStorage.getItem('UserKey');
  try {
    const { method, url, postData: data } = apiInfo;
    const token = await getItem('token'); // get token
    let apiData = {};
    apiData = { ...apiData, method, url };
    if (data) { // set payload
      data.LoggedUserKey = LoggedUserKey;
      apiData = { ...apiData, data };
    }
    // console.log('apiData', apiData);
    if (![apiConfig.API_LOGIN].includes(url)) { // token excemption routes
      if (token) { // set token in headers
        apiData = {
          ...apiData,
          headers: { 'x-access-token': token },
        };
      }
    }

    const response = await axios(apiData);
    if (response) return response;
    return false;
  } catch (err) {
    console.log(`api-err ${apiInfo?.url} failed due to`, err);
    return false;
  }
}
