import React, {
  useState, useContext,
} from 'react';
// import CircularIndeterminate from '../../../components/dashboard/common/CircularIndeterminate';
// import PaginationTab from '../../../components/dashboard/common/PaginationTab';
import AlertNotificationFilter from './alert-notification-filter';
import AlertNotificationsTable from './alert-notification-table';
import { LanguageContext } from '../../../LanguageContext';
import './alert-notification-module.css';
/* eslint no-unused-vars:0 */
const AlertNotifications = () => {
  const [filterVisible, setFilterVisible] = useState(!(window.innerWidth < 769));
  // const [filterVisible, setFilterVisible] = useState(true);
  const [page, setPage] = useState(1);
  const [filterValues, setFiltervalues] = useState({});

  const Filtervaluechanged = (data) => {
    setPage(1);
    console.log(data);
    setFiltervalues(data);
  };

  const setpageValue = (data) => {
    setPage(data);
  };

  // for language change
  const [Lang] = useContext(LanguageContext);

  return (
    <>
      <div className="wrapper cm-wrapper alertnoti">
        <div className="contentWrap conditionMonitoring monitoringcontentWrap monitoringPage alert-notifications">
          <div>
            <div className="tabArea">
              <ul id="navList" className="nav nav-tabs tabsName">
                <li className="active">
                  <a data-toggle="tab">{Lang.AlertnotificationTitle}</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="alertnotifylog">
                  <div className="contentPart">
                    <AlertNotificationFilter setFilterVisible={setFilterVisible} filterVisible={filterVisible} onSubmited={Filtervaluechanged} />

                    {/* <div className={`col-md-2 ${filterVisible ? "" : "t"}`}>
                                </div> */}
                    <div className={`${filterVisible ? '' : 't'} tab-pane fade no-gutters in active`}>
                      <AlertNotificationsTable filterValues={filterValues} page={page} setPage={setpageValue} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default AlertNotifications;
