import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
/* eslint max-len:0 */
import { fetchNotificationGroup, deleteNotificationGroup } from '../../components/ConditionMonitoring/NotificationGroup/notificationGroupAPI';

const initialState = {
  notificationGroup: [],
  notificationGroupStatus: 'idle',
  deleteStatus: 'idle',
};

// .get(
//     `${API_Url}/api/getNotificationGroup?id=${id}&searchkey=${searchKey}&pageindex=${pageindex}&pageCount=${pageCount}`
//   )
//   .then((res) => {
//     return res.data;
//   });
export const fetchNotificationGroupAsync = createAsyncThunk('notificationGroup/fetchNotificationGroup', async ({
  id, searchKey, pageIndex, pageCount,
}) => {
  const response = await fetchNotificationGroup(id, searchKey, pageIndex, pageCount);
  return response;
});

export const deleteNotificationGroupAsync = createAsyncThunk('notificationGroup/deleteNotificationGroup', async (id) => {
  const response = await deleteNotificationGroup(id);
  return response;
});

export const notificationGroup = createSlice({
  name: 'notificationGroup',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationGroupAsync.pending, (state) => {
        state.notificationGroupStatus = 'loading';
      })
      .addCase(fetchNotificationGroupAsync.fulfilled, (state, action) => {
        state.notificationGroupStatus = 'idle';
        state.notificationGroup = action.payload;
      })
      .addCase(deleteNotificationGroupAsync.pending, (state) => {
        state.deleteStatus = 'loading';
      })
      .addCase(deleteNotificationGroupAsync.fulfilled, (state) => {
        state.deleteStatus = 'idle';
        message.success('Record deleted succesfully');
      })
      .addCase(deleteNotificationGroupAsync.rejected, (state, action) => {
        state.deleteStatus = 'idle';
        if (action.error && action.error.message.includes('512')) message.error('Unable to delete the group, as it is associated with other conditions');
        else message.error('Unable to delete the group');
      });
  },
});

export default notificationGroup.reducer;
