import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { viewCausualParameters, deleteCausualParameters } from '../../components/ConditionMonitoring/FMEA/causual-parameters/causualParametersViewApi';

import { removeCausualParameter } from './failureModeDetailsSlice';

const initialState = {
  causualParameters: [],
  causualParametersStatus: 'idle',
};

export const viewCausualParametersAsync = createAsyncThunk(
  'causualParameter/viewCausualParameter',
  async (id) => {
    const response = await viewCausualParameters(id);
    return response;
  },
);

export const deleteCausualParametersAsync = createAsyncThunk(
  'causualParameter/deleteCausualParameter',
  async (deleteObject, thunkApi) => {
    const response = await deleteCausualParameters(deleteObject);
    if (response === 'OK') {
      thunkApi.dispatch(removeCausualParameter(deleteObject));
    }
    return response;
  },
);

export const causualParametersEntry = createSlice({
  name: 'causualParameter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(viewCausualParametersAsync.pending, (state) => {
        state.causualParametersStatus = 'loading';
      })
      .addCase(viewCausualParametersAsync.fulfilled, (state, action) => {
        state.causualParametersStatus = 'idle';
        state.causualParameters = action.payload;
      })
      // .addCase(viewCausualParametersAsync.rejected, (state, action) => {
      .addCase(viewCausualParametersAsync.rejected, (state) => {
        state.causualParametersStatus = 'idle';
      });
  },
});

export default causualParametersEntry.reducer;
