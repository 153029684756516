import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import './rsc.css';
import apiConfig from '../../config/api.config.json';
import authHeader from '../../config/auth.header';
/* eslint  react/prop-types:0 */
/* eslint  no-shadow:0 */
/* eslint  no-alert:0 */

function UploadFile({ closePopUPdata }) {
  const restRef = useRef(null);
  const [files, setFiles] = useState('');
  const [uploadFiles, setuploadFiles] = useState(false);
  const [filesNames, setFilesNames] = useState([]);
  // state for checking file size
  const [fileSize, setFileSize] = useState(true);
  // for file upload progress message
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  // for displaying response message
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  // base end point url
  // const FILE_UPLOAD_BASE_ENDPOINT = "http://localhost:8282";
  console.log(fileSize, fileUploadProgress, fileUploadResponse);
  const uploadFileHandler = (event) => {
    //  alert("Please select max 10 files.");
    event.preventDefault();

    if (event.target.files.length <= 10) {
      setFiles(event.target.files);
      const { files } = event.target;
      const add = [];
      for (let i = 0; i < files.length; i += 1) {
        add.push(files[i].name);
      }

      setFilesNames(add);
    } else {
      restRef.current.value = '';
      setFiles([]);
      setFilesNames([]);

      alert('Only ten files You can Upload');
    }
  };
  useEffect(() => {
    if (files.length !== 0) { setuploadFiles(true); } else { setuploadFiles(false); }
  }, [files]);
  const fileSubmitHandler = (event) => {
    event.preventDefault();
    setFileSize(true);
    setFileUploadProgress(true);
    setFileUploadResponse(null);

    const formData = new FormData();

    for (let i = 0; i < files.length; i += 1) {
      setFileSize(false);
      setFileUploadProgress(false);
      setFileUploadResponse(null);

      formData.append('files', files[i]);
    }
    axios
      .post(apiConfig.UPLOADPDFFILE, formData, {
        headers: {
          'content-type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          ...authHeader(),
        },
      })
      .then((response) => {
        if (response.data[0].messge === 'File uploaded successfully') {
          alert('File uploaded successfully');
          closePopUPdata();
        } else {
          alert('Error In File Uploading ');
        }
      }).catch((error) => {
        console.log(error);
        alert('Error In File Uploading ');
      });
  };

  return (
    <div className="innerContentPopup">
      <form onSubmit={fileSubmitHandler}>
        <div className="Inputclass">
          <div
            className="col-md2"
            style={{ color: 'white' }}
          >
              &nbsp;
          </div>
          <div className="col-md4 ">
            <input
              type="file"
              accept=".pdf"
              name="files"
              multiple
              onChange={uploadFileHandler}
              style={{
                color: 'aliceblue',
                font: 'icon',
              }}
              ref={restRef}
            />
          </div>

          <div className="col-md4 ">
            <button
              className="btn btn-primary buttonCss marginLeft"
              type="submit"
              disabled={!uploadFiles}
            >
              Upload
            </button>
          </div>
        </div>
        <div className="Inputclass">
          <div className="col-md4 ">
            <table>
              <tr>
                {filesNames.slice(0, 5).map((data, index) => {
                  console.log(data, 'kranthiiiiiiii');
                  return (
                    <td
                      key={index}
                      style={{
                        padding: '5px',
                        background: 'aliceblue',
                        color: 'black',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                      }}
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
            </table>
          </div>
        </div>
        <div className="Inputclass">
          <div className="col-md4 ">
            <table>
              <tr>
                {filesNames.slice(5, 11).map((data, index1) => (
                  <td
                    key={index1}
                    style={{
                      padding: '5px',
                      background: 'aliceblue',
                      color: 'black',
                      borderStyle: 'solid',
                      borderWidth: '2px',
                    }}
                  >
                    {data}
                  </td>
                ))}
              </tr>
            </table>
          </div>
        </div>
      </form>
    </div>
  );
}
export default UploadFile;
