import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { setCausalParameter } from './failureModeDetailsSlice';
import { fetchMachineParameters, saveCausualParameter } from '../../components/ConditionMonitoring/FMEA/causual-parameters/causualParametersAddApi';
// import { saveCausualParameter } from "./causualParametersAddApi";
// import { Success_Message, Error_Message } from "../../configs/config";
import { SuccessMessage, ErrorMessage } from '../../helpers/constants';

const initialState = {
  machineParameters: [],
  machineParametersStatus: 'idle',
  causualParameters: [],
  causualParametersStatus: 'idle',
};

export const fetchMachineParametersAsync = createAsyncThunk(
  'causualParameterAdd/fetchMachineParameters',
  async ({ modelID, sectionID, functionID }) => {
    const response = await fetchMachineParameters(
      modelID,
      sectionID,
      functionID,
    );
    return response;
  },
);

export const saveCausualParameterAsync = createAsyncThunk(
  'saveCausualParameter/saveCausualParameters',
  async (body, thunkApi) => {
    const response = await saveCausualParameter(body);
    thunkApi.dispatch(setCausalParameter(response));
    return response;
  },
);

export const causualParametersAdd = createSlice({
  name: 'causualParametersAdd',
  initialState,
  reducers: {

  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineParametersAsync.pending, (state) => {
        state.machineParametersStatus = 'loading';
      })
      .addCase(fetchMachineParametersAsync.fulfilled, (state, action) => {
        state.machineParametersStatus = 'idle';
        state.machineParameters = action.payload;
      })

      .addCase(saveCausualParameterAsync.pending, (state) => {
        state.causualParametersStatus = 'loading';
      })
      .addCase(saveCausualParameterAsync.fulfilled, (state, action) => {
        state.causualParametersStatus = 'idle';
        state.causualParameters = action.payload;
        message.success(SuccessMessage);
      })
      .addCase(saveCausualParameterAsync.rejected, (state) => {
        state.causualParametersStatus = 'idle';
        message.error(ErrorMessage);
      });
  },
});

export default causualParametersAdd.reducer;
