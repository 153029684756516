/* eslint-disable */
import React from 'react';
import '../ConditionMonitoring/Condition-Monitoring/condition-monitoring.css';
import DMTab from './DMTab';

const DigitalMaintenanceRepository = () => (
  <div>
    <div className="wrapper cm-wrapper">
      <div className="contentWrap conditionMonitoring monitoringcontentWrap monitoringPage failuremodulePage">
        <DMTab />
      </div>
    </div>
  </div>
);
export default DigitalMaintenanceRepository;
