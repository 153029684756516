import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import Popup from './popup';
// import Aux from "./Auxs";
import './ftr.css';
import apiConfig from '../../config/api.config.json';
import authHeader from '../../config/auth.header';

const customStyles = {
  // menu: (provided, state) => {
  menu: (provided) => {
    const active = 'green';
    //  const menuIsOpen = true;
    return { ...provided, active };
  },
  container: (provided) => ({
    ...provided,

    //  menuIsOpen: true,
  }),

  // singleValue: (provided, state) => {
  singleValue: (provided) => {
    const color = 'white';
    return { ...provided, color };
  },
};

function Container() {
  const [popUpView, setPopUpView] = useState(false);
  const [uploadDisable, setuploadDisable] = useState(true);
  const [filesListValue, setfilesListValue] = useState({
    value: '',
    label: '',
  });
  const [filesList, setFilesList] = useState([]);
  const [pdfurl, setPDFurl] = useState(null);
  const closePopUPdata = () => {
    setPopUpView((pre) => !pre);
  };
  // get the list of files  from  Bloob Storage
  const onSearch = () => {
    const headers = authHeader();
    const config = {
      method: 'get',
      url: apiConfig.APIGETFILEDETATILS,
      headers,
    };
    axios(config)
      .then((response) => {
        const data = [];
        response.data.message.map((lable) => {
          data.push({
            value: lable,
            label: lable,
          });
        });
        setFilesList(data);
      })
      .catch((error) => {
        console.log(error);
        setFilesList([]);
      });
  };

  // intinal featching the File list
  useEffect(() => {
    onSearch();
  }, []);
  // set the fileurl for download
  const getPdfFiles = () => {
    // setPDFurl(apiConfig.APIGETSHOWTOPDF+"?filename=" +filesListValue.value)
    const headers = authHeader();
    const config = {
      method: 'post',
      url: apiConfig.APIGETSHOWTOPDF,
      data: { filename: filesListValue.value },
      headers,
    };
    axios(config)
      .then((response) => {
        setPDFurl(response.data.url);
        setuploadDisable(true);
      })
      .catch((error) => {
        console.log(error.message);
        setuploadDisable(true);
      });
  };
  useEffect(() => {
    if (filesListValue.value !== '') {
      setuploadDisable(false);
      getPdfFiles();
    }
  }, [filesListValue]);

  const handleChangeYearfilter = (data) => {
    setfilesListValue(data);
  };

  return (
    <div className="file_upload_container">
      {popUpView && <Popup closePopUPdata={closePopUPdata} />}
      <div className="ftr_upload_btn">
        <button
          type="button"
          onClick={closePopUPdata}
          className="btn btn-primary "
          disabled={!uploadDisable}
        >
          Click to Upload
          {' '}
        </button>
      </div>
      <div className="fileupload ">
        <div className="fileuploadL">
          <label className="dropdownlables">Search PrintData file</label>
          <div className="dropDownButton ">
            <Select
              className="dropdown_rsd"
              name="fileList"
              defaultValue={false}
              value={filesListValue}
              onChange={handleChangeYearfilter}
              options={filesList}
              noOptionsMessage={() => 'No File is available'}
              styles={customStyles}
            />
          </div>
        </div>

        <div className="fileuploadR">
          {filesListValue.label !== '' ? (
            <embed
              src={pdfurl}
              type="application/pdf"
              frameBorder="0"
              scrolling="auto"
              height="100%"
              width="100%"
              onLoad={() => {
                setuploadDisable(true);
              }}
            />
          ) : (
            <div className="ftr_file_not_found"> No file is selected </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Container;
