import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  fetchMachineModel, saveFailureNotification, fetchFailureModesForModel, fetchNotificationGroup, fetchNotificationTemplate, editFailureNotification,
} from '../../components/ConditionMonitoring/failure-notification/failureNotificationAddApi';

import { SuccessMessage, ErrorMessage } from '../../helpers/constants';

const initialState = {
  machineModels: [],
  machineModelStatus: 'idle',
  notificationGroups: [],
  notificationGroupStatus: 'idle',
  failureModes: [],
  failureModeStatus: 'idle',
  notificationTemplates: [],
  notificationTemplateStatus: 'idle',
  failureNotificationStatus: 'idle',
  failureNotification: '',
  editFailureNotifications: {},
  editFailureNotificationsStatus: 'idle',
  recordSaved: false,
};

export const saveFailureNotificationAsync = createAsyncThunk(
  'failureNotificationAdd/saveFailureNotification',
  // async (body, thunkApi) => {
  async (body) => {
    console.log('saveFailureNotificationAsync', body);
    const response = await saveFailureNotification(body);
    // thunkApi.dispatch(setEditDetails(response));
    return response;
  },
);

export const editFailureNotificationAsync = createAsyncThunk(
  'failureNotificationAdd/editFailureNotifications',
  async ({ id }) => {
    const response = await editFailureNotification(id);
    return response;
  },
);

export const fetchFailureModesForModelAsync = createAsyncThunk(
  'failureNotificationAdd/fetchFailureModesForModel',
  async ({ id }) => {
    const response = await fetchFailureModesForModel(id);
    return response;
  },
);

export const fetchMachineModelAsync = createAsyncThunk(
  'failureNotificationAdd/fetchMachineModel',
  async () => {
    const response = await fetchMachineModel();
    return response;
  },
);

export const fetchNotificationGroupAsync = createAsyncThunk(
  'failureNotificationAdd/fetchNotificationGroup',
  async () => {
    const response = await fetchNotificationGroup();
    return response;
  },
);

export const fetchNotificationTemplateAsync = createAsyncThunk(
  'failureNotificationAdd/fetchNotificationTemplate',
  async () => {
    const response = await fetchNotificationTemplate();
    return response;
  },
);

export const failureNotificationEntry = createSlice({
  name: 'failureNotificationAdd',
  initialState,
  reducers: {

    // clearSaveRecord: (state, action) => {
    clearSaveRecord: (state) => {
      state.recordSaved = false;
      state.editFailureNotifications = {};
    },
    clearEdit: (state) => {
      state.editFailureNotifications = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineModelAsync.pending, (state) => {
        state.machineModelStatus = 'loading';
      })
      .addCase(fetchMachineModelAsync.fulfilled, (state, action) => {
        state.machineModelStatus = 'idle';
        state.machineModels = action.payload;
      })

      .addCase(fetchFailureModesForModelAsync.pending, (state) => {
        state.failureModeStatus = 'loading';
      })
      .addCase(fetchFailureModesForModelAsync.fulfilled, (state, action) => {
        state.failureModeStatus = 'idle';
        state.failureModes = action.payload;
      })

      .addCase(fetchNotificationGroupAsync.pending, (state) => {
        state.notificationGroupStatus = 'loading';
      })
      .addCase(fetchNotificationGroupAsync.fulfilled, (state, action) => {
        state.notificationGroupStatus = 'idle';
        state.notificationGroups = action.payload;
      })

      .addCase(fetchNotificationTemplateAsync.pending, (state) => {
        state.notificationTemplateStatus = 'loading';
      })
      .addCase(fetchNotificationTemplateAsync.fulfilled, (state, action) => {
        state.notificationTemplateStatus = 'idle';
        state.notificationTemplates = action.payload;
      })

      .addCase(saveFailureNotificationAsync.pending, (state) => {
        state.failureNotificationStatus = 'loading';
      })
      // .addCase(saveFailureNotificationAsync.fulfilled, (state, action) => {
      .addCase(saveFailureNotificationAsync.fulfilled, (state) => {
        state.failureNotificationStatus = 'idle';
        state.recordSaved = true;

        // setTimeout(()=> {
        message.success(SuccessMessage);
        // },100);
      })
      .addCase(saveFailureNotificationAsync.rejected, (state, action) => {
        state.failureNotificationStatus = 'idle';
        setTimeout(() => {
          if (action.error.message === 'Request failed with status code 409') {
            message.error('Duplicate Record');
          } else {
            message.error(ErrorMessage);
          }
        }, 100);
      })

      .addCase(editFailureNotificationAsync.pending, (state) => {
        state.editFailureNotificationsStatus = 'loading';
      })
      .addCase(editFailureNotificationAsync.fulfilled, (state, action) => {
        state.editFailureNotificationsStatus = 'idle';
        state.editFailureNotifications = action.payload;
      })
      .addCase(editFailureNotificationAsync.rejected, (state) => {
        state.editFailureNotificationsStatus = 'idle';
      });
  },
});

export const {
  clearSaveRecord, clearEdit,
} = failureNotificationEntry.actions;

export default failureNotificationEntry.reducer;
