/* eslint-disable */
import axiosInstance from "../utils/api";

export default function putMaintenanceHistory(key, changetype, mntype, timetaken, remark) {
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  let data = {};
    data = {
      "MainTypeKey": changetype,
      "ActionTakenTime": timetaken,
      "ActionTaken" : mntype,
      "Remark" : remark
    }
  
  return axiosInstance
    .put(`api/dm/machines/maintainance-history/${key}`, data, config)
    .then(function (response) {
      return response;
    })
    .catch(function (err) {
      if (err.response) {
        return err;
      } else if (err.request) {
        return err;
      } else {
        return err.message;
      }
    });
}
