/* eslint  linebreak-style:0 */
/* eslint dot-notation:0 */
/* eslint operator-linebreak:0 */
/* eslint indent:0 */
/* eslint eol-last:0 */
/* eslint no-trailing-spaces:0 */
/* eslint no-multiple-empty-lines:0 */
/* eslint operator-linebreak:0 */
const authHeader = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return {
      'x-access-token': token,
      refreshToken: token,
    };
  }
  return {};
};

export default authHeader;
