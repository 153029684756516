import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getSchedulings, saveSchedulings } from '../../components/ConditionMonitoring/settings/schedulingApi';
// import { Success_Message, Error_Message } from "../../../configs/config";
import { SuccessMessage, ErrorMessage } from '../../helpers/constants';

const initialState = {
  schedulings: [],
  status: 'idle',
  recordSaved: false,
};

export const getSchedulingsAsync = createAsyncThunk(
  'schedulings/getSchedulings',
  async () => {
    const response = await getSchedulings();
    return response;
  },
);

export const saveSchedulingsAsync = createAsyncThunk(
  'schedulings/saveSchedulings',
  async (values) => {
    const body = [];
    body.push({ MachineConditionType: 'G', ...values.GC, CreatedUser_ID: 1 });
    body.push({ MachineConditionType: 'F', ...values.FC, CreatedUser_ID: 1 });
    body.push({ MachineConditionType: 'M', ...values.MC, CreatedUser_ID: 1 });
    const request = await saveSchedulings(body);
    return request;
  },
);

export const schedulingsSlice = createSlice({
  name: 'schedulings',
  initialState,
  reducers: {
    clearSaveRecord: (state) => {
      state.recordSaved = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSchedulingsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSchedulingsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.schedulings = action.payload;
      })
      .addCase(getSchedulingsAsync.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(saveSchedulingsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveSchedulingsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.recordSaved = true;
        message.success(SuccessMessage);
      })
      .addCase(saveSchedulingsAsync.rejected, (state) => {
        state.status = 'idle';
        message.error(ErrorMessage);
      });
  },
});
export const {
  clearSaveRecord,
} = schedulingsSlice.actions;

export default schedulingsSlice.reducer;
