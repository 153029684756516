import React, { useState, useRef } from 'react';
import Select from 'react-select';
import { range } from 'lodash';
// import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { PDFDownloadLink } from '@react-pdf/renderer';
import './ftr.css';
import apiConfig from '../../config/api.config.json';
// import "react-toastify/dist/ReactToastify.css";
import Estimated from './estimated';
import Styles from './FTRUpload.module.css';
import FTRPDF from './FTRPDF';
/* eslint-disable camelcase */
// /* eslint linebreak-style: ["error", "unix"] */
/* eslint no-trailing-spaces: "error" */
/* eslint jsx-a11y/label-has-associated-control:0 */
/* eslint jsx-a11y/control-has-associated-label:0 */
/* eslint  no-prototype-builtins:0 */
/* eslint  no-alert:0 */
import authHeader from '../../config/auth.header';

const degrees = range(0, 361).reduce(
  (number, newvalue) => [...number, { value: newvalue, label: newvalue }],
  [],
);

const inkSet1 = [
  { value: 'RMI010002', label: 'RMI010002' },
  { value: 'RMI010004', label: 'RMI010004' },
  { value: 'RMI010010', label: 'RMI010010' },
  { value: 'RMI010013', label: 'RMI010013' },
  { value: 'RMI010014', label: 'RMI010014' },
  { value: 'RMI010021', label: 'RMI010021' },
  { value: 'RMI010026', label: 'RMI010026' },
  { value: 'RMI010030', label: 'RMI010030' },
  { value: 'RMI010033', label: 'RMI010033' },
  { value: 'RMI010034', label: 'RMI010034' },
  { value: 'RMI010047', label: 'RMI010047' },
  { value: 'RMI010054', label: 'RMI010054' },
  { value: 'RMI010121', label: 'RMI010121' },
  { value: 'RMI010182', label: 'RMI010182' },
];

const inkSet2 = [
  { value: 'RMI010002', label: 'RMI010002' },
  { value: 'RMI010004', label: 'RMI010004' },
  { value: 'RMI010010', label: 'RMI010010' },
  { value: 'RMI010013', label: 'RMI010013' },
  { value: 'RMI010014', label: 'RMI010014' },
  { value: 'RMI010021', label: 'RMI010021' },
  { value: 'RMI010026', label: 'RMI010026' },
  { value: 'RMI010030', label: 'RMI010030' },
  { value: 'RMI010033', label: 'RMI010033' },
  { value: 'RMI010034', label: 'RMI010034' },
  { value: 'RMI010047', label: 'RMI010047' },
  { value: 'RMI010054', label: 'RMI010054' },
  { value: 'RMI010121', label: 'RMI010121' },
  { value: 'RMI010180', label: 'RMI010180' },
  { value: 'RMI010182', label: 'RMI010182' },
];

// const customStyles = {
//   menu: (provided, state) => {
//     const active = "green";
//     return { ...provided, active };
//   },
//   singleValue: (provided, state) => {
//     const color = "white";
//     return { ...provided, color };
//   },
// };

const customStylesLight = {
  theme: '',
  control: (base, state) => ({
    ...base,
    background: '#FFF',
    // match with the menu
    borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
    // Overwrittes the different states of border
    borderColor: 'black',
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
};
const customStylesDark = {
  // option: provided => ({
  //    ...provided,
  //    color: 'white'
  //  }),
  input: (styles) => ({
    ...styles,
    // backgroundColor:"white",
    color: 'white',
  }),
  // placeholder: (styles) => ({ ...styles, .('#ccc') }),
  control: (base, state) => ({
    ...base,
    background: '#252525',
    backgroundColor: 'white',
    // match with the menu

    // fontColor:"white",
    borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
    // Overwrittes the different states of border
    borderColor: 'black',
    // Removes weird border around container
    //   boxShadow: state.isFocused ? null : null,
    //   "&:hover": {
    //     // Overwrittes the different states of border
    //     borderColor: state.isFocused ? "red" : "blue"
    //   }
  }),
  menu: (base) => ({
    ...base,

    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  singleValue: (provided) => {
    const color = 'white';
    return { ...provided, color };
  },
};

const MessageTypeOptions = [
  { value: '9RB', label: '9RB' },
  { value: '9RC', label: '9RC' },
  { value: '9RL', label: '9RL' },
];

const validations = {
  capcolor: {
    Pattern: '^[0-9a-zA-z]{5}$',
    ErrMsg: 'Cap Color value should have length 5',
  },
  bodycolor: {
    Pattern: '^[0-9a-zA-z]{5}$',
    ErrMsg: 'Body Color value should have length 5',
  },
  amcno: {
    Pattern: '^^H[0-9][0-9]?$',
    ErrMsg: 'Machine number should Start With H and should not have length more than 3',
  },
};
function Searchbar() {
  /* eslint camelcase: "error" */
  const {
    batchSearch,
    detailTitle,
    detailsContainer,
    ftrBtns,
    ftrInput,
    ftrTable,
    ftrUploadRun,
    ftrUploadSection,
    ftrFileInput,
    hrRow,
  } = Styles;
  const fields = {
    capmsg: '',
    bodymsg: '',
    messagetype: '',
    degreeofcoveragebody: '',
    degreeofcoveragecap: '',
    inkusedset1: '',
    capcolor: '',
    bodycolor: '',
    amcno: '',
    aoperator: '',
    mcspeed: '',
    aviscositysetpointcap: '',
    aviscositysetpointbody: '',
    aprintinghead1gscap: '',
    aprintinghead1gsbody: '',
    aprintinghead1oscap: '',
    aprintinghead1osbody: '',
    abladeprecap: '',
    abladeprebody: '',
    afirstofloss: '',
    machine_no: '123',
    sap_size: '4',
    plant_name: 'Shirwal',
    artwork: 'artwork',
    gel_type: '1',
    batch: '',
    inkusedset2: '',

    previscosityInkcolor: 0,
    preaprintinghead1gscap: 0,
    preaprintinghead1oscap: 0,
    preabladeprecap: 0,

    preaviscosityInkbody: 0,
    preaprintinghead1gsbody: 0,
    preaprintinghead1osbody: 0,
    preabladeprebody: 0,
  };
  const currentTheme = 'dark';
  const refuload = useRef();
  const [files, setFiles] = useState('');
  const [formFeild, setFormFeilds] = useState(fields);
  const [, setNoEstimationCode] = useState('');
  const [batchNo, setBatchNO] = useState('');
  const [filterOptionValue, setFilterOptionValue] = useState({
    value: formFeild.messagetype,
    label: formFeild.messagetype,
  });
  const [filterOptionDegreeCap, seTfilterOptionDegreeCap] = useState({
    value: formFeild.degreeofcoveragecap,
    label: formFeild.degreeofcoveragecap,
  });
  const [filterOptionDegreeBody, seTfilterOptionDegreeBody] = useState({
    value: formFeild.degreeofcoveragebody,
    label: formFeild.degreeofcoveragebody,
  });

  const [filterInkset1, seTfilterInkset1] = useState({
    value: formFeild.inkusedset1,
    label: formFeild.inkusedset1,
  });
  const [filterInkset2, seTfilterInkset2] = useState({
    value: formFeild.inkusedset2,
    label: formFeild.inkusedset2,
  });

  const [, SetEstimated] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [allowPrint, setAllowPrint] = useState(false);
  const [batchSize, setBatchSize] = useState(null);
  console.log(selectedFile);
  const onChangeFields = (e) => {
    const { name, value } = e.target;
    setFormFeilds((pre) => ({ ...pre, [name]: value }));
  };
  const checkValidation = (e) => {
    const { name, value } = e.target;
    const { Pattern, ErrMsg } = validations[name];
    const regex = new RegExp(Pattern);
    if (value !== '' && regex.test(value) !== true) {
      alert(ErrMsg);
      setFormFeilds((pre) => ({ ...pre, [name]: '' }));
    }
  };

  const onFileChange = (event) => {
    event.preventDefault();
    setFiles(event.target.files);
    setSelectedFile({ selectedFile: refuload.current?.files[0] });
  };

  function onFileUpload() {
    if ((batchNo === '') && (files === '')) {
      alert('Please Browse the File');
      return false;
    }

    // Create an object of formData
    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append('files', files[i]);
    }

    // Update the formData object

    axios
      .post(apiConfig.API_FTR_FILEUPLOD, formData, {
        headers: {
          'content-type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          ...authHeader(),
        },
      })
      .then((response) => {
        if (response.data[0].messge === 'File uploaded successfully') {
          alert('File uploaded successfully');
        } else {
          alert('Error In File Uploading ');
        }
      })
      .catch((error) => {
        console.log(error);
        alert('Error In File Uploading ');
      });
    return null;
  }

  const OnValidatio = () => {
    const validationRequried = [];
    if (formFeild.amcno === '' || formFeild.amcno === undefined) {
      validationRequried.push('Machine Serial No ');
    }
    return validationRequried;
  };

  const resetField = () => {
    setFormFeilds(fields);
    setFormFeilds((pre) => ({ ...pre, batch: batchNo }));
    setFilterOptionValue({ value: '', label: '' });
    seTfilterOptionDegreeCap({ value: '', label: '' });
    seTfilterOptionDegreeBody({ value: '', label: '' });
    // setAllowPrint(true)

    seTfilterInkset1({ value: '', label: '' });
    seTfilterInkset2({ value: '', label: '' });
    setAllowPrint(false);
  };

  const setFields = (resp) => {
    setFormFeilds(resp.data.rep);
    setFilterOptionValue({
      value: resp.data.rep.messagetype,
      label: resp.data.rep.messagetype,
    });
    seTfilterOptionDegreeCap({
      value: resp.data.rep.degreeofcoveragecap,
      label: resp.data.rep.degreeofcoveragecap,
    });
    seTfilterOptionDegreeBody({
      value: resp.data.rep.degreeofcoveragebody,
      label: resp.data.rep.degreeofcoveragebody,
    });
    setAllowPrint(true);

    seTfilterInkset1({
      value: resp.data.rep.inkusedset1,
      label: resp.data.rep.inkusedset1,
    });
    seTfilterInkset2({
      value: resp.data.rep.inkusedset2,
      label: resp.data.rep.inkusedset2,
    });
    setFormFeilds((pre) => ({
      ...pre,
      batch: batchNo,
      capcolor: resp.data.rep.CapColor,
      bodycolor: resp.data.rep.BodyColor,
    }));
  };

  const seachData = () => {
    const headers = {
      headers: authHeader(),
    };
    axios
      .get(`${apiConfig.APIGETBATHDETAILS}?batch=${batchNo}`, headers)
      .then((resp) => {
        setFormFeilds(fields);
        if (resp.data.rep.ProductionBatchNo) {
          setBatchSize(Number(resp.data.rep.Size));
          axios
            .get(
              `${apiConfig.CHECKESTIMATIONAREPOSSIBLE
              }?machine_no=${resp.data.rep.MaterialNo
              }&size=${resp.data.rep.Size}`, headers,
            )
            .then((response) => {
              if (response.data.rep !== 0) {
                console.log(resp.data.rep);
                setFields(resp);
              } else {
                resetField();
                setNoEstimationCode('No esitmation is provided');
              }
            });
          // if (resp.data.rep.Size === '04' || resp.data.rep.Size === '02') {
          // } else {
          //   alert('Model is only train for Size 04 and Size 02 ');
          //   resetField();
          // }
        } else {
          alert('Please Enter Valid Batch');
          resetField();
        }
      });
  };

  const onSearch = () => {
    seachData();
  };

  function runFORModel() {
    const validateError = [
      'batch',
      'degreeofcoveragebody',
      'degreeofcoveragecap',
      'inkusedset1',
      'inkusedset2',
      'messagetype',
    ];
    const requriedfeilds = [];
    validateError.forEach((data) => {
      if (formFeild[data] === '' || formFeild[data] === undefined) {
        requriedfeilds.push(data);
      }
    });
    if (requriedfeilds.length > 0) {
      alert(`Please enter these Fields ${requriedfeilds.join()}`);
      return false;
    }
    const headers = {
      headers: authHeader(),
    };
    axios
      .get(
        `${apiConfig.FTPPRINTINGPRIDICTION
        }?batch=${batchNo.trim()
        }&degreeofcoveragebody=${formFeild.degreeofcoveragebody
        }&degreeofcoveragecap=${formFeild.degreeofcoveragecap
        }&inkusedset1=${formFeild.inkusedset1.trim()
        }&inkusedset2=${formFeild.inkusedset2.trim()
        }&messagetype=${formFeild.messagetype.trim()}&size=${batchSize}`, headers,
      )
      .then((resp) => {
        const { data } = resp.data;
        if (data.hasOwnProperty('error')) {
          SetEstimated(false);
          setNoEstimationCode('No esitmation is provided');
          setFormFeilds((pre) => ({
            ...pre,
            previscosityInkcolor: 0,
            preaprintinghead1gscap: 0,
            preaprintinghead1oscap: 0,
            preabladeprecap: 0,

            preaviscosityInkbody: 0,
            preaprintinghead1gsbody: 0,
            preaprintinghead1osbody: 0,
            preabladeprebody: 0,
          }));
          alert('Model can not generate successfully ');
        } else {
          SetEstimated(true);
          setFormFeilds((pre) => ({
            ...pre,
            previscosityInkcolor: data.Cap_Viscosity.toFixed(2),
            preaprintinghead1gscap: data.Printing_Gearside_Cap.toFixed(2),
            preaprintinghead1oscap:
              data.Printing_Operatingside_Cap.toFixed(2),
            preabladeprecap: data.Blade_Pressure_Body.toFixed(2),
            preaviscosityInkbody: data.Body_Viscosity.toFixed(2),
            preaprintinghead1gsbody: data.Printing_Gearside_Body.toFixed(2),
            preaprintinghead1osbody:
              data.Printing_Operatingside_Body.toFixed(2),
            preabladeprebody: data.Blade_Pressure_Cap.toFixed(2),
          }));
          alert('Model run sucessfully');
        }
      })
      .catch((err) => {
        console.log(err);
        SetEstimated(false);
        setNoEstimationCode('No esitmation is provided');
        setFormFeilds((pre) => ({
          ...pre,
          previscosityInkcolor: 0,
          preaprintinghead1gscap: 0,
          preaprintinghead1oscap: 0,
          preabladeprecap: 0,

          preaviscosityInkbody: 0,
          preaprintinghead1gsbody: 0,
          preaprintinghead1osbody: 0,
          preabladeprebody: 0,
        }));
        alert('Model can not generate successfully ');
      });
    return null;
  }
  // get batch data for pdf to print
  // const getDataForPDF = () => {
  //   axios
  //     .post(apiConfig.API_FTR_BATCH, { data: batchNo })
  //     .then((res) => {
  //       if (res.data.rep) {
  //         alert("PDF is Saved Successfully" + res.status);
  //         // generatePDF(res.data.rep);
  //       }
  //     })
  //     .catch((e) => {
  //       alert("Error in Generating the PDF");
  //     });
  // };
  const saveData = () => {
    const validationFeilds = OnValidatio();

    if (validationFeilds.length > 0) {
      alert(`Please enter these Fields ${validationFeilds.join()}.`);
    } else {
      const postDdata = {
        ...formFeild,
        machine_no: formFeild.amcno,
      };
      console.clear();
      console.log(postDdata);
      const headers = {
        headers: authHeader(),
      };
      axios
        .post(apiConfig.SAVEDATA, { data: postDdata }, headers)
        .then((res) => {
          if (res.data.rep) {
            alert('Data is Saved Successfully');
            // toast.success("Data is saved sucessfully", {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   toastId: 2,
            // });
          }
        })
        .catch((e) => {
          console.log(e);
          alert('Error in Data Save ');
          console.log(e);
        });
    }
  };
  const onChangeHandle = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setBatchNO(e.target.value);
    setFormFeilds((pre) => ({ ...pre, [name]: value }));
  };
  const handleChange = (data) => {
    setFilterOptionValue(data);
    setFormFeilds((pre) => ({ ...pre, messagetype: data.value }));
  };

  const handleChangeDegree = (data) => {
    seTfilterOptionDegreeCap(data);
    setFormFeilds((pre) => ({ ...pre, degreeofcoveragecap: data.value }));
  };

  const handleChangeDegreeBody = (data) => {
    seTfilterOptionDegreeBody(data);
    setFormFeilds((pre) => ({ ...pre, degreeofcoveragebody: data.value }));
  };

  const handleChangeInkset1 = (data) => {
    seTfilterInkset1(data);
    setFormFeilds((pre) => ({ ...pre, inkusedset1: data.value }));
  };
  const handleChangeInkset2 = (data) => {
    seTfilterInkset2(data);
    setFormFeilds((pre) => ({ ...pre, inkusedset2: data.value }));
  };

  return (
    <div className="print_file_container ftr-content">
      {/* <ToastContainer style={{ width: "700px" }} /> */}

      <div className={`${batchSearch}`}>
        <label className="">Batch Number</label>
        <div className="">
          <input
            type="text"
            className={`${ftrInput}`}
            name="batch"
            onChange={onChangeHandle}
            value={batchNo}
            placeholder="Enter"
          />
          <button type="button" onClick={() => onSearch()} className="btn btn-primary">
            Search
          </button>
        </div>
      </div>
      <div>
        <div className={`${detailsContainer}`}>
          <div>
            <label className={`${detailTitle}`}>
              Batch Details
              {' '}
              {batchSize != null ? ` / Size ${batchSize < 10 ? `0${batchSize}` : `${batchSize}`}` : ''}
            </label>
            <table className={`${ftrTable}`}>
              <thead>
                <tr>
                  <th />
                  <th>Color</th>
                  <th>Degree Of Coverage</th>
                  <th>Ink Type</th>
                  <th>Message Type</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cap:</td>
                  <td>
                    <input
                      type="text"
                      className={`${ftrInput}`}
                      value={formFeild.capcolor}
                      onChange={(e) => onChangeFields(e)}
                      name="capcolor"
                      onBlur={checkValidation}
                    />
                  </td>
                  <td>
                    <Select
                      className="dropdown_ftr"
                      name="degreeofcoveragecap"
                      onChange={handleChangeDegree}
                      defaultValue={false}
                      options={degrees}
                      value={{
                        value: filterOptionDegreeCap.value,
                        label: filterOptionDegreeCap.value,
                      }}
                      noOptionsMessage={() => 'NAN'}
                      styles={
                        currentTheme === 'light'
                          ? customStylesLight
                          : customStylesDark
                      }
                    />
                  </td>
                  <td>
                    <Select
                      className="dropdown_ftr"
                      name="inkusedset1"
                      onChange={handleChangeInkset1}
                      defaultValue={false}
                      options={inkSet1}
                      value={filterInkset1}
                      noOptionsMessage={() => 'NAN'}
                      styles={
                        currentTheme === 'light'
                          ? customStylesLight
                          : customStylesDark
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={formFeild.capmsg}
                      className={`${ftrInput}`}
                      onChange={(e) => onChangeFields(e)}
                      name="capmsg"
                    />
                  </td>
                  <td>
                    <Select
                      className="dropdown_ftr"
                      name="messagetype"
                      onChange={handleChange}
                      defaultValue={false}
                      options={MessageTypeOptions}
                      value={filterOptionValue}
                      noOptionsMessage={() => 'NAN'}
                      styles={
                        currentTheme === 'light'
                          ? customStylesLight
                          : customStylesDark
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Body :</td>
                  <td>
                    <input
                      type="text"
                      value={formFeild.bodycolor}
                      className={`${ftrInput}`}
                      onChange={(e) => onChangeFields(e)}
                      name="bodycolor"
                      onBlur={checkValidation}
                    />
                  </td>
                  <td>
                    <Select
                      className="dropdown_ftr"
                      name="degreeofcoveragebody"
                      onChange={handleChangeDegreeBody}
                      defaultValue={false}
                      options={degrees}
                      value={filterOptionDegreeBody}
                      noOptionsMessage={() => 'NAN'}
                      styles={
                        currentTheme === 'light'
                          ? customStylesLight
                          : customStylesDark
                      }
                    />
                  </td>
                  <td>
                    <Select
                      className="dropdown_ftr"
                      name="inkusedset2"
                      onChange={handleChangeInkset2}
                      defaultValue={false}
                      options={inkSet2}
                      value={filterInkset2}
                      noOptionsMessage={() => 'NAN'}
                      styles={
                        currentTheme === 'light'
                          ? customStylesLight
                          : customStylesDark
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={formFeild.bodymsg}
                      onChange={(e) => onChangeFields(e)}
                      className={`${ftrInput}`}
                      name="bodymsg"
                    />
                  </td>
                </tr>
                <tr>
                  <td />
                  <td colSpan={5}>
                    <div className={`${ftrUploadRun}`}>
                      <div className={`${ftrUploadSection}`}>
                        <label>Upload Artwork</label>
                        <div>
                          <input
                            type="file"
                            onChange={(e) => onFileChange(e)}
                            className={`${ftrFileInput}`}
                            ref={refuload}
                          />
                          <button
                            type="button"
                            onClick={() => onFileUpload()}
                            className="btn btn-primary"
                          >
                            Upload
                          </button>

                          {formFeild.artwork === 'artwork' ? (
                            ''
                          ) : (
                            <div className="col-md2 Inputclass">
                              <span>
                                <a
                                  href={`${process.env.REACT_APP_SERVERURL}/ftr/showimage?fileName=${formFeild.artwork}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {formFeild.artwork}
                                </a>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={`${ftrBtns}`}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => {
                            runFORModel(e);
                          }}
                        >
                          Run
                        </button>
                        <button type="button" className="btn btn-primary">Cancel</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={`${detailsContainer}`}>
          <Estimated fomdata={formFeild} />
          <div className={`${hrRow}`} />
          <div>
            <label className={`${detailTitle}`}>
              Actual Settings (To be Entered)
            </label>
            <table className={`${ftrTable}`}>
              <thead>
                <tr>
                  <th />
                  <th>Machine No</th>
                  <th>Operator Name</th>
                  <th>Machine speed</th>
                  <th>First of loss(Kg)</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td />
                  <td>
                    <input
                      type="text"
                      step="0.10"
                      value={formFeild.amcno}
                      onChange={(e) => onChangeFields(e)}
                      className={`${ftrInput}`}
                      name="amcno"
                      pattern="[0-9]"
                      onBlur={checkValidation}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={formFeild.aoperator}
                      onChange={(e) => onChangeFields(e)}
                      className={`${ftrInput}`}
                      name="aoperator"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step="0.5"
                      value={formFeild.mcspeed}
                      onChange={(e) => onChangeFields(e)}
                      className={`${ftrInput}`}
                      name="mcspeed"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step="0.01"
                      value={formFeild.afirstofloss}
                      onChange={(e) => onChangeFields(e)}
                      className={`${ftrInput}`}
                      name="afirstofloss"
                    />
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>

            <table className={`${ftrTable}`}>
              <thead>
                <tr>
                  <th />
                  <th>Viscosity Ink</th>
                  <th>Printing GS</th>
                  <th>Printing OS</th>
                  <th>Blade Pressure</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cap :</td>
                  <td>
                    <input
                      type="number"
                      step="0.10"
                      size="10"
                      className={`${ftrInput}`}
                      value={formFeild.aviscositysetpointcap}
                      onChange={(e) => onChangeFields(e)}
                      name="aviscositysetpointcap"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step="0.10"
                      size="10"
                      className={`${ftrInput}`}
                      value={formFeild.aprintinghead1gscap}
                      onChange={(e) => onChangeFields(e)}
                      name="aprintinghead1gscap"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step="0.10"
                      size="10"
                      className={`${ftrInput}`}
                      value={formFeild.aprintinghead1oscap}
                      onChange={(e) => onChangeFields(e)}
                      name="aprintinghead1oscap"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step="0.10"
                      size="10"
                      className={`${ftrInput}`}
                      value={formFeild.abladeprecap}
                      onChange={(e) => onChangeFields(e)}
                      name="abladeprecap"
                    />
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>Body :</td>
                  <td>
                    <input
                      type="number"
                      step="0.10"
                      size="10"
                      className={`${ftrInput}`}
                      value={formFeild.aviscositysetpointbody}
                      onChange={(e) => onChangeFields(e)}
                      name="aviscositysetpointbody"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step="0.10"
                      size="10"
                      className={`${ftrInput}`}
                      value={formFeild.aprintinghead1gsbody}
                      onChange={(e) => onChangeFields(e)}
                      name="aprintinghead1gsbody"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step="0.10"
                      size="10"
                      className={`${ftrInput}`}
                      value={formFeild.aprintinghead1osbody}
                      onChange={(e) => onChangeFields(e)}
                      name="aprintinghead1osbody"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step="0.10"
                      size="10"
                      className={`${ftrInput}`}
                      value={formFeild.abladeprebody}
                      onChange={(e) => onChangeFields(e)}
                      name="abladeprebody"
                    />
                  </td>
                  <td>
                    <div className={`${ftrBtns}`}>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => saveData()}
                      >
                        Save
                      </button>
                      {/* {allowPrint ? (
                        <button className="btn btn-primary">
                          <span
                            style={{ color: "white" }}
                            onClick={getDataForPDF}
                          >
                            Print
                          </span>
                        </button>
                      ) : (
                        ""
                      )} */}
                      {allowPrint ? (
                        <button type="button" className="btn btn-primary">
                          <PDFDownloadLink
                            document={<FTRPDF formFeild={formFeild} />}
                            fileName="FTR PRINT"
                          >
                            <span style={{ color: 'white' }}>Print</span>
                          </PDFDownloadLink>
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Searchbar;
