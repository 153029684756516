/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
// import { useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
// import '../../../../../src/components/dashboard/monitoring/kpi/kpi.css';
import '../../../styles/kpi.css';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { LanguageContext } from '../../../LanguageContext';
// import { ThemeContext } from '../../../../ThemeContext';
// import { theme, useStyles } from '../../../../components/dashboard/monitoring/kpi/popup/popupStyles';
import '../../../styles/dashboard.css';
import '../../ConditionMonitoring/Condition-Monitoring/condition-monitoring.css';
// import InlineDateTimePickerDemo from '../InlineDateTimePickerDemo';
// import InlineDateTimeTimePickerDemo from '../RSD/InlineDateTimePickerDemo';
import {
  fetchMachineModelAsync,
  fetchMachineModelNameAsync,
  fetchCustomerNameAsync,
  fetchExecutionResultAsync,
  fetchConditionWiseAsync,
  fetchModelWiseAsync,
  fetchMachineWiseAsync,
  fetchFrequencyWiseAsync,
} from '../../../redux/slices/conditionMonitoringSlice';

export default function CMFilter({ filterVisible, setFilterVisible, page }) {
  const dispatch = useDispatch();
  // const history = useHistory();
  // const classes = useStyles({ currentTheme });

  const validate = () => { };

  const defaultCardCount = 15;
  const size = defaultCardCount;

  const [isFilterActive, setIsFilterActive] = useState(false);

  const conTypes = ['General', 'Failure', 'Maintenance'];

  const dateFormatter = (item) => moment(item).format('YYYY-MM-DD HH:mm:ss.SSS');

  // const { option } = useParams();
  const [model, setModel] = useState('');
  // const [machine, setMachine] = useState("");
  // const [customer, setCustomer] = useState("");
  // const [type, setType] = useState("");
  // const [from, setFrom] = useState("");
  // const [to, setTo] = useState("");
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');

  // var dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMachineModelAsync());
  }, []);

  const machineModels = useSelector(
    (state) => state?.conditionMonitoringReport?.machineModels,
  );

  useEffect(() => {
    dispatch(fetchMachineModelNameAsync(model));
  }, [model]);

  const machines = useSelector(
    (state) => state.conditionMonitoringReport.machineModelNames,
  );

  useEffect(() => {
    dispatch(fetchCustomerNameAsync());
  }, []);

  const customers = useSelector(
    (state) => state.conditionMonitoringReport.machineCustomers,
  );

  const fromStart = new Date();
  fromStart.setDate(fromStart.getDate() - 30);
  const toEnd = new Date();

  const fetchInit = () => {
    dispatch(fetchExecutionResultAsync({
      from: dateFormatter(fromStart), to: dateFormatter(toEnd), page, size,
    }));

    dispatch(fetchConditionWiseAsync({
      from: dateFormatter(fromStart), to: dateFormatter(toEnd),
    }));

    dispatch(fetchModelWiseAsync({
      from: dateFormatter(fromStart), to: dateFormatter(toEnd),
    }));

    dispatch(fetchMachineWiseAsync({
      from: dateFormatter(fromStart), to: dateFormatter(toEnd),
    }));

    dispatch(fetchFrequencyWiseAsync({
      from: dateFormatter(fromStart), to: dateFormatter(toEnd),
    }));
  };

  useEffect(() => {
    fetchInit();
  }, []);

  const formRef = useRef(null);

  useEffect(() => {
    const { values } = formRef.current.getState();
    dispatch(fetchExecutionResultAsync({
      model: values.MachineModel_Name,
      machine: values.Machine_Name,
      customer: values.Customer,
      type: values.conTypes,
      from: dateFormatter(values.From_Date),
      to: dateFormatter(values.To_Date),
      page,
      size,
    }));
  }, [page]);

  const onSubmit = async (values) => {
    dispatch(fetchExecutionResultAsync({
      model: values.MachineModel_Name,
      machine: values.Machine_Name,
      customer: values.Customer,
      type: values.conTypes,
      from: dateFormatter(values.From_Date),
      to: dateFormatter(values.To_Date),
      page,
      size,
    }));

    dispatch(fetchConditionWiseAsync({
      model: values.MachineModel_Name,
      machine: values.Machine_Name,
      customer: values.Customer,
      type: values.conTypes,
      from: dateFormatter(values.From_Date),
      to: dateFormatter(values.To_Date),
    }));

    dispatch(fetchModelWiseAsync({
      model: values.MachineModel_Name,
      machine: values.Machine_Name,
      customer: values.Customer,
      from: dateFormatter(values.From_Date),
      to: dateFormatter(values.To_Date),
    }));

    dispatch(fetchMachineWiseAsync({
      model: values.MachineModel_Name,
      machine: values.Machine_Name,
      customer: values.Customer,
      from: dateFormatter(values.From_Date),
      to: dateFormatter(values.To_Date),
    }));

    dispatch(fetchFrequencyWiseAsync({
      model: values.MachineModel_Name,
      machine: values.Machine_Name,
      customer: values.Customer,
      type: values.conTypes,
      from: dateFormatter(values.From_Date),
      to: dateFormatter(values.To_Date),
    }));

    if (values.MachineModel_Name || values.Machine_Name || values.Customer || (values.conTypes && values.conTypes.length > 0) || (values.From_Date !== fromStart) || (values.To_Date !== toEnd)) {
      setIsFilterActive(true);
    } else {
      setIsFilterActive(false);
    }
  };

  const [Lang] = useContext(LanguageContext);

  const getSelected = (ids) => {
    const selected = conTypes.filter((n) => ids.find((id) => n === id));
    return selected.map((s) => s).join(', ');
  };

  return (
    <>

      <div
        className={`filterInnerDiv ${filterVisible ? 'active' : ''} 
            ${isFilterActive ? 'filter-is-on' : ''}`}
        onClick={() => setFilterVisible(!filterVisible)}
      >
        <span
          className="filterIcon pointer"
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.418 2.5H2.41797L7.21797 8.80667V13.1667L9.61797 14.5V8.80667L14.418 2.5Z"
              stroke="#8F8F8F"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="cm-filter conditionMonitoring">
        <div className={`filterDiv ${filterVisible ? '' : 'removedWidth'}`}>
          <div className="exResultOverflowScroll">
            <Form
              mutators={{
                setReset: (args, state, utils) => {
                  utils.changeValue(state, 'From_Date', () => moment(fromStart).format('YYYY-MM-DDTHH:mm'));
                  utils.changeValue(state, 'To_Date', () => moment(toEnd).format('YYYY-MM-DDTHH:mm'));
                  utils.changeValue(state, 'MachineModel_Name', () => '');
                  utils.changeValue(state, 'Machine_Name', () => '');
                  utils.changeValue(state, 'Customer', () => '');
                  utils.changeValue(state, 'conTypes', () => []);
                  setModel('');
                  fetchInit();
                  setIsFilterActive(false);
                },
              }}
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{ From_Date: moment(fromStart).format('YYYY-MM-DDTHH:mm'), To_Date: moment(toEnd).format('YYYY-MM-DDTHH:mm') }}
              keepDirtyOnReinitialize
              // eslint-disable-next-line no-unused-vars
              render={({ handleSubmit, form, reset }) => {
                formRef.current = form;
                return (
                  <div className="slick-carousel slickcarouselTop">
                    <form onSubmit={handleSubmit} id="failure-mode-form">
                      <div className="panel-body">
                        <span
                          className="resetBtn resetpost pointer"
                          onClick={() => {
                            form.mutators.setReset();
                          }}
                        >
                          {Lang.Reset}
                        </span>
                        <div>
                          <div className="form-group mui-date-picker">
                            <div className="input-control">
                              <label className="font10">From</label>
                              <div className="input-group">
                                <Field
                                  name="From_Date"
                                  className="form-control"
                                >
                                  {(props) => {
                                    console.log(props.input.value);
                                    return (
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                          disableFuture
                                          variant="inline"
                                          format="dd-MM-yyyy HH:mm"
                                          margin="normal"
                                          id="from"
                                          maxDate={form.getFieldState('To_Date') && form.getFieldState('To_Date').value}
                                          value={props.input.value}
                                          onChange={(value) => {
                                            props.input.onChange(value);
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>

                                    // <InlineDateTimePickerDemo

                                    //     startDate={props.input.value}
                                    //     setStartDate={(value) => {
                                    //         props.input.onChange(value)
                                    //     }}
                                    //     setEndDate={setEndDate}
                                    //     valueFlag={"from"}
                                    //     inputProps={{ max: form.getFieldState("To_Date") && form.getFieldState("To_Date").value }}
                                    //     style={{ dispaly: "block" }}
                                    // />
                                    );
                                  }}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="form-group mui-date-picker">
                            <div className="input-control">
                              <label className="font10">To</label>
                              <div className="input-group">
                                <Field
                                  name="To_Date"
                                  className="form-control"
                                >
                                  {(props) => (
                                    <DateTimePicker
                                      disableFuture
                                      variant="inline"
                                      format="dd-MM-yyyy HH:mm"
                                      margin="normal"
                                      id="to"
                                      minDate={form.getFieldState('From_Date') && form.getFieldState('From_Date').value}
                                      value={props.input.value}
                                      onChange={(value) => {
                                        props.input.onChange(value);
                                      }}
                                    />

                                    // <InlineDateTimePickerDemo

                                    //     endDate={props.input.value}
                                    //     setEndDate={(value) => {
                                    //         props.input.onChange(value)
                                    //     }}
                                    //     valueFlag={"to"}
                                    //     inputProps={{ min: form.getFieldState("From_Date") && form.getFieldState("From_Date").value }}
                                    // />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-control">
                              <label className="font10">
                                {Lang.MachineModel}
                              </label>
                              <div className="input-group">
                                <div>
                                  <Field
                                    name="MachineModel_Name"
                                    className="form-control"
                                    placeholder="Select"
                                  >
                                    {(props) => (
                                      <Autocomplete
                                        value={(machineModels && machineModels.find((m) => m.MachineModel_Name === (props.input.value || model))) || ''}
                                        getOptionSelected={(option, value) => option.MachineModel_Name === value.MachineModel_Name}
                                        onChange={(event, value) => {
                                          setModel(value ? value.MachineModel_Name : '');
                                          props.input.onChange(value ? value.MachineModel_Name : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={machineModels}
                                        getOptionLabel={(option) => option.MachineModel_Name || ''}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Machine Model" variant="outlined" />}
                                        disablePortal
                                      />
                                    )}
                                  </Field>
                                  <span id="machinemodel" className="caret" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-control">
                              <label className="font10">Machine</label>
                              <div className="input-group">
                                <div>
                                  <Field
                                    name="Machine_Name"
                                    className="form-control"
                                  >
                                    {(props) => (
                                      <Autocomplete
                                        value={(machines && machines.find((m) => m.Machine_Name === props.input.value)) || ''}
                                        getOptionSelected={(option, value) => option.Machine_Name === value.Machine_Name}
                                        onChange={(event, value) => {
                                          props.input.onChange(value ? value.Machine_Name : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={machines}
                                        getOptionLabel={(option) => option.Machine_Name || ''}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Machine" variant="outlined" />}
                                        disablePortal
                                      />
                                    )}
                                  </Field>

                                  <span id="machinemodel" className="caret" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-control">
                              <label className="font10">Customer</label>
                              <div className="input-group">
                                <div>
                                  <Field
                                    name="Customer"
                                    className="form-control"
                                  >
                                    {(props) => (

                                      <Autocomplete
                                        value={(customers && customers.find((m) => m.Key_OrganizationID === props.input.value)) || ''}
                                        getOptionSelected={(option, value) => option.Organization_Name === value.Organization_Name}
                                        onChange={(event, value) => {
                                          console.log(event, value);
                                          props.input.onChange(value ? value.Key_OrganizationID : undefined);
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={customers}
                                        getOptionLabel={(option) => option.Organization_Name || ''}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Customer" variant="outlined" />}
                                        disablePortal
                                      />
                                    )}
                                  </Field>

                                  <span className="caret" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-control">
                              <label className="font10">Condition Type</label>
                              <div className="input-group">
                                <Field
                                  name="conTypes"
                                  className="form-control"
                                  component="select"
                                  placeholder="Select"
                                >
                                  {(props) => (
                                    <Select
                                      {...props.input}
                                      className={`form-control ${props.input.value === '' ? 'placeholder' : ''
                                      }
                                                                                               ${props.input.value === '' && props.meta.touched
                                        ? 'error-field'
                                        : ''
                                                                                }`}
                                      multiple
                                      value={props.input.value || []}
                                      onChange={props.input.onChange}
                                      renderValue={getSelected}
                                    >

                                      {conTypes
                                                                                && conTypes.map((m) => (

                                                                                  <MenuItem key={m} value={m}>
                                                                                    <ListItemIcon>
                                                                                      <Checkbox checked={props.input.value.indexOf(m) > -1} />
                                                                                    </ListItemIcon>
                                                                                    <ListItemText primary={m} />
                                                                                  </MenuItem>
                                                                                ))}
                                    </Select>
                                  )}
                                </Field>

                              </div>
                            </div>
                          </div>
                          <Button
                            className="comnEditDel MuiButton-containedPrimary editGroupBtn floatLeft"
                            type="submit"
                          >
                            GO
                          </Button>
                        </div>

                      </div>

                    </form>
                  </div>
                );
              }}
            />

          </div>

        </div>
      </div>
    </>
  );
}
