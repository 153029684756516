/*eslint-disable*/
import React, { useState, useEffect } from "react";
import moment from "moment";

import OverviewChart from "./OverviewChart.jsx";
import getDippingStatus from "../../../API/getDippingStatus.js";
import NoData from "../../Reusable/NoData.jsx";
import VisavailChart from "./Visavail.jsx";

import "./DippingOverview.css";
import { CircularProgress } from "@mui/material";

function DippingOverview({ data }) {
  const [ProgressData, setProgressData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchDippingData = async (e) => {
    const data = await getDippingStatus(e);
    setProgressData(data?.data.data);
    setLoading(false);
  };

  useEffect(() => {
    if (data.flag != "new") {
      setLoading(true);
      fetchDippingData(data);
    }
  }, [data]);

  return (
    <div style={{ height: "100%" }}>
      <div className="overview-head">
        <div className="overview-head-text" style={{ fontSize: "14px" }}>
          Dipping Overview
        </div>
        {loading ? null : (
          <>
            {!!ProgressData.length && (
              <div className="color-code">
                <div style={{ display: "flex" }}>
                  <div id="dip-off"></div>
                  <div className="dip-text" style={{ fontSize: "12px" }}>
                    Off
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div id="dip-on"></div>
                  <div className="dip-text" style={{ fontSize: "12px" }}>
                    On
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "70%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={15} />
        </div>
      ) : (
        <>
          <div style={{ height: "70%" }}>
            {ProgressData.length ? (
              // <OverviewChart ProgressData={ProgressData} />
              <VisavailChart Data={ProgressData} />
            ) : (
              <NoData />
            )}
          </div>
          {!!ProgressData.length && (
            <div className="date-range-visavail">
              <div className="visavail-date">
                {moment(ProgressData[0].StartTime).format(
                  "DD-MM-YYYY HH:mm:ss"
                )}
              
              </div>
              <div className="visavail-date" style={{ textAlign: "right" }}>
                {moment(ProgressData[ProgressData.length - 1].EndTime).format(
                  "DD-MM-YYYY HH:mm:ss"
                )}
              </div>
              
                
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default DippingOverview;
