import { APIUrl } from '../../../helpers/constants';
import CallAPI from '../../../services/conditionMonitoring.service';

export function fetchNotificationTemplate(id, searchKey, pageindex, pageCount) {
  const apiInfo = {
    url: `${APIUrl}/api/getNotificationTemplate?id=${id || ''}&searchkey=${searchKey || ''}&pageindex=${pageindex || ''}&pageCount=${pageCount || ''}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getNotificationTemplate?id=${id || ''}&searchkey=${searchKey || ''}&pageindex=${pageindex || ''}&pageCount=${pageCount || ''}`, headers,
  //   )
  //   .then((res) => res.data);
}

export function saveNotificationTemplate(data) {
  const apiInfo = {
    url: `${APIUrl}/api/saveNotificationTemplate`,
    method: 'POST',
    postData: data,
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.post(`${APIUrl}/api/saveNotificationTemplate`, data, headers).then((res) => res.data);
}

export function deleteNotificationTemplate(id) {
  const apiInfo = {
    url: `${APIUrl}/api/deleteNotificationTemplate?id=${id}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.get(`${APIUrl}/api/deleteNotificationTemplate?id=${id}`, headers).then((res) => res.data);
}

export function getTemplatePlaceHolder() {
  const apiInfo = {
    url: `${APIUrl}/api/getTemplatePlaceholder`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.get(`${APIUrl}/api/getTemplatePlaceholder`, headers).then((res) => res.data);
}

export function getAccessRole(emailID) {
  const apiInfo = {
    url: `${APIUrl}/api/getUserAccess?email=${emailID}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.get(`${APIUrl}/api/getUserAccess?email=${emailID}`, headers).then((res) => res.data);
}
