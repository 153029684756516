/*eslint-disable*/
import React from "react";
import DefectChart from "./DefectChart.jsx";
import "./Defect.css";
function Defect({ data }) {
  return (
    <div style={{ height: "100%" }}>
      <div className="Defects-heading" style={{fontSize:"14px"}}>Defects</div>
      <div style={{ height: "90%" }}>
        {data.MachineKey != "" && data.Shift != "" && (
          <DefectChart datas={data} />
        )}
      </div>
    </div>
  );
}

export default Defect;
