import Icon from '../Icon';
import './style.css';

const index = ({
  searchChange, addList,
}) => (
  <div className="">
    {/* <label>Search</label> */}
    <div className="EM__search">
      <input type="search" placeholder="Search by EM" className="EM__input" onChange={(e) => searchChange(e, addList ? 'add' : 'remove')} />
      <Icon name="search" />
    </div>
  </div>
);
export default index;
