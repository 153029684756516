import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import config from '../../config/api.config.json';
import './ftr.css';
import Showdata from './popup';
import Styles from './iotmatrix.module.css';
import generatePDF from './printIOMatrix';
import handelSave from './bodyModelSave';
import authHeader from '../../config/auth.header';
/* eslint jsx-a11y/label-has-associated-control:0 */
/* eslint jsx-a11y/anchor-is-valid:0 */
/* eslint  no-alert:0 */

const customStyles = {
  // menu: (provided, state) => {
  menu: (provided) => ({ ...provided }),
  container: (provided) => ({
    ...provided,
  }),

  // singleValue: (provided, state) => {
  singleValue: (provided) => {
    const color = 'white';
    return { ...provided, color };
  },
};
// Selection Option for Plant
const PlantOptions = [
  {
    label: 'Shirwal',
    value: 'Shirwal',
  },
];
// Selection option for Plant
const SizeOptions = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
];
const MachineOption = [
  { value: '54', label: 'HCM 54' },
  { value: '55', label: 'HCM 55' },
  { value: '56', label: 'HCM 56' },
  { value: '57', label: 'HCM 57' },
  { value: '58', label: 'HCM 58' },
  { value: '59', label: 'HCM 59' },
  { value: '60', label: 'HCM 60' },
];
const formDetails = {
  Plant: 'Shirwal',
  Machine: '',
  Size: '',
  HCMSpeed: '',
  AverageDipLength: '',
  BodyDBCalibartionViscosity: '',
  BodyDBAcutalViscosity: '',

  BodyDBAcutalTemp: '',
  BodyLubiricationOfTime: '',
  Distance1: '',
  Distance2: '',
  Distance3: '',
  Distance4: '',
  Distance5: '',
  Distance6: '',

  Time1: '',
  Time2: '',
  Time3: '',
  Time4: '',
  Time5: '',
  Time6: '',
  Time7: '',
  result: '-',
  model: 'Body',
  Timestamp: '',
};

// Function defination start
function BodyModal() {
  // const componentRef = useRef();
  const {
    iomatrixContent,
    iomatrixForm,
    iotmatrixCol,
    iotmatrixInput,
    iotmatrixRow,
    iomatrixTime,
    iotmatrixDownload,
    submitSection,
  } = Styles;
  // Filter option usestate
  const [machiesList, setMachiesList] = useState([]);
  const [prediction, setPrediction] = useState(true);
  const [timestamp, setTimestamp] = useState();

  const plantFilterDefault = {
    label: 'Select Plant',
    value: '',
  };
  const MachineFilterDefault = {
    label: 'Select Machine',
    value: '',
  };
  const sizeFilterDefault = {
    label: 'Select Size',
    value: '',
  };
  const [plantFilter, setPlantFilter] = useState(plantFilterDefault);
  const [MachineFilter, setMachineFilter] = useState(MachineFilterDefault);
  const [sizeFilter, setSizeFilter] = useState(sizeFilterDefault);
  // setting featch  button state to disable when nopant,machine and size is selected
  const [searchState, setSearchState] = useState(false);

  // Disable form submit
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);

  // create blank from to store the select data
  const [formData, setFromData] = useState(formDetails);
  // use effect for machine and  size selection
  useEffect(() => {
    const formValues = Object.values(formData);
    let i;
    for (i = 0; i < formValues.length; i += 1) {
      if (formValues[i].trim() === '') {
        break;
      }
    }
    if (i >= formValues.length) {
      setIsSubmitDisable(false);
    } else {
      setIsSubmitDisable(true);
    }
  }, [formData]);

  // useEffect forlive featching data to make enable
  useEffect(() => {
    setPrediction(true);
    if (
      (plantFilter.value !== '')
      && (MachineFilter.value !== '')
      && (sizeFilter.value !== '')
    ) {
      setSearchState(true);
    } else {
      setSearchState(false);
    }
  }, [plantFilter, MachineFilter, sizeFilter]);

  const [resultView, setResultView] = useState(false);
  const [popUpView, setPopUpView] = useState(false);

  const closePopUPdata = () => {
    setPopUpView((pre) => !pre);
  };
  // const machineData = state.content[currPage];
  const OnclickCancel = (e) => {
    e.preventDefault();
    setFromData(formDetails);
    setPlantFilter(plantFilterDefault);
    setMachineFilter(MachineFilterDefault);
    setSizeFilter(sizeFilterDefault);
    setTimestamp('');
    setPrediction(true);
  };
  // handel on on sumbit call the API
  const onSubmit = (e) => {
    e.preventDefault();
    const validation = [];

    Object.keys(formData).forEach((data12) => {
      if (formData[data12] !== '') {
        console.log('');
      } else {
        validation.push(data12);
      }
    });

    if (validation.length > 0) {
      alert(`Please enter the field ${validation.join(', ')}`);
    } else {
      const data1 = {
        Inputs: {
          data: [
            {
              Column2: '',
              Machine: MachineFilter.value,
              Size: sizeFilter.value,
              Side: formData.model,
              Average_Dip_length: formData.AverageDipLength,
              HCM_Speed: formData.HCMSpeed,
              DB_Viscosity_Calibration_Factor:
                formData.BodyDBCalibartionViscosity,
              DB_Actual_Viscosity: formData.BodyDBAcutalViscosity,
              DB_Actual_Temp: formData.BodyDBAcutalTemp,
              Lubrication_Off_Time: formData.BodyLubiricationOfTime,
              Rate1: formData.Time1 !== 0 ? ((formData.Distance2 - formData.Distance1) / formData.Time1).toFixed(2) : 0,
              Rate2: formData.Time2 !== 0 ? ((formData.Distance3 - formData.Distance2) / formData.Time2).toFixed(2) : 0,
              Rate3: formData.Time3 !== 0 ? ((formData.Distance4 - formData.Distance3) / formData.Time3).toFixed(2) : 0,
              Rate4: formData.Time5 !== 0 ? ((formData.Distance5 - formData.Distance4) / formData.Time4).toFixed(2) : 0,
              Rate5: formData.Time5 !== 0 ? ((formData.Distance6 - formData.Distance5) / formData.Time5).toFixed(2) : 0,
              Rate6: formData.Time7 !== 0 ? (formData.Distance6 / formData.Time7).toFixed(2) : 0,
            },
          ],
        },
        GlobalParameters: 0.00001,
      };
      const headers = authHeader();
      const payload = {
        method: 'post',
        url: config.API_PREDICT_BODY_MODEL_IOMATRIX,
        data: data1,
        headers,
      };
      axios(payload)
        .then((response) => {
          if (response.data) {
            setPopUpView(true);
            setFromData((pre) => ({
              ...pre,
              result: response.data.result[0].toFixed(2),
            }));
            setResultView(response.data.result[0].toFixed(2));
            setPrediction(false);
          } else {
            setPopUpView(true);
            setResultView('No Prediction ');
            setFromData((pre) => ({ ...pre, result: 'No Prediction from The Model ' }));
            setPrediction(true);
          }
        })
        .catch((error) => {
          setPopUpView(true);
          setFromData((pre) => ({ ...pre, result: error.message }));
          setResultView('Model is unreachable Please try after sometime');
          setPrediction(true);
        });
    }
  };

  // Handle on change event of
  const onChangeHandle = (e) => {
    // const re = /^[0-9 .\b]+$/;
    const re = /^[0-9|.]+$/;
    const { name } = e.target;
    const { value } = e.target;
    if (e.target.value === '' || re.test(e.target.value)) {
      setFromData((pre) => ({ ...pre, [name]: value }));
    } else {
      // e.target.value=0;
      alert(`Please enter numeric value for the field ${name}`);
    }
  };

  // handle  size  change
  const handleChangeSize = (data) => {
    // changing the featch data will reset
    // on change of  plant  reset the machine filter
    setFromData(formDetails);
    setSizeFilter(data);
    setFromData((pre) => ({ ...pre, Size: data.label }));
  };

  // handle on change plant function
  const handleChangePlant = (data) => {
    // on change of  plant  reset the machine filter
    setFromData(formDetails);
    setMachineFilter({ label: 'Select Machine ', value: '' });
    // on chabge the plant reset the size filter
    setSizeFilter({ label: 'Select Size', value: '' });
    // sel the plant filter to select value
    setPlantFilter(data);
    // setFromData((pre) => ({ ...pre, ["plant"]: data.label }));
  };

  const handleChangeMachine = (data) => {
    setFromData(formDetails);
    // Changine the Machine Reset  the formdata
    setSizeFilter({ label: 'Select Size', value: '' });
    setMachineFilter(data);
    // setFromData((pre) => ({ ...pre, ["machineNo"]: data.label }));
  };
  // get the machine list based on the plant selection
  const getMachineList = () => {
    setMachiesList(MachineOption);
    // axios
    //     .get(config.APIMACHINEDATA + "?plant=" + plantFilter.label)
    //     .then((response) => {
    //         var plantList = response.data.message;
    //         var plant = [];
    //         plantList.forEach((ele, index) => {
    //             // eslint-disable-next-line radix
    //             const myArray = parseInt(ele.split("_0")[1], 0);
    //             if (myArray > 25) {
    //                 plant.push({ label: ele, value: ele })
    //             }
    //         })
    //         setMachiesList(plant);
    //     })
    //     .catch((error) => {
    //         setMachineFilter({ label: "", value: "", })
    //         setMachiesList([]);
    //     });
  };

  useEffect(() => {
    getMachineList();
  }, [plantFilter]);

  const onSearchData = () => {
    const Plant = plantFilter.label;
    const MachineNo = MachineFilter.label.replace('_0', ' ');
    const Size = sizeFilter.label;
    setSearchState(false);
    const data = { MachineNo, Plant, Size };
    const headers = authHeader();

    axios
      .post(config.API_FETCH_TWX_IOMATRIX, data, { headers })
      .then((res) => {
        setSearchState(true);
        console.log({ res: res.data });
        if (res.data.status === 200) {
          setFromData((pre) => ({
            ...pre,
            Machine: MachineFilter.value,
            Size: sizeFilter.value,
            HCMSpeed: res.data.data[0].HCMSpeed.toFixed(2),
            BodyDBCalibartionViscosity:
              res.data.data[0].BodyDBViscosityCalibrationFactor.toFixed(2),
            BodyDBAcutalViscosity: res.data.data[0].BodyViscosity.toFixed(2),
            BodyDBAcutalTemp: res.data.data[0].BodyTemp.toFixed(2),
            BodyLubiricationOfTime:
              res.data.data[0].BodyLubricationOffTime === 0
                ? `${0.0}`
                : (`${res.data.data[0].BodyLubricationOffTime}`),
            Distance1: res.data.data[0].BodyDistance1.toFixed(2),
            Distance2: res.data.data[0].BodyDistance2.toFixed(2),
            Distance3: res.data.data[0].BodyDistance3.toFixed(2),
            Distance4: res.data.data[0].BodyDistance4.toFixed(2),
            Distance5: res.data.data[0].BodyDistance5.toFixed(2),
            Distance6: res.data.data[0].BodyDistance6.toFixed(2),
            Time1: res.data.data[0].BodyTime1.toFixed(2),
            Time2: res.data.data[0].BodyTime2.toFixed(2),
            Time3: res.data.data[0].BodyTime3.toFixed(2),
            Time4: res.data.data[0].BodyTime4.toFixed(2),
            Time5: res.data.data[0].BodyTime5.toFixed(2),
            Time6:
              res.data.data[0].BodyTime6 !== null
                ? res.data.data[0].BodyTime6.toFixed(2)
                : '',
            Time7: res.data.data[0].BodyTime7.toFixed(2),
            Timestamp: res.data.data[0].Timestamp,
          }));
          setTimestamp(res.data.data[0].Timestamp);
        } else {
          alert(
            'Enable to Fatch the data from Server ,Please enter the data manualy or Please Try after Sometime',
          );
          // toast.success("Enable to Fatch the data from Server ,Please enter the data manualy or Please Try after Sometime ", {
          //     position: toast.POSITION.TOP_CENTER,
          //     autoClose: 5000,
          //     toastId: 2,
          // });
        }
      })
      .catch((error) => {
        console.log(error);
        setSearchState(true);
      });
  };
  const handleDownloadImage = (e) => {
    e.preventDefault();
    generatePDF(formData);
  };
  const onclickhandelSave = (e) => {
    e.preventDefault();
    handelSave(formData);
  };

  return (
    <div className={`${iomatrixContent}`}>
      {popUpView && (
        <Showdata closePopUPdata={closePopUPdata} data={resultView} />
      )}
      <div className={`${iotmatrixRow}`}>
        <div className={`${iotmatrixCol}`}>
          <label htmlFor="Machine No"> Plants List: </label>
          <div>
            <Select
              className="dropdown_rsd"
              name="plant"
              onChange={handleChangePlant}
              defaultValue={false}
              options={PlantOptions}
              value={plantFilter}
              noOptionsMessage={() => 'Select Proper Plant'}
              styles={customStyles}
            />
          </div>
        </div>
        <div className={`${iotmatrixCol}`}>
          <label htmlFor="Machine No"> Machine Number: </label>

          <div>
            <Select
              className="dropdown_rsd"
              name="machineNo"
              onChange={handleChangeMachine}
              defaultValue={false}
              options={machiesList}
              value={MachineFilter}
              noOptionsMessage={() => 'Select Proper Plant'}
              styles={customStyles}
            />
          </div>
        </div>
        <div className={`${iotmatrixCol}`}>
          <label htmlFor="Size"> Size: </label>

          <Select
            className="dropdown_rsd"
            name="monthTable"
            defaultValue={false}
            value={sizeFilter}
            onChange={handleChangeSize}
            options={SizeOptions}
            noOptionsMessage={() => 'No other size range'}
            styles={customStyles}
          />
        </div>
        <div className={`${iotmatrixCol}`}>
          <button
            type="button"
            className="btn-primary"
            onClick={onSearchData}
            disabled={!searchState}
            title="Please select the Plant,Machine and Size to Fetch Data"
          >
            Fetch From Machine
          </button>
          {timestamp ? (
            <span className={`${iomatrixTime}`}>{timestamp}</span>
          ) : (
            ''
          )}
        </div>
      </div>

      <form onSubmit={onSubmit} className={`${iomatrixForm}`}>
        <div className={`${iotmatrixRow}`}>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="HCMSpeed"> HCM Speed: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="HCMSpeed"
              value={formData.HCMSpeed}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Size"> Body DB Viscosity Calibartion Factor: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="BodyDBCalibartionViscosity"
              value={formData.BodyDBCalibartionViscosity}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Average Dip Length"> Body DB Acutal Viscosity: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              value={formData.BodyDBAcutalViscosity}
              name="BodyDBAcutalViscosity"
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Machine No"> Body DB Actual Temp: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="BodyDBAcutalTemp"
              value={formData.BodyDBAcutalTemp}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Size"> Body Luburication Of Time: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="BodyLubiricationOfTime"
              value={formData.BodyLubiricationOfTime}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Average Dip Length"> Distance 1: </label>

            <input
              type="text"
              className={`${iotmatrixInput}`}
              value={formData.Distance1}
              onChange={onChangeHandle}
              name="Distance1"
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Machine No"> Distance 2 </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="Distance2"
              value={formData.Distance2}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Size"> Distance 3: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="Distance3"
              value={formData.Distance3}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Average Dip Length"> Distance 4: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              value={formData.Distance4}
              name="Distance4"
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Machine No"> Distance 5 </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="Distance5"
              value={formData.Distance5}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Size"> Distance 6: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="Distance6"
              value={formData.Distance6}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Average Dip Length"> Time 1: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              value={formData.Time1}
              name="Time1"
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Machine No"> Time 2 </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="Time2"
              value={formData.Time2}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Size"> Time 3: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="Time3"
              value={formData.Time3}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Average Dip Length"> Time 4: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              value={formData.Time4}
              name="Time4"
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Machine No"> Time 5 </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="Time5"
              value={formData.Time5}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Size"> Time 7: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              name="Time6"
              value={formData.Time7}
            />
          </div>
          <div className={`${iotmatrixCol}`}>
            <label htmlFor="Average Dip Length"> Average Dip Length: </label>

            <input
              type="text"
              onChange={onChangeHandle}
              className={`${iotmatrixInput}`}
              value={formData.AverageDipLength}
              name="AverageDipLength"
            />
          </div>
        </div>
        <div className={`${submitSection}`}>
          <div className="iotmatrix-btns">
            <button type="submit" className="btn btn-primary" disabled={isSubmitDisable}>
              Submit
            </button>
            <button
              type="button"
              className="btn btn-primary"
              value="clear"
              onClick={OnclickCancel}
            >
              Clear
            </button>
          </div>
          {!prediction ? (
            <div className={`${iotmatrixDownload}`}>
              <label htmlFor="Average Dip Length">
                Pick Up Weight :
                {' '}
                {resultView}
              </label>
              <a type="button" href="#" onClick={handleDownloadImage}>
                Download as PDF
              </a>
              <a href="#" onClick={onclickhandelSave}>
                Save
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
      </form>
    </div>
  );
}

export default BodyModal;
