/* eslint-disable react/no-unknown-property */
import React, { useState, useContext } from 'react';
import { Dialog } from '@material-ui/core';
import moment from 'moment';
import { Form } from 'react-final-form';
import { DateTimePicker } from '@material-ui/pickers';
import { LanguageContext } from '../../../LanguageContext';
import style from '../NotificationTemplate/edit-notification-template.module.css';

/* eslint no-shadow:0 */

const validate = () => { };
const AddExecutionResultModal = ({
  currentDetails, onSaved, onClosed, needsUpdate,
}) => {
  const [errorTxt, setErrorTxtValue] = useState('');
  const [errorFields, setErrorFields] = useState([]);
  const [isResolved, setResolvedType] = useState(true);
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [date, setEndDate] = useState(new Date());

  if (needsUpdate.value && currentDetails) {
    setResolvedType(currentDetails.isResolved || true);
    setName(currentDetails.ResolvedBy || '');
    setContent(currentDetails.ResolutionDescription || '');
    setEndDate(currentDetails.ResolvedOnTimestamp ? new Date(currentDetails.ResolvedOnTimestamp) : new Date() || new Date());
    needsUpdate.value = false;
  }

  const onCloseBtnClicked = () => {
    onClosed();
  };

  const onSaveBtnClicked = async () => {
    // const errorString = '';
    const errorFields = [];

    if (!name) {
      errorFields.push('name');
    }

    setErrorFields(errorFields);

    if (errorFields.length > 0) {
      return;
    }
    const res = await onSaved(currentDetails, name, isResolved, content, date);
    if (!res.Result) setErrorTxtValue(res.Message);
  };

  const onNameChanged = (e) => {
    setName(e.target.value);
  };

  // const onCommunicationTypeChanged = (e) => {
  //   setResolvedType(e.target.id === 'email');
  // };

  const OnContentchanged = (e) => {
    setContent(e.target.value);
  };

  // for language change
  const [Lang] = useContext(LanguageContext);

  return (
    <Dialog open disableEnforceFocus>
      <div className="modelboxopen" ref={(e) => { e && e.style.setProperty('z-index', 1000, 'important'); }}>
        <div className={`modelboxopenInnner ${style.modelBox}`} id="notification-dialog">
          <div className="wrapContent">
            <div className="containerIner2">
              <div className="popupCloseButton bbt">
                <h3 className="titleDiv">{Lang.IssueResolution}</h3>
                <div
                  className="closepopup pointer"
                  onClick={onCloseBtnClicked}
                >
                  &#x2715;
                </div>
              </div>
              <Form
                onSubmit={onSaveBtnClicked}
                validate={validate}
                keepDirtyOnReinitialize
                initialValues={{ To_Date: moment(date).format('YYYY-MM-DDTHH:mm') }}
                // render={({ handleSubmit, form, reset }) => {
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="contentPopup notification-template-editor">
                      <div className="innerContentPopup">
                        <div className="descriptionCreate">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group formgroup">
                                <div className="font14">
                                  <div id="edit-notification-group-fields">

                                    {/* <div className="row-md-16 radio-label">
                                      <div className="form-group mt-2">
                                        <label className="font12">{Lang.Resolved}</label>
                                        <div>
                                          <input
                                            name="type"
                                            type="radio"
                                            checked={isResolved}
                                            value="Email"
                                            className="form-control customForm input-md"
                                          />
                                          <label id="email" className={`font14 ${style.radio}`} onClick={onCommunicationTypeChanged}>Yes</label>
                                          <input
                                            name="type"
                                            type="radio"
                                            checked={!isResolved}
                                            value="SMS"
                                            className="form-control customForm input-md"
                                          />
                                          <label id="sms" className={`font14 ${style.radio}`} onClick={onCommunicationTypeChanged}>No</label>
                                        </div>
                                      </div>
                                    </div> */}

                                    <div className="row-md-16">
                                      <div className="form-group">
                                        <label className="font12">{Lang.Name}</label>
                                        <span className="redStar">*</span>
                                        <input
                                          id="textbox1"
                                          name="textbox1"
                                          type="text"
                                          placeholder={Lang.EnterName}
                                          onChange={onNameChanged}
                                          value={name}
                                          className={`form-control customForm input-md${errorFields.indexOf('name') !== -1 ? ' error-field' : ''}`}
                                        />
                                      </div>
                                    </div>

                                    <div className="row-md-16">
                                      <div className="form-group mui-date-picker isresolvtmstmp">
                                        <label className="font12">{Lang.TimeStamp}</label>
                                        <span className="redStar">*</span>
                                        {/* <div className="input-group width-100">
                                            <Field
                                              name="To_Date"
                                              className={"form-control"}
                                            >
                                              {(props) => (
                                                <InlineDateTimePickerDemo
                                                  endDate={props.input.value}
                                                  setEndDate={(value) => {
                                                    props.input.onChange(value)
                                                  }}
                                                  valueFlag={"to"}
                                                />
                                              )}
                                            </Field>
                                          </div> */}

                                        <DateTimePicker
                                          value={date}
                                          autoOk
                                          ampm={false}
                                          onChange={setEndDate}
                                          className="form-control customForm input-md date-picker"
                                          format="dd-MM-yyyy HH:mm"
                                        />
                                      </div>
                                    </div>

                                    <div className="row-md-16">
                                      <div className="form-group">
                                        <label className="font12">{Lang.Comments}</label>
                                        <textarea
                                          id="textarea1"
                                          name="textarea1"
                                          type="text"
                                          placeholder={Lang.EnterComments}
                                          onChange={OnContentchanged}
                                          value={content}
                                          cols={200}
                                          rows={5}
                                          className="form-control customForm input-md text-area"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="enterpins placeColor">
                                  <div className="pinErrorTxt">{errorTxt}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footerPopupWithBtn">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <button
                              type="button"
                              id="cancelBtn"
                              name="cancel"
                              className="btn btn-default cancelBtn"
                              value="2"
                              onClick={onCloseBtnClicked}
                            >
                              {Lang.Cancel}
                            </button>
                            <button
                              type="submit"
                              id="saveBtn"
                              name="Save"
                              className="btn btn-default saveBtn"
                              htmlType="submit"
                            >
                              {Lang.Save}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddExecutionResultModal;
