// import axios from 'axios';
// import { userId, APIUrl } from '../../../helpers/constants';
import { APIUrl } from '../../../helpers/constants';
// import getItem from '../../../helpers/getLocalStorageItem';
// import authHeader from '../../../config/auth.header';
import CallAPI from '../../../services/conditionMonitoring.service';

// const headers = {
//   headers: authHeader(),
// };

export function fetchMachineModel() {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineModel`,
    method: 'GET',
    isMultiPart: false,
    CreatedUserID: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .get(
  //     `${APIUrl}/api/getMachineModel`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchFailureModesForModel(machineModelId) {
  const apiInfo = {
    url: `${APIUrl}/api/getFailureModeForModel?machineModelId=${machineModelId}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .get(
  //     `${APIUrl}/api/getFailureModeForModel?machineModelId=${machineModelId}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchNotificationTemplate() {
  const apiInfo = {
    url: `${APIUrl}/api/getNotificationTemplate`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .get(

  //     `${APIUrl}/api/getNotificationTemplate`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchNotificationGroup() {
  const apiInfo = {
    url: `${APIUrl}/api/getNotificationGroup`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .get(

  //     `${APIUrl}/api/getNotificationGroup`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function editFailureNotification(id) {
  const apiInfo = {
    url: `${APIUrl}/api/editFailureNotificationDetails?search=${id}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(

  //     `${APIUrl}/api/editFailureNotificationDetails?search=${id}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function saveFailureNotification(generalCondition) {
  // generalCondition.CreatedUser_ID = userId && getItem('UserKey');
  const apiInfo = {
    url: `${APIUrl}/api/saveFailureNotification`,
    method: 'POST',
    postData: generalCondition,
    isMultiPart: false,
    CreatedUserID: true,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .post(`${APIUrl}/api/saveFailureNotification`, generalCondition, { headers })
  //   .then((res) => res.data);
}
