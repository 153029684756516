/* eslint-disable react/jsx-props-no-spreading */
// reusable pin confirmation dialog
// action to be taken after successful validation should be in props.onValidate()

import React, {
  useState, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getSchedulingsAsync, saveSchedulingsAsync, clearSaveRecord } from '../../../redux/slices/schedulingSlice';
import Icon from '../../Dashboard/Sidebar/images/Icon';
import './scheduling.css';
/* eslint max-len:0 */
/* eslint no-shadow:0 */

const SchedulingModal = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSchedulingsAsync());
  }, []);
  // for language change
  // const Lang = [{}];
  const frequency = ['M', 'H', 'D'];
  const frequencyPair = { M: 'Minutes', H: 'Hours', D: 'Days' };
  const [formattedSchedulings, setFormattedSchedulings] = useState();

  const schedulings = useSelector((state) => state?.scheduling?.schedulings);
  useEffect(() => {
    const gc = schedulings.find((s) => s.MachineConditionType === 'G');
    const fc = schedulings.find((s) => s.MachineConditionType === 'F');
    const mc = schedulings.find((s) => s.MachineConditionType === 'M');

    setFormattedSchedulings({
      GC: gc,
      FC: fc,
      MC: mc,
    });
  }, [schedulings]);

  const recordSaved = useSelector((state) => state.scheduling.recordSaved);
  useEffect(() => {
    if (recordSaved) {
      dispatch(clearSaveRecord());
      props.setShowPopup(false);
    }
  }, [recordSaved]);

  const required = (value) => (value ? undefined : 'Required');

  const onSubmit = async (values) => {
    dispatch(saveSchedulingsAsync(values));
  };

  // const validate = () => { };

  return (
    <>
      <div
        className={`modelboxopen2 confirmPin schedule-setting${props.showPopup ? '' : 'hidden'
        }`}
      >
        <div className="modelboxopenInnner" id="modelboxopenInnner6">
          <div className="wrapContent">
            <div className="containerIner2">
              <div className="popupCloseButton bbt">
                <h3 className="titleDiv">Scheduling Settings</h3>
                <a
                  className="closepopup"
                  onClick={() => {
                    props.setShowPopup(false);
                  }}
                >
                  &#x2715;
                </a>
              </div>

              <Form
                onSubmit={onSubmit}
                validate={(values) => {
                  const errors = {};
                  if (values) {
                    if (values.GC && values.GC.Duration < 30 && values.GC.DurationType === 'M') {
                      // errors.Duration = 'The minimum acceptable duration is 30 minutes'
                      errors.GC = { Duration: 'The minimum acceptable duration is 30 minutes' };
                    }

                    if (values.FC && values.FC.Duration < 30 && values.FC.DurationType === 'M') {
                      // errors.Duration = 'The minimum acceptable duration is 30 minutes'
                      errors.FC = { Duration: 'The minimum acceptable duration is 30 minutes' };
                    }

                    if (values.MC && values.MC.Duration < 30 && values.MC.DurationType === 'M') {
                      // errors.Duration = 'The minimum acceptable duration is 30 minutes'
                      errors.MC = { Duration: 'The minimum acceptable duration is 30 minutes' };
                    }
                  }

                  return errors;
                }}
                initialValues={formattedSchedulings}
                keepDirtyOnReinitialize
                render={({ handleSubmit, form }) => (
                  <form onSubmit={handleSubmit} id="schedulings-form">
                    <div className=" contentPopup conditionMonitoring ">
                      <div className="innerContentPopup">
                        <div className="descriptionCreate">
                          <div className="modal-panel">
                            <div>
                              <div className="alert alert-info" role="alert">
                                <label className="font10">
                                  <Icon name="AlertIcon" />
                                  {' '}
                                  The minimum acceptable duration is 30 minutes
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="modal-panel">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="newPin">General Condition</div>
                                <div className="font12">
                                  Repeat Every
                                  <span className="redStar">*</span>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="enterpins placeColor">
                                      <Field
                                        name="GC.Duration"
                                        className="form-control"
                                        validate={required}
                                        placeholder=""
                                      >
                                        {(props) => (
                                          <input
                                            {...props.input}
                                            id="gc-duration"
                                            type="number"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            maxLength="4"
                                            placeholder=""
                                            className={`form-control customForm input-md ${props.input.value === '' ? 'placeholder' : ''
                                            }
                                           ${((props.input.value === '' && props.meta.touched)
                                                || (props.input.value < 30 && form.getFieldState('GC.DurationType') && form.getFieldState('GC.DurationType').value === 'M'))
                                              ? 'error-field'
                                              : ''
                                              }`}
                                            // autoFocus=""
                                            tabIndex="0"
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="enterpins placeColor">
                                      <Field
                                        name="GC.DurationType"
                                        className="form-control"
                                        validate={required}
                                        placeholder="Please Select Duration Type"
                                      >
                                        {(props) => (
                                          <Autocomplete
                                            value={(frequency && frequency.find((m) => m === props.input.value)) || ''}
                                            getOptionSelected={(option, value) => option === value}
                                            onChange={(event, value) => {
                                              props.input.onChange(value || '');
                                            }}
                                            onBlur={props.input.onBlur}
                                            options={frequency}
                                            getOptionLabel={(option) => (option ? frequencyPair[option] : '')}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Please Select"
                                                className={`${props.input.value === '' && props.meta.touched
                                                  ? 'error-field'
                                                  : ''
                                                }`}
                                                variant="outlined"
                                              />
                                            )}
                                            disabled={false}
                                            disablePortal
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-panel">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="newPin">Failure Condition</div>
                                <div className="font12">
                                  Repeat Every
                                  <span className="redStar">*</span>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="enterpins placeColor">
                                      <Field
                                        name="FC.Duration"
                                        className="form-control"
                                        validate={required}
                                        placeholder=""
                                      >
                                        {(props) => (
                                          <input
                                            {...props.input}
                                            id="fc-duration"
                                            type="number"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            maxLength="4"
                                            placeholder=""
                                            className={`form-control customForm input-md ${props.input.value === '' ? 'placeholder' : ''
                                            }
                                           ${(props.input.value === '' && props.meta.touched)
                                           || (props.input.value < 30 && form.getFieldState('FC.DurationType') && form.getFieldState('FC.DurationType').value === 'M')
                                              ? 'error-field'
                                              : ''
                                              }`}
                                            // autoFocus=""
                                            tabIndex="0"
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="enterpins placeColor">
                                      <Field
                                        name="FC.DurationType"
                                        className="form-control"
                                        validate={required}
                                        placeholder="Please Select Duration Type"
                                      >
                                        {(props) => (
                                          <Autocomplete
                                            value={(frequency && frequency.find((m) => m === props.input.value)) || ''}
                                            getOptionSelected={(option, value) => option === value}
                                            onChange={(event, value) => {
                                              // setPage(1);
                                              // setModel(value);
                                              props.input.onChange(value || '');
                                            }}
                                            onBlur={props.input.onBlur}
                                            options={frequency}
                                            getOptionLabel={(option) => (option ? frequencyPair[option] : '')}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Please Select"
                                                className={`${props.input.value === '' && props.meta.touched
                                                  ? 'error-field'
                                                  : ''
                                                }`}
                                                variant="outlined"
                                              />
                                            )}
                                            disabled={false}
                                            disablePortal
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-panel">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="newPin">Maintenance Master</div>
                                <div className="font12">
                                  Repeat Every
                                  <span className="redStar">*</span>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="enterpins placeColor">
                                      <Field
                                        name="MC.Duration"
                                        className="form-control"
                                        validate={required}
                                        placeholder="Please Select Duration Type"
                                      >
                                        {(props) => (
                                          <input
                                            {...props.input}
                                            id="mc-duration"
                                            type="number"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            maxLength="4"
                                            placeholder=""
                                            className={`form-control customForm input-md ${props.input.value === '' ? 'placeholder' : ''
                                            }
                                           ${(props.input.value === '' && props.meta.touched)
                                           || (props.input.value < 30 && form.getFieldState('MC.DurationType') && form.getFieldState('MC.DurationType').value === 'M')
                                              ? 'error-field'
                                              : ''
                                              }`}
                                            // autoFocus=""
                                            tabIndex="0"
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="enterpins placeColor">
                                      <Field
                                        name="MC.DurationType"
                                        className="form-control"
                                        validate={required}
                                        placeholder=""
                                      >
                                        {(props) => (
                                          <Autocomplete
                                            value={(frequency && frequency.find((m) => m === props.input.value)) || ''}
                                            getOptionSelected={(option, value) => option === value}
                                            onChange={(event, value) => {
                                              // setPage(1);
                                              // setModel(value);
                                              props.input.onChange(value || '');
                                            }}
                                            onBlur={props.input.onBlur}
                                            options={frequency}
                                            getOptionLabel={(option) => (option ? frequencyPair[option] : '')}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Please Select"
                                                className={`${props.input.value === '' && props.meta.touched
                                                  ? 'error-field'
                                                  : ''
                                                }`}
                                                variant="outlined"
                                              />
                                            )}
                                            disabled={false}
                                            disablePortal
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footerPopupWithBtn">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <button
                              id="cancelBtn"
                              name="cancel"
                              className="btn btn-default cancelBtn"
                              onClick={() => props.setShowPopup(false)}
                              type="button"
                            >
                              Cancel
                            </button>
                            <button
                              id="saveBtn"
                              name="Save"
                              className="btn btn-default saveBtn"
                              type="submit"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchedulingModal;
