import React from 'react';

export default function DynamicSvg({ color, type }) {
  let icon = null;
  if (type === 'download') {
    icon = (
      <svg width="20" height="20" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 2V9H20" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0739 11.7273L11.5405 14.206H11.5973L13.071 11.7273H14.8075L12.5881 15.3636L14.8572 19H13.0888L11.5973 16.5178H11.5405L10.049 19H8.28764L10.5639 15.3636L8.33026 11.7273H10.0739Z" fill="black" />
      </svg>
    );
  } else if (type === 'notupload') {
    icon = (
      <svg width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.2788 7.4082L8.27881 19.4082" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.27881 7.4082L20.2788 19.4082" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27 13.5172C27 20.3975 21.2131 26.0345 14 26.0345C6.78693 26.0345 1 20.3975 1 13.5172C1 6.63695 6.78693 1 14 1C21.2131 1 27 6.63695 27 13.5172Z" stroke="#e12e25" strokeWidth="2" />
      </svg>
    );
  } else if (type === 'upload') {
    icon = (
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17 8L12 3L7 8" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 3V15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }
  return icon;
}
