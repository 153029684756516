import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { savePotentialCauses, fetchOccurenceCriteria, fetchDetectionCriteria } from '../../components/ConditionMonitoring/FMEA/potential-causes/potentialCauseAddApi';

import { setPotentialCause } from './failureModeDetailsSlice';
// import { Success_Message, Error_Message } from "../../configs/config";
import { SuccessMessage, ErrorMessage } from '../../helpers/constants';

const initialState = {
  potentialCauses: [],
  potentialCausesStatus: 'idle',
  occurenceCriteria: [],
  occurenceCriteriaStatus: 'idle',
  detectionCriteria: [],
  detectionCriteriaStatus: 'idle',
};

export const potentialCausesAddAsync = createAsyncThunk(
  'potentialCausesAdd/savePotentialCauses',
  async (body, thunkApi) => {
    const response = await savePotentialCauses(body);
    thunkApi.dispatch(setPotentialCause(response));
    return response;
  },
);

export const fetchOccurenceCriteriaAsync = createAsyncThunk(
  'potentialCauseAdd/fetchOccurenceCriteria',
  async () => {
    const response = await fetchOccurenceCriteria();
    return response;
  },
);

export const fetchDetectionCriteriaAsync = createAsyncThunk(
  'potentialCauseAdd/fetchDetectionCriteria',
  async () => {
    const response = await fetchDetectionCriteria();
    return response;
  },
);

export const potentialCausesAdd = createSlice({
  name: 'potentialCausesAdd',
  initialState,
  reducers: {

  },

  extraReducers: (builder) => {
    builder
      .addCase(potentialCausesAddAsync.pending, (state) => {
        state.potentialCausesStatus = 'loading';
      })
      .addCase(potentialCausesAddAsync.fulfilled, (state, action) => {
        state.potentialCausesStatus = 'idle';
        state.potentialCauses = action.payload;
        setTimeout(() => {
          message.success(SuccessMessage);
        }, 100);
      })
      .addCase(potentialCausesAddAsync.rejected, (state) => {
        state.potentialCausesStatus = 'idle';
        setTimeout(() => {
          message.error(ErrorMessage);
        }, 100);
      })

      .addCase(fetchOccurenceCriteriaAsync.pending, (state) => {
        state.occurenceCriteriaStatus = 'loading';
      })
      .addCase(fetchOccurenceCriteriaAsync.fulfilled, (state, action) => {
        state.occurenceCriteriaStatus = 'idle';
        state.occurenceCriteria = action.payload;
      })

      .addCase(fetchDetectionCriteriaAsync.pending, (state) => {
        state.detectionCriteriaStatus = 'loading';
      })
      .addCase(fetchDetectionCriteriaAsync.fulfilled, (state, action) => {
        state.detectionCriteriaStatus = 'idle';
        state.detectionCriteria = action.payload;
      });
  },
});

export default potentialCausesAdd.reducer;
