export const defaultMentionStyle = {
  backgroundColor: '#963214',
};

export const defaultStyle = {
  control: {

  },

  '&multiLine': {
    control: {
      minHeight: 74,
    },
    highlighter: {
      padding: '6px 12px',

    },
    input: {
      minHeight: 74,
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,
    highlighter: {

    },
    input: {

    },
  },

  suggestions: {
    list: {
      backgroundColor: 'black',
      border: '1px solid rgba(255, 255, 255 ,0.5)',
      fontSize: 14,
      width: 200,

    },
    item: {
      padding: '6px 12px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
        color: 'black',
      },

    },

  },
};
