/* eslint-disable */
import axiosInstance from "../utils/api";

export default function putEditServoMotor(e) {
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance
    .put(`/api/dm/servo-motors/${e.ServoMotorCodeName}`, e, config)
    .then(function (response) {
      return response;
    })
    .catch(function (err) {
      if (err.response) {
        return err;
      } else if (err.request) {
        return err;
      } else {
        return err.message;
      }
    });
}
