/* eslint-disable class-methods-use-this */
import axios from 'axios';
import config from '../../config/api.config.json';
import authHeader from '../../config/auth.header';
/* eslint implicit-arrow-linebreak:0 */
class PowerBiService {
  getAccessToken() {
    const data = {};
    return axios
      .post(config.POWERBI_GET_ACCESSTOKEN, data, {
        headers: authHeader(),
      })
      .then((response) => ({
        status: response.status,
        data: response.data,
      }))
      .catch((error) => ({
        status: error.response.status,
      }));
  }

  getReports(request) {
    const data = {
      auth: request.auth,
      org: request.org,
    };
    return axios
      .post(config.POWERBI_GET_REPORTS, data, {
        headers: authHeader(),
      })
      .then((response) => ({
        status: response.status,
        data: response.data,
      }))
      .catch((error) => ({
        status: error.response.status,
      }));
  }
}

export default new PowerBiService();
