// import React, { useState, useContext, Fragment } from 'react';
// import { useParams, useHistory } from 'react-router-dom';
// import { Form, Field } from 'react-final-form';
import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  // PieChart, Pie,
  LineChart,
  Tooltip,
  Line,
  CartesianGrid,
  Legend,
} from 'recharts';
import { useSelector } from 'react-redux';
import MUITooltip from '@material-ui/core/Tooltip';
// import { LanguageContext } from '../../../../LanguageContext';
import '../../../styles/dashboard.css';
import '../../ConditionMonitoring/Condition-Monitoring/condition-monitoring.css';
import './cm-reports.css';
// import { ReactComponent as Zoom } from '../../../dashboard/reports/ECR/svgs/expand.svg';
import Icon from '../../Dashboard/Sidebar/images/Icon';
// import {
//   fetchMachineModelAsync,
//   fetchMachineModelNameAsync,
// } from '../../../redux/slices/conditionMonitoringSlice';

// const validate = () => { };

export default function CMTrends() {
  // const dispatch = useDispatch();
  // const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [zoomComponent, setZoomComponent] = useState();
  const [title, setTitle] = useState('');

  // const { option } = useParams();

  const conditionWiseResults = useSelector(
    (state) => state.conditionMonitoringReport.conditionWiseResults,
  );

  const modelWiseResults = useSelector(
    (state) => state.conditionMonitoringReport.ModelWiseResults,
  );

  const machineWiseResults = useSelector(
    (state) => state.conditionMonitoringReport.MachineWiseResults,
  );

  const frequencyWiseResults = useSelector(
    (state) => state.conditionMonitoringReport.FrequencyWiseResults,
  );

  const makeZoom = (component, header) => {
    setVisible(true);
    setZoomComponent(component);
    setTitle(header);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  // const [Lang, ,] = useContext(LanguageContext);

  const TopFrequency = ({ height }) => (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        width={500}
        height={250}
        data={frequencyWiseResults}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="TIME" />
        <YAxis allowDecimals={false} />
        <Legend />
        <Tooltip cursor={{ fill: 'transparent' }} visible="true" />
        <Line type="monotone" dataKey="General" stroke="#D57878" />
        <Line type="monotone" dataKey="Failure" stroke="#C4D55F" />
        <Line type="monotone" dataKey="Maintenance" stroke="#8BCD9D" />
      </LineChart>
    </ResponsiveContainer>
  );

  const TopFailure = ({ height, lableLength }) => (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={conditionWiseResults}>
        <XAxis
          dataKey="Condition_Description"
          interval={0}
          tickFormatter={(x) => (x.length > lableLength ? `${x.substring(0, lableLength - 1)}...` : x)}
        />
        <YAxis allowDecimals={false} />
        <Legend iconType="square" iconSize={10} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="Condition"
          fill="#5D97F6"
          maxBarSize={15}
        />
      </BarChart>
    </ResponsiveContainer>
  );

  const TopModel = ({ height, lableLength }) => (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={modelWiseResults} className="top-model">
        <XAxis dataKey="machine_model" interval={0} tickFormatter={(x) => (x.length > lableLength ? `${x.substring(0, lableLength - 1)}...` : x)} />
        <YAxis allowDecimals={false} />
        <Legend iconType="square" iconSize={10} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="Machine model"
          fill="#5D97F6"
          maxBarSize={15}
        />
      </BarChart>
    </ResponsiveContainer>
  );

  const TopMachine = ({ height, lableLength }) => (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={machineWiseResults}>
        <XAxis dataKey="machine_name" interval={0} tickFormatter={(x) => (x.length > lableLength ? `${x.substring(0, lableLength - 1)}...` : x)} />
        <YAxis allowDecimals={false} />
        <Legend iconType="square" iconSize={10} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="Machine"
          fill="#5D97F6"
          maxBarSize={15}
        />
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <>
      <>

        <div
          className="pannelWrap cm-reports common-scroll eventOverflowScroll"
          id="pannelWrap"
        >
          <div className="container-fluid pl-04">
            <div className="row">

              <div className="col-md-6 dashboard-card">

                <div className="card">
                  <label className="font10">
                    Condition Monitoring - Frequency Wise
                  </label>
                  {frequencyWiseResults && frequencyWiseResults.length > 0
                                        && (
                                        <>
                                          <MUITooltip
                                            className="conditional-monitoring"
                                            title="Enlarged View"
                                            PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                            arrow
                                          >
                                            <label className="font10 pull-right" onClick={() => makeZoom(<TopFrequency height={450} />, 'Condition Monitoring - Frequency Wise')}><Icon name="ZoomIcon" /></label>
                                          </MUITooltip>
                                          <TopFrequency height={250} />
                                        </>
                                        )}

                  {(frequencyWiseResults === undefined || frequencyWiseResults.length === 0)
                                        && <div className="font10 noRecords">No Record Available</div>}
                </div>

              </div>

              <div className="col-md-6 dashboard-card">

                <div className="card">
                  <label className="font10">
                    Condition Wise - Top 10
                  </label>
                  {conditionWiseResults && conditionWiseResults.length > 0
                                        && (
                                        <>
                                          <MUITooltip
                                            className="conditional-monitoring"
                                            title="Enlarged View"
                                            PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                            arrow
                                          >
                                            <label className="font10 pull-right" onClick={() => makeZoom(<TopFailure height={450} lableLength={15} />, 'Condition Wise - Top 10')}><Icon name="ZoomIcon" /></label>
                                          </MUITooltip>
                                          <TopFailure height={250} lableLength={7} />
                                        </>
                                        )}
                  {(conditionWiseResults === undefined || conditionWiseResults.length === 0)
                                        && <div className="font10 noRecords">No Record Available</div>}
                </div>

              </div>
            </div>
            <div className="row">
              <div className="col-md-6 dashboard-card">

                <div className="card">
                  <label className="font10">
                    Top 10 Failures - Machine Model Wise
                  </label>
                  {modelWiseResults && modelWiseResults.length > 0
                                        && (
                                        <>
                                          <MUITooltip
                                            className="conditional-monitoring"
                                            title="Enlarged View"
                                            PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                            arrow
                                          >
                                            <label className="font10 pull-right" onClick={() => makeZoom(<TopModel height={450} lableLength={15} />, 'Top 10 Failures - Machine Model')}><Icon name="ZoomIcon" /></label>
                                          </MUITooltip>
                                          <TopModel height={250} lableLength={7} />
                                        </>
                                        )}
                  {(modelWiseResults === undefined || modelWiseResults.length === 0)
                                        && <div className="font10 noRecords">No Record Available</div>}
                </div>

              </div>

              <div className="col-md-6 dashboard-card">

                <div className="card">
                  <label className="font10">
                    Top 10 Failures - Machine Wise
                  </label>
                  {machineWiseResults && machineWiseResults.length > 0
                                        && (
                                        <>
                                          <MUITooltip
                                            className="conditional-monitoring"
                                            title="Enlarged View"
                                            PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                            arrow
                                          >
                                            <label className="font10 pull-right" onClick={() => makeZoom(<TopMachine height={450} lableLength={15} />, 'Top 10 Failures - Machine')}><Icon name="ZoomIcon" /></label>
                                          </MUITooltip>
                                          <TopMachine height={250} lableLength={7} />
                                        </>
                                        )}
                  {(machineWiseResults === undefined || machineWiseResults.length === 0)
                                        && <div className="font10 noRecords">No Record Available</div>}
                </div>

              </div>
            </div>
          </div>
        </div>

      </>

      <div
        className={`modelboxopen2 cmmodel ${visible ? '' : 'hidden'
        }`}
      >
        <div className="modelboxopenInnner conditionMonitoring" id="modelboxopenInnner6">
          <div className="wrapContent">
            <div className="containerIner2" />
            <div className="contentPopup">
              <div className="popupCloseButton bbt">
                <h3 className="titleDiv">{title}</h3>
                <a
                  className="closepopup"
                  onClick={handleCancel}
                >
                  &#x2715;
                </a>
              </div>
              <div className="innerContentPopup">
                <div className="descriptionCreate">
                  <div>
                    <div className="col-md-12 mb-8">
                      <div>
                        <div className="enterpins placeColor">
                          <div className="font14">
                            {zoomComponent}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
