import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { viewFailureIndicators, deleteFailureIndicator } from '../../components/ConditionMonitoring/FMEA/failure-indicators/failureIndicatorsViewApi';
import { removeFailureIndicator } from './failureModeDetailsSlice';

const initialState = {
  failureIndicators: [],
  failureIndicatorStatus: 'idle',
};

export const viewFailureIndicatorsAsync = createAsyncThunk(
  'failureIndicator/viewhFailureIndicator',
  async (id) => {
    const response = await viewFailureIndicators(id);
    return response;
  },
);

export const deleteFailureIndicatorAsync = createAsyncThunk(
  'deleteFailureIndicator/deleteFailureIndicators',
  async (failureIndicatorId, thunkApi) => {
    const response = await deleteFailureIndicator(failureIndicatorId);
    if (response === 'OK') {
      thunkApi.dispatch(removeFailureIndicator(failureIndicatorId));
    }
    return response;
  },
);

export const failureIndicatorsEntry = createSlice({
  name: 'failureIndicators',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(viewFailureIndicatorsAsync.pending, (state) => {
        state.failureIndicatorStatus = 'loading';
      })
      .addCase(viewFailureIndicatorsAsync.fulfilled, (state, action) => {
        state.failureIndicatorStatus = 'idle';
        state.failureIndicators = action.payload;
      })
      // .addCase(viewFailureIndicatorsAsync.rejected, (state, action) => {
      .addCase(viewFailureIndicatorsAsync.rejected, (state) => {
        state.failureIndicatorStatus = 'idle';
      });
  },
});

export default failureIndicatorsEntry.reducer;
