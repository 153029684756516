// className="showOnHover"
import React from 'react';
/* eslint  react/prop-types:0 */
/* eslint max-len:0 */
const NEXT = <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.49219 9L8.49219 6L5.49219 3" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" /></svg>;

const PREV = <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.33594 3L5.33594 6L8.33594 9" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" /></svg>;
const FIRST_PAGE = (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="active">
    <path d="M10.3359 3L7.33594 6L10.3359 9" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.33594 3L3.33594 6L6.33594 9" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
const LAST_PAGE = (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.49219 9L6.49219 6L3.49219 3" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.49219 9L10.4922 6L7.49219 3" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
const EDIT = (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_139_8067)">
      <path d="M6 10C6 7.79086 7.79086 6 10 6H26C28.2091 6 30 7.79086 30 10V26C30 28.2091 28.2091 30 26 30H10C7.79086 30 6 28.2091 6 26V10Z" fill="#1F1F1F" />
    </g>
    <path d="M21.0779 12.5014C21.2368 12.3424 21.4255 12.2163 21.6332 12.1303C21.8409 12.0443 22.0635 12 22.2883 12C22.513 12 22.7356 12.0443 22.9433 12.1303C23.151 12.2163 23.3397 12.3424 23.4986 12.5014C23.6576 12.6603 23.7837 12.849 23.8697 13.0567C23.9557 13.2644 24 13.487 24 13.7117C24 13.9365 23.9557 14.1591 23.8697 14.3668C23.7837 14.5745 23.6576 14.7632 23.4986 14.9221L15.3286 23.0922L12 24L12.9078 20.6714L21.0779 12.5014Z" fill="#5D97F6" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <filter id="filter0_d_139_8067" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_139_8067" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_139_8067" result="shape" />
      </filter>
    </defs>
  </svg>
);
const DELETE = (
  <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_139_8090)">
      <path d="M6.5 10C6.5 7.79086 8.29086 6 10.5 6H26.5C28.7091 6 30.5 7.79086 30.5 10V26C30.5 28.2091 28.7091 30 26.5 30H10.5C8.29086 30 6.5 28.2091 6.5 26V10Z" fill="#1F1F1F" />
    </g>
    <path d="M13 14.3999H14.2222H24" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.778 14.4V22.8C22.778 23.1183 22.6492 23.4235 22.42 23.6485C22.1908 23.8736 21.8799 24 21.5557 24H15.4446C15.1205 24 14.8096 23.8736 14.5804 23.6485C14.3512 23.4235 14.2224 23.1183 14.2224 22.8V14.4H22.778ZM16.0557 14.4V13.2C16.0557 12.8817 16.1845 12.5765 16.4137 12.3515C16.6429 12.1264 16.9538 12 17.278 12H19.7224C20.0466 12 20.3574 12.1264 20.5867 12.3515C20.8159 12.5765 20.9446 12.8817 20.9446 13.2V14.4" fill="#5D97F6" />
    <path d="M16.0557 14.4V13.2C16.0557 12.8817 16.1845 12.5765 16.4137 12.3515C16.6429 12.1264 16.9538 12 17.278 12H19.7224C20.0466 12 20.3574 12.1264 20.5867 12.3515C20.8159 12.5765 20.9446 12.8817 20.9446 13.2V14.4M22.778 14.4V22.8C22.778 23.1183 22.6492 23.4235 22.42 23.6485C22.1908 23.8736 21.8799 24 21.5557 24H15.4446C15.1205 24 14.8096 23.8736 14.5804 23.6485C14.3512 23.4235 14.2224 23.1183 14.2224 22.8V14.4H22.778Z" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.2776 17.3999V20.9999" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.7224 17.3999V20.9999" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <filter id="filter0_d_139_8090" x="0.5" y="0" width="44" height="44" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_139_8090" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_139_8090" result="shape" />
      </filter>
    </defs>
  </svg>
);
const FirstPage = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3359 3L7.33594 6L10.3359 9"
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33594 3L3.33594 6L6.33594 9"
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Prev = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33594 3L5.33594 6L8.33594 9"
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Next = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.49219 9L8.49219 6L5.49219 3"
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LastPage = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.49219 9L6.49219 6L3.49219 3"
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.49219 9L10.4922 6L7.49219 3"
      stroke="#8F8F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
function ActiveIcon({ icon }) {
  switch (icon) {
    case 'NEXT':
      return NEXT;
    case 'PREV':
      return PREV;
    case 'FIRST-PAGE':
      return FIRST_PAGE;
    case 'LAST-PAGE':
      return LAST_PAGE;
    case 'EDIT': return EDIT;
    case 'DELETE': return DELETE;
    case 'FirstPage': return FirstPage;
    case 'Prev': return Prev;
    case 'Next': return Next;
    case 'LastPage': return LastPage;
    default:
      return '';
  }
}

export default ActiveIcon;
