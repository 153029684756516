// <!-- eslint-disable-next-line vue/max-attributes-per-line -->
/* eslint max-len:0 */
function getIcon(name) {
  switch (name.toUpperCase()) {
    case 'MINUS':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.33331 8H10.6666" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    case 'EDIT': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M11.0779 2.50136C11.2368 2.34241 11.4255 2.21632 11.6332 2.1303C11.8409 2.04428 12.0635 2 12.2883 2C12.513 2 12.7356 2.04428 12.9433 2.1303C13.151 2.21632 13.3397 2.34241 13.4986 2.50136C13.6576 2.66031 13.7837 2.84901 13.8697 3.05669C13.9557 3.26436 14 3.48695 14 3.71174C14 3.93653 13.9557 4.15912 13.8697 4.3668C13.7837 4.57447 13.6576 4.76317 13.4986 4.92212L5.32855 13.0922L2 14L2.90779 10.6714L11.0779 2.50136Z" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
    case 'TICK': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <rect x="2.01718" y="2" width="12.103" height="12" rx="2" fill="#5D97F6" stroke="#5D97F6" />
        <path d="M11.4306 5.5L6.80794 10.0833L4.70673 8" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
    case 'MINUSACTIVE': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z" fill="#5D97F6" />
        <path d="M5.33337 8H10.6667" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
    case 'CALENDER': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.6667 3.09375H3.33333C2.59695 3.09375 2 3.63629 2 4.30556V12.7882C2 13.4575 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.4575 14 12.7882V4.30556C14 3.63629 13.403 3.09375 12.6667 3.09375Z" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.6665 2V4" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.3335 2V4" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 6.6665H14" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
    case 'BELL': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3677 3.17095C11.4991 3.0449 11.6772 2.97409 11.8629 2.97409H12.1369C12.3227 2.97409 12.5008 3.0449 12.6321 3.17095C12.7635 3.297 12.8372 3.46795 12.8372 3.64621V4.08455H12.6408C12.4254 4.06776 12.2085 4.06119 11.9908 4.06507C11.8105 4.06519 11.6317 4.07176 11.4547 4.08455H11.1626V3.64621C11.1626 3.46795 11.2364 3.29699 11.3677 3.17095ZM10.1477 4.29791V3.64621C10.1477 3.20961 10.3284 2.79089 10.6501 2.48216C10.9717 2.17344 11.408 2 11.8629 2H12.1369C12.5919 2 13.0281 2.17344 13.3498 2.48216C13.6714 2.79089 13.8522 3.20961 13.8522 3.64621V4.28961C14.9525 4.57887 15.9869 5.14004 16.8794 5.9371C17.5068 6.48362 18.0123 7.18012 18.364 7.97062C18.7161 8.76222 18.9066 9.63151 18.9239 10.5141L18.924 10.5233L18.9239 15.9383L20.7139 18.9929C20.8022 19.1437 20.8006 19.3282 20.7096 19.4775C20.6186 19.6269 20.4518 19.7187 20.2715 19.7187H15.1599C14.9977 20.3383 14.6482 20.8985 14.1501 21.3134C13.5498 21.8133 12.7963 22 12 22C11.183 22 10.4502 21.8133 9.84987 21.3134C9.35171 20.8985 9.00229 20.3383 8.84002 19.7187H3.72841C3.54831 19.7187 3.3817 19.6271 3.29062 19.4779C3.19954 19.3288 3.19764 19.1445 3.28561 18.9937L5.06755 15.9388V10.5233C5.06755 7.53957 7.22955 5.05001 10.1477 4.29791ZM11.4934 5.05864C8.45413 5.29539 6.08246 7.66088 6.08246 10.5233V16.0658C6.08246 16.1491 6.0602 16.2311 6.0178 16.3038L4.59407 18.7446H9.2547L9.26288 18.7445H14.7371L14.7453 18.7446H19.4039L17.9742 16.3046C17.9315 16.2317 17.909 16.1495 17.909 16.0658V10.528C17.8936 9.76796 17.7291 9.02432 17.4305 8.35309C17.1314 7.68052 16.7071 7.10195 16.1952 6.6566L16.1901 6.65218C15.1411 5.71448 13.8899 5.16826 12.5973 5.05864H11.4934ZM10.515 20.5777C10.243 20.3512 10.0317 20.0549 9.90116 19.7187H14.0988C13.9683 20.0549 13.7569 20.3512 13.4849 20.5777C13.0757 20.9184 12.4698 21.0263 11.9491 21.0263C11.9153 21.0231 11.9829 21.0231 11.9491 21.0263C11.4284 21.0263 10.9242 20.9184 10.515 20.5777Z"
          fill="#8F8F8F"
        />
      </svg>
    );
    case 'PLUS': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 5.3335V10.6668" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.33337 8H10.6667" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
    case 'SEARCH': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M9.97622 17.7303C14.3179 17.7303 17.8376 14.2089 17.8376 9.86514C17.8376 5.52134 14.3179 2 9.97622 2C5.63451 2 2.11487 5.52134 2.11487 9.86514C2.11487 14.2089 5.63451 17.7303 9.97622 17.7303Z" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.1155 21.9994L15.6415 15.5222" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
    case 'DELETE': return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M2.5 4.3999H3.72222H13.5" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.55575 4.4V3.2C5.55575 2.88174 5.68452 2.57652 5.91373 2.35147C6.14294 2.12643 6.45381 2 6.77797 2H9.22241C9.54657 2 9.85744 2.12643 10.0867 2.35147C10.3159 2.57652 10.4446 2.88174 10.4446 3.2V4.4M12.278 4.4V12.8C12.278 13.1183 12.1492 13.4235 11.92 13.6485C11.6908 13.8736 11.3799 14 11.0557 14H4.94463C4.62048 14 4.3096 13.8736 4.08039 13.6485C3.85118 13.4235 3.72241 13.1183 3.72241 12.8V4.4H12.278Z" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.77759 7.3999V10.9999" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.22241 7.3999V10.9999" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
    default: return '';
  }
}
const index = ({ name, className = '', onClick = null }) => <span className={`icon ${className} ${name.toUpperCase()}`} onClick={onClick}>{getIcon(name)}</span>;
export default index;
