import { useState } from 'react';
import PowerBIReport from '../PowerBI';
import AddEntry from './AddEntry';

const ShiftReport = ({ data }) => {
  const [popUpDisp, setPopUpDis] = useState(false);
  return (
    <>
      {popUpDisp && <AddEntry closePopUP={setPopUpDis} />}
      <div className="shift_report_container">
        <div className="add_entry_btn">
          <button
            type="button"
            className="btn-primary"
            onClick={() => setPopUpDis((prev) => !prev)}
          >
            Add Entry
          </button>
        </div>
        <PowerBIReport data={data} />
      </div>
    </>
  );
};

export default ShiftReport;
