/*eslint-disable*/
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

import Styles from "./NoData.module.css"

function NoData() {
  return (
    <div className={Styles.head}>
      <div className={Styles.icon}><AiOutlineInfoCircle/></div>
      <div className={Styles.text}>No Data</div>
    </div>
  );
}

export default NoData;
