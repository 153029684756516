/* eslint-disable import/prefer-default-export */
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
// import { OnChange } from 'react-final-form-listeners';
import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import Tooltip from '@material-ui/core/Tooltip';
import {
  saveRecommendedActionsAsync,
  deleteRecommendedActionsAsync,
} from '../../../redux/slices/recommendedActionSlice';
import ConfirmationDialog from '../../../common/ConfirmationDialog/ConfirmationDialog';
import '../../../styles/failureMode.css';
import { ReactComponent as Edit } from '../../../images/editSpoc.svg';
import { ReactComponent as Delete } from '../../../images/deleteIcon.svg';
import { ReactComponent as AlertIcon } from '../../../images/alert-circle.svg';
import { ReactComponent as Plus } from '../../../images/plus.svg';
// import Icon from '../../Dashboard/Sidebar/images/Icon';

const validate = () => { };

export const RecommendedAction = ({ recommendedAction, potentialCauseId, onLocalActionsChanged }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [editItem, setEditItem] = useState();
  const [visible, setVisible] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  // const [modalText, setModalText] = useState('Are you sure to delete this?');
  const modalText = 'Are you sure to delete this?';
  // const [showConfirmation, setConfirmation] = useState({});
  const [showConfirmation] = useState({});

  const [localActions, setLocalActions] = useState(recommendedAction || []);

  useEffect(() => {
    setLocalActions(recommendedAction || []);
  }, [recommendedAction]);

  useEffect(() => {
    onLocalActionsChanged(localActions);
  }, [localActions]);

  const onAddRecommendedAction = () => {
    if (description) {
      if (potentialCauseId) {
        dispatch(
          saveRecommendedActionsAsync({
            Description: description,
            PotentialCause_ID: potentialCauseId,
            RecommendedAction_ID: editItem && editItem.RecommendedAction_ID,
          }),
        ).then((res) => {
          console.log(res);
          setEditItem();
        });
      } else {
        const temp = [...localActions];
        if (editItem && editItem.RecommendedAction_ID) {
          const edited = temp.find((item) => item.RecommendedAction_ID === editItem.RecommendedAction_ID);
          if (edited && edited.RecommendedAction_ID) {
            edited.Description = description;
          }
        } else {
          temp.push({ RecommendedAction_ID: temp.length + 1, Description: description });
        }
        setLocalActions(temp);
        setEditItem();
        setEditItem({ Description: '' });
      }
    }
    setDescription('');
  };

  useEffect(() => {

  }, [editItem]);

  const handleOk = () => {
    if (potentialCauseId) {
      dispatch(deleteRecommendedActionsAsync({
        PotentialCause_ID: potentialCauseId,
        RecommendedAction_ID: deleteId,
      }));
    } else {
      setLocalActions(localActions.filter((action) => action.RecommendedAction_ID !== deleteId));
    }
    setDeleteId(undefined);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };
  // const showDialog = (title, text, onDone) => {
  //   setConfirmation({
  //     show: true,
  //     title,
  //     text,
  //     onClose: () => {
  //       setConfirmation({ show: false });
  //     },
  //     onDone: () => {
  //       onDone();
  //       setConfirmation({ show: false });
  //     },
  //   });
  // };

  const handleCancel = () => {
    setVisible(false);
  };

  const onEditActionClick = (r) => {
    setDescription(r.Description);
    setEditItem(r);
  };

  const onDeleteActionClick = (id) => {
    setDeleteId(id);
    // showDialog("Confirm", "Are you sure want to delete this?", ()=>{handleOk()});
    setVisible(true);
  };

  return (
    <>
      <Form
        onSubmit={() => { }}
        validate={validate}
        initialValues={editItem || {}}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <div className="form-group recommended">
                <label>Recommended Action</label>
                <span>
                  <Tooltip
                    PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                    arrow
                    title={(
                      <div className="fia-desc">
                        <div className="fia-desc-head">Points to consider when configuring Recommended Action:</div>
                        <br />
                        <span>Immediate action that is to be initiated when a failure has occurred</span>
                        <br />
                      </div>
                    )}
                  >
                    <AlertIcon />
                  </Tooltip>
                  {/* <Tooltip title="Immediate action that is to be initiated when a failure has occurred">
                  <i className="fa fa-info-circle icon-margin"></i>
                  </Tooltip> */}
                </span>
                <textarea
                  name="Description"
                  className="form-control customForm"
                  component="input"
                  placeholder="Recommended Action"
                  maxLength={2000}
                  value={description}
                  onChange={(e) => { setDescription(e.target.value); }}
                />

                <div className="pull-right add-container">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      onAddRecommendedAction();
                      form.reset();
                    }}
                  >
                    <Plus />
                    {/* <img src="/static/media/plus.361e1ba5.svg" alt="" title="" /> */}
                  </button>

                </div>
              </div>
            </div>

            <div className="clearfix" />

            <div
              className="recommended-action-list"

            >
              {localActions
                && localActions.map((r) => (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <label value={r.RecommendedAction_ID}>
                          {r.Description}
                        </label>
                        <div className="pull-right edit">
                          <label
                            className="col-md-1"
                            onClick={() => {
                              onEditActionClick(r);
                            }}
                          >
                            {/* <Icon name="EditIcon" /> */}
                            <Edit />
                          </label>
                          <label
                            className="col-md-1"
                            onClick={() => {
                              onDeleteActionClick(r.RecommendedAction_ID);
                            }}
                          >
                            {/* <Icon name="DeleteIcon" /> */}
                            <Delete />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </form>
        )}
      />
      {showConfirmation.show
        && (
        <ConfirmationDialog
          onClosed={showConfirmation.onClose}
          title={showConfirmation.title}
          text={showConfirmation.text}
          onDoneClicked={showConfirmation.onDone}
        />
        )}
      <Modal
        title="Delete"
        visible={visible}
        width={300}
        onOk={handleOk}
        okText="Delete"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        className="failuremodulePage"
      >
        <div className="font14">{modalText}</div>
      </Modal>
    </>
  );
};
