/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Monthlydata from './Montlydata';
import addhook from './addhook';
import Weeklydata from './weeklydata';
const data = new Date();
const customStyles = {
  menu: (provided) => {
    const active = 'green';
    return { ...provided, active };
  },

  singleValue: (provided) => {
    const color = 'white';
    // const height = "24px";
    return { ...provided, color };
  },
};



function Addentry({ data, closePopUP }) {
  const [plantsList, setPlantsList] = useState([]);
  const [calenederOptions, setCalenderOptions] = useState([]);
  const [yearOption, setYearOption] = useState([]);
  const [yearFilter, setYearFilter] = useState({});
  const [calenderFilter, setCalenderFilter] = useState([]);
  const [plantFilter, setPlantFilter] = useState([]);
  const [weekRanges, setWeekRanges] = useState([]);
  const { ...hooks } = addhook({
    setPlantsList,
    setCalenderOptions,
    setYearOption,
    setPlantFilter,
    setYearFilter,
    setCalenderFilter,
    setWeekRanges
  });

  const [onSave, setOnSave] = useState(false);

  // const [yearOption] = useState(yearOptions);
  // const [filterOptionValue, setFilterOptionValue] = useState(3);
  const handleChange = (dat) => {
    setPlantFilter(dat);
  };
  const handleChangeCalender = (dat) => {
    setCalenderFilter(dat);
    hooks.getWeekRanges(calenderFilter.value, yearFilter.value); 
  };

  const handleChangeYearfilter = (dat) => {
    hooks.getFinancialYearMonths(dat.value);
    setYearFilter(dat);
  };
  const onRestButton = () => {
    setPlantFilter(plantsList[0]);
    setYearFilter(yearOption[0]);
    setCalenderFilter(calenederOptions[0]);
  };
  function getCurrentFinancialYear() {
    var fiscalyear = "";
    var date = new Date();
    var today = new Date(date.setMonth(date.getMonth()));
    if ((today.getMonth() + 1) <= 3) {
      fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
      fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    return fiscalyear
  }
  
  useEffect(() => {
    hooks.getPlantList();
    // hooks.getFinancialYear(data.getFullYear());
    hooks.getFinancialYear(+getCurrentFinancialYear().split('-')[0]);
    hooks.getFinancialYearMonths(+getCurrentFinancialYear().split('-')[0]);
    // hooks.getFinancialYearMonths(+data.getFullYear()-1);
  }, []);
  
  useEffect(() => {
    if(calenderFilter?.value && yearFilter?.value){
      hooks.getWeekRanges(calenderFilter.value, yearFilter.value); 
    }
  },[calenderFilter, yearFilter]);
  // console.log("weekRanges", weekRanges);
  return (
    <div className="RSD_popup">
      <div className="RSD_popup__head">
        <h3 className="RSD_popup_title">
          RSD Report-Add Entry
          <a
            className="RSD_close_btn"
            onClick={() => closePopUP((prev) => !prev)}
          >
            &#x2715;
          </a>
        </h3>
      </div>
      <div className="RSD_popup__body">
        <div className="RSD_Weekly_report">
          <div className="RSD_Weekly_report_head">
            <div className="RSD_Weekly_report_head_left">
              <div className="RSD_filter">
                <div className="RSD_select_container">
                  <label htmlFor="plant" className="">
                    <span className="lbl">Plant</span>
                    <Select
                      id="plant"
                      className="dropdown_rsd RSD_select rsd-plnt"
                      name="plant"
                      onChange={handleChange}
                      defaultValue={false}
                      options={plantsList}
                      value={plantFilter}
                      noOptionsMessage={() => 'No other plant are available'}
                      styles={customStyles}
                    />
                  </label>
                </div>
                <div className="RSD_select_container">
                  <label htmlFor="year" className="">
                    <span className="lbl">Year</span>
                    <Select
                      id="year"
                      className="dropdown_rsd RSD_select"
                      name="yeARTable"
                      defaultValue={false}
                      value={yearFilter}
                      onChange={handleChangeYearfilter}
                      options={yearOption}
                      noOptionsMessage={() => 'No Other Year Range'}
                      styles={customStyles}
                    />
                  </label>
                </div>
                <div className="RSD_select_container">
                  <label htmlFor="month" className="">
                    <span className="lbl">Month</span>
                    <Select
                      id="month"
                      className="dropdown_rsd RSD_select"
                      name="monthTable"
                      defaultValue={false}
                      value={calenderFilter}
                      onChange={handleChangeCalender}
                      options={calenederOptions}
                      noOptionsMessage={() => 'No Other Month Range'}
                      styles={customStyles}
                    />
                  </label>
                </div>
              </div>
              <div className="RSD_reset">
                <button
                  type="button"
                  variant="contained"
                  onClick={onRestButton}
                  className="RSD_reset_btn"
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="RSD_Weekly_report_head_right">
              <ol className="item_listbtn">
                <li>
                  <span className="font-color_red" />
                  Gelatin
                </li>
                <li>
                  <span className="font-color_green" />
                  HPMC
                </li>
                <li>
                  <span className="font-color_yellow"> </span>
                  HR
                </li>
                <li>
                  <span className="font-color_blue"> </span>
                  Tabsule
                </li>
                <li>
                  <span className="font-color_cyan"> </span>
                  H+
                </li>
                
              </ol>
            </div>
          </div>
          <div className="RSD_Weekly_report_body">
            {(Object.keys(plantFilter).length && Object.keys(calenderFilter).length && Object.keys(yearFilter).length )
              && (
                <Weeklydata
                  plantFilter={plantFilter}
                  calenderFilter={calenderFilter}
                  yearFilter={yearFilter}
                  weekRanges = {weekRanges}
                  setOnSave={setOnSave}
                  reportInfo={data} // report information
                />
              )}
          </div>
        </div>
        <div className="RSD_mothly_report" id="monthly">
          <Monthlydata
            plantFilter={plantFilter}
            calenderFilter={calenderFilter}
            yearFilter={yearFilter}
            onSave={onSave}
            weekRanges = {weekRanges}
          />
        </div>
      </div>
    </div>
  );
}

export default Addentry;
