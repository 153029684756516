import React from 'react';
import {
  Document,
  Page,
  //   View,
  //   Text,
  //   Image,
  //   Canvas,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import Html from 'react-pdf-html';

import Montserrat from './Montserrat/Montserrat-Regular.ttf';
/* eslint  react/prop-types:0 */

function convertToDate(timestamp) {
  const date = new Date(timestamp);
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  const result = `${date.getDate()
  }/${
    month
  }/${
    date.getFullYear()
  } ${
    date.getHours()
  }:${
    date.getMinutes()
  }:${
    date.getSeconds()}`;

  return result;
}

function FTRPDF({ formFeild }) {
//   const formFeild = {
//     id: 114037,
//     timestamp: "2022-06-15T08:24:46.183Z",
//     ProductionBatchNo: "H25220623",
//     SalesOrderNo: "1104064531",
//     SalesOrderQty: "14.500",
//     PlanStarttime: "2022-06-18T00:00:00.000Z",
//     PlanEndtime: "2022-06-19T00:00:00.000Z",
//     DispatchDate: "2022-06-17T08:24:37.108Z",
//     Size: "02",
//     BodyColor: "YL917",
//     CapColor: "BL892",
//     CustomerName: "SQUARE PHARMACEUTICALS LIMITED",
//     MaterialNo: "BRNSBL892YL917E02",
//     OrderNo: "912000507168",
//     ProductType: "P",
//     InspectionLot: "030001643533",
//     Message: "",
//     CAP_IRON: "N",
//     BODY_IRON: "N",
//     GELSRCDESC: "REGULAR",
//     Plant: "1120",
//     SalesOrderItemNo: "000010",
//     batch: "H25220623",
//     capcolor: "BL892",
//     bodycolor: "YL917",
//     degreeofcoveragecap: 1,
//     inkusedset1: "RMI010182",
//     capmsg: "test",
//     messagetype: "9RB",
//     degreeofcoveragebody: 1,
//     inkusedset2: "RMI010182",
//     bodymsg: "test",
//     previscosityInkcolor: 0,
//     preaprintinghead1gscap: 0,
//     preaprintinghead1oscap: 0,
//     preabladeprecap: 0,
//     preaviscosityInkbody: 0,
//     preaprintinghead1gsbody: 0,
//     preaprintinghead1osbody: 0,
//     preabladeprebody: 0,
//     amcno: "11",
//     aoperator: "11",
//     mcspeed: "11",
//     afirstofloss: "11",
//     aviscositysetpointcap: "11",
//     aprintinghead1gscap: "11",
//     aprintinghead1oscap: "11",
//     abladeprecap: "11",
//     aviscositysetpointbody: "11",
//     aprintinghead1gsbody: "11",
//     aprintinghead1osbody: "11",
//     abladeprebody: "11",
//   };
  const {
    BodyColor,
    CapColor,
    batch,
    degreeofcoveragecap,
    inkusedset1,
    messagetype,
    degreeofcoveragebody,
    inkusedset2,
    previscosityInkcolor,
    preaprintinghead1gscap,
    preaprintinghead1oscap,
    preabladeprecap,
    preaviscosityInkbody,
    preaprintinghead1gsbody,
    preaprintinghead1osbody,
    preabladeprebody,
    amcno,
    aoperator,
    aviscositysetpointcap,
    aprintinghead1gscap,
    aprintinghead1oscap,
    abladeprecap,
    aviscositysetpointbody,
    aprintinghead1gsbody,
    aprintinghead1osbody,
    abladeprebody,
    bodymsg,
    capmsg,
  } = formFeild;
  function getHTML() {
    const style = `<style>
                    h3 {
                    margin: 0;
                    }
                    table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 7px;
                    }
                    .header_table {
                    border-bottom: 1px solid #d4d4d4;
                    padding-bottom: 5px;
                    }
                    .header_table td:nth-child(1) {
                    width: 30%;
                    }
                    .header_table td:nth-child(2) {
                    width: 70%;
                    }
                    td,
                    th {
                    padding: 5px;
                    font-size: 12px;
                    color: black;
                    vertical-align: middle;
                    }
                    .all_border{
                    border: 1px solid #d4d4d4;
                    }
                    .all_border tr:not(:last-child) {
                    border-bottom: 1px solid #d4d4d4;
                    }
                    .all_border td:not(:last-child),
                    .all_border th:not(:last-child) {
                    border-right: 1px solid #d4d4d4;
                    }
                    .center {
                    text-align: center;
                    }
                    .right {
                    text-align: right;
                    }
                    .container {
                    width: 100%;
                    margin: auto;
                    padding:10px 15px
                    }
                    .head_title {
                    margin: 0;
                    text-align: right;
                    text-transform: uppercase;
                    font-size: 16px;
                    }
                    .timestamp {
                    color: gray;
                    margin-top: 10px;
                    text-align: right;
                    font-size: 10px;
                    }
                    tr:nth-child(even) td {
                    background-color: #f5f5f5;
                    }
                    .even {
                    background-color: #f5f5f5;
                    }
                    .highlight {
                    /* color: ; */
                    }
                    .bold {
                    font-weight: 700;
                    }
                    .title {
                    text-transform: uppercase;
                    color: #000;
                    font-weight: 700;
                    font-size: 14px;
                    margin-bottom: 10px;
                    /* font-family: Arial; */
                    margin-top: 30px;
                    }
                    .input_table td:nth-child(1) {
                        width: 25%;
                        min-width: 25%;
                    }
                    .input_table td:nth-child(2) {
                    width: 75%;
                    min-width: 75%;
                    }
                    .content {
                    padding: 0 10px;
                    }
                    .logo{
                        width:100px;
                        height:50px;
                    }
                </style>`;
    const html = `<html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Document</title>
            ${style}
        </head>
        <body>
            <div class="container">
            <table class="header_table">
                <tr>
                <td>
                    <img src="/image/ACG-LOGO.png"  class="logo"/>
                </td>
                <td>
                    <h3 class="head_title">Print Setting Estimation Data</h3>
                    <div class="timestamp">Timestamp: ${convertToDate(new Date())}</div>
                </td>
                </tr>
            </table>

            <div class="content">
                <h3 class="title">Input fields</h3>
                <table class="input_table all_border">
                <tr>
                    <td class="bold"><b>Batch No</b></td>
                    <td>${batch}</td>
                </tr>
                <tr class="even">
                    <td class="bold"><b>Cap Colour</b></td>
                    <td>${CapColor}</td>
                </tr>
                <tr>
                    <td class="bold"><b>Body Colour</b></td>
                    <td>${BodyColor}</td>
                </tr>
                <tr class="even">
                    <td class="bold"><b>Message Type</b></td>
                    <td>${messagetype}</td>
                </tr>
                </table>

                <table class="all_border">
                    <tr class="bold">
                        <td><b>PART</b></td>
                        <td><b>DEGREE OF COVERAGE</b></td>
                        <td><b>INK TYPE RMI#</b></td>
                        <td><b>MESSAGE</b></td>
                    </tr>
                    <tr class="even">
                        <td class="bold"><b>CAP</b></td>
                        <td>${degreeofcoveragecap}</td>
                        <td>${inkusedset1}</td>
                        <td>${capmsg}</td>
                    </tr>
                    <tr class="">
                        <td class="bold"><b>BODY</b></td>
                        <td>${degreeofcoveragebody}</td>
                        <td>${inkusedset2}</td>
                        <td>${bodymsg}</td>
                    </tr>
                </table>

                <h3 class="title">Output Data</h3>
                <table class="all_border">
                <tr>
                    <td colspan="4" class="center bold"><b>Estimated Data</b></td>
                </tr>
                <tr class="even">
                    <td colspan="2" class="right bold"><b>Machine #</b></td>
                    <td colspan="2">${amcno}</td>
                </tr>
                <tr>
                    <td><b>Viscosity Cap Side</b></td>
                    <td>${previscosityInkcolor}</td>
                    <td><b>Viscosity Body Side</b></td>
                    <td>${preaviscosityInkbody}</td>
                </tr>
                <tr class="even">
                    <td><b>Printing GS Cap</b></td>
                    <td>${preaprintinghead1gscap}</td>
                    <td><b>Printing GS Body</b></td>
                    <td>${preaprintinghead1gsbody}</td>
                </tr>
                <tr>
                    <td><b>Printing OS Cap</b></td>
                    <td>${preaprintinghead1oscap}</td>
                    <td><b>Printing OS Body</b></td>
                    <td>${preaprintinghead1osbody}</td>
                </tr>
                <tr>
                    <td><b>Blade Pressure Cap Side</b></td>
                    <td>${preabladeprecap}</td>
                    <td><b>Blade Pressure Body Side</b></td>
                    <td>${preabladeprebody}</td>
                </tr>
                </table>

                <table class="all_border">
                <tr>
                    <td colspan="4" class="center bold"><b>Actual Data</b></td>
                </tr>
                <tr class="even">
                    <td class="bold"><b>Operator Name</b></td>
                    <td>${aoperator}</td>
                    <td class="bold"><b>Machine#</b></td>
                    <td>${amcno}</td>
                </tr>
                <tr>
                    <td class="bold"><b>Viscosity Cap Side</b></td>
                    <td>${aviscositysetpointcap}</td>
                    <td class="bold"><b>Viscosity Body Side</b></td>
                    <td>${aviscositysetpointbody}</td>
                </tr>
                <tr class="even">
                    <td class="bold"><b>Printing GS Cap</b></td>
                    <td>${aprintinghead1gscap}</td>
                    <td class="bold"><b>Printing GS Body</b></td>
                    <td>${aprintinghead1gsbody}</td>
                </tr>
                <tr class="even">
                    <td class="bold"><b>Printing OS Cap</b></td>
                    <td>${aprintinghead1oscap}</td>
                    <td class="bold"><b>Printing OS Body</b></td>
                    <td>${aprintinghead1osbody}</td>
                </tr>
                <tr class="even">
                    <td class="bold"><b>Blade Pressure Cap Side</b></td>
                    <td>${abladeprecap}</td>
                    <td class="bold"><b>Blade Pressure Body Side</b></td>
                    <td>${abladeprebody}</td>
                </tr>
                </table>
            </div>
            </div>
        </body>
        </html>`;
    return html;
  }

  //  const src = "./Montserrat/Montserrat-Regular.ttf";
  // Register font
  Font.register({ family: 'Montserrat', src: Montserrat });
  // Font.register({
  //     family: 'Montserrat',
  //     fonts: [
  //       { src: Montserrat },
  //     ]
  //   });

  const { PageStyle } = StyleSheet.create({
    PageStyle: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
    },
  });
  return (
    <Document>
      <Page style={PageStyle}>
        <Html>{getHTML()}</Html>
      </Page>
    </Document>
  );
}
export default FTRPDF;
