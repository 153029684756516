import React, { useState, useContext, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import LiHtml from '../../../common/horizontalMenu/horizontalMenu';
import { LanguageContext } from '../../../LanguageContext';

const AlertNotificationModal = ({ currentDetails, onClosed }) => {
  const smsData = currentDetails.length > 0 ? currentDetails.filter((a) => a.CT === 'S') : null;
  const mailData = currentDetails.length > 0 ? currentDetails.filter((a) => a.CT === 'E') : null;
  const sms = currentDetails.length > 0 ? currentDetails.filter((a) => a.CT === 'S')[0] : null;
  const mail = currentDetails.length > 0 ? currentDetails.filter((a) => a.CT === 'E')[0] : null;
  const [tabState, setTabState] = useState(false);

  const onCloseBtnClicked = () => {
    onClosed();
  };

  const tabFuntions = (tabvalue) => {
    setTabState({ selectedTab: tabvalue });
  };

  useEffect(() => {
    tabFuntions('email');
  }, []);

  // for language change
  const [Lang] = useContext(LanguageContext);

  return (
    <Dialog open disableEnforceFocus>
      <div className="modelboxopen" ref={(e) => { e && e.style.setProperty('z-index', 1000, 'important'); }}>
        <div className="modelboxopenInnner modelBox email-smsalert" id="notification-dialog">
          <div className="wrapContent">
            <div className="containerIner2">
              <div className="popupCloseButton bbt">
                <h3 className="titleDiv">Email and SMS Alert Status</h3>
                <div
                  className="closepopup pointer"
                  onClick={onCloseBtnClicked}
                >
                  &#x2715;
                </div>
              </div>
              <div className="contentPopup notification-template-editor alerts-notification">
                <div className="innerContentPopup">
                  <div className="descriptionCreate">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="tabArea">
                          <ul className="nav nav-tabs tabsName alert-notify">
                            <LiHtml
                              tabValue="email"
                              selectedTab={tabState.selectedTab}
                              onClickFun={() => tabFuntions('email')}
                              tabName="Email"
                            />
                            <LiHtml
                              tabValue="sms"
                              selectedTab={tabState.selectedTab}
                              onClickFun={() => tabFuntions('sms')}
                              tabName="SMS"
                            />
                          </ul>
                          <div className="tab-content">
                            {tabState.selectedTab === 'email' && (
                              <div
                                id="Emailtab"
                                className={`tab-pane fade ${tabState.selectedTab === 'email'
                                  ? 'in active'
                                  : ''
                                }`}
                              >
                                <div className="edit-notification-group-fields">
                                  { mailData && mailData.length > 0
                                    && mailData.map((element) => {
                                      if (element.RecipientType === 'T') {
                                        return (
                                          <div className="row-md-16">
                                            <div className="form-group">
                                              <label className="font12">
                                                {Lang.Email}
                                              </label>
                                              <input
                                                className="form-control customForm input-md"
                                                type="text"
                                                disabled
                                                value={element.RecipientDetails}
                                              />
                                            </div>
                                            <i className={element.Delivery_Status === 'S' ? 'fa fa-check' : 'fa fa-times'} />
                                          </div>
                                        );
                                      }
                                    })}
                                  {
                                    mail
                                    && (
                                      <>
                                        <div className="row-md-16">
                                          <div className="form-group">
                                            <label className="font12">
                                              {Lang.Message}
                                            </label>
                                            <div
                                              className="alt-emailtxt"
                                              dangerouslySetInnerHTML={{ __html: mail.MessageText_Email }}
                                            />
                                            {/* <textarea
                                              className="form-control customForm input-md text-area"
                                              type="text"
                                              disabled
                                              rows={5}
                                              col={200}
                                            >
                                              {mail.MessageText_Email}
                                            </textarea> */}
                                          </div>
                                        </div>
                                        <div className="row-md-16">
                                          <div className="form-group">
                                            <label className="font12">
                                              {Lang.Subject}
                                            </label>
                                            <textarea
                                              className="form-control customForm input-md text-area"
                                              type="text"
                                              disabled
                                              rows={5}
                                              col={200}
                                            >
                                              {mail.Subject}
                                            </textarea>
                                          </div>
                                        </div>
                                        <div className="row-md-16">
                                          <div className="form-group">
                                            <label className="timestamp-badge">
                                              TimeStamp
                                              {' '}
                                              {mail.Timestamp}
                                            </label>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  }
                                </div>
                              </div>
                            )}
                            {tabState.selectedTab === 'sms' && (
                              <div
                                id="Smstab"
                                className={`tab-pane fade ${tabState.selectedTab === 'sms'
                                  ? 'in active'
                                  : ''
                                }`}
                              >
                                <div className="edit-notification-group-fields">
                                  {
                                    smsData && smsData.length > 0
                                    && smsData.map((element) => (
                                      <div className="row-md-16">
                                        <div className="form-group width-95">
                                          <label className="font12">
                                            {Lang.SMS}
                                          </label>
                                          <input
                                            className="form-control customForm input-md"
                                            type="text"
                                            disabled
                                            value={element.RecipientDetails}
                                          />
                                        </div>
                                        <i className={element.Delivery_Status === 'S' ? 'fa fa-check' : 'fa fa-times'} />
                                      </div>
                                    ))
                                  }
                                  {
                                    sms
                                    && (
                                      <>
                                        <div className="row-md-16">
                                          <div className="form-group">
                                            <label className="font12">
                                              {Lang.Message}
                                            </label>
                                            <textarea
                                              className="form-control customForm input-md text-area"
                                              type="text"
                                              disabled
                                              rows={5}
                                              col={200}
                                            >
                                              {sms.MessageText_SMS}
                                            </textarea>
                                          </div>
                                        </div>
                                        <div className="row-md-16">
                                          <div className="form-group">
                                            <label className="timestamp-badge">
                                              TimeStamp
                                              {' '}
                                              {sms.Timestamp}
                                            </label>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  }
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Dialog>
  );
};

export default AlertNotificationModal;
