// import axios from 'axios';
// import { user_id, APIUrl } from '../../../configs/config';
import { userId, APIUrl } from '../../../../../helpers/constants';
// import authHeader from '../../../../../config/auth.header';

// const headers = {
//   headers: authHeader(),
// };
import CallAPI from '../../../../../services/conditionMonitoring.service';

// A mock function to mimic making an async request for data
export function fetchMachineModel() {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineModel`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(

  //     `${APIUrl}/api/getMachineModel`, headers,
  //   )
  //   .then((res) => res.data);
}

export function fetchMachineSection(modelId) {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineSection?machinemodelID=${modelId}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMachineSection?machinemodelID=${modelId}`, headers,
  //   )
  //   .then((res) => res.data);
}

export function fetchMachineFunction(sectionId) {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineFunction?machinesectionID=${sectionId}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMachineFunction?machinesectionID=${sectionId}`, headers,
  //   )
  //   .then((res) => res.data);
}

export function fetchSeverityCriteria() {
  const apiInfo = {
    url: `${APIUrl}/api/getSeverityCriteria`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(`${APIUrl}/api/getSeverityCriteria`, headers)
  //   .then((res) => res.data);
}

export function saveFailureMode(failureMode) {
  failureMode.CreatedUser_ID = userId || localStorage.getItem('UserKey');
  const apiInfo = {
    url: `${APIUrl}/api/saveFailureModeDetails`,
    method: 'POST',
    postData: failureMode,
    isMultiPart: false,
    CreatedUserID: true,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .post(`${APIUrl}/api/saveFailureModeDetails`, failureMode, headers)
  //   .then((res) => res.data);
}
