/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Select from "react-select";
import MaintenanceLog from "../MaintenanceLog/MaintenanceLog";
import "./Vibration.css";
import getAllMachineList from "../../../API/getAllMachineList";
import getPlantList from "../../../API/getPlantList";
import getVibrationMotor from "../../../API/getVibrationMotor";
import getVibrationGraph from "../../../API/getVibrationGraph";
import { Modal, Box, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Style from "./style.module.css";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import PerformanceCard from "./VibrationCard";
import NoData from "../../Reusable/NoData";
import zoomPlugin from "chartjs-plugin-zoom";
import { useDispatch, useSelector } from "react-redux";
import { setVibrationAnalysisFilter } from "../../../redux/slices/filterDMSlice";

Chart.register(zoomPlugin);

const Vibration = () => {
  const customSelect = {
    placeholder: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    control: (props, state) => ({
      ...props,
      width: "195px",
      border: "none",
      boxShadow: state.isFocused && "none",
      backgroundColor: "#1f1f1f",
      fontSize: "12px",
      outline: "none",
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
    singleValue: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    input: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    menu: (props) => ({
      ...props,
      backgroundColor: "#1f1f1f",
    }),
    option: (props, state) => ({
      ...props,
      backgroundColor: state.isFocused ? "#5D97F6" : "",
      color: state.isFocused ? "#121212" : "#e0e0e0",
      fontSize: "12px",
    }),
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    overflow: "auto",
    bgcolor: "#1F1F1F",
    borderRadius: "4px",
    border: "1px solid #313131",
    boxShadow: 50,
    outline: "none",
  };

  const option = {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          drag: {
            enabled: true,
            borderColor: "rgba(225,225,225,0.3)", // Border color of the rectangle box
            borderWidth: 1,
            backgroundColor: "rgba(225,225,225,0.2)", // Background color of the rectangle box
            animationDuration: 1000,
          },
          mode: "xy",
          speed: 100,
        },
        pan: {
          enabled: true,
          mode: "x",
          speed: 0.5,
        },
      },
    },
    scales: {
      yAxes: {
        ticks: {
          color: "#ffffff",
        },
        title: {
          display: true,
          text: "Values",
          color: "#FFFFFF",
          font: {
            size: 13,
            family: "Arial, Sans Serif",
          },
        },
      },
      xAxes: {
        ticks: {
          color: "#ffffff",
        },
        title: {
          display: true,
          text: "Time",
          color: "#FFFFFF",
          font: {
            size: 13,
            family: "Arial, Sans Serif",
          },
        },
      },
    },
  };

  const dispatch = useDispatch();
  const VibrationFilter = useSelector(
    (state) => state.DMFilter.VibrationAnalysisFilter.inputData
  );

  const [plant, setPlant] = useState([]);
  const [machine, setMachine] = useState([]);

  const [Loading, setLoading] = useState(true);
  const [firstTime, setfirstTime] = useState(false);
  const [ItemData, setItemData] = useState(null);

  const [IsMaintenance, setIsMaintenance] = useState(false);
  const [VibrationMotor, setVibrationMotor] = useState([]);

  const [PerformanceData, setPerformanceData] = useState(null);
  const [IsExpand, setIsExpand] = useState(false);

  const toggleMaintenance = () => {
    setIsMaintenance(!IsMaintenance);
  };
  const getPlantListData = async () => {
    const response = await getPlantList();
    setPlant(response?.data?.data);
    getMachineList(
      response?.data?.data?.some(
        (e) => e.PlantKey == VibrationFilter?.plant?.PlantKey
      )
        ? VibrationFilter?.plant
        : response?.data?.data[0]
    );
    SaveData(
      VibrationFilter?.plant ?? response?.data?.data[0],
      machine?.some((e) => e.MachineKey == VibrationFilter?.machine?.MachineKey)
        ? VibrationFilter?.machine
        : null,
      VibrationMotor?.some(
        (e) => e.MachineKey == VibrationFilter?.motor?.MachineKey
      )
        ? VibrationFilter?.motor
        : null,
      "PLant"
    );
  };

  const getMachineList = async (e) => {
    const response = await getAllMachineList(e?.PlantKey);
    setMachine(response?.data?.data);

    callGetServoMotorList(
      e,
      response?.data?.data?.some(
        (e) => e.MachineKey == VibrationFilter?.machine?.MachineKey
      )
        ? VibrationFilter?.machine
        : response?.data?.data[0]
    );

    SaveData(
      e,
      response?.data?.data?.some(
        (e) => e?.MachineKey == VibrationFilter?.machine?.MachineKey
      )
        ? VibrationFilter?.machine
        : response?.data?.data[0],
      VibrationMotor?.some(
        (e) => e.MachineKey == VibrationFilter?.motor?.MachineKey
      )
        ? VibrationFilter?.motor
        : null,
      "MAchine"
    );
  };

  const callGetServoMotorList = async (p, m) => {
    const response = await getVibrationMotor(m?.MachineKey);
    setVibrationMotor(response?.data?.data);
    SaveData(
      p,
      m,
      response?.data?.data?.some(
        (e) => e?.MachineKey == VibrationFilter?.motor?.MachineKey
      )
        ? VibrationFilter?.motor
        : response?.data?.data[0],
      "Motor"
    );
    setfirstTime(true);
  };

  const SaveData = async (plant, machine, motor, e) => {
    console.log("datasss", plant, machine, motor, e);
    dispatch(
      setVibrationAnalysisFilter({
        inputData: {
          plant,
          machine,
          motor,
        },
      })
    );
  };

  const callPerfLineChart = async () => {
    if (VibrationFilter?.motor) {
      setLoading(true);
      const response = await getVibrationGraph(VibrationFilter?.motor?.SensorMachineMappingKey);
      console.log("reeeeeee", response);
      setPerformanceData(response?.data?.data);
      setLoading(false);
    }
  };

  const toggleExpand = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsExpand(!IsExpand);
    }
  };

  useEffect(() => {
    getPlantListData();
  }, []);

  useEffect(() => {
    if (firstTime) callPerfLineChart();
  }, [firstTime]);

  useEffect(() => {
    console.log("VibrationFilter", VibrationFilter);
  }, [VibrationFilter]);

  return (
    <div className={Style.ParamPerf__container}>
      <div className="top-box">
        <div className="box-1">
          <div className="select-div">
            <Select
              isSearchable={false}
              styles={customSelect}
              className="selct"
              placeholder="Select Plant"
              options={plant}
              getOptionValue={(x) => x.PlantKey}
              getOptionLabel={(x) => x.PlantName}
              onChange={(e) => {
                SaveData(e, null, null, "Plant Box");
                getMachineList(e);
              }}
              value={VibrationFilter?.plant}
            />
          </div>
          <div className="select-div" style={{ marginLeft: "10px" }}>
            <Select
              isSearchable={false}
              styles={customSelect}
              className="selct"
              placeholder="Select Machine"
              options={machine}
              getOptionValue={(x) => x.MachineKey}
              getOptionLabel={(x) => x.MachineSerialNo}
              onChange={(e) => {
                SaveData(VibrationFilter?.plant, e, null, "Machine box");
                callGetServoMotorList(VibrationFilter?.plant, e);
              }}
              value={VibrationFilter?.machine}
            />
          </div>
          <div className="select-div" style={{ marginLeft: "10px" }}>
            <Select
              isSearchable={true}
              styles={customSelect}
              className="selct"
              placeholder="Select Sensor"
              options={VibrationMotor}
              getOptionValue={(x) => x.MachineKey}
              getOptionLabel={(x) => x.SensorName}
              onChange={(e) => {
                SaveData(
                  VibrationFilter?.plant,
                  VibrationFilter?.machine,
                  e,
                  "Motor Box"
                );
              }}
              value={VibrationFilter?.motor}
            />
          </div>
          <div>
            <button
              className="btn-go"
              onClick={() => {
                callPerfLineChart();
              }}
            >
              Go
            </button>
          </div>
        </div>
        <div className="box-2">
          <button
            className="digital-btn"
            onClick={() => {
              setIsMaintenance(true);
            }}
          >
            Maintenance Log
          </button>
        </div>
      </div>
      {Loading ? (
        <div
          style={{
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={15} />
        </div>
      ) : (
        <>
          {PerformanceData?.[0]?.cardName ? (
            <div className="middle-box">
              <div className="graph-label">
                <p
                  style={{
                    marginRight: "20px",
                    display: "flex",
                    justifyContent: "row",
                  }}
                >
                  <span
                    style={{
                      borderTop: "3px solid #FFEC19",
                      width: "25px",
                      margin: "7.5px 10px 0px 0px",
                    }}
                  ></span>{" "}
                  Minimum Value
                </p>
                <p
                  style={{
                    margin: "0px",
                    display: "flex",
                    justifyContent: "row",
                  }}
                >
                  <span
                    style={{
                      borderTop: "3px solid #DC3838",
                      width: "25px",
                      margin: "7.5px 10px 0px 0px",
                    }}
                  ></span>
                  Maximum Value
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {PerformanceData?.map((item, index) => {
                  return (
                    <div key={index} style={{ width: "33%" }}>
                      {!!item?.values.length && (
                        <PerformanceCard
                          key={item?.cardName}
                          title={item?.cardName}
                          item={{
                            timestamp: item?.timestamps,
                            maxValue: item?.maxValues,
                            minValue: item?.minValues,
                            values: item?.values,
                          }}
                          setItemData={setItemData}
                          toggleExpand={toggleExpand}
                          color={item?.color}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="middle-box" style={{ height: "50%" }}>
              <NoData />
            </div>
          )}
        </>
      )}

      <Modal
        open={IsExpand}
        onClose={toggleExpand}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "0.2px solid #444444",
              padding: "5px 20px",
              background: "#565b60",
              borderRadius: "4px 4px 0px 0px",
            }}
          >
            <h4
              style={{
                fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
                marginTop: "0px",
                fontWeight: 600,
                fontSize: "1rem",
              }}
            >
              {ItemData?.title}
            </h4>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={toggleExpand}
              size={15}
            />
          </div>
          <div style={{ padding: 20 }}>
            <Line
              data={{
                labels: ItemData?.timestamp,
                datasets: [
                  {
                    // label: "warning limit",
                    label: "Maximum Value",
                    data: ItemData?.maxValue,
                    fill: false,
                    pointRadius: 0,
                    borderColor: "#DC3838",
                  },
                  {
                    // label: "triggered limit",
                    label: "Minimum Value",
                    data: ItemData?.minValue,
                    fill: false,
                    pointRadius: 0,
                    borderColor: "#FFEC19",
                  },
                  {
                    label: "value",
                    data: ItemData?.values,
                    fill: false,
                    borderColor: ItemData?.color,
                  },],
              }}
              options={option}
            />
          </div>
        </Box>
      </Modal>
      {VibrationFilter?.plant?.PlantKey && (
        <MaintenanceLog
          IsMaintenance={IsMaintenance}
          toggleMaintenance={toggleMaintenance}
          inputData={VibrationFilter}
          // setInputData={setInputData}
          setLogTitle={() => { }}
        />
      )}
    </div>
  );
};

export default Vibration;
