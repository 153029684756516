// IMPORTING ROUTES
import AppRoutes from '../config/appicationRoutes.config.json';

export const UpdateNotificationGroupRoute = 'NotificationGroup/Details';
export const NotificationGroupRoute = 'NotificationGroup';
export const NotificationTemplateRoute = 'notification-template';

export const Idle = 'idle';
export const Loading = 'loading';
/* eslint max-len:0 */
export const emailValidateRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const mobileNoValRegex = /^(\+\d{1,4}[- ]?)?\d{10}$/;
export const nameValRegex = /^[a-zA-Z\d- ]+$/;

export const SuccessMessage = 'Record Saved Successfully';
export const ErrorMessage = 'Error in Saving Record';
export const DuplicateRecord = 'Duplicate Record';
export const APIUrl = '';

export const userId = localStorage.getItem('UserKey') !== undefined
&& localStorage.getItem('UserKey') !== 0
  ? localStorage.getItem('UserKey')
  : 1;

const {
  CmDashboard, NotificationGroup, NotificationTemplate, CmGeneralCondition, ExecutionResult, AlertNotificationLog,
} = AppRoutes;

export const getSubMenuRoute = (submenu) => {
  const obj = {
    CONDDASH: CmDashboard,
    CONDNOTGRP: NotificationGroup,
    CONDNOTTEM: NotificationTemplate,
    CONDMASTER: CmGeneralCondition,
    CONDER: ExecutionResult,
    CONDAN: AlertNotificationLog,
  };
  return obj[submenu] ? obj[submenu] : '';
};

export const EMAIL_ID_AND_PASSWORD_IS_REQUIRED = 'Email ID and Password is Required!';
export const EMAIL_IS_REQUIRED = 'Email ID is Required!';
export const PASSWORD_IS_REQUIRED = 'Password is Required!';
export const PASSWORD_CONFIRM_PASSWORD_REQUIRED = 'New Password and Confirm Password is Required!';
export const PASSWORD_RULES = 'Password must be min 8 characters and include one special character,number and capital letter';
export const PASSWORD_CONFIRM_PASSWORD_SAME = 'Password and Confirm Password should be same!';
export const EMAILID_NOT_REGISTERED_WITH_US = 'This Email ID is not registered with us. Please contact admin.';
export const PLEASE_CHECK_EMAILID_CONTACT_ADMIN = 'Please check your Email ID, otherwise contact admin';

export const PLEASE_ENTER_CURRENT_PASSWORD = 'Please enter Current Password';
export const PLEASE_ENTER_NEWPASSWORD = 'Please enter New Password';
export const PLEASE_ENTER_CONFIRMPASSWORD = 'Please enter Confirm Password';
export const NEWPASSWORD_CONFIRM_PASSWORD_SAME = 'New Password and Confirm password should be same!';
export const CURRENT_NEW_PASSWORD_SHOULD_NOT_SAME = 'Current Password and New Password should not be same';
