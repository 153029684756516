// import axios from 'axios';
// import { user_id, APIUrl } from "../../configs/config";
import { APIUrl } from '../../../helpers/constants';
// import authHeader from '../../../config/auth.header';
import CallAPI from '../../../services/conditionMonitoring.service';
// const headers = {
//   headers: authHeader(),
// };

export function getExecutionResultList(search, page, size, name, from, to, resolved) {
  const apiInfo = {
    url: `${APIUrl}/api/getExecutionResultList?search=${search}&page=${page}&size=${size}&name=${name}&from=${from}&to=${to}&resolved=${resolved}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getExecutionResultList?search=${search}&page=${page}&size=${size}&name=${name}&from=${from}&to=${to}&resolved=${resolved}`, headers,
  //   )
  //   .then((res) => res.data);
}

export function saveExecutionResult(data) {
  const apiInfo = {
    url: `${APIUrl}/api/saveExecutionResult`,
    method: 'POST',
    postData: data,
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .post(
  //     `${APIUrl}/api/saveExecutionResult`,
  //     data, headers,
  //   )
  //   .then((res) => res.data);
}

export function fetchMachineModel() {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineModel`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(

  //     `${APIUrl}/api/getMachineModel`, headers,
  //   )
  //   .then((res) => res.data);
}

export function fetchMachineName() {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineName`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(

  //     `${APIUrl}/api/getMachineName`, headers,
  //   )
  //   .then((res) => res.data);
}
