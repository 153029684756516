import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserIcon from './images/UserIcon';
import LogoutIcon from './images/LogoutIcon';
import userManagementService from '../../services/userManagement.service';
import Styles from './style.module.css';
import Logo from './images/Logo';
// IMPORTING ACTIONS
import { resetState } from '../../redux/slices/userSlice';
/* eslint no-useless-escape:0 */
function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
const { logout } = userManagementService;

/* eslint no-use-before-define: "error" */
function Header() {
  let firstname = localStorage.getItem('firstname');
  let lastname = localStorage.getItem('lastname');

  firstname = firstname !== 'null' && firstname !== 'undefined' ? firstname : '';
  lastname = lastname !== 'null' && lastname !== 'undefined' ? lastname : '';

  const email = localStorage.getItem('email');
  const SECOND_CHAR = lastname ? lastname[0] : firstname && firstname[1];
  const tile = firstname && firstname[0] + SECOND_CHAR;
  // const tile = "kk";
  const history = useHistory();
  const pageName = history.location.pathname.split('/')[1];
  const subPageName = history.location.pathname.split('/').length > 3 ? history.location.pathname.split('/')[2] : '';
  const subTitle = subPageName ? subPageName.split('-').join(' ') : '';
  const dispatch = useDispatch();
  const { DefaultRoute, Menus, Reports } = useSelector((state) => state.user);
  const reportType = getParameterByName('reportType');
  const reportTypeTitle = Reports[reportType] ? Reports[reportType].ReportName : reportType;
  // Reports[reportType]['ReportName']
  // const urlParams = new URLSearchParams(window.location.search);
  // const myParam = urlParams.get('myParam');

  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  // let value = params.some_key; // "some_value"
  /* eslint guard-for-in:0 */
  // const title = getTitle(pageName);
  const getTitle = () => {
    let title = pageName;
    for (const item in Menus) {
      if (pageName === item) {
        title = Menus[pageName]?.MenuHeaderName;
      } else {
        for (const submenuItem in Menus[item]?.SubMenu) {
          const submenu = Menus[item]?.SubMenu?.[submenuItem]?.MenuName && Menus[item]?.SubMenu?.[submenuItem].MenuName.replaceAll(' ', '');
          if (submenu === pageName) {
            title = Menus[item]?.SubMenu?.[submenuItem]?.MenuHeaderName;
          }
        }
      }
    }
    return subTitle ? `${title} > ${subTitle}` : `${title}`;
  };
  // Menus[pageName] ? Menus[pageName].MenuHeaderName : pageName;
  const title = getTitle(); // Menus[pageName] ? Menus[pageName].MenuHeaderName : pageName;
  const [isShowPanel, setIsShowPanel] = useState(false);
  const profileInfo = useRef();

  const togglePanel = () => {
    setIsShowPanel(!isShowPanel);
  };
  const handleDocumentClick = (e) => {
    if (profileInfo.current.contains(e.target) === false) {
      setIsShowPanel(false);
    }
  };
  const Logout = () => {
    // setIsShowPanel(false)
    logout()
      .then((res) => {
        const { status } = res;
        // EXECUTES WHEN ALL OK
        if (status === 200) {
          localStorage.clear();
          // <Redirect to="/" />
          dispatch(resetState());
          history.replace('/');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <header className={`${Styles.header}`} id="header">
      <div className={`${Styles.header__left}`}>
        <Link className={`${Styles.header__logo}`} to={DefaultRoute}>
          <Logo />
        </Link>
        {title && reportType == null ? (
          <div className={`${Styles.header__pageInfo}`}>{title}</div>
        ) : (
          ''
        )}

        {title === 'Reports' && reportType != null ? (
          <ul className="breadscrums">
            <li className="breadscrums_item">
              <Link to={DefaultRoute}>{title}</Link>
            </li>
            <li className="breadscrums_item">{reportTypeTitle}</li>
          </ul>
        ) : (
          ''
        )}
      </div>
      <div className={`${Styles.profileInfo}`} ref={profileInfo}>
        <div
          className={`${Styles.profileInfo__ShortName} ${isShowPanel ? `${Styles.active}` : ''
          }`}
          onClick={togglePanel}
        >
          {tile}
        </div>
        <div
          className={`${Styles.profileInfo__panel} ${isShowPanel ? `${Styles.showPanel}` : ''
          }`}
        >
          <div className={`${Styles.profileInfo__infoContainer}`}>
            <div className={`${Styles.profileInfo__icon}`}>
              <UserIcon />
            </div>
            <div className={`${Styles.profileInfo__info}`}>
              <span className={`${Styles.profileInfo__infoLabel}`}>
                {firstname || ''}
                {' '}
                {lastname || ''}
              </span>
              <span className={`${Styles.profileInfo__email}`}>{email}</span>
            </div>
          </div>
          <div
            className={`${Styles.profileInfo__infoContainer} ${Styles.profileInfo__logout}`}
            onClick={Logout}
          >
            <div className={`${Styles.profileInfo__icon}`}>
              <LogoutIcon />
            </div>
            <div className={`${Styles.profileInfo__info}`}>
              <span className={`${Styles.profileInfo__infoLabel}`}>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
