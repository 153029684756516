import { useEffect, useState } from 'react';
import Select from 'react-select';

import userManagementService from '../../../services/userManagement.service';

// import API from '../../../config/API.config.json';

const { CallAPI } = userManagementService;
// const { LoadRole } = API;
const customStyles = {
  // menu: (provided, state) => {
  menu: (provided) => ({ ...provided }),
  container: (provided) => ({
    ...provided,
  }),

  // singleValue: (provided, state) => {
  singleValue: (provided) => {
    const color = 'white';
    return { ...provided, color };
  },
};
const index = ({
  value, formData, setFormData, current, API, data: currentData, disable = false,
}) => {
  const defaultSelect = { value, label: value };
  const [roleValue, setRoleValue] = useState(defaultSelect);
  const [options, setOptions] = useState([]);
  const handleSelect = (selected) => {
    setRoleValue(selected);
    // setFormData({ ...formData, [currentData.mappingKey]: selected.value, [current]: selected.value });
    setFormData((prev) => ({
      ...prev, [currentData.mappingKey]: selected.value, [current]: selected.value,
    }));
  };
  useEffect(() => {
    console.log(formData);

    (async () => {
      const result = await CallAPI(API);
      const { data, status } = result;
      if (status === 200) {
        const roles = [];
        const { label, value: selectValue, mappingKey } = currentData;
        // console.log(currentData);
        // console.log(currentData.Default);
        if (currentData.Default) {
          roles.push(...currentData.DefaultOptions);
          // console.log(mappingKey);
          // console.log({ ...formData, [mappingKey]: -1 });
          // setFormData({ ...formData, [mappingKey]: -1 });
          setFormData((prev) => ({
            ...prev, [mappingKey]: -1,
          }));
        }
        for (let i = 0; i < data.length; i += 1) {
          const element = data[i];
          roles.push({
            label: element[label],
            value: element[selectValue],
            // RoleKey: element[selectValue],
          });

          // ADDING RELATED MAPPING KEY TO FORM DATA
          if (element[label] === value) {
            // setFormData({ ...formData, [mappingKey]: element[mappingKey] });
            setFormData((prev) => ({
              ...prev, [mappingKey]: element[mappingKey],
            }));
            // console.log('*****************');
            // console.log(mappingKey);
            // console.log(formData);
          }
        }
        setOptions(roles);
      }
    })();
  }, []);
  return (
    <Select
      className="dropdown_rsd admin-panel-dropdown"
      options={options}
      value={roleValue}
      onChange={handleSelect}
      noOptionsMessage={() => 'Select Table'}
      styles={customStyles}
      isSearchable
      isDisabled={disable}
    />
  );
};
export default index;
