/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
// import { compileExpression } from 'filtrex';
import { message } from 'antd';
import { Mention, MentionsInput } from 'react-mentions';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { LanguageContext } from '../../../LanguageContext';
import { defaultStyle, defaultMentionStyle } from '../mentions';
import MCRecommendedAction from './MCRecommendedAction';
import '../Components/AccordianControl.css';
import '../../../styles/dashboard.css';
import Icon from '../../Dashboard/Sidebar/images/Icon';
import {
  fetchMachineModelAsync,
  fetchMachineSectionAsync,
  fetchMachineFunctionAsync,
  saveMaintenanceConditionAsync,
  fetchNotificationGroupAsync,
  fetchMachineParametersAsync,
  editMaintenanceConditionAsync,
  fetchNotificationTemplateAsync,
  fetchMachineModelNameAsync,
  clearSaveRecord,
  clearEdit,
} from '../../../redux/slices/maintenanceAddSlice';
import { validateFormula } from '../../../common/formulaValidation';
/* eslint no-unused-expressions:0 */
/* eslint no-unused-vars:0 */
/* eslint no-shadow:0 */
/* eslint max-len:0 */
const style = defaultStyle;
const inputEl = React.createRef();

const validate = () => { };
export default function AddMaintenanceCondition({ view }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { option } = useParams();

  const [expanded, setExpanded] = useState('general');

  const [model, setModel] = useState('');
  const [section, setSection] = useState('');
  const [fun, setFun] = useState('');
  const [formula, setFormula] = useState('');
  const [validFormula, setValidFormula] = useState(true);
  const [recommendedActions, setRecommendedActions] = useState([]);
  const [formulaTouched, setFormulaTouched] = useState(false);

  // var dispatch = useDispatch();

  const [result, setResult] = useState([]);

  const recordSaved = useSelector((state) => state.maintenanceAdd.recordSaved);
  useEffect(() => {
    if (recordSaved) {
      dispatch(clearSaveRecord());
      history.push('/ConditionMasters/Maintenance-Masters');
    }
  }, [recordSaved]);

  const onAddEntryClose = () => {
    dispatch(clearEdit());
    history.push('/ConditionMasters/Maintenance-Masters');
  };

  useEffect(() => {
    dispatch(fetchMachineModelAsync());
  }, []);

  useEffect(() => {
    if (option) {
      dispatch(editMaintenanceConditionAsync({ id: option }));
    }
  }, [option]);

  const editMaintenanceConditions = useSelector(
    (state) => state.maintenanceAdd.editMaintenanceConditions,
  );

  useEffect(() => {
    if (editMaintenanceConditions) {
      setFormula(editMaintenanceConditions.MaintenanceConditionFormula);
      setRecommendedActions(editMaintenanceConditions.recommendedAction);
    }
  }, [editMaintenanceConditions]);

  const machineModels = useSelector(
    (state) => state.maintenanceAdd.machineModels,
  );

  useEffect(() => {
    if (model) {
      dispatch(fetchMachineSectionAsync(model));
      dispatch(fetchMachineModelNameAsync(model));
    }
  }, [model]);

  const machineSections = useSelector(
    (state) => state.maintenanceAdd.machineSections,
  );

  useEffect(() => {
    if (editMaintenanceConditions) {
      editMaintenanceConditions;
    }
  });

  useEffect(() => {
    if (section) {
      dispatch(fetchMachineFunctionAsync(section));
    }
  }, [section]);

  const machineFunctions = useSelector(
    (state) => state.maintenanceAdd.machineFunctions,
  );

  useEffect(() => {
    if (model) {
      dispatch(fetchMachineParametersAsync({ model, section, fun }));
    }
  }, [model, section, fun]);

  const machineParameters = useSelector(
    (state) => state.maintenanceAdd.machineParameters,
  );

  const isLoading = useSelector(
    (state) => state.maintenanceAdd.maintenanceConditionStatus === 'loading',
  );

  useEffect(() => {
    dispatch(fetchNotificationGroupAsync());
  }, []);

  const notificationGroups = useSelector(
    (state) => state.maintenanceAdd.notificationGroups,
  );

  useEffect(() => {
    dispatch(fetchNotificationTemplateAsync());
  }, []);

  const notificationTemplates = useSelector(
    (state) => state.maintenanceAdd.notificationTemplates,
  );

  const required = (value) => (value ? undefined : 'Required');
  const checkValues = (form) => {
    const { errors } = form.getState();
    let isError = false;
    /* eslint guard-for-in: 0 */
    for (const item in errors) {
      if (errors[item] === 'Required') {
        isError = true;
      }
    }
    if (isError) {
      message.error('Please enter required fields');
    }
  };
  const onSubmit = async (values) => {
    /* eslint camelcase: 0 */
    const {
      Communication_Email, NotificationTemplate_ID_Email, Communication_SMS, NotificationTemplate_ID_SMS,
    } = values;
    if (!Communication_Email && !Communication_SMS) {
      message.error('Please choose atleast one communication type for alerts');
      return false;
    }
    if (Communication_Email && !NotificationTemplate_ID_Email) {
      message.error('Please choose Email template');
      return false;
    }

    if (Communication_SMS && !NotificationTemplate_ID_SMS) {
      message.error('Please choose SMS template');
      return false;
    }
    if ((editMaintenanceConditions === undefined || editMaintenanceConditions.MaintenanceCondition_ID === undefined) && recommendedActions) {
      values.recommendedactions = recommendedActions.map((l) => ({
        Description: l.Description,
      }));
    }
    let parameterDSeriesFormula = formula;
    if (validFormula && (formula !== undefined && formula !== '')) {
      const parameters = formula.match(/@{[^@{}]*}/gm);
      if (parameters.length > 0) {
        for (const p of parameters) {
          if (p) {
            const dbParam = machineParameters.find((mp) => mp.ParameterName === p.substring(2, p.length - 1));
            parameterDSeriesFormula = parameterDSeriesFormula.replace(p, dbParam.ParameterDSeriesName);
          }
        }
      }

      dispatch(saveMaintenanceConditionAsync({ ...values, MaintenanceConditionFormula: formula, MaintenanceConditionDSeriesFormula: parameterDSeriesFormula }));
    }
  };

  useEffect(() => {
    setResult(
      machineParameters.map((m) => ({
        id: m.MachineParameter_ID,
        display: m.ParameterName,
        subText: m.ParameterUOM,
      })),
    );
  }, [machineParameters]);

  const [Lang] = useContext(LanguageContext);

  const disable = editMaintenanceConditions && editMaintenanceConditions.MaintenanceCondition_ID;

  const onChange = (event, newValue, newPlainTextValue, mentions) => {
    setFormula(newValue);
    setValidFormula(validateFormula(newValue));
  };

  const getSelected = (ids) => {
    const selected = notificationGroups.filter((n) => ids.find((id) => n.NotificationGroup_ID === id));
    return selected.map((s) => s.NotificationGroupName).join(', ');
  };

  return (
    <div className="wrapper cm-wrapper">
      <div className="contentWrap conditionMonitoring monitoringcontentWrap monitoringPage">
        <Form
          mutators={{
            setClearEmail: (args, state, utils) => {
              utils.changeValue(state, 'NotificationTemplate_ID_Email', () => undefined);
            },
            setClearSMS: (args, state, utils) => {
              utils.changeValue(state, 'NotificationTemplate_ID_SMS', () => undefined);
            },

          }}
          onSubmit={onSubmit}
          validate={validate}
          initialValues={editMaintenanceConditions}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form }) => (
            <div className="row slick-carousel slickcarouselTop">
              <form onSubmit={handleSubmit} id="failure-mode-form">

                <div className="grid-header col-sm-12 mb-15 ">
                  <div className="back arrow" onClick={(e) => { e.preventDefault(); onAddEntryClose(); }}>
                    <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.9999 8.00014C14.9999 7.86753 14.9472 7.74036 14.8535 7.64659C14.7597 7.55282 14.6325 7.50014 14.4999 7.50014L2.70692 7.50014L5.85392 4.35414C5.90041 4.30766 5.93728 4.25247 5.96244 4.19173C5.9876 4.13099 6.00055 4.06589 6.00055 4.00014C6.00055 3.9344 5.9876 3.8693 5.96244 3.80856C5.93728 3.74782 5.90041 3.69263 5.85392 3.64614C5.80743 3.59966 5.75224 3.56278 5.6915 3.53762C5.63076 3.51246 5.56566 3.49951 5.49992 3.49951C5.43417 3.49951 5.36907 3.51246 5.30833 3.53762C5.24759 3.56278 5.1924 3.59966 5.14592 3.64614L1.14592 7.64614C1.09935 7.69259 1.06241 7.74776 1.0372 7.80851C1.012 7.86925 0.999023 7.93438 0.999023 8.00014C0.999023 8.06591 1.012 8.13103 1.0372 8.19178C1.06241 8.25252 1.09935 8.3077 1.14592 8.35414L5.14592 12.3541C5.1924 12.4006 5.24759 12.4375 5.30833 12.4627C5.36907 12.4878 5.43417 12.5008 5.49992 12.5008C5.56566 12.5008 5.63076 12.4878 5.6915 12.4627C5.75224 12.4375 5.80743 12.4006 5.85392 12.3541C5.90041 12.3077 5.93728 12.2525 5.96244 12.1917C5.9876 12.131 6.00055 12.0659 6.00055 12.0001C6.00055 11.9344 5.9876 11.8693 5.96244 11.8086C5.93728 11.7478 5.90041 11.6926 5.85392 11.6461L2.70692 8.50014H14.4999C14.6325 8.50014 14.7597 8.44747 14.8535 8.3537C14.9472 8.25993 14.9999 8.13275 14.9999 8.00014Z" fill="white" />
                    </svg>
                  </div>
                  <button
                    type="button"
                    className="pull-right btn btn-secondary"
                    onClick={onAddEntryClose}
                  >

                    Cancel
                  </button>
                  {view
                    && (
                      <button
                        type="button"
                        className="pull-right btn btn-primary"
                        onClick={() => history.push(`/ConditionMasters/Maintenance-Masters/Add-Maintenance-Masters/${editMaintenanceConditions.MaintenanceCondition_ID}`)}
                      >
                        Edit
                      </button>
                    )}

                  {!view
                    && (
                      <button
                        type="submit"
                        className="pull-right btn btn-primary"
                        htmlType="submit"
                        onClick={() => { setFormulaTouched(true); checkValues(form); }}
                        disabled={isLoading}
                      >

                        Save
                      </button>
                    )}

                </div>
                <div className="col-sm-12">

                  {/* -- Code Start for Accordian -- */}
                  <div className={view ? 'panel panel-default alarmRows viewpage' : 'panel panel-default alarmRows'}>
                    <div
                      className={`panel-heading pointer mb-5 ${expanded === 'general' ? 'active' : ''}`}
                      onClick={() => (expanded === 'general' ? setExpanded('') : setExpanded('general'))}
                    >
                      <h4 className="panel-title">
                        <div data-toggle="collapse" data-parent="#accordion1">
                          <span className="row2">Maintenance Details</span>
                        </div>
                      </h4>
                    </div>
                    <div className={`panel-collapse collapse ${expanded === 'general' ? 'in' : ''}`}>
                      <div className="panel-body">
                        <div>
                          <div className="row ">
                            <div className="col-md-3">
                              <div className="form-group">
                                <div className="input-control">
                                  <label className="font10">
                                    {Lang.MachineModel}
                                    <span className="redStar">*</span>
                                  </label>
                                  <div className="input-group">
                                    <div>
                                      <Field
                                        name="MachineModel_ID"
                                        className="form-control"
                                        validate={required}
                                        placeholder="Select"
                                      >
                                        {(props) => (
                                          <Autocomplete
                                            value={(machineModels && machineModels.find((m) => m.MachineModel_ID === props.input.value)) || ''}
                                            getOptionSelected={(option, value) => option.MachineModel_ID === value.MachineModelID}
                                            onChange={(event, value) => {
                                              props.input.onChange(value ? value.MachineModel_ID : '');
                                            }}
                                            onBlur={props.input.onBlur}
                                            options={machineModels}
                                            getOptionLabel={(option) => option.MachineModel_Name || ''}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Please Select Machine Model"
                                                className={`${props.input.value === '' && props.meta.touched
                                                  ? 'error-field'
                                                  : ''
                                                }`}
                                                variant="outlined"
                                              />
                                            )}
                                            disabled={view || disable}
                                          />
                                        )}
                                      </Field>
                                      <OnChange name="MachineModel_ID">
                                        {(value, previous) => {
                                          setModel(value);
                                          if (form.getState().dirty) {
                                            setFormula('');
                                            form.change('MachineSection_ID', '');
                                            form.change('MachineFunction_ID', '');
                                          }
                                        }}
                                      </OnChange>
                                      <span id="machinemodel" className="caret" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <div className="input-control">
                                  <label className="font10">{Lang.MachineSection}</label>
                                  <div className="input-group">
                                    <div>
                                      <Field
                                        name="MachineSection_ID"
                                        className="form-control"
                                      >
                                        {(props) => (
                                          <Autocomplete
                                            value={(machineSections && machineSections.find((m) => m.MachineSection_ID === props.input.value)) || ''}
                                            getOptionSelected={(option, value) => option.MachineSection_ID === value.MachineSectionID}
                                            onChange={(event, value) => {
                                              props.input.onChange(value ? value.MachineSection_ID : '');
                                            }}
                                            onBlur={props.input.onBlur}
                                            options={machineSections}
                                            getOptionLabel={(option) => option.MachineSection_Name || ''}
                                            renderInput={(params) => <TextField {...params} placeholder="Please Select Machine Section" variant="outlined" />}
                                            disabled={view || disable}
                                          />
                                        )}
                                      </Field>
                                      <OnChange name="MachineSection_ID">
                                        {(value, previous) => {
                                          setSection(value);
                                          if (form.getState().dirty) {
                                            setFormula('');
                                            form.change('MachineFunction_ID', '');
                                          }
                                        }}
                                      </OnChange>
                                      <span id="machinemodel" className="caret" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <div className="input-control">
                                  <label className="font10">Machine Component</label>
                                  <div className="input-group">
                                    <div>
                                      <Field
                                        name="MachineFunction_ID"
                                        className="form-control"
                                      >
                                        {(props) => (
                                          <Autocomplete
                                            value={(machineFunctions && machineFunctions.find((m) => m.MachineFunction_ID === props.input.value)) || ''}
                                            getOptionSelected={(option, value) => option.MachineFunction_ID === value.MachineFunctionID}
                                            onChange={(event, value) => {
                                              props.input.onChange(value ? value.MachineFunction_ID : '');
                                            }}
                                            onBlur={props.input.onBlur}
                                            options={machineFunctions}
                                            getOptionLabel={(option) => option.MachineFunction_Name || ''}
                                            renderInput={(params) => <TextField {...params} placeholder="Please Select Machine Component" variant="outlined" />}
                                            disabled={view || disable}
                                          />
                                        )}
                                      </Field>
                                      <OnChange name="MachineFunction_ID">
                                        {(value, previous) => {
                                          setFun(value);
                                          if (form.getState().dirty) {
                                            setFormula('');
                                          }
                                        }}
                                      </OnChange>
                                      <span id="machinemodel" className="caret" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="font12">
                                  Condition Name
                                  <span className="redStar">*</span>
                                </label>
                                <Field
                                  name="Description"
                                  validate={required}
                                  className="form-control"
                                >
                                  {({ input, meta }) => (
                                    <div style={{ display: 'block' }}>
                                      <input
                                        className={`form-control ${meta.error && meta.touched && 'error-field'
                                        }`}
                                        {...input}
                                        type="text"
                                        placeholder="Maintenance Condition"
                                        disabled={view}
                                      />
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group text-area-color">
                                <label className="font12">
                                  Formula
                                  {' '}
                                  <div
                                    className={validFormula ? 'valid-formula' : 'invalid-formula'}
                                  />
                                  <span className="redStar">*</span>
                                  <span className="tooltip_formula">
                                    <Tooltip
                                      placement="right"
                                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                      title={(
                                        <div className="fia-desc cm-fia-desc">
                                          <div className="fia-desc-head">Points to consider when configuring formula:</div>
                                          <br />
                                          <span>1. Type @ and select from a list of parameters relevant</span>
                                          <br />
                                          <span>2. Provide a space between operators and operands</span>
                                          <br />
                                          <span>Eg: Temperature &gt; 35</span>
                                          <br />
                                          <span>3. Nested formula can also be configured. Make sure to provide spaces.</span>
                                          <br />
                                          <span>Eg: (Temperature &lt; 0 or Temperature &gt; 15) and ((Pressure * 0.5) &gt; 20)</span>
                                          <br />
                                          <span>4. Use == instead of = .</span>
                                          <br />
                                          <span>Eg: (Temperature == 100) and ((Pressure * 0.5) == 20)</span>
                                          <br />
                                          <span>5. Product Type Should be either HPMC or GELATIN.</span>
                                          <br />
                                          <span>Eg: Product Type == 'HPMC' </span>
                                          <br />
                                          <span>6. Machine Type Should be either MINIHCM, HCM or HPLUS.</span>
                                          <br />
                                          <span>Eg: Machine Type == 'HPLUS' </span>
                                          <br />
                                          <span>7. Machine Serial No. Should be start with H and Line No eg.H101 </span>
                                          <br />
                                          <span>Eg:Machine Serial No == 'H101' </span>
                                          <br />
                                          {/* eslint react/no-unescaped-entities: 0 */}
                                          <span>8. First Add the Conditions, then Group them.</span>
                                          <br />
                                        </div>
                                      )}
                                      // arrow
                                    >
                                      {/* <Icon name="AlertIcon" /> */}
                                      <svg className="pathInfo0" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="pathInfo1" opacity="0.88" d="M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
                                        <path className="pathInfo2" opacity="0.88" d="M8.00336 10.6667V8.00001" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
                                        <path className="pathInfo3" opacity="0.88" d="M8.00323 5.79167H8.0099" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
                                      </svg>
                                    </Tooltip>
                                  </span>
                                </label>
                                <label className="font14" />
                                <MentionsInput
                                  value={formula}
                                  onChange={onChange}
                                  style={style}
                                  inputRef={inputEl}
                                  a11ySuggestionsListLabel="Suggested mentions"
                                  disabled={view}
                                  onBlur={() => { setFormulaTouched(true); }}
                                  className={` ${(formula === '' || formula === undefined) && formulaTouched ? 'error-field' : ''
                                  }`}
                                  placeholder="Formula"
                                >
                                  <Mention
                                    markup="@{__display__}"
                                    displayTransform={(id, display) => `${display}`}
                                    data={(search, callback) => {
                                      if (search === '') {
                                        return result;
                                      }
                                      return result.filter(
                                        (r) => r.display.toLowerCase().indexOf(search.toLowerCase()) >= 0,
                                      );
                                    }}
                                    style={defaultMentionStyle}
                                    renderSuggestion={(
                                      suggestion,
                                      search,
                                      highlightedDisplay,
                                      index,
                                      focused,
                                    ) => (

                                      <div className={`user ${focused ? 'focused' : ''}`}>
                                        <span>{highlightedDisplay}</span>
                                        {' '}
                                        <div style={{ float: 'right', fontSize: 11, marginTop: 5 }}>
                                          (
                                          {suggestion.subText}
                                          )
                                        </div>
                                      </div>
                                    )}
                                  />
                                </MentionsInput>
                              </div>

                            </div>
                            <div className="sub-grid">
                              <div className="headerTable">
                                <span className="row0"> Machine Model</span>
                                <span className="row1"> Machine Name </span>
                                <span className="row2"> Offset Value </span>
                              </div>

                              {editMaintenanceConditions.MaintenanceOffset
                                && editMaintenanceConditions.MaintenanceOffset.map((item, index) => (

                                  <div className="panel-heading">
                                    <div>
                                      <span />
                                      <span className="row0">{item.Machine_Model}</span>
                                      <span className="row1">{item.Machine_Name}</span>
                                      <span className="row2">
                                        {/* <input type="text" /> */}
                                        <Field
                                          name={`MaintenanceOffset[${index}]OffsetValue`}
                                          className="form-control"
                                        >
                                          {({ input, meta }) => (
                                            <div style={{ display: 'block' }}>
                                              <input
                                                {...input}
                                                type="text"
                                                placeholder="Offset Value"
                                                disabled={view}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                        <Field
                                          name={`MaintenanceOffset[${index}]Machine_ID`}
                                          className="form-control "
                                          type="hidden"
                                        >
                                          {({ input, meta }) => (
                                            <div style={{ display: 'block' }}>
                                              <input
                                                {...input}
                                                placeholder="Machine ID"
                                                disabled={view}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            </div>
                            <div className="col-md-12">
                              <MCRecommendedAction
                                recommendedAction={editMaintenanceConditions.recommendedactions}
                                maintenanceId={editMaintenanceConditions.MaintenanceCondition_ID}
                                onLocalActionsChanged={setRecommendedActions}
                                viewOnly={view}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`panel-heading pointer mb-5 ${expanded === 'notification' ? 'active' : ''}`}
                      onClick={() => (expanded === 'notification' ? setExpanded('') : setExpanded('notification'))}
                    >
                      <h4 className="panel-title">
                        <div data-toggle="collapse" data-parent="#accordion2">
                          <span className="row2">Notification and Alert Settings</span>
                        </div>
                      </h4>
                    </div>
                    <div className={`panel-collapse collapse ${expanded === 'notification' ? 'in' : ''}`}>
                      <div className="panel-body">
                        <div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="col-sm-12 no-gutters">
                                  Message type
                                  {' '}
                                  <span className="redStar">*</span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="radio-button">
                                  <div className="form-group">
                                    <Field
                                      name="Criticality"
                                      component="input"
                                      type="radio"
                                      value="N"
                                      className="form-control"
                                      validate={required}
                                      id="notification-message"
                                      disabled={view}
                                    />
                                    <label htmlFor="notification-message" className="font14opacity">Notification Message</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="radio-button">
                                  <div className="form-group">
                                    <Field
                                      name="Criticality"
                                      component="input"
                                      type="radio"
                                      value="A"
                                      className="form-control"
                                      validate={required}
                                      id="alert-message"
                                      disabled={view}
                                    />
                                    <label htmlFor="alert-message" className="font14opacity">Alert Message</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="form-group">
                                <Field
                                  name="MessageText"
                                  component="input"
                                  // type="text"
                                  // className="form-control customForm input-md"
                                  validate={required}
                                  id="message-text"
                                >
                                  {({ input, meta }) => (
                                    <div style={{ display: 'block' }}>
                                      <input
                                          // className="form-control customForm input-md"
                                        className={`form-control customForm input-md ${meta.error && meta.touched && 'error-field'
                                        }`}
                                        {...input}
                                        type="text"
                                        disabled={view}
                                      />
                                    </div>
                                  )}
                                </Field>

                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="col-sm-12 no-gutters">Communication type</label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="input-control">
                                  <label className="critical-label">
                                    <Field
                                      name="Communication_Email"
                                      component="input"
                                      type="checkbox"
                                      className="form-control"
                                      id="communication-email"
                                      disabled={view}
                                    />
                                    <OnChange name="Communication_Email">
                                      {(value, previous) => {
                                        // setCheck(value);
                                        // { !value && form.mutators.setClearEmail(); }
                                        !value && form.mutators.setClearEmail();
                                      }}
                                    </OnChange>
                                    <span className="icon" />
                                    <span className="label-text">Email</span>
                                  </label>
                                  <Field
                                    name="NotificationTemplate_ID_Email"
                                    // className={"form-control"}
                                    component="select"
                                    placeholder="Select"
                                    allowNull
                                    parse={(value) => (value === '' ? null : value)}
                                  >
                                    {(props) => (
                                      <Autocomplete
                                        value={(notificationTemplates && notificationTemplates.find((m) => m.NotificationTemplate_ID === props.input.value)) || ''}
                                        getOptionSelected={(option, value) => option.NotificationTemplate_ID === value.NotificationTemplateID}
                                        onChange={(event, value) => {
                                          props.input.onChange(value ? value.NotificationTemplate_ID : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={notificationTemplates.filter((n) => n.CommunicationType === 'E')}
                                        getOptionLabel={(option) => option.TemplateName || ''}
                                        renderInput={(params) => <TextField {...params} placeholder="Please Select Notification Template" variant="outlined" />}
                                        disabled={view || (form.getFieldState('Communication_Email') && !form.getFieldState('Communication_Email').value)}
                                        disablePortal
                                        disableCloseOnSelect
                                      />
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="input-control">
                                  <label className="critical-label">
                                    <Field
                                      name="Communication_SMS"
                                      component="input"
                                      type="checkbox"
                                      className="form-control"
                                      id="communication-sms"
                                      disabled={view}
                                    />
                                    <OnChange name="Communication_SMS">
                                      {(value, previous) => {
                                        // setCheck(value);
                                        // { !value && form.mutators.setClearSMS(); }
                                        !value && form.mutators.setClearSMS();
                                      }}
                                    </OnChange>
                                    <span className="icon" />
                                    <span className="label-text">SMS</span>
                                  </label>
                                  <label className="font14" />
                                  <Field
                                    name="NotificationTemplate_ID_SMS"
                                    // className={"form-control"}
                                    component="select"
                                    placeholder="Select"
                                    allowNull
                                    parse={(value) => (value === '' ? null : value)}
                                  >
                                    {(props) => (
                                      <Autocomplete
                                        value={(notificationTemplates && notificationTemplates.find((m) => m.NotificationTemplate_ID === props.input.value)) || ''}
                                        getOptionSelected={(option, value) => option.NotificationTemplate_ID === value.NotificationTemplateID}
                                        onChange={(event, value) => {
                                          props.input.onChange(value ? value.NotificationTemplate_ID : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={notificationTemplates.filter((n) => n.CommunicationType === 'S')}
                                        getOptionLabel={(option) => option.TemplateName || ''}
                                        renderInput={(params) => <TextField {...params} placeholder="Please Select Notification Template" variant="outlined" />}
                                        disabled={view || (form.getFieldState('Communication_SMS') && !form.getFieldState('Communication_SMS').value)}
                                        disablePortal
                                        disableCloseOnSelect
                                      />
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="col-sm-12 no-gutters">Audience</label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label> To</label>
                                <Field
                                  name="To"
                                  className="form-control"
                                  placeholder="Select"
                                >
                                  {(props) => (
                                    <Select
                                      {...props.input}
                                      className={`form-control ${props.input.value === '' ? 'placeholder' : ''
                                      }
                                          ${props.input.value === '' && props.meta.touched
                                        ? 'error-field'
                                        : ''
                                        }`}
                                      multiple
                                      value={props.input.value || []}
                                      onChange={props.input.onChange}
                                      renderValue={getSelected}
                                      disabled={view}
                                      SelectDisplayProps={{ className: 'MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiInputBase-input MuiInput-input multi-select' }}
                                    >
                                      {notificationGroups
                                        && notificationGroups.map((m) => (

                                          <MenuItem key={m.NotificationGroup_ID} value={m.NotificationGroup_ID}>
                                            <ListItemIcon>
                                              <Checkbox checked={props.input.value.indexOf(m.NotificationGroup_ID) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={m.NotificationGroupName} />
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  )}
                                </Field>
                                {/* <label className="critical-label">
                                  <Field
                                    name="IsIncludeCustomer"
                                    component="input"
                                    type="checkbox"
                                    className="form-control"
                                    id="is-include-customer"
                                    disabled={view}
                                  />
                                  <span className="icon" />
                                  <span className="label-text">Include Customer</span>
                                </label> */}
                              </div>

                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label> CC</label>
                                <Field
                                  name="CC"
                                  className="form-control"
                                  placeholder="Select"
                                >
                                  {(props) => (
                                    <Select
                                      {...props.input}
                                      className={`form-control ${props.input.value === '' ? 'placeholder' : ''
                                      }
                                          ${props.input.value === '' && props.meta.touched
                                        ? 'error-field'
                                        : ''
                                        }`}
                                      multiple
                                      value={props.input.value || []}
                                      onChange={props.input.onChange}
                                      renderValue={getSelected}
                                      disabled={view}
                                      SelectDisplayProps={{ className: 'MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiInputBase-input MuiInput-input multi-select' }}
                                    >
                                      {notificationGroups
                                        && notificationGroups.map((m) => (
                                          <MenuItem key={m.NotificationGroup_ID} value={m.NotificationGroup_ID}>
                                            <ListItemIcon>
                                              <Checkbox checked={props.input.value.indexOf(m.NotificationGroup_ID) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={m.NotificationGroupName} />
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        />
      </div>
    </div>
  );
}
