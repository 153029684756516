import DatePicker from 'react-datepicker';
import moment from 'moment';
import './style.css';
import Icon from '../Icon';

const index = ({
  startDate, handleStartDate, endDate, handleEndDate,
}) => (
  <div className="EM__dateRange">
    {console.log('startDate', startDate)}
    {/* <label>Date Range</label> */}
    <div className="EM__dateRangePicker">
      <div className="EM__datepicker">
        <DatePicker
          dateFormat="YYYY/MM/DD"
          value={moment(startDate).format('YYYY/MM/DD')}
          onChange={handleStartDate}
          placeholderText="from"
        />
        <Icon name="CALENDER" />
      </div>
      <div className="EM__datepicker">
        <DatePicker
          dateFormat="YYYY/MM/DD"
          value={moment(endDate).format('YYYY/MM/DD')}
          onChange={handleEndDate}
          placeholderText="to"
        />
        <Icon name="CALENDER" />
      </div>
    </div>
  </div>
);
export default index;
