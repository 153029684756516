import moment from 'moment';
/* eslint no-unused-vars:0 */
import './style.css';
import { useState } from 'react';
import Search from '../Search';

const List = ({ data = [], onCheckChange = () => { } }) => (
  <ul className="EM_options">
    {data.map(({ MachineSerialNo }, index) => (
      <li key={MachineSerialNo + index}>
        <label className="d-flex">
          <input type="checkbox" value={MachineSerialNo} data-key={MachineSerialNo} onChange={onCheckChange} />
          <div className="EM_options_content">
            {MachineSerialNo}
          </div>
        </label>
      </li>
    ))}
  </ul>
);
let LeftListChecked = {};
let RightListChecked = {};
// let RightListJSON = {}
const OriginalTableElement = {};
let ElementsToRemove = [];

const index = ({
  setIsShowPopup, allMachines, tableData,
  setTableData, setAllMachines, DefaultHeaderCol, JSONData, setJSONData,
  setItemsToRemove, itemsToRemove,
}) => {
  const [ListLeft, setListLeft] = useState(allMachines);
  const [ListLeftTemp] = useState(allMachines);
  const [ListRightTemp, setListRightTemp] = useState([]);
  const [ListRight, setListRight] = useState(tableData);
  // const [removeCheckedItems, setRemoveCheckedItems] = useState([]);
  // const [isAdd, setIsAdd] = useState(false);
  const closePopup = () => {
    if (ElementsToRemove.length > 0) {
      setItemsToRemove([...itemsToRemove, ...ElementsToRemove]);
      console.log([...itemsToRemove, ...ElementsToRemove]);
    }
    setIsShowPopup(false);
  };

  const HandleAdd = () => {
    // setIsAdd(true);
    const CheckedItems = [];
    const UnCheckedItem = [];
    for (let i = 0; i < ListLeft.length; i += 1) {
      // console.log('fffffffff', ListRight.length, i);
      const element = ListLeft[i];
      const key = element.MachineSerialNo;
      if (LeftListChecked[key] === true) {
        const elementWithInfo = {
          ...{ SrNo: +ListRight.length + 1 }, ID: null, ...DefaultHeaderCol, ValidFrom: moment().format('YYYY-MM-DD'), ValidTo: moment('2099-12-31').format('YYYY-MM-DD'), ...element,
        };
        if (!OriginalTableElement[element.MachineSerialNo]) {
          elementWithInfo.isNew = true;
        }
        CheckedItems.push(elementWithInfo);
      } else {
        UnCheckedItem.push(element);
      }
    }
    setJSONData(CheckedItems);
    setListRight([...ListRight, ...CheckedItems]);
    setListRightTemp([...ListRight, ...CheckedItems]);
    setListLeft(UnCheckedItem);
    // console.log('ListRight', ListRight);
    // console.log('CheckedItems', CheckedItems);
    setTableData([...ListRight, ...CheckedItems]);
    setAllMachines(UnCheckedItem);
    LeftListChecked = {};
  };
  const HandleRemove = () => {
    // setIsAdd(false);
    const CheckedItems = [];
    const UnCheckedItem = [];
    for (let i = 0; i < ListRight.length; i += 1) {
      const element = ListRight[i];
      const key = element.MachineSerialNo;
      if (RightListChecked[key] === true) {
        CheckedItems.push(element);
      } else {
        UnCheckedItem.push(element);
      }

      if (!element.isNew) {
        OriginalTableElement[element.MachineSerialNo] = true;
      }
    }
    setListLeft([...ListLeft, ...CheckedItems]);
    setListRight(UnCheckedItem);
    // setRemoveCheckedItems(UnCheckedItem);
    setTableData(UnCheckedItem);
    setAllMachines([...ListLeft, ...CheckedItems]);

    ElementsToRemove = CheckedItems;

    // NewTableData = [...ListRight, ...CheckedItems]
    // NewAllMachineData = [...UnCheckedItem]
    RightListChecked = {};
  };
  // const confirmEnergyMeters = () => {
  //   let data = [];
  //   if (isAdd) {
  //     const totalRecords = [...ListRight, ...JSONData];
  //     data = [...new Map(totalRecords.map((m) => [m.MachineSerialNo, m])).values()];
  //   } else {
  //     data = removeCheckedItems;
  //   }
  //   setTableData(data);
  //   // setTableData(removeCheckedItems);
  //   setIsShowPopup(false);
  // };
  const onCheckChange = (e) => {
    const { key } = e.target.dataset;
    if (e.target.checked === true) {
      LeftListChecked[key] = true;
    } else {
      LeftListChecked[key] = false;
    }

    console.log(LeftListChecked);
  };
  const onCheckChangeRight = (e) => {
    const key = e.target.value;
    if (e.target.checked === true) {
      RightListChecked[key] = true;
    } else {
      RightListChecked[key] = false;
    }

    console.log(RightListChecked);
  };
  const searchChange = (e, type) => {
    setListLeft(ListLeftTemp);
    const { value } = e.target;
    if (value && type === 'add') {
      setListLeft((prev) => (
        (prev || []).filter((item) => item.MachineSerialNo.toLowerCase().indexOf(value.toLowerCase()) !== -1)
      ));
    } else if (!value && type === 'add') {
      setListLeft(ListLeftTemp);
      return false;
    } else if (value && type === 'remove') {
      setListRight((prev) => (
        (prev || []).filter((item) => item.MachineSerialNo.toLowerCase().indexOf(value.toLowerCase()) !== -1)
      ));
    } else if (!value && type === 'remove') {
      setListRight(ListRightTemp);
      return false;
    }
  };

  return (
    <>
      <div className="Edit__popup">
        <div className="Edit__popupHead">
          <span className="Edit__popupTitle">Energy Meter Parameters List</span>
          <span onClick={closePopup} className="Edit__close">X</span>
        </div>
        <div className="Edit__popupBody">
          <div className="Edit__popupLeft Edit__popupContent">
            <div className="Edit__popupContentTitle">Parameter List</div>
            <div className="Edit__popupContentData">
              <Search searchChange={searchChange} addList />
              <div className="Edit__popupContentListContainer">
                <List data={ListLeft} onCheckChange={onCheckChange} />
              </div>
            </div>
            <div className="Edit__popupContentActionBtn">
              <button type="button" className="btn-primary" onClick={HandleAdd}>Add</button>
            </div>
          </div>
          <div className="Edit__popupRight Edit__popupContent">
            <div className="Edit__popupContentTitle">Parameter List</div>
            <div className="Edit__popupContentData">
              {/* <Search searchChange={searchChange} addList={false} /> */}
              <div style={{ marginTop: '5.5%' }} />
              <div className="Edit__popupContentListContainer">
                <List data={ListRight} onCheckChange={onCheckChangeRight} />
              </div>
            </div>
            <div className="Edit__popupContentActionBtn">
              <button type="button" className="btn-primary" onClick={HandleRemove}>Remove</button>
            </div>
          </div>
        </div>
        {/* <div className="cnfmBtn">
          <button type="button" className="btn-primary" onClick={confirmEnergyMeters}>Confirm</button>
        </div> */}
        {/* <div className="Edit__popupFooter">
          <button type="button" className="btn-primary Edit__popupSave">Save</button>
        </div> */}
      </div>
      <div className="bg-layer" onClick={closePopup} />
    </>
  );
};
export default index;
