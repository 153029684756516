/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { message } from "antd";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useResizeColumns,
  useFlexLayout,
} from "react-table";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";

import getServoMotorParameter from "../../../API/getServoMotorParameter.js";
import putDeleteServoMotorParameter from "../../../API/putDeleteServoMotorParameter.js";
import Icon from "../../Dashboard/Sidebar/images/Icon.jsx";
import ServoMotorParameterDrawer from "./Drawer/ServoMotorParamerterDrawer.jsx";
import Toast from "../../Reusable/Toast.js";
import ConfirmationModal from "../Modal.jsx";

import styles from "./ServoMotorParameter.module.css";

function ServoMotorParameter() {
  const [data, setData] = useState([]);
  const [editDrawer, setEditDrawer] = useState({
    status: false,
    path: "",
  });
  const [rows, setRow] = useState({});
  const [currPage, setCurrPage] = useState(0);
  const [confirmModal, setConfirmModal] = useState({
    status: false,
    method: "",
  });
  const [item, setItem] = useState(null);

  const handleClose = () => {
    setConfirmModal({ status: false, method: "" });
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Key",
      //   accessor: "ParameterKey",
      // },
      {
        Header: "Name",
        accessor: "ParameterName",
      },
      // {
      //   Header: "Servo Motor Key",
      //   accessor: "ServoMotorKey",
      // },
      {
        Header: "Servo Motor Name",
        accessor: "MOTORNAME",
      },
      {
        Header: "DTagName",
        accessor: "DTagName",
      },
      {
        Header: "UOM",
        accessor: "UOM",
      },
      {
        Header: "DataCategory",
        accessor: "DataCategory",
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "MaxValue",
        accessor: "MaxValue",
      },
      {
        Header: "MinValue",
        accessor: "MinValue",
      },

      {
        Header: "Actions",
        accessor: "Action",
        Cell: ({ row }) => {
          return (
            <div className={styles.tableIcon}>
              <span
                onClick={() => {
                  setRow(row?.original);
                  setEditDrawer({
                    status: true,
                    path: "Edit",
                  });
                }}
                className={styles.edit}
              >
                <Icon name="EditIcon" />
              </span>
              <span
                className={styles.delete}
                size={14}
                onClick={() => {
                  setConfirmModal({ status: true, method: "Delete" });
                  setItem(row?.original);
                }}
              >
                <Icon name="DeleteIcon" />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const deleteData = async () => {
    const res = await putDeleteServoMotorParameter(item);
    if (res?.data?.status != 1) {
      message.error(res?.data?.error?.message);
    }
    message.success(res?.data?.data);
    getServo();
    setConfirmModal({ status: false, method: "" });
  };

  const getServo = async () => {
    let res = await getServoMotorParameter();
    setData(res?.data?.data);
  };

  useEffect(() => {
    getServo();
  }, [editDrawer]);

  const GlobalSearch = ({ preGlobalFilteredRows, setGlobalFilter }) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState("");
    const onChange = useAsyncDebounce((value) => {
      console.log(value);
      setGlobalFilter(value || undefined);
    }, 200);
    return (
      <>
        <input
          type="text"
          className={styles.input}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search Here"
        />
        <BsSearch className={styles.icon} size={12} />
      </>
    );
  };

  const Table = ({ columns, data }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      rows,
      previousPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: 15,
          pageIndex: currPage,
        },
      },
      useFilters,
      useGlobalFilter,
      usePagination,
      useResizeColumns,
      useFlexLayout
    );
    // useEffect(() => {
    //   setCurrPage(pageIndex);
    // }, [pageIndex]);

    return (
      <>
        <div className={styles.headers}>
          <div className={styles.inputBox}>
            <GlobalSearch
              preGlobalFilteredRows={preGlobalFilteredRows}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
          <div
            className={styles.addBtn}
            onClick={() =>
              setEditDrawer({
                status: true,
                path: "Add",
              })
            }
          >
            <div className={styles.plusIconBox}>
              <AiOutlinePlus className={styles.plusIcon} size={15} />
            </div>
            <div className={styles.addBtnText}>Add</div>
          </div>
        </div>
        <div {...getTableProps()} className={styles.table}>
          <div className={styles.headTable}>
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className={styles.tr}>
                {headerGroup.headers.map((column) => (
                  <div {...column.getHeaderProps()} className={styles.th}>
                    {column.render("Header")}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className={styles.tbody}>
            {page.length>0? page.map((row, i) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className={styles.tr}>
                  {row.cells.map((cell) => {
                    return (
                      <div {...cell.getCellProps()} className={styles.td}>
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            }): (
              <div className={styles.tableNo}>No Record Available</div>
            )}
          </div>
        </div>
        <div className={styles.pagination}>
          <div className={styles.results}>Showing results of {rows.length}</div>
          <div className={styles.btn}>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <Icon name="FirstPage" />
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              <Icon name="Prev" />
            </button>
            <div className={styles.pageIndex}>
              {pageIndex + 1} of {pageOptions.length}
            </div>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              <Icon name="Next" />
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <Icon name="LastPage" />
            </button>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <div>
        <Table columns={columns} data={data} />
      </div>
      <ServoMotorParameterDrawer
        setEditDrawer={setEditDrawer}
        editDrawer={editDrawer}
        row={rows}
      />
      <ConfirmationModal
        open={confirmModal}
        handleClose={handleClose}
        handleConfirm={deleteData}
      />
    </div>
  );
}

export default ServoMotorParameter;
