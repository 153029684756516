import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import moment from 'moment';
import axios from 'axios';
// import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { message } from 'antd';
import { Tooltip } from '@material-ui/core';
// import { ReactComponent as Excel } from '../../../images/excel.svg';
// import { ReactComponent as Cross } from '../../../images/x.svg';
// import { ReactComponent as Upload } from '../../../images/UploadIcon.svg';
import CallAPI from '../../../services/seniorManagement.service';
import apiConfig from '../../../config/api.config.json';
import './sm.css';
import { SuccessMessage, ErrorMessage } from '../../../helpers/constants';
import Loader from '../../../common/Loader';
import DynamicSvg from '../../../common/DynamicSvg';
/* eslint no-unused-vars:0 */
function ExcelImport() { // component starts from here
  const [loader, setLoader] = useState(false);
  const [PlantOptions, setPlantOptions] = useState([]);
  const [plantFilter, setPlantFilter] = useState({});
  const [data, setData] = useState([]);
  const getPreviousMonthYear = () => {
    const currentDate = moment();
    return moment(currentDate).subtract('1', 'month').format('MMM-YYYY');
  };
  const getFutureMonthYear = (MonthYear) => {
    const getMonth = moment(MonthYear).format('M');
    if (+getMonth === +moment(getPreviousMonthYear()).format('M')) { // allowing the user to upload file for previous month
      return false;
    }
    return true;
    // if (+currentMonth !== +getMonth) { // allowing the user to upload file for current month
    //   return true;
    // }
  };

  const getData = async (plantKey) => {
    const apiInfo = {
      url: `${apiConfig.GET_SM_DATA}?plantKey=${plantKey}`,
      method: 'GET',
    };
    const result = await CallAPI(apiInfo);
    if (result.status === 200) {
      const response = result?.data?.data;
      setData(response);
    } else {
      setData([]);
    }
  };
  const saveFile = async (month, code, event) => {
    setLoader(true);
    const DateKey = moment(month).format().split('T')[0].split('-').join('');
    const formData = new FormData();
    formData.append('PlantKey', plantFilter.value);
    formData.append('month', month);
    formData.append('DateKey', DateKey);
    formData.append('type', code);
    formData.append('file', event.target.files[0]);
    event.target.value = null;
    const apiInfo = {
      method: 'POST',
      url: apiConfig.SAVE_FILE,
      postData: formData,
      isMultiPart: true,
    };
    const response = await CallAPI(apiInfo);
    if (response.data.status === 200) {
      message.success(SuccessMessage);
      await getData(plantFilter.value);
    } else if ([400, 404].includes(response.data.status)) {
      message.error(response.data.message);
    } else {
      message.error(ErrorMessage);
    }
    setLoader(false);
  };
  const getClassIconName = (FileName, MonthYear) => {
    const FinancialMonth = moment(MonthYear).unix();
    const previousMonth = moment(getPreviousMonthYear()).unix();
    // const currentMonthYear = moment().format('MMM-YYYY');
    // const currentMonth = moment().format('M');
    /* eslint no-else-return:0 */
    if (!FileName && previousMonth > FinancialMonth) { // temporary diable
      return { classname: 'danger', icon: <DynamicSvg color="#e12e25" type="notupload" /> };
    }

    // if (!FileName && previousMonth > FinancialMonth) { // temporary enable
    //   return { classname: 'ntuplod', icon: <DynamicSvg color="#878787" type="upload" /> };
    // }

    if (MonthYear === getPreviousMonthYear()) {
      return { classname: 'orange', icon: <DynamicSvg color="#f3a70ef0" type="upload" /> };
    }
    // if (FileName && MonthYear === getPreviousMonthYear()) {
    //   return { classname: 'orange', icon: <DynamicSvg color="#f3a70ef0" type="download" /> };
    // }
    // if (FileName && MonthYear === currentMonthYear) {
    //   return { classname: 'ntuplod', icon: <DynamicSvg color="#878787" type="upload" /> };
    // }
    if (FileName) {
      return { classname: 'uploaded', icon: <DynamicSvg color="#52b56d" type="download" /> };
    }
    if (!FileName) {
      return { classname: 'ntuplod', icon: <DynamicSvg color="#878787" type="upload" /> };
    }
  };

  const getPlants = async () => {
    const apiInfo = {
      url: `${apiConfig.GET_PLANTS}`,
      method: 'GET',
      isMultiPart: false,
    };
    const result = await CallAPI(apiInfo);
    if (result.status === 200) {
      const response = result?.data?.data;
      setPlantOptions(response);
      setPlantFilter(response[0]);
      await getData(response[0].value);
    } else {
      setPlantOptions([]);
    }
  };

  useEffect(() => {
    getPlants();
  }, []);

  const DownloadFile = async (item) => {
    const fileName = `${plantFilter.label}-${item.Description}-${moment(item.FullDate).format('MMM-YYYY')}`;
    axios
      .get(`${apiConfig.SM_DOWNLOAD_FILE}?filename=${item.FileName}`, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        return 'Downloaded';
      }).catch((e) => {
        const fr = new FileReader();
        fr.onload = (event) => {
          console.log(JSON.parse(event.target.result));
        };
        fr.readAsText(e.response.data);
        message.error('Unable to download.');
      });
  };
  const handleChangePlant = async (event) => {
    await getData(event.value);
    setPlantFilter(event);
  };
  // const getIcons = (monthYear) => {
  //   const todayDate = moment(getPreviousMonthYear()).unix();
  //   const date = moment(monthYear).unix();
  //   // return date >= todayDate ? <DynamicSvg color="#878787" type="upload" /> : <DynamicSvg color="#e12e25" type="notupload" />;
  //   return date >= todayDate ? <FileUploadOutlinedIcon /> : <Cross />;
  // };
  // const isDownloadAvailable = (MonthYear, FileName) => {
  //   const monthYear = moment(MonthYear).format('MMM-YYYY');
  //   const currentMonthYear = moment().format('MMM-YYYY');
  //   const previousMonth = getPreviousMonthYear();

  //   if (FileName && ![previousMonth, currentMonthYear].includes(monthYear)) {
  //     return true;
  //   }
  //   return false;
  // };
  return (
    <>
      <div className="sm_container">
        <div className="tp_blk">
          <div>
            <label htmlFor="plantKey"> Plants: </label>
            <div>
              <Select
                className="dropdown_rsd"
                name="plant"
                onChange={(e) => handleChangePlant(e)}
                options={PlantOptions}
                value={plantFilter}
                noOptionsMessage={() => 'Select Proper Plant'}
              />
            </div>
          </div>
        </div>
        <table className="SmTbl">
          <tbody>
            {
                    (data || []).map((item, index) => (
                      <tr key={index}>
                        <td>
                          {moment(item.MonthYear).format('MMM-YYYY')}
                        </td>
                        {
                          (item.data || []).map((item1) => {
                            const { classname, icon } = getClassIconName(item1.FileName, moment(item.MonthYear).format('MMM-YYYY'));
                            const monthYear = moment(item.MonthYear).format('MMM-YYYY');
                            // const currentMonthYear = moment().format('MMM-YYYY');
                            const previousMonthYear = getPreviousMonthYear();
                            return (
                              <td className={classname}>
                                { (item1.FileName && ![previousMonthYear].includes(monthYear))
                                  ? (
                                    <Tooltip title={moment(item1.UploadedOn).format('YYYY-MM-DD HH:mm:ss')} arrow sx={{ m: 1, p: 2, fontSize: '14px' }}>
                                      <p onClick={() => DownloadFile(item1)}>
                                        <span>{icon}</span>
                                        <span>{item1.Description}</span>
                                      </p>
                                    </Tooltip>
                                  )
                                  : (
                                    <p>
                                      <span>{icon}</span>
                                      <span>{item1.Description}</span>
                                      <input
                                        onChange={(event) => saveFile(item.MonthYear, item1.ParentCode, event)}
                                        type="file"
                                        placeholder="Select File"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        disabled={getFutureMonthYear(item.MonthYear)}
                                        title=""
                                      />
                                    </p>
                                  )}
                              </td>
                            );
                          })
                        }
                      </tr>
                    ))
                }
          </tbody>
        </table>
        <Loader loadStatus={loader} />
      </div>
    </>
  );
}
export default ExcelImport;
