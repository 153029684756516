/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form as FormikForm } from "formik";
import { Button, Spin } from "antd";
import putEditServoMotor from "../../../../API/putEditServoMotor.js";
import postAddEditServoMotor from "../../../../API/postAddServoMotor.js";
import Toast from "../../../Reusable/Toast.js";
import ConfirmationModal from "../../Modal.jsx";
import "../../../../styles/failureMode.css";
import { message } from "antd";

const validateAdd = Yup.object().shape({
  ServoMotorCodeName: Yup.string()
    .trim()
    .required("Please enter Servo Motor Code Name"),
  ServoMotorName: Yup.string().trim().required("Please enter name"),
});

const ServoMotorForm = ({ setEditDrawer, editDrawer, row }) => {
  const [spinning, setSpinning] = useState(true);
  const [confirmModal, setConfirmModal] = useState({
    staus: false,
    method: "",
  });
  const [values, setValues] = useState(null);

  const handleClose = () => {
    setConfirmModal({ staus: false, method: "" });
  };

  const initialValues = {
    ServoMotorCodeName:
      editDrawer.path == "Edit" ? row?.ServoMotorCodeName : "",
    ServoMotorName: editDrawer.path == "Edit" ? row?.ServoMotorName : "",
  };

  const editServoMotor = async () => {
    const res = await putEditServoMotor(values);
    if (res?.data?.status == 1) {
      message.success(res?.data?.data);
      setEditDrawer({
        status: false,
        path: "success",
      });
      return;
    }
    message.error(res?.data?.error?.message);
    handleClose();
  };
  const addServoMotor = async (e) => {
    const res = await postAddEditServoMotor(e);
    if (res?.data?.status == 1) {
      message.success(res?.data?.data);
      setEditDrawer({
        status: false,
        path: "success",
      });

      return;
    }
    message.error(res?.data?.error?.message);
  };

  const handleSubmitEvent = (values) => {
    setValues(values);

    if (editDrawer.path == "Edit") {
      setConfirmModal({ status: true, method: "Edit" });
    }
    if (editDrawer.path == "Add") {
      setConfirmModal({ status: true, method: "Add" });
    }
  };

  useEffect(() => {
    setSpinning(false);
  }, [spinning]);

  return (
    <Spin spinning={spinning}>
      <Formik
        initialValues={initialValues}
        validationSchema={validateAdd}
        onSubmit={handleSubmitEvent}
      >
        {({ values, errors, handleChange, touched }) => {
          return (
            <FormikForm id="failure-mode-form" className=" fm">
              <div className="form-group">
                <label className="font10">
                  Servo Moto Code Name
                  <span className="redStar">*</span>
                </label>
                <div style={{ display: "block" }}>
                  <input
                    name="ServoMotorCodeName"
                    className={`form-control customForm input-md ${
                      errors.ServoMotorCodeName &&
                      touched.ServoMotorCodeName &&
                      "error-field"
                    }`}
                    id="code"
                    placeholder="Enter Servo Motor Code Name"
                    type="code"
                    value={values.ServoMotorCodeName}
                    onChange={handleChange}
                    disabled={editDrawer.path == "Edit"}
                  />
                  {errors.ServoMotorCodeName && touched.ServoMotorCodeName && (
                    <span
                      className="error-field"
                      style={{ color: "red", marginTop: ".5rem" }}
                    >
                      {errors.ServoMotorCodeName}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label className="font10">
                  Servo Motor Name
                  <span className="redStar">*</span>
                </label>
                <div style={{ display: "block" }}>
                  <input
                    name="ServoMotorName"
                    className={`form-control customForm input-md ${
                      errors.ServoMotorName &&
                      touched.ServoMotorName &&
                      "error-field"
                    }`}
                    id="name"
                    placeholder="Enter Servo Motor Name"
                    type="text"
                    value={values.ServoMotorName}
                    onChange={handleChange}
                  />
                  {errors.ServoMotorName && touched.ServoMotorName && (
                    <span
                      className="error-field"
                      style={{ color: "red", marginTop: ".5rem" }}
                    >
                      {errors.ServoMotorName}
                    </span>
                  )}
                </div>
              </div>

              <div className="pull-right">
                <Button
                  type="button"
                  style={{ marginRight: "8px" }}
                  onClick={() => setEditDrawer(false)}
                >
                  Cancel
                </Button>
                <Button type="primary" trigger="click" htmlType="submit">
                  Save
                </Button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
      <ConfirmationModal
        open={confirmModal}
        handleClose={handleClose}
        handleConfirm={confirmModal == "Add" ? addServoMotor : editServoMotor}
      />
    </Spin>
  );
};
export default ServoMotorForm;
