import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config/api.config.json';
import authHeader from '../../config/auth.header';
/* eslint  no-alert:0 */

function PredictModel() {
  const [TableData, setResult] = useState([]);
  const [isreceived, setReceived] = useState(false);
  //  const [iserror, setError] = useState(false);
  // get table heading data
  const ThData = () => {
    // get table column
    const column = Object.keys(TableData[0]);
    return column.map((data) => {
      /* eslint no-param-reassign: 0 */
      data = data === 'PredictionResult' ? 'Prediction' : data;
      return (
        <th key={data} className={`${data}`}>
          {data}
        </th>
      );
    });
  };

  function convertToDate(timestamp) {
    const date = new Date(timestamp);
    const result = `${date.getDate()
    }/${
      date.getMonth() + 1
    }/${
      date.getFullYear()
    } ${
      date.getHours()
    }:${
      date.getMinutes()
    }:${
      date.getSeconds()}`;

    return result;
  }

  // get table row data
  const tdData = () => {
    const column = Object.keys(TableData[0]);
    return TableData.map((data, index) => (
      <tr key={index}>
        {column.map((v, index1) => {
          const val = v === 'Timestamp' ? convertToDate(data[v]) : data[v];
          return <td key={index1} className={`${v}`}>{val}</td>;
        })}
      </tr>
    ));
  };
  const getData = () => {
    const headers = authHeader();
    const payload = {
      method: 'get',
      url: config.API_IOMATRIX_GETPREDECTION,
      data: {},
      headers,
    };
    axios(payload)
      .then((response) => {
        setResult(response.data.res);
        setReceived(true);
      })
      .catch((error) => { console.log(error); alert('Problem in Data loading'); });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {isreceived === true ? (
        <table className="prediction_table">
          <thead>
            <tr>{ThData()}</tr>
          </thead>
          <tbody>{tdData()}</tbody>
        </table>
      ) : (
        <div>Please wait Data loading is in Progress ....</div>
      )}
    </div>
  );
}
export default PredictModel;
