// import axios from 'axios';
// import { user_id, API_Url } from "../../configs/config";
import { userId, APIUrl } from '../../../../helpers/constants';
// A mock function to mimic making an async request for data
// import authHeader from '../../../../config/auth.header';
import CallAPI from '../../../../services/conditionMonitoring.service';
// const headers = authHeader();

export function fetchMachineParameters(modelID, sectionID, functionID) {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineParameters?machinemodelID=${modelID}&machinesectionID=${sectionID}&machinefunctionID=${functionID}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMachineParameters?machinemodelID=${modelID}&machinesectionID=${sectionID}&machinefunctionID=${functionID}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function saveCausualParameter(causualParameter) {
  causualParameter.CreatedUser_ID = userId;
  causualParameter.Indicator = '';

  const toLower = causualParameter?.CausalParameterDSeries.toLowerCase();
  if (toLower.indexOf('gelatin') !== -1) {
    causualParameter.CausalParameterDSeries = causualParameter?.CausalParameterDSeries.replace(/gelatin/gi, 'GELATIN');
    causualParameter.IndirectFormula = causualParameter?.IndirectFormula.replace(/gelatin/gi, 'GELATIN');
  }
  if (causualParameter?.CausalParameterDSeries.toLowerCase().indexOf('hpmc') !== -1) {
    causualParameter.CausalParameterDSeries = causualParameter?.CausalParameterDSeries.replace(/hpmc/gi, 'HPMC');
    causualParameter.IndirectFormula = causualParameter?.IndirectFormula.replace(/hpmc/gi, 'HPMC');
  }

  const apiInfo = {
    url: `${APIUrl}/api/saveCausualParameter`,
    method: 'POST',
    postData: causualParameter,
    CreatedUserID: true,
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // causualParameter.PotentialCause_ID = 1;
  // return axios
  //   .post(`${APIUrl}/api/saveCausualParameter`, causualParameter, { headers })
  //   .then((res) => res.data);
}
