/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { message } from 'antd';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
// import { convertTimeHH_MMFormat, convertTimeDiff } from "../../../../services/TimeConversion.service";
// import {defaultStyle, defaultMentionStyle} from '../../../styles/mentions';
// import { defaultStyle } from '../mentions';
import '../Components/AccordianControl.css';
import '../../../styles/dashboard.css';

import {
  fetchMachineModelAsync, fetchFailureModesForModelAsync, editFailureNotificationAsync,
  fetchNotificationGroupAsync, fetchNotificationTemplateAsync, saveFailureNotificationAsync,
  clearSaveRecord, clearEdit,
} from '../../../redux/slices/failureNotificationAddSlice';
/* eslint no-unused-expressions:0 */
/* eslint max-len:0 */
/* eslint no-shadow:0 */

// const style = defaultStyle;
// const inputEl = React.createRef();

const validate = () => { };

export default function AddFailureNotification({ view }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { option } = useParams();
  const [expanded, setExpanded] = useState('general');
  const [model, setModel] = useState('');
  //  const [mode, setMode] = useState('');
  // var dispatch = useDispatch();
  const recordSaved = useSelector((state) => state.failureNotificationAdd.recordSaved);
  useEffect(() => {
    if (recordSaved) {
      dispatch(clearSaveRecord());
      history.push('/FMEA/Failure-Conditions');
    }
  }, [recordSaved]);

  const onAddEntryClose = () => {
    dispatch(clearEdit());
    history.push('/FMEA/Failure-Conditions');
  };

  useEffect(() => {
    dispatch(fetchMachineModelAsync());
  }, []);

  useEffect(() => {
    if (option) {
      dispatch(editFailureNotificationAsync({ id: option }));
    }
  }, [option]);

  const editFailureNotifications = useSelector(
    (state) => state.failureNotificationAdd.editFailureNotifications,
  );

  const machineModels = useSelector(
    (state) => state.failureNotificationAdd.machineModels,
  );

  useEffect(() => {
    if (model) {
      dispatch(fetchFailureModesForModelAsync({ id: model }));
    }
  }, [model]);

  const failureModes = useSelector(
    (state) => state.failureNotificationAdd.failureModes,
  );

  const isLoading = useSelector(
    (state) => state.failureNotificationAdd.failureNotificationStatus === 'loading',
  );

  useEffect(() => {
    dispatch(fetchNotificationGroupAsync());
  }, []);

  const notificationGroups = useSelector(
    (state) => state.failureNotificationAdd.notificationGroups,
  );

  useEffect(() => {
    dispatch(fetchNotificationTemplateAsync());
  }, []);

  const notificationTemplates = useSelector(
    (state) => state.failureNotificationAdd.notificationTemplates,
  );

  const required = (value) => (value ? undefined : 'Required');

  const disable = editFailureNotifications && editFailureNotifications.FailureNotification_ID;

  const onSubmit = async (values) => {
    /* eslint camelcase: 0 */
    const {
      Communication_Email, NotificationTemplate_ID_Email, Communication_SMS, NotificationTemplate_ID_SMS,
    } = values;
    if (!Communication_Email && !Communication_SMS) {
      message.error('Please choose atleast one communication type for alerts');
      return false;
    }
    if (Communication_Email && !NotificationTemplate_ID_Email) {
      message.error('Please choose Email template');
      return false;
    }

    if (Communication_SMS && !NotificationTemplate_ID_SMS) {
      message.error('Please choose SMS template');
      return false;
    }
    dispatch(saveFailureNotificationAsync({ ...values }));
  };

  const getSelected = (ids) => {
    const selected = notificationGroups.filter((n) => ids.find((id) => n.NotificationGroup_ID === id));
    return selected.map((s) => s.NotificationGroupName).join(', ');
  };
  const checkValues = (form) => {
    const { errors } = form.getState();
    let isError = false;
    /* eslint guard-for-in: 0 */
    for (const item in errors) {
      console.log('--->', errors[item]);
      if (errors[item] === 'Required') {
        isError = true;
      }
    }
    if (isError) {
      message.error('Please enter required fields');
    }
  };
  return (
    <div className="wrapper cm-wrapper">
      <div className="contentWrap conditionMonitoring monitoringcontentWrap Addfailurenotification monitoringPage">
        <Form
          mutators={{
            setClearEmail: (args, state, utils) => {
              utils.changeValue(state, 'NotificationTemplate_ID_Email', () => undefined);
            },
            setClearSMS: (args, state, utils) => {
              utils.changeValue(state, 'NotificationTemplate_ID_SMS', () => undefined);
            },

          }}
          onSubmit={onSubmit}
          validate={validate}
          initialValues={editFailureNotifications}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form }) => (
            <div className="row slick-carousel slickcarouselTop">
              <form onSubmit={handleSubmit} id="failure-mode-form">

                <div className="grid-header col-sm-12 mb-15 ">
                  <div className="back arrow" onClick={(e) => { e.preventDefault(); onAddEntryClose(); }}>
                    <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.9999 8.00014C14.9999 7.86753 14.9472 7.74036 14.8535 7.64659C14.7597 7.55282 14.6325 7.50014 14.4999 7.50014L2.70692 7.50014L5.85392 4.35414C5.90041 4.30766 5.93728 4.25247 5.96244 4.19173C5.9876 4.13099 6.00055 4.06589 6.00055 4.00014C6.00055 3.9344 5.9876 3.8693 5.96244 3.80856C5.93728 3.74782 5.90041 3.69263 5.85392 3.64614C5.80743 3.59966 5.75224 3.56278 5.6915 3.53762C5.63076 3.51246 5.56566 3.49951 5.49992 3.49951C5.43417 3.49951 5.36907 3.51246 5.30833 3.53762C5.24759 3.56278 5.1924 3.59966 5.14592 3.64614L1.14592 7.64614C1.09935 7.69259 1.06241 7.74776 1.0372 7.80851C1.012 7.86925 0.999023 7.93438 0.999023 8.00014C0.999023 8.06591 1.012 8.13103 1.0372 8.19178C1.06241 8.25252 1.09935 8.3077 1.14592 8.35414L5.14592 12.3541C5.1924 12.4006 5.24759 12.4375 5.30833 12.4627C5.36907 12.4878 5.43417 12.5008 5.49992 12.5008C5.56566 12.5008 5.63076 12.4878 5.6915 12.4627C5.75224 12.4375 5.80743 12.4006 5.85392 12.3541C5.90041 12.3077 5.93728 12.2525 5.96244 12.1917C5.9876 12.131 6.00055 12.0659 6.00055 12.0001C6.00055 11.9344 5.9876 11.8693 5.96244 11.8086C5.93728 11.7478 5.90041 11.6926 5.85392 11.6461L2.70692 8.50014H14.4999C14.6325 8.50014 14.7597 8.44747 14.8535 8.3537C14.9472 8.25993 14.9999 8.13275 14.9999 8.00014Z" fill="white" />
                    </svg>
                  </div>
                  <button
                    type="button"
                    className="pull-right btn btn-secondary"
                    onClick={onAddEntryClose}
                  >

                    Cancel
                  </button>
                  {view
                    && (
                      <button
                        type="button"
                        className="pull-right btn btn-primary"
                        onClick={() => history.push(`/ConditionMasters/add-failure-notification/${editFailureNotifications.FailureNotification_ID}`)}
                      >
                        Edit
                      </button>
                    )}
                  {!view
                    && (
                      <button type="submit" onClick={() => checkValues(form)} className="pull-right btn btn-primary" htmlType="submit" disabled={isLoading}>
                        Save
                      </button>
                    )}

                </div>
                <div className="col-sm-12">
                  {/* -- Code Start for Accordian -- */}
                  <div className={view ? 'panel panel-default alarmRows viewpage' : 'panel panel-default alarmRows'}>

                    <div
                      className={`panel-heading pointer mb-5 ${expanded === 'general' ? 'active' : ''}`}
                      onClick={() => (expanded === 'general' ? setExpanded('') : setExpanded('general'))}
                    >
                      <h4 className="panel-title">
                        <div data-toggle="collapse" data-parent="#accordion2">
                          <span className="row2">Notification and Alert Settings</span>
                        </div>
                      </h4>
                    </div>
                    <div className={`panel-collapse collapse ${expanded === 'general' ? 'in' : ''}`}>
                      <div className="panel-body">
                        <div>
                          <div className="row ">
                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="input-control">
                                  <label className="font10">
                                    Machine Model
                                    <span className="redStar">*</span>
                                  </label>
                                  <div className="input-group">

                                    <div>

                                      <Field
                                        name="MachineModel_ID"
                                        className="form-control"
                                        validate={required}
                                        placeholder="Select"
                                      >
                                        {(props) => (

                                          <Autocomplete
                                            value={(machineModels && machineModels.find((m) => m.MachineModel_ID === props.input.value)) || ''}
                                            getOptionSelected={(option, value) => option.MachineModel_ID === value.MachineModelID}
                                            onChange={(event, value) => {
                                              props.input.onChange(value ? value.MachineModel_ID : '');
                                            }}
                                            onBlur={props.input.onBlur}
                                            options={machineModels}
                                            getOptionLabel={(option) => option.MachineModel_Name || ''}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Please Select Machine Model"
                                                className={`${props.input.value === '' && props.meta.touched
                                                  ? 'error-field'
                                                  : ''
                                                }`}
                                                variant="outlined"
                                              />
                                            )}
                                            disabled={view || disable}
                                            disablePortal
                                          />
                                        )}
                                      </Field>
                                      <OnChange name="MachineModel_ID">
                                        {(value, previous) => {
                                          setModel(value);
                                          if (previous) {
                                            form.change('FailureMode_ID', undefined);
                                          }
                                        }}
                                      </OnChange>
                                      <span id="machinemodel" className="caret" />
                                    </div>

                                  </div>
                                </div>

                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="input-control">
                                  <label className="font10">
                                    Failure Mode
                                    <span className="redStar">*</span>
                                  </label>
                                  <div className="input-group">

                                    <div>

                                      <Field
                                        name="FailureMode_ID"
                                        className="form-control"
                                        validate={required}
                                      >
                                        {(props) => (

                                          <Autocomplete
                                            value={(failureModes && failureModes.find((m) => m.FailureMode_ID === props.input.value)) || ''}
                                            getOptionSelected={(option, value) => option.FailureMode_ID === value.FailureModeID}
                                            onChange={(event, value) => {
                                              props.input.onChange(value ? value.FailureMode_ID : '');
                                            }}
                                            onBlur={props.input.onBlur}
                                            options={failureModes}
                                            getOptionLabel={(option) => option.Description || ''}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Please Select Failure Mode"
                                                className={`${props.input.value === '' && props.meta.touched
                                                  ? 'error-field'
                                                  : ''
                                                }`}
                                                variant="outlined"
                                              />
                                            )}
                                            disabled={view || disable}
                                            disablePortal
                                          />
                                        )}
                                      </Field>
                                      {/* <OnChange name="FailureMode_ID">
                                                                                {(value, previous) => {
                                                                                    setMode(value);
                                                                                }}
                                                                            </OnChange> */}
                                      <span id="machinemodel" className="caret" />
                                    </div>

                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="col-sm-12 no-gutters">
                                  Message type
                                  {' '}
                                  <span className="redStar">*</span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">

                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="radio-button">
                                  <div className="form-group">
                                    <Field
                                      name="Criticality"
                                      component="input"
                                      type="radio"
                                      value="N"
                                      className="form-control"
                                      validate={required}
                                      id="notification-message"
                                      disabled={view}
                                    />
                                    <label htmlFor="notification-message" className="font14opacity">Notification Message</label>

                                  </div>
                                </div>

                              </div>

                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="radio-button">
                                  <div className="form-group">
                                    <Field
                                      name="Criticality"
                                      component="input"
                                      type="radio"
                                      value="A"
                                      className="form-control"
                                      validate={required}
                                      id="alert-message"
                                      disabled={view}
                                    />
                                    <label htmlFor="alert-message" className="font14opacity">Alert Message</label>

                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="form-group">
                                <Field
                                  name="MessageText"
                                  component="input"
                                  // type="text"
                                  // className="form-control customForm input-md"
                                  validate={required}
                                  id="message-text"
                                >
                                  {({ input, meta }) => (
                                    <div style={{ display: 'block' }}>
                                      <input
                                          // className="form-control customForm input-md"
                                        className={`form-control customForm input-md ${meta.error && meta.touched && 'error-field'
                                        }`}
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...input}
                                        type="text"
                                        disabled={view}
                                      />
                                    </div>
                                  )}
                                </Field>

                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="col-sm-12 no-gutters">Communication type</label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="input-control">
                                  <label className="critical-label">

                                    <Field
                                      name="Communication_Email"
                                      component="input"
                                      type="checkbox"
                                      className="form-control"
                                      id="communication-email"
                                      disabled={view}
                                    />
                                    <OnChange name="Communication_Email">
                                      {/* {(value, previous) => { */}
                                      {(value) => {
                                        // setCheck(value);
                                        // { !value && form.mutators.setClearEmail(); }
                                        !value && form.mutators.setClearEmail();
                                      }}
                                    </OnChange>
                                    <span className="icon" />
                                    <span className="label-text">Email</span>
                                  </label>

                                  <label className="font14" />

                                  <Field
                                    name="NotificationTemplate_ID_Email"
                                    // className={"form-control"}
                                    component="select"
                                    placeholder="Select"
                                    allowNull
                                    parse={(value) => (value === '' ? null : value)}
                                  >
                                    {(props) => (

                                      <Autocomplete
                                        value={(notificationTemplates && notificationTemplates.find((m) => m.NotificationTemplate_ID === props.input.value)) || ''}
                                        getOptionSelected={(option, value) => option.NotificationTemplate_ID === value.NotificationTemplateID}
                                        onChange={(event, value) => {
                                          props.input.onChange(value ? value.NotificationTemplate_ID : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={notificationTemplates.filter((n) => n.CommunicationType === 'E')}
                                        getOptionLabel={(option) => option.TemplateName || ''}
                                        renderInput={(params) => <TextField {...params} placeholder="Please Select Notification Template" variant="outlined" />}
                                        disabled={view || (form.getFieldState('Communication_Email') && !form.getFieldState('Communication_Email').value)}
                                        disablePortal
                                        disableCloseOnSelect
                                      />
                                    )}
                                  </Field>

                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="input-control">
                                  <label className="critical-label">

                                    <Field
                                      name="Communication_SMS"
                                      component="input"
                                      type="checkbox"
                                      className="form-control"
                                      id="communication-sms"
                                      disabled={view}
                                    />
                                    <OnChange name="Communication_SMS">
                                      {/* {(value, previous) => { */}
                                      {(value) => {
                                        // setCheck(value);
                                        // {!value && form.mutators.setClearSMS();}
                                        !value && form.mutators.setClearSMS();
                                      }}
                                    </OnChange>
                                    <span className="icon" />
                                    <span className="label-text">SMS</span>
                                  </label>

                                  <label className="font14" />

                                  <Field
                                    name="NotificationTemplate_ID_SMS"
                                    // className={"form-control"}
                                    component="select"
                                    placeholder="Select"
                                    allowNull
                                    parse={(value) => (value === '' ? null : value)}
                                  >
                                    {(props) => (

                                      <Autocomplete
                                        value={(notificationTemplates && notificationTemplates.find((m) => m.NotificationTemplate_ID === props.input.value)) || ''}
                                        getOptionSelected={(option, value) => option.NotificationTemplate_ID === value.NotificationTemplateID}
                                        onChange={(event, value) => {
                                          props.input.onChange(value ? value.NotificationTemplate_ID : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={notificationTemplates.filter((n) => n.CommunicationType === 'S')}
                                        getOptionLabel={(option) => option.TemplateName || ''}
                                        renderInput={(params) => <TextField {...params} placeholder="Please Select Notification Template" variant="outlined" />}
                                        disabled={view || (form.getFieldState('Communication_SMS') && !form.getFieldState('Communication_SMS').value)}
                                        disablePortal
                                        disableCloseOnSelect
                                      />
                                    )}
                                  </Field>

                                </div>

                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="col-sm-12 no-gutters">Audience</label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label> To</label>

                                <label className="font14" />

                                <Field
                                  name="To"
                                  className="form-control"
                                  placeholder="Select"
                                >
                                  {(props) => (
                                    <Select
                                      {...props.input}
                                      className={`form-control ${props.input.value === '' ? 'placeholder' : ''
                                      }
                                      ${props.input.value === '' && props.meta.touched
                                        ? 'error-field'
                                        : ''}`}
                                      multiple
                                      value={props.input.value || []}
                                      onChange={props.input.onChange}
                                      renderValue={getSelected}
                                      disabled={view}
                                      SelectDisplayProps={{ className: 'MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiInputBase-input MuiInput-input multi-select' }}
                                    >
                                      {notificationGroups
                                        && notificationGroups.map((m) => (

                                          <MenuItem key={m.NotificationGroup_ID} value={m.NotificationGroup_ID}>
                                            <ListItemIcon>
                                              <Checkbox checked={props.input.value.indexOf(m.NotificationGroup_ID) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={m.NotificationGroupName} />
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  )}
                                </Field>

                                {/* <label className="critical-label">
                                  <Field
                                    name="IsIncludeCustomer"
                                    component="input"
                                    type="checkbox"
                                    className="form-control"
                                    id="is-include-customer"
                                    disabled={view}
                                  />
                                  <span className="icon" />
                                  <span className="label-text">Include Customer</span>
                                </label> */}
                              </div>

                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label> CC</label>

                                <Field
                                  name="CC"
                                  className="form-control"
                                  // component={Select}
                                  // validate={required}
                                  placeholder="Select"
                                >
                                  {(props) => (
                                    <Select
                                      {...props.input}
                                      className={`form-control ${props.input.value === '' ? 'placeholder' : ''
                                      }
                                        ${props.input.value === '' && props.meta.touched
                                        ? 'error-field'
                                        : ''
                                        }`}
                                      multiple
                                      value={props.input.value || []}
                                      onChange={props.input.onChange}
                                      renderValue={getSelected}
                                      disabled={view}
                                      SelectDisplayProps={{ className: 'MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiInputBase-input MuiInput-input multi-select' }}
                                    >

                                      {notificationGroups
                                        && notificationGroups.map((m) => (

                                          <MenuItem key={m.NotificationGroup_ID} value={m.NotificationGroup_ID}>
                                            <ListItemIcon>
                                              <Checkbox checked={props.input.value.indexOf(m.NotificationGroup_ID) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={m.NotificationGroupName} />
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  )}
                                </Field>

                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>

          )}
        />

      </div>
    </div>
  );
}
