import { useState } from 'react';
import PowerBIReport from '../PowerBI';
import Popup from './popup/ECRPopup';

const ECR = ({ data }) => {
  const [open, setOpen] = useState(false);
  const showPopup = () => {
    setOpen(true);
  };
  return (
    <>
      <div className="RSD_report_container">
        <div className="add_entry_btn">
          <button type="button" className="btn-primary" onClick={showPopup}>Add Entry</button>
        </div>
        <PowerBIReport data={data} />
      </div>
      {open === true
        ? <Popup open={open} setOpen={setOpen} reportInfo={data} />
        : ''}
    </>
  );
};

export default ECR;
