/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useRef,
} from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Dialog } from '@material-ui/core';
import { message } from 'antd';
import { uploadFailureModeFileAsync } from '../../../../../redux/slices/ImportFMEAListingSlice';
import style from '../../../NotificationTemplate/edit-notification-template.module.css';
import { ReactComponent as OpenFileIcon } from '../../../../../images/openfileIcon.svg';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog/ConfirmationDialog';
// import Icon from '../../../../Dashboard/Sidebar/images/Icon';
/* eslint no-unused-vars:0 */
const Addimportfmea = ({ onSaved, onClosed }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [selectedfile, setSelectedfile] = useState([]);
  const [model, setModel] = useState('');
  const [machineID, setMachineID] = useState('');
  const [errorFields, setErrorFields] = useState([]);
  const [showConfirmation, setConfirmation] = useState({});
  const [isProcessing, setIsProcessing] = useState();

  const inputRef = useRef();

  // const history = useHistory();
  async function onUploadBtnClicked() {
    setIsProcessing(true);
    const formData = new FormData();
    formData.append('file', inputRef.current.files[0]);
    formData.append('Machine_ID', machineID);
    await dispatch(uploadFailureModeFileAsync(formData));
    onSaved();
    setIsProcessing(false);
  }

  function chooseFile() {
    const { current } = inputRef;
    current.click();
  }

  function validate() {
    if (isProcessing) return;
    const errFields = [];
    if (!machineID) errFields.push('machineid');
    if (inputRef.current.files.length === 0) errFields.push('files');
    setErrorFields(errFields);
    return errFields.length === 0;
  }

  function showDialog(title, text, onDone) {
    setConfirmation({
      show: true,
      title,
      text,
      onClose: () => {
        setConfirmation({ show: false });
      },
      onDone: () => {
        onDone();
        setConfirmation({ show: false });
      },
    });
  }

  const machineModels = useSelector(
    (state) => state.failureModeEntry.machineModels,
  );

  return (
    <Dialog className="addImportDialog" open disableEnforceFocus>
      <div className="modelboxopen" ref={(e) => { e && e.style.setProperty('z-index', 1000, 'important'); }}>
        <div className={`excelimportpopup modelboxopenInnner ${style.modelBox}`} id="notification-dialog">
          <div className="wrapContent">
            <div className="containerIner2">
              <div className="popupCloseButton bbt">
                <h3 className="titleDiv">Import Failure Mode</h3>
                <div
                  className="closepopup pointer"
                  onClick={() => onClosed()}
                >
                  &#x2715;
                </div>
              </div>
              <div className="descriptionCreate contentPopup conditionMonitoring">
                <Form
                  onSubmit={() => { }}
                  render={({ handleSubmit, form }) => (
                    <Field
                      name="OccurrenceCriteria_ID"
                      className="form-control"
                      // validate={required}
                      placeholder="Occurrence Frequency"
                    >
                      {(props) => (
                        <div className="font14 notificationgroup col-md-12">
                          <div id="edit-notification-group-fields">
                            <div className="row-md-16">
                              <div className="form-group comboDiv">
                                <label className="font12">Machine Model</label>
                                <span className="redStar">*</span>
                                <Autocomplete
                                  value={(machineModels && machineModels.find((m) => m.MachineModel_ID === props.input.value)) || ''}
                                  getOptionSelected={(option, value) => option.MachineModel_ID === value.MachineModelID}
                                  onChange={(event, value) => {
                                    setPage(1);
                                    if (value) setModel(value.MachineModel_ID);
                                    else setModel('');
                                    setMachineID(value ? value.MachineModel_ID : null);
                                    props.input.onChange(value ? value.MachineModel_ID : '');
                                  }}
                                  onBlur={props.input.onBlur}
                                  options={machineModels}
                                  getOptionLabel={(option) => option.MachineModel_Name || ''}
                                  renderInput={(params) => <TextField {...params} placeholder="Please Select" className={`${(errorFields.indexOf('machineid') !== -1 ? ' error-field' : '')}`} variant="outlined" />}
                                  disabled={false}
                                  disablePortal={false}

                                />
                              </div>
                            </div>
                          </div>

                          <input
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                const ext = e.target.files[0].name.match(/\.[0-9a-z]+$/i);
                                if (ext && ((ext[0] === '.xlsx') || ext[0] === '.xls')) setSelectedfile(e.target.files);
                                else {
                                  e.target.value = null;
                                  setSelectedfile([]);
                                  message.warn('File format not supported.');
                                }
                              } else {
                                e.target.value = null;
                                setSelectedfile([]);
                              }
                            }}
                            id="select-file"
                            type="file"
                            ref={inputRef}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: 'none' }}
                          />

                          <div className="row-md-16">
                            <div className="form-group filePicker">
                              <label className="font12">File</label>
                              <span className="redStar">*</span>
                              <input
                                onClick={chooseFile}
                                type="text"
                                placeholder="Select File"
                                value={selectedfile.length === 0 ? '' : selectedfile[0].name}
                                className={`form-control customForm input-md${errorFields.indexOf('files') !== -1 ? ' error-field' : ''}`}
                              />
                              <span onClick={chooseFile} className="folder">
                                {/* <Icon name="OpenFileIcon" /> */}
                                <OpenFileIcon />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </Field>
                  )}
                />

                {/* <input
                  //className="form-control customForm input-md"

                  ref={(ref) => this.upload = ref}
                  type="file"
                  style = {{display : 'none'}}
                  onChange={""}
                /> */}

              </div>
              <div className="footerPopupWithBtn">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        id="cancelBtn"
                        name="cancel"
                        className="btn btn-default cancelBtn"
                        value="2"
                        onClick={() => onClosed()}
                        type="button"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        id="saveBtn"
                        name="Save"
                        className="btn btn-default saveBtn"
                        value="1"
                        onClick={() => {
                          if (validate()) {
                            showDialog('Confirm', 'The manual entries made against the machine model will be changed to inactive automatically. Do you wish to continue?', () => {
                              onUploadBtnClicked();
                            });
                          }
                        }}
                      >
                        Upload
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmation.show
        && (
        <ConfirmationDialog
          onClosed={showConfirmation.onClose}
          title={showConfirmation.title}
          text={showConfirmation.text}
          onDoneClicked={showConfirmation.onDone}
        />
        )}
    </Dialog>
  );
};

export default Addimportfmea;
