/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Select from "react-select";
import MaintenanceLog from "../MaintenanceLog/MaintenanceLog";
import "./parameterPerformance.css";
import getAllMachineList from "../../../API/getAllMachineList";
import getPlantList from "../../../API/getPlantList";
import getServoMotorList from "../../../API/getServoMotorList";
import getPerfLineChart from "../../../API/getPerfLineChart";
import { Modal, Box, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Style from "./style.module.css";
import "./parameterPerformance.css";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import PerformanceCard from "./PerformanceCard";
import NoData from "../../Reusable/NoData";
import zoomPlugin from "chartjs-plugin-zoom";
import { useSelector, useDispatch } from "react-redux";
import { setParameterPerformanceFilter } from "../../../redux/slices/filterDMSlice";
import { message } from "antd";

Chart.register(zoomPlugin);

const ParameterPerformance = () => {
  const customSelect = {
    placeholder: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    control: (props, state) => ({
      ...props,
      width: "195px",
      border: "none",
      boxShadow: state.isFocused && "none",
      backgroundColor: "#1f1f1f",
      fontSize: "12px",
      outline: "none",
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
    singleValue: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    input: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    menu: (props) => ({
      ...props,
      backgroundColor: "#1f1f1f",
    }),
    option: (props, state) => ({
      ...props,
      backgroundColor: state.isFocused ? "#5D97F6" : "",
      color: state.isFocused ? "#121212" : "#e0e0e0",
      fontSize: "12px",
    }),
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    overflow: "auto",
    bgcolor: "#1F1F1F",
    borderRadius: "4px",
    border: "1px solid #313131",
    boxShadow: 50,
    outline: "none",
  };

  const option = {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          drag: {
            enabled: true,
            borderColor: "rgba(225,225,225,0.3)", // Border color of the rectangle box
            borderWidth: 1,
            backgroundColor: "rgba(225,225,225,0.2)", // Background color of the rectangle box
            animationDuration: 1000,
          },
          mode: "xy",
          speed: 100,
        },
        pan: {
          enabled: true,
          mode: "x",
          speed: 0.5,
        },
      },
    },
    scales: {
      yAxes: {
        ticks: {
          color: "#ffffff",
        },
        title: {
          display: true,
          text: "Values",
          color: "#FFFFFF",
          font: {
            size: 13,
            family: "Arial, Sans Serif",
          },
        },
      },
      xAxes: {
        ticks: {
          color: "#ffffff",
        },
        title: {
          display: true,
          text: "Time",
          color: "#FFFFFF",
          font: {
            size: 13,
            family: "Arial, Sans Serif",
          },
        },
      },
    },
  };

  const dispatch = useDispatch();
  const ParameterPerformanceFilter = useSelector(
    (state) => state.DMFilter.ParameterPerformanceFilter.inputData
  );
  const [plant, setPlant] = useState([]);
  const [machine, setMachine] = useState([]);

  const [IsMaintenance, setIsMaintenance] = useState(false);
  const [ServoMotorList, setServoMotorList] = useState([]);

  const [IsExpand, setIsExpand] = useState(false);
  const [firsttime, setFirsttime] = useState(false);
  const [PerformanceData, setPerformanceData] = useState(null);
  const [ItemData, setItemData] = useState(null);

  const [Loading, setLoading] = useState(true);

  const getPlantListData = async () => {
    const response = await getPlantList();
    setPlant(response?.data.data);
    getMachineList(
      response?.data?.data?.some(
        (e) => e.PlantKey == ParameterPerformanceFilter?.plant?.PlantKey
      )
        ? ParameterPerformanceFilter?.plant
        : response?.data?.data[0]
    );
    SaveData(
      ParameterPerformanceFilter?.plant ?? response?.data?.data[0],
      machine?.some((e) => e.MachineKey == ParameterPerformanceFilter?.machine?.MachineKey)
        ? ParameterPerformanceFilter?.machine
        : null,
      ServoMotorList?.some(
        (e) => e.MachineKey == ParameterPerformanceFilter?.motor?.MachineKey
      )
        ? ParameterPerformanceFilter?.motor
        : null,
      "PLant"
    );
  };

  const getMachineList = async (e) => {
    const response = await getAllMachineList(e.PlantKey);
    setMachine(response?.data?.data);
    callGetServoMotorList(
      e,
      response?.data?.data?.some(
        (e) => e.MachineKey == ParameterPerformanceFilter?.machine?.MachineKey
      )
        ? ParameterPerformanceFilter?.machine
        : response?.data?.data[0]
    );
    SaveData(
      e,
      response?.data?.data?.some(
        (e) => e?.MachineKey == ParameterPerformanceFilter?.machine?.MachineKey
      )
        ? ParameterPerformanceFilter?.machine
        : response?.data?.data[0],
      ServoMotorList?.some(
        (e) => e.ServoMotorKey == ParameterPerformanceFilter?.motor?.ServoMotorKey
      )
        ? ParameterPerformanceFilter?.motor
        : null,
      "MAchine"
    );
  };

  const callGetServoMotorList = async (p, m) => {
    const response = await getServoMotorList();
    let names = groupBy(response.data.data, "ServoMotorName");
    let finalData = [];
    for (let item in names) {
      let keys = (names[item] || []).map((el) => el.ServoMotorKey);
      let motorName =
        names[item] && names[item].length ? names[item][0].ServoMotorName : "";
      finalData = [
        ...finalData,
        {
          ServoMotorKey: keys.toString(),
          ServoMotorName: motorName,
        },
      ];
    }
    setServoMotorList(finalData);
    console.log("🚀 ~ callGetServoMotorList ~ finalData:", finalData);
    SaveData(
      p,
      m,
      finalData?.some(
        (e) => e?.ServoMotorKey == ParameterPerformanceFilter?.motor?.ServoMotorKey
      )
        ? ParameterPerformanceFilter?.motor
        : finalData[0],
      "Motor"
    );
  };



  const SaveData = async (plant, machine, motor, e) => {
    console.log("datasss", plant, machine, motor, e);
    dispatch(
      setParameterPerformanceFilter({
        inputData: {
          plant,
          machine,
          motor,
        },
      })
    );
  };

  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );

  useEffect(() => {
    getPlantListData();
    // callGetServoMotorList();
  }, []);



  const toggleMaintenance = () => {
    setIsMaintenance(!IsMaintenance);
  };

  const callPerfLineChart = async () => {
    if (ParameterPerformanceFilter?.machine && ParameterPerformanceFilter?.plant && ParameterPerformanceFilter?.motor) {
      setLoading(true);
      setPerformanceData(null);
      console.log('ParameterPerformanceFilter', ParameterPerformanceFilter);
      const data = {
        MachineKey: ParameterPerformanceFilter?.machine?.MachineKey,
        ServoMotorKey: ParameterPerformanceFilter?.motor?.ServoMotorKey,
      };
      const response = await getPerfLineChart(data);
      setPerformanceData(response?.data?.data);
      setLoading(false);
    } else {
      message.warn("Select all filter values");
    }
  };

  const toggleExpand = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsExpand(!IsExpand);
    }
  };
  useEffect(() => {
    if (ParameterPerformanceFilter?.machine && ParameterPerformanceFilter?.plant && ParameterPerformanceFilter?.motor) {
      !firsttime && callPerfLineChart();
      setFirsttime(true);
    }
  }, [ParameterPerformanceFilter]);

  return (
    <div className={Style.ParamPerf__container}>
      <div className="top-box">
        <div className="box-1">
          <div className="select-div">
            <Select
              isSearchable={false}
              styles={customSelect}
              className="selct"
              placeholder="Select Plant"
              options={plant}
              getOptionValue={(x) => x.PlantKey}
              getOptionLabel={(x) => x.PlantName}
              onChange={(e) => {
                SaveData(e, null, null, "Plant Box");
                getMachineList(e);
              }}
              value={ParameterPerformanceFilter?.plant}
            />
          </div>
          <div className="select-div" style={{ marginLeft: "10px" }}>
            <Select
              key={ParameterPerformanceFilter?.machine?.MachineKey}
              isSearchable={false}
              styles={customSelect}
              className="selct"
              placeholder="Select Machine"
              options={machine}
              getOptionValue={(x) => x.MachineKey}
              getOptionLabel={(x) => x.MachineSerialNo}
              onChange={(e) => {
                SaveData(ParameterPerformanceFilter?.plant, e, null, "Machine box");
                callGetServoMotorList(ParameterPerformanceFilter?.plant, e);
              }}
              value={ParameterPerformanceFilter?.machine}
            />
          </div>
          <div className="select-div" style={{ marginLeft: "10px" }}>
            <Select
              isSearchable={true}
              styles={customSelect}
              className="selct"
              placeholder="Select Motor"
              options={ServoMotorList}
              getOptionValue={(x) => x.ServoMotorKey}
              getOptionLabel={(x) => x.ServoMotorName}
              onChange={(e) => {
                SaveData(
                  ParameterPerformanceFilter?.plant,
                  ParameterPerformanceFilter?.machine,
                  e,
                  "Motor Box"
                );
              }}
              value={ParameterPerformanceFilter?.motor}
            />
          </div>
          <div>
            <button
              className="btn-go"
              onClick={() => {
                callPerfLineChart();
              }}
            >
              Go
            </button>
          </div>
        </div>
        <div className="box-2">
          <button
            className="digital-btn"
            onClick={() => {
              setIsMaintenance(true);
            }}
          >
            Maintenance Log
          </button>
        </div>
      </div>
      {Loading ? (
        <div
          style={{
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={15} />
        </div>
      ) : (
        <>
          {PerformanceData?.[0]?.[0]?.cardName ? (
            <div className="middle-box">
              <div className="graph-label">
                <p
                  style={{
                    marginRight: "20px",
                    display: "flex",
                    justifyContent: "row",
                  }}
                >
                  <span
                    style={{
                      borderTop: "3px solid #FFEC19",
                      width: "25px",
                      margin: "7.5px 10px 0px 0px",
                    }}
                  ></span>{" "}
                  Warning Limit
                </p>
                <p
                  style={{
                    margin: "0px",
                    display: "flex",
                    justifyContent: "row",
                  }}
                >
                  <span
                    style={{
                      borderTop: "3px solid #DC3838",
                      width: "25px",
                      margin: "7.5px 10px 0px 0px",
                    }}
                  ></span>
                  Trigger Limit
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {console.log("PerformanceData", PerformanceData)}
                {PerformanceData?.length > 0 &&
                  PerformanceData.map((item, index) => {
                    return (
                      <div key={index} style={{ width: "33%" }}>
                        {item.map((e) => {
                          return (
                            !!e?.values.length && (
                              <PerformanceCard
                                key={e?.cardName}
                                title={`${e?.cardName}`}
                                item={{
                                  timestamp: e?.timestamps,
                                  MaxStopValue: e?.MaxStopValue,
                                  MinStopValue: e?.MinStopValue,
                                  MaxWarningValue: e?.MaxWarningValue,
                                  MinWarningValue: e?.MinWarningValue,
                                  values: e?.values,
                                }}
                                setItemData={setItemData}
                                toggleExpand={toggleExpand}
                                color={e?.color}
                              />
                            )
                          );
                        })}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "20px 0",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              borderTop: `2px solid ${item[0]?.color}`,
                              width: "30px",
                              margin: "7px 10px 0px 0px",
                            }}
                          ></div>
                          <p>{item[0]?.groupName}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div className="middle-box" style={{ height: "50%" }}>
              <NoData />
            </div>
          )}
        </>
      )}

      <Modal
        open={IsExpand}
        onClose={toggleExpand}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "0.2px solid #444444",
              padding: "5px 20px",
              background: "#565b60",
              borderRadius: "4px 4px 0px 0px",
            }}
          >
            <h4
              style={{
                fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
                marginTop: "0px",
                fontWeight: 600,
                fontSize: "1rem",
              }}
            >
              {ItemData?.title}
            </h4>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={toggleExpand}
              size={15}
            />
          </div>
          <div style={{ padding: 20 }}>
            <Line
              data={{
                labels: ItemData?.timestamp,
                datasets: [
                  {
                    label: "Trigger Limit",
                    data: ItemData?.MaxStopValue,
                    fill: false,
                    pointRadius: 0,
                    borderColor: "#DC3838",
                  },
                  {
                    label: "Trigger Limit",
                    data: ItemData?.MinStopValue,
                    fill: false,
                    pointRadius: 0,
                    borderColor: "#DC3838"
                  },
                  {
                    label: "Warning Limit",
                    data: ItemData?.MaxWarningValue,
                    fill: false,
                    pointRadius: 0,
                    borderColor: "#FFEC19",
                  },
                  {
                    label: "Warning Limit",
                    data: ItemData?.MinWarningValue,
                    fill: false,
                    pointRadius: 0,
                    borderColor: "#FFEC19"
                  },
                  {
                    label: "value",
                    data: ItemData?.values,
                    pointRadius: 0,
                    fill: false,
                    borderColor: ItemData?.color,
                  }
                ],
              }}
              options={option}
            />
          </div>
        </Box>
      </Modal>
      {ParameterPerformanceFilter?.plant?.PlantKey && (
        <MaintenanceLog
          IsMaintenance={IsMaintenance}
          toggleMaintenance={toggleMaintenance}
          inputData={ParameterPerformanceFilter}
          // setInputData={setInputData}
          setLogTitle={() => { }}
        />
      )}
    </div>
  );
};

export default ParameterPerformance;
