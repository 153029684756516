// import axios from 'axios';
// import { APIUrl } from '../../configs/config';
import { APIUrl } from '../../../helpers/constants';
// import authHeader from '../../../config/auth.header';
import CallAPI from '../../../services/conditionMonitoring.service';
// const headers = {
//   headers: authHeader(),
// };

export function getAlertNotificationList(search, page, size, name, from, to, comType, comMode) {
  const apiInfo = {
    url: `${APIUrl}/api/getAlertNotificationList?search=${search}&page=${page}&size=${size}&name=${name}&from=${from}&to=${to}&comType=${comType}&comMode=${comMode}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getAlertNotificationList?search=${search}&page=${page}&size=${size}&name=${name}&from=${from}&to=${to}&comType=${comType}&comMode=${comMode}`, headers,
  //   )
  //   .then((res) => res.data);
}

export function fetchAlertNotificationById(id) {
  const apiInfo = {
    url: `${APIUrl}/api/fetchAlertNotificationById?id=${id}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/fetchAlertNotificationById?id=${id}`, headers,
  //   )
  //   .then((res) => res.data);
}
