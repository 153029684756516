// import axios from 'axios';
// import { user_id, APIUrl } from '../../configs/config';
// const user_id = 1;
import { APIUrl } from '../../../helpers/constants';
// import authHeader from '../../../config/auth.header';
import CallAPI from '../../../services/conditionMonitoring.service';

export function fetchNotificationGroup(id, searchKey, pageindex, pageCount) {
  const apiInfo = {
    url: `${APIUrl}/api/getNotificationGroup?id=${id}&searchkey=${searchKey}&pageindex=${pageindex}&pageCount=${pageCount}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .get(
  //     `${APIUrl}/api/getNotificationGroup?id=${id}&searchkey=${searchKey}&pageindex=${pageindex}&pageCount=${pageCount}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function deleteNotificationGroup(id) {
  const apiInfo = {
    url: `${APIUrl}/api/deleteNotificationGroup?id=${id}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.get(`${APIUrl}/api/deleteNotificationGroup?id=${id}`, { headers }).then((res) => res.data);
}
