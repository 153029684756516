import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  fetchMachineModel, fetchMachineSection, fetchMachineFunction, saveGeneralCondition,
  fetchNotificationGroup, fetchMachineParameters, fetchNotificationTemplate, editGeneralCondition,
} from '../../components/ConditionMonitoring/general-condition/generalConditionAddApi';

import { saveRecommendedActions, deleteRecommendedActions } from '../../components/ConditionMonitoring/recommended-actions/recommendedActionApi';
// import { setEditDetails } from "../../failure-mode-details/failureModeDetailsSlice";
// import { SuccessMessage, ErrorMessage } from "../../../configs/config";
import { SuccessMessage, ErrorMessage } from '../../helpers/constants';

const initialState = {
  machineModels: [],
  machineModelStatus: 'idle',
  machineSections: [],
  machineSectionStatus: 'idle',
  machineFunctions: [],
  machineFunctionStatus: 'idle',
  machineParameters: [],
  machineParametersStatus: 'idle',
  notificationGroups: [],
  notificationGroupStatus: 'idle',
  notificationTemplates: [],
  notificationTemplateStatus: 'idle',
  generalConditionStatus: 'idle',
  editGeneralConditions: {},
  editGeneralConditionsStatus: 'idle',
  recommendedActionsStatus: 'idle',
  recordSaved: false,
};
// original code below
// export const saveGeneralConditionAsync = createAsyncThunk(
//   'generalConditionAdd/saveGeneralConditionDetails',
//   // async (body, thunkApi) => {
//   async (body) => {
//     const response = await saveGeneralCondition(body);
//     // thunkApi.dispatch(setEditDetails(response));
//     return response;
//   },
// );
// changed code below
export const saveGeneralConditionAsync = createAsyncThunk(
  'generalConditionAdd/saveGeneralConditionDetails',
  // async (body, thunkApi) => {
  async (body, { rejectWithValue }) => {
    try {
      const response = await saveGeneralCondition(body);
      if (response?.status && response?.status === 500) {
        // return rejectWithValue(response?.error || 'failed to save record');
        return rejectWithValue('Failed to save record');
      }
      return response;
    } catch (err) {
      console.log('err', err);
      // return rejectWithValue(err?.response?.data || 'failed to save record');
      return rejectWithValue('Failed to save record');
    }
  },
);

export const editGeneralConditionAsync = createAsyncThunk(
  'generalConditionAdd/editGeneralConditions',
  async ({ id }) => {
    const response = await editGeneralCondition(id);
    return response;
  },
);

export const fetchMachineModelAsync = createAsyncThunk(
  'generalConditionAdd/fetchMachineModel',
  async () => {
    const response = await fetchMachineModel();
    return response;
  },
);

export const fetchMachineSectionAsync = createAsyncThunk(
  'generalConditionAdd/fetchMachineSection',
  async (modelId) => {
    const response = await fetchMachineSection(modelId);
    return response;
  },
);

export const fetchMachineFunctionAsync = createAsyncThunk(
  'generalConditionAdd/fetchMachineFunction',
  async (sectionId) => {
    const response = await fetchMachineFunction(sectionId);
    return response;
  },
);

export const fetchMachineParametersAsync = createAsyncThunk(
  'generalConditionAdd/fetchMachineParameters',
  async ({ model, section, fun }) => {
    const response = await fetchMachineParameters(
      model,
      section,
      fun,
    );
    return response;
  },
);

export const fetchNotificationGroupAsync = createAsyncThunk(
  'generalConditionAdd/fetchNotificationGroup',
  async () => {
    const response = await fetchNotificationGroup();
    return response;
  },
);

export const fetchNotificationTemplateAsync = createAsyncThunk(
  'generalConditionAdd/fetchNotificationTemplate',
  async () => {
    const response = await fetchNotificationTemplate();
    return response;
  },
);

export const saveRecommendedActionsAsync = createAsyncThunk(
  'generalConditionAdd/saveRecommendedActions',
  // async (recommendedAction, thunkApi) => {
  async (recommendedAction) => {
    if (recommendedAction.GeneralCondition_ID) {
      const response = await saveRecommendedActions(recommendedAction);
      // thunkApi.dispatch(setRecommendedAction(response));
      return response;
    }

    // thunkApi.dispatch(setRecommendedAction(recommendedAction));
    return recommendedAction;
  },
);

export const deleteRecommendedActionsAsync = createAsyncThunk(
  'generalConditionAdd/deleteRecommendedActions',
  // async (recommendedActionId, thunkApi) => {
  async (recommendedActionId) => {
    const response = await deleteRecommendedActions(recommendedActionId);
    if (response === 'OK') {
      // thunkApi.dispatch(removeRecommendedAction(recommendedActionId));
    }
    return response;
  },
);

export const generalConditionEntry = createSlice({
  name: 'generalConditionAdd',
  initialState,
  reducers: {

    // clearSaveRecord: (state, action) => {
    clearSaveRecord: (state) => {
      state.recordSaved = false;
      state.editGeneralConditions = {};
    },

    clearEdit: (state) => {
      state.editGeneralConditions = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineModelAsync.pending, (state) => {
        state.machineModelStatus = 'loading';
      })
      .addCase(fetchMachineModelAsync.fulfilled, (state, action) => {
        state.machineModelStatus = 'idle';
        state.machineModels = action.payload;
      })

      .addCase(fetchMachineSectionAsync.pending, (state) => {
        state.machineSectionStatus = 'loading';
        state.machineSections = [];
        state.machineFunctions = [];
      })
      .addCase(fetchMachineSectionAsync.fulfilled, (state, action) => {
        state.machineSectionStatus = 'idle';
        state.machineSections = action.payload;
      })

      .addCase(fetchMachineFunctionAsync.pending, (state) => {
        state.machineFunctionStatus = 'loading';
      })
      .addCase(fetchMachineFunctionAsync.fulfilled, (state, action) => {
        state.machineFunctionStatus = 'idle';
        state.machineFunctions = action.payload;
      })

      .addCase(fetchMachineParametersAsync.pending, (state) => {
        state.machineParametersStatus = 'loading';
      })
      .addCase(fetchMachineParametersAsync.fulfilled, (state, action) => {
        state.machineParametersStatus = 'idle';
        state.machineParameters = action.payload;
      })

      .addCase(fetchNotificationGroupAsync.pending, (state) => {
        state.notificationGroupStatus = 'loading';
      })
      .addCase(fetchNotificationGroupAsync.fulfilled, (state, action) => {
        state.notificationGroupStatus = 'idle';
        state.notificationGroups = action.payload;
      })

      .addCase(fetchNotificationTemplateAsync.pending, (state) => {
        state.notificationTemplateStatus = 'loading';
      })
      .addCase(fetchNotificationTemplateAsync.fulfilled, (state, action) => {
        state.notificationTemplateStatus = 'idle';
        state.notificationTemplates = action.payload;
      })

      .addCase(saveGeneralConditionAsync.pending, (state) => {
        state.generalConditionStatus = 'loading';
      })
      // .addCase(saveGeneralConditionAsync.fulfilled, (state, action) => {
      .addCase(saveGeneralConditionAsync.fulfilled, (state, action) => {
        state.generalConditionStatus = 'idle';
        state.recordSaved = true;
        console.log('saveGeneralConditionAsync.fulfilled', state, action);
        setTimeout(() => {
          message.success(SuccessMessage);
        }, 100);
      })
      .addCase(saveGeneralConditionAsync.rejected, (state, action) => {
        state.generalConditionStatus = 'idle';
        console.log('saveGeneralConditionAsync.rejected', state, action);
        setTimeout(() => {
          message.error(action?.payload || ErrorMessage);
        }, 100);
      })

      .addCase(editGeneralConditionAsync.pending, (state) => {
        state.editGeneralConditionsStatus = 'loading';
      })
      .addCase(editGeneralConditionAsync.fulfilled, (state, action) => {
        state.editGeneralConditionsStatus = 'idle';
        state.editGeneralConditions = action.payload;
      })
      .addCase(editGeneralConditionAsync.rejected, (state) => {
        state.editGeneralConditionsStatus = 'idle';
      })

      .addCase(saveRecommendedActionsAsync.pending, (state) => {
        state.recommendedActionsStatus = 'loading';
      })
      .addCase(saveRecommendedActionsAsync.fulfilled, (state, action) => {
        state.recommendedActionsStatus = 'idle';
        const existingRecommendedAction = state.editGeneralConditions.recommendedactions
        && state.editGeneralConditions.recommendedactions.findIndex(
          (i) => i.RecommendedAction_ID === action.payload.RecommendedAction_ID,
        );

        if (existingRecommendedAction >= 0) {
          state.editGeneralConditions.recommendedactions[existingRecommendedAction] = action.payload;
        } else if (state.editGeneralConditions.recommendedactions) {
          state.editGeneralConditions.recommendedactions.push(action.payload);
        } else {
          state.editGeneralConditions.recommendedactions = [action.payload];
        }
      })
      .addCase(saveRecommendedActionsAsync.rejected, (state) => {
        state.recommendedActionsStatus = 'idle';
      })

      .addCase(deleteRecommendedActionsAsync.pending, (state) => {
        state.recommendedActionsStatus = 'loading';
      })
      .addCase(deleteRecommendedActionsAsync.fulfilled, (state, action) => {
        state.recommendedActionsStatus = 'idle';
        const existingRecommendedAction = state.editGeneralConditions.recommendedactions.findIndex(
          (i) => i.RecommendedAction_ID === action.meta.arg,
        );
        if (existingRecommendedAction >= 0) {
          state.editGeneralConditions.recommendedactions.splice(
            existingRecommendedAction,
            1,
          );
        }
      })
      .addCase(deleteRecommendedActionsAsync.rejected, (state) => {
        state.recommendedActionsStatus = 'idle';
      });
  },
});

export const {
  clearSaveRecord, clearEdit,
} = generalConditionEntry.actions;

export default generalConditionEntry.reducer;
