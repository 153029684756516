// Author:- Sanket
// Reviewd By:- Aniket
// Hindi & Marathi strings of application

export const LangHstrings = {
  Language: 'हिन्दी',
  ChangeLanguage: 'भाषा बदलें',
  // text for login form
  welcome: 'स्वागत हे',
  emailId: 'ईमेल आईडी',
  password: 'पासवर्ड',
  forgot: 'पासवर्ड भुल गये ?',
  login: 'लॉग इन करें',

  // Logout Texts
  Logout: 'लॉग आउट',
  LogoutTxt1: ' आपको FUTUREdna से सफलतापूर्वक लॉग आउट कर दिया गया है',
  LogoutTxt2: 'FUTUREdna का उपयोग करने के लिए धन्यवाद!',
  ClickHere: 'यहाँ क्लिक करें',
  ClickHereTxt: 'फिर से प्रवेश करने के लिए',

  // NoInernet Txt
  NoInternetTxt:
      'यह मशीन अभी ऑफलाइन है। आप अभी भी मशीन के ऐतिहासिक डेटा तक पहुंच सकते हैं।',

  // Sidebar texts
  Home: 'होम',
  Monitoring: 'मॉनिटरिंग',
  AlertNotif: 'अलर्ट और सूचनाएं',
  SettingIcon: 'सेटिंग्स',
  HelpIc: 'हेल्प',
  AllNotif: 'सभी अधिसूचनाएं',
  CustAlert: 'कस्टम अलर्ट',
  ChangePass: 'पासवर्ड बदलें',
  ChangePIN: 'पिन बदलें',
  AppSettings: 'एप्लीकेशन सेटिंग',
  AllParameters: 'ऑल प्यारामीटर्स',
  Downtime: 'डाउनटाइम',
  Reports: 'रिपोर्ट्स',
  OEEInsights: 'OEE अंतर्दृष्टि',
  // text for map side filter
  MapLang: 'hi',
  LiveMap: 'लाइव मैप ट्रैकिंग',
  MachineList: 'मशीन लिस्ट',
  Country: 'देश',
  States: 'राज्य',
  MachineModel: 'मशीन मॉडल',
  Plants: 'मशीन',
  Reset: 'रीसेट करें',
  TypeHere: 'टाइप करें...',
  AllMach: 'सभी मशीन',
  CriticalMach: 'क्रिटिकल मशीन',
  AMCdueMach: 'एएमसी ड्यू मशीन',

  // text for machine side filter

  // Plants: "मशीन",
  // MachineModel: "मशीन मॉडल",

  LineNo: 'पंक्ति संख्या',
  Serial: 'क्रमांक',
  SerialShort: 'क्रमांक',
  // Reset: "रीसेट करें",
  // TypeHere: "टाइप करें...",

  All: 'सब',
  Criticalt: 'क्रिटिकल',
  AMCdue: 'एएमसी ड्यू',
  machineList: 'मशीन सूची',

  // Machine list card texts
  MachineOffline: 'मशीन ऑफ़लाइन है',
  Since: 'कब से? :-',
  CriticalAlarmt: 'क्रिटिकल अलार्म:',
  Mode: 'मोड',
  MachineFor: 'के लिए मशीन सूची:',

  // machine card tooltip
  CommisionOn: 'कब शुरू हुआ?',
  PlantName: 'मशीन का नाम',
  CriticalAlarm: 'क्रिटिकल अलार्म:',

  // for overview side filter
  // Plants: "मशीन",
  // MachineModel: "मशीन मॉडल",
  // LineNo: "लाइन नंबर",
  // Serial: "क्रमांक",
  // Reset: "रीसेट करें",
  // TypeHere: "टाइप करें...",

  // overview texts
  monitoring: 'मॉनिटरिंग',
  overview: 'ओवरव्यू',
  MachineSetting: 'मशीन सेटिंग',
  Kpeei: 'केपिआय',
  Alarms: 'एलार्म',
  Events: 'इवेंट्स',
  LiveBatch: 'लाइव बैच',
  CustomRepo: 'कस्टम रिपोर्ट ',
  Beta: 'यह बीटा परीक्षण में है',
  Prev: 'पिछला',
  Next: 'अगला',

  stat: 'स्थिति',
  Machineview: 'मशीन का दर्शन',
  TotalProdCount: 'कुल प्रोडक्शन काउंट',

  // AppSettings Texts
  Setting: 'सेटिंग',
  AppSetting: 'एप्लिकेशन सेटिंग',
  ChangingTheme: 'थीम बदलें',
  Black: 'डार्क (डिफ़ॉल्ट)',
  White: 'लाइट',
  ChangingLang: 'भाषा बदलें',
  ChangingFont: 'फॉण्ट आकार बदलें',
  Medium: 'मध्यम (डिफ़ॉल्ट)',

  // Overview Status Marquee text
  Stopped: 'रोक दिया गया है',
  Running: 'चल रहा है',
  NotAvailable: 'उपलब्ध नहीं है',
  OffStatus: 'ऑफ़लाइन स्थिति',
  NAStatus: 'उपलब्ध स्थिति',
  MachineMode: 'मशीन मोड',

  ConnectivityStatus: 'कनेक्टिविटी की स्थिति',
  Online: 'ऑनलाइन',
  Offline: 'ऑफलाइन',
  MachineStatus: 'मशीन की स्थिति ',
  ComingSoon: 'जल्द आ रहा है',
  AvgMachineSpeed: 'औसत मशीन की गति',
  AvgRunning: 'औसत रनिंग घंटा प्रति दिन',
  AvgDowntime: 'औसत डाउनटाइम घंटा प्रति दिन',
  AvgAsset: 'औसत संपत्ति का उपयोग',
  AvgBreakdown: 'औसत ब्रेकडाउन घंटा प्रति दिन',
  AvgAlarm: 'औसत अलार्म गणना प्रति दिन',
  MachineStatust: 'मशीन की स्थिति:',

  // Machine Setting Texts
  ConfigPara: 'पैरामीटर्स कॉन्फ़िगर करें',

  // kpi
  ConfigKpi: 'केपिआय कॉन्फ़िगर करें',

  // kpi tool tip
  AbsoluteRelative: 'Absolute/Relative',
  Absolute: 'Absolute:',
  Relative: 'Relative:',
  AbsoluteText: '  Upper limit > Parameter KPI > Lower limit',
  RelativeText:
      '  (Upper limit + Set value) > Parameter KPI > (set Value - Lower limit)',

  // Alarm Setting
  AlarmSetting: 'अलार्म सेटिंग्स',
  Active: 'सक्रिय',
  History: 'इतिहास',
  AlarmName: 'अलार्म का नाम',
  TriggerTime: 'ट्रिगर का समय',
  ResetTime: 'रीसेट समय',
  Duration: 'अवधि (मिनट)',
  Severity: 'तीव्रता',
  NoRecord: 'कोई रिकॉर्ड उपलब्ध नहीं है',
  TriggeredTime: 'ट्रिगर का समय:',
  NoAlarm: 'कोई सक्रिय अलार्म उपलब्ध नहीं है',

  // Alarm tooltip
  /// Severity: "तीव्रता",
  Critical: 'क्रिटिकल:',
  CriticalTxt: 'मशीन के प्रदर्शन और उत्पाद उत्पादन को प्रभावित करता है',
  Major: 'मेजर:',
  MajorTxt: 'ऑपरेटर कार्रवाई की आवश्यकता है',
  Minor: 'माइनर:',
  MinorTxt: 'कोई कार्रवाई आवश्यक नहीं / केवल जानकारी ',

  // Event texts
  EventSetting: 'इवेंट सेटिंग',
  EventName: 'इवेंट नाम',

  // LiveBatch texts
  LiveBatchStatus: 'लाइव बैच स्टेटस',
  MachineOffTxt:
      'मशीन वर्तमान में ऑफ़लाइन है। लाइव बैच का विवरण उपलब्ध नहीं होगा।  इस बीच, आप निगरानी के अन्य टैब की जाँच जारी रख सकते हैं।',
  BatchNo: 'बैच नम्बर',
  StartTime: ' शुरू समय ',
  EndTime: 'अंतिम समय',
  TotalAlarm: 'कुल अलार्म काउंट',
  ProdQual: 'उत्पादन की गुणवत्ता',
  ProdRate: 'उत्पादन दर',
  ProdTime: 'उत्पादन समय',
  LiveProdCount: 'लाइव प्रोडक्शन काउंट',
  NoData: 'कोई डेटा उपलब्ध नहीं है',

  // AllNotification texts
  TimeStamp: 'टाइम स्टैम्प',
  MachName: 'मशीन का नाम',
  AlertName: 'अलर्ट नाम',
  Manage: 'प्रबंधित',

  // Custom Alert Table
  Condition: 'स्थिति',
  CreateDate: 'रचना तिथि',

  // Notification Pannel
  Notif: 'नोटिफिकेशन्स',
  ViewAll: 'सभी देखें',
  NoNotif: 'कोई अधिसूचना उपलब्ध नहीं है',
  ClearNotif: 'सभी नोटिफिकेशन्स साफ़ करें',
  Dismiss: 'खारिज करें',
  SelectStatus: 'स्थिति का चयन करें',
  SelectDuration: 'अवधि का चयन करें',
  Select: 'चुनते हैं',

  // Password/PIN div texts
  ChangeHere: 'यहां अपना पासवर्ड बदलें',
  ChangePINhere: 'यहां अपना पिन बदलें',
  OldPass: 'पुराना पासवर्ड',
  OldPIN: 'पुराना पिन',
  EnterPass: 'पुराना पासवर्ड दाखिल करें',
  EnterOldPIN: 'पुराना पिन दाखिल करें',
  NewPass: 'नया पासवर्ड',
  NewPIN: 'नया पिन',
  EnterNewPass: 'नया पासवर्ड दाखिल करें',
  EnterNewPIN: 'नया पिन दाखिल करें',
  ConfirmPass: 'पासवर्ड की पुष्टि कीजिये',
  ConfirmPIN: 'पिन की पुष्टि कीजिये',
  EnterConfirmPass: 'पुष्टि पासवर्ड दर्ज करें',
  EnterConfirmPIN: 'पुष्टि पिन दर्ज करें',
  Clear: 'साफ करें',
  Save: 'सेव करें',

  // PIN modal texts
  EnterPIN: 'पिन दर्ज करें',
  PleaseEnter: 'अपनी पहुंच प्रमाणित करने के लिए कृपया 4 अंकों का पिन डालें।',
  Enter: 'यहाँ से प्रवेश करें',
  Cancel: 'रद्द करें',
  Ok: 'ठीक है',

  // createalert modal
  CreateAlert: 'अलर्ट बनाएं',
  SelectPara: 'पैरामीटर का चयन करें',
  EnterAlert: 'अलर्ट नाम दर्ज करें',
  AlertCondition: 'चेतावनी की स्थिति',
  SelectCondition: 'शर्त का चयन करें',
  AlertTriggerInteval: 'अलर्ट ट्रिगर इंटरवल',
  SelectInterval: 'अंतराल का चयन करें',
  InstantAlert: 'तुरंत अलर्ट',
  Notify: 'द्वारा सूचित करें',
  Limit: 'सीमा',
  Value: 'मूल्य',
  EnterTxt: 'लिखना प्रारम्भ करें',
  PushNotif: 'पुश अधिसूचना - वेब',
  Email: 'ई-मेल',
  CustAlertSave: 'कस्टम अलर्ट सहेजा गया',
  DataSaved: 'डेटा सफलतापूर्वक संग्रहीत किया गया है',
  Or: 'या',

  // remote add edit contact  modal
  AddNewContact: 'Add New Contact',
  EditNewContact: 'Edit New Contact',
  OEMServiceSPOC: 'OEM Service SPOC',
  CustomerSPOC: 'Customer SPOC',
  EnterName: 'Enter Name',
  EnterRole: 'Enter Role',
  EnterEmail: 'Enter Email',
  PrimaryMobile: 'Primary Mobile Number',
  AlternateMobile: 'Enter Alternate Mobile NUmber',
  EnterMachineModel: 'Enter Machine Model',
  EnterOrgnization: 'Enter Orgnization',
  EnterPlant: 'Enter Plant',
  EnterSerialNumber: 'Enter Serial Number',
  Add: 'Add',

  // report module
  // Side filter
  Date: 'तिथि',
  DateRange: 'Date Range',
  FileName: 'फाइल नेम',
  Download: 'डाऊनलोड',
  SrNo: 'सीर. नं.',
  MachineStopTime: 'मशीन स्टॉप टाइम',
  MachineStartTime: 'मशीन स्टार्ट टाइम',
  TotalDowntime: 'टोटल डाउनटाइम (होर्स)',
  DowntimeReason: 'डाउनटाइम रिसंन',
  AssociatedAlerts: 'एसोसिएट अलर्ट',
};

export const LangMstrings = {
  welcome: 'स्वागत आहे',
  emailId: 'ई-मेल आयडी',
  password: 'पासवर्ड',
  forgot: 'पासवर्ड विसरले ?',
  login: 'लॉग इन करा',
};
