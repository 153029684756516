/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { CgMaximizeAlt } from "react-icons/cg";
import {
  Modal,
  Box,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { BiSearch } from "react-icons/bi";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Tooltip from '@material-ui/core/Tooltip';
import getMaintenanceHistory from "../../../API/getMaintenanceHistory";
import putMaintenanceHistory from "../../../API/putMaintenanceHistory";
import getMaintenanceType from "../../../API/getMaintenanceType.js";
import NoData from "../../Reusable/NoData";
import moment from "moment";
import "./MaintenanceHistory.css";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Select from "react-select";
import swal from "sweetalert";

function MaintenanceHistory({ maintenanceHistory, inputData }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    height: "95vh",
    overflow: "hidden",
    bgcolor: "#1F1F1F",
    borderRadius: "4px",
    border: "1px solid #313131",
    boxShadow: 50,
    outline: "none",
  };

  const editmodalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    overflow: "hidden",
    bgcolor: "#1F1F1F",
    borderRadius: "4px",
    border: "1px solid #313131",
    boxShadow: 50,
    outline: "none",
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    height: "200px",
    overflow: "auto",
    bgcolor: "#1F1F1F",
    borderRadius: "4px",
    border: "1px solid #313131",
    boxShadow: 50,
    outline: "none",
  };

  const customSelect = {
    placeholder: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    control: (props, state) => ({
      ...props,
      width: "100%",
      border: "1px solid #5d5e60",
      boxShadow: state.isFocused && "none",
      backgroundColor: "#1f1f1f",
      fontSize: "1rem",
      minHeight: "unset",
      height: "2.6rem",
      outline: "none",
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
    singleValue: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    input: (props) => ({
      ...props,
      color: "#e0e0e0",
    }),
    menu: (props) => ({
      ...props,
      backgroundColor: "#1f1f1f",
    }),
    option: (props, state) => ({
      ...props,
      backgroundColor: state.isFocused ? "#5D97F6" : "",
      color: state.isFocused ? "#121212" : "#e0e0e0",
      fontSize: "1rem",
    }),

  };

  const [OpenModal, setOpenModal] = useState(false);
  const [OpeneditModal, setOpeneditModal] = useState(false);
  const [maintenanceHistoryFull, setmaintenanceHistoryFull] = useState([]);
  const [loading, setLoading] = useState(true);
  const [MaintenanceType, setMaintenanceType] = useState([]);
  const [Data, setData] = useState([]);
  const [timetaken, setTime] = useState("");
  const [changetype, setChangetype] = useState("");
  const [mntype, setMntype] = useState("");
  const [remark, setRemark] = useState("");
  const [Editable, setEditable] = useState(true);
  const [Index, setIndex] = useState(-1);

  const handleTime = (e, index) => {
    let date = moment(e.toDate()).format("YYYY-MM-DD HH:mm:ss");
    setTime(String(date));
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenModal(!OpenModal);
    }
  };

  const handleCloseedit = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpeneditModal(!OpeneditModal);
    }
  };

  const getMaintenanceList = async () => {
    const response = await getMaintenanceType();
    setMaintenanceType(response.data?.data);
  };

  const callGetMaintenanceHistory = async () => {
    const response = await getMaintenanceHistory(
      inputData?.machine?.MachineKey,
      null
    );
    setmaintenanceHistoryFull(response?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    if (inputData?.machine?.MachineKey) {
      setLoading(true);
      callGetMaintenanceHistory();
      getMaintenanceList();
    }
  }, [OpenModal]);

  const [FilteredHistory, setFilteredHistory] = useState([]);
  const handleSearch = (el) => {
    const value = el.target.value.toLocaleLowerCase();
    const filter = maintenanceHistoryFull.filter((o) => {
      if (
        o.ComponentName.toLocaleLowerCase().includes(value) ||
        o.MachineName.toLocaleLowerCase().includes(value) ||
        o.ActionTaken.toLocaleLowerCase().includes(value) ||
        o.MaintenanceType.toLocaleLowerCase().includes(value)
      ) {
        return o;
      }
    });
    setFilteredHistory(filter);
  };
  const [HistoryData, setHistoryData] = useState([]);
  useEffect(() => {
    if (FilteredHistory.length > 0) {
      setHistoryData(FilteredHistory);
    } else {
      setHistoryData(maintenanceHistoryFull);
    }
  }, [maintenanceHistoryFull, FilteredHistory]);

  function DisplayTime(dateTimeString) {
    const inputDate = moment(dateTimeString, 'DD-MM-YYYY hh:mm:ss A');
    const currentDate = moment();
    const timeAgo = moment.duration(currentDate.diff(inputDate)).humanize();
    return `${timeAgo} ago`;
  }

  const handleHistoryedit = async () => {
    setOpeneditModal(!OpeneditModal);
    const response = await putMaintenanceHistory(
      Data?.LogKey,
      changetype?.MainTypeKey,
      mntype,
      timetaken,
      remark
    );
    if (response?.status === 200) {
      swal(response?.data?.data, "", "success");
      callGetMaintenanceHistory();
    } else {
      swal("Failed to sumbut", "Please try later", "error");
    }
    setLoading(false);
  };

  const [toggleConfirmation, settoggleConfirmation] = useState(false);
  const AskConfirmation = () => {
    settoggleConfirmation(true);
  };

  const handleAlert = (e, click) => {
    if (click != "backdropClick") {
      settoggleConfirmation(false);
    }
  };

  useEffect(() => {
    setEditable(true);
    const index = HistoryData?.findIndex((e) => e.Editable == "N");
    if (index != -1) {
      setEditable(false);
    }
  }, [HistoryData]);


  useEffect(() => {
    const temp = HistoryData?.find((e) => e.LogKey == Index);
    setData(temp);
    setChangetype({
      MainTypeKey: temp?.MainTypeKey,
      Type: temp?.MaintenanceType,
    });
    setMntype(temp?.ActionTaken);
    setRemark(temp?.Remark);
    setTime(
      moment(
        moment(temp?.ActionTakenTime, "DD-MM-YYYY HH:mm").format(
          "MM-DD-YYYY HH:mm"
        )
      )
    );
  }, [Index]);
  console.log('HistoryData', HistoryData);
  return (
    <div className="history-container">
      <div className="history-head">
        <div className="history-head-text" style={{ fontSize: "14px" }}>
          Maintenance History
        </div>
        {maintenanceHistory?.length > 0 && (
          <div style={{ cursor: "pointer" }}>
            <CgMaximizeAlt size={17} onClick={() => setOpenModal(!OpenModal)} />
          </div>
        )}
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={15} />
        </div>
      ) : (
        <>
          {maintenanceHistory?.length > 0 ? (
            <>
              <TableContainer
                className="tableContainer2"
                style={{ margin: "0 14px 10px 15px" }}
              >
                <Table
                  sx={{ whiteSpace: "normal", tableLayout: "fixed" }}
                  stickyheader={"true"}
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow className="tableHeader">
                      <TableCell align="center" className="head">
                        Component
                      </TableCell>
                      <TableCell align="center" className="head">
                        Last maintenance
                      </TableCell>
                      <TableCell align="center" className="head">
                        Duration since
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {maintenanceHistory?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            sx={{ padding: "0px 15px" }}
                            align="left"
                            className="table-data"
                            style={{ fontSize: "12px" }}
                          >
                            {item.ComponentName}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "0px 15px" }}
                            align="center"
                            className="table-data"
                            style={{ fontSize: "12px" }}
                          >
                            {item.ActionTakenTime}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "0px 15px" }}
                            align="center"
                            className="table-data"
                            style={{ fontSize: "12px" }}
                          >
                            {DisplayTime(item.ActionTakenTime)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Modal
                open={OpenModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "0.2px solid #444444",
                      padding: "5px 20px",
                      background: "#565b60",
                      borderRadius: "4px 4px 0px 0px",
                    }}
                  >
                    <h4
                      style={{
                        fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
                        marginTop: "0px",
                        fontWeight: 600,
                        fontSize: "1rem",
                      }}
                    >
                      Maintenance History
                    </h4>
                    <CloseIcon
                      onClick={() => setOpenModal(!OpenModal)}
                      size={15}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      background: "#393939",
                      borderRadius: 4,
                      margin: "10px 20px 0px 20px",
                      paddingLeft: 10,
                      width: "250px",
                    }}
                  >
                    <BiSearch size={25} />
                    <input
                      type="text"
                      style={{
                        width: "250px",
                        padding: "10px",
                        borderRadius: "4px",
                        height: "38px",
                        float: "right",
                        background: "#393939",
                        color: "white",
                        border: 0,
                      }}
                      placeholder="Search maintenance history..."
                      onChange={(el) => {
                        handleSearch(el);
                      }}
                    />
                  </div>
                  <div
                    className="Maintenance__tableContainer"
                    style={{ height: "82.5%", marginTop: "10px" }}
                  >
                    <TableContainer
                      className="tableContainer3"
                      style={{ marginBottom: 0 }}
                    >
                      <Table
                        sx={{ whiteSpace: "nowrap", tableLayout: "fixed" }}
                        stickyheader={"true"}
                        aria-label="sticky table"
                        className="mainTbl"
                      >
                        <TableHead>
                          <TableRow className="tableHeader">
                            <TableCell
                              align="center"
                              style={{ whiteSpace: "break-spaces" }}
                              className="head"
                            >
                              Machine
                            </TableCell>
                            <TableCell
                              align="center"
                              className="head"
                            >
                              Component
                            </TableCell>
                            <TableCell
                              align="center"
                              className="head"
                            >
                              Change Type
                            </TableCell>
                            <TableCell
                              align="center"
                              className="head"
                            >
                              Maintenance Type
                            </TableCell>
                            <TableCell align="center" className="head">
                              Last maintenance
                            </TableCell>
                            <TableCell align="center" className="head">
                              Duration since
                            </TableCell>
                            <TableCell align="center" className="head">
                              Remarks
                            </TableCell>
                            {Editable && (
                              <TableCell align="center" className="head">
                                Action
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {HistoryData?.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  sx={{ padding: "0px 15px" }}
                                  align="center"
                                  className="table-data"
                                >
                                  {item.MachineName}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0px 15px" }}
                                  align="center"
                                  className="table-data"
                                >
                                  <Tooltip
                                    PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                    // arrow
                                    placement="left-end"
                                    title={(
                                      <div className="fia-desc">
                                        <span>{item.ComponentName}</span>
                                        <br />
                                      </div>)}
                                  >
                                    <div>
                                      {item.ComponentName}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0px 15px" }}
                                  align="center"
                                  className="table-data"
                                >
                                  {item.MaintenanceType}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0px 15px" }}
                                  align="center"
                                  className="table-data"
                                >
                                  {item.ActionTaken}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0px 15px" }}
                                  align="center"
                                  className="table-data"
                                >
                                  {item.ActionTakenTime}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0px 15px" }}
                                  align="center"
                                  className="table-data"
                                >
                                  {DisplayTime(item.ActionTakenTime)}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0px 15px" }}
                                  align="center"
                                  className="table-data"
                                >
                                  <Tooltip
                                    PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                    // arrow
                                    placement="left-start"
                                    title={(
                                      <div className="fia-desc">
                                        <span>{item.Remark}</span>
                                        <br />
                                      </div>)}
                                  >
                                   <div>{item.Remark}</div> 
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0px 15px" }}
                                  align="center"
                                  className="table-data"
                                >
                                  {Editable && (
                                    <EditIcon
                                      onClick={() => {
                                        setIndex(item?.LogKey);
                                        // setData(item);
                                        // setTime(item.ActionTakenTime);
                                        // setChangetype({
                                        //   MainTypeKey: item.MainTypeKey,
                                        //   Type: item.MaintenanceType,
                                        // });
                                        // setMntype(item.ActionTaken);
                                        // setRemark(item.Remark);
                                        setOpeneditModal(!OpeneditModal);
                                      }}
                                      size={15}
                                    ></EditIcon>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Box>
              </Modal>

              <Modal
                open={OpeneditModal}
                onClose={handleCloseedit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={editmodalstyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "0.2px solid #444444",
                      padding: "5px 20px",
                      background: "#565b60",
                      borderRadius: "4px 4px 0px 0px",
                    }}
                  >
                    <h4
                      style={{
                        fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
                        marginTop: "0px",
                        fontWeight: 600,
                        fontSize: "1rem",
                      }}
                    >
                      Edit Maintenance History
                    </h4>
                    <CloseIcon
                      onClick={() => setOpeneditModal(!OpeneditModal)}
                      size={15}
                    />
                  </div>
                  <div
                    className="Maintenance__tableContainer"
                    style={{ height: "82.5%", marginTop: "10px" }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ width: "48%", margin: "1%" }}>
                        <div className="form-group">
                          <label className="font10">Machine</label>
                          <div style={{ display: "block" }}>
                            <input
                              className={`form-control customForm input-md`}
                              type="text"
                              placeholder="Machine"
                              readOnly
                              value={Data?.MachineName}
                              style={{ backgroundColor: "#575757" }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="font10">Component</label>
                          <div style={{ display: "block" }}>
                            <input
                              className={`form-control customForm input-md`}
                              type="text"
                              placeholder="Machine"
                              readOnly
                              value={Data?.ComponentName}
                              style={{ backgroundColor: "#575757" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "48%", margin: "1%" }} >
                        <div className="form-group">
                          <label className="font10">Routinely / Breakdown</label>
                          <div
                            style={{
                              borderRadius: "4px",
                              width: "100%",
                              height: '32px'
                            }}
                          >
                            <Select
                              isSearchable={true}
                              styles={customSelect}
                              className="select"
                              placeholder="Select Change Type"
                              options={MaintenanceType}
                              getOptionValue={(x) => x.MainTypeKey}
                              getOptionLabel={(x) => x.Type}
                              value={changetype}
                              onChange={(e) => {
                                setChangetype(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group" style={{ paddingTop: "2px" }}>
                          <label className="font10">Last maintenance</label>
                          <div
                            style={{
                              display: "block",
                              border: "1px solid #5d5e60",
                              padding: 5,
                              borderRadius: 5,
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                value={timetaken}
                                inputFormat="DD-MM-YYYY HH:mm"
                                onChange={(e) => {
                                  handleTime(dayjs(e));
                                }}
                                disableFuture={true}
                                format="DD-MM-YYYY HH:mm"
                                renderInput={(params) => (
                                  <TextField
                                    sx={{
                                      svg: { color: "#fff" },
                                      input: { color: "#fff" },
                                    }}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ width: "48%", margin: "1%" }}>
                        <div className="form-group">
                          <label className="font10">Maintenance Type</label>
                          <div style={{ display: "block" }}>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              style={{ display: "flex", flexDirection: "row" }}
                              onChange={(e) => setMntype(e.target.value)}
                              defaultValue={mntype}
                            >
                              <FormControlLabel
                                value="Repair"
                                control={
                                  <Radio
                                    sx={{
                                      color: "#fff",
                                      width: 60,
                                    }}
                                  />
                                }
                                label="Repair"
                              />
                              <FormControlLabel
                                value="Replace"
                                control={
                                  <Radio
                                    sx={{
                                      color: "#fff",
                                      width: 60,
                                    }}
                                  />
                                }
                                label="Replace"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="font10">Remark</label>
                      <div style={{ display: "block" }}>
                        <input
                          className={`form-control customForm input-md`}
                          type="text"
                          placeholder="Remark"
                          value={remark}
                          onChange={(e) => {
                            setRemark(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        onClick={() => setOpeneditModal(!OpeneditModal)}
                        className="btn-cancel"
                        style={{
                          marginLeft: 0,
                          float: "right",
                          marginBottom: 10,
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => settoggleConfirmation(true)}
                        className="btn-go"
                        style={{
                          marginLeft: 0,
                          float: "right",
                          marginBottom: 10,
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Box>
              </Modal>

              <Modal
                open={toggleConfirmation}
                onClose={handleAlert}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style2}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "0.2px solid #444444",
                      padding: "5px 20px",
                      backgroundColor: "#565b60",
                    }}
                  >
                    <h4
                      style={{
                        fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
                        marginTop: "0px",
                        fontWeight: 600,
                        fontSize: "1rem",
                      }}
                    >
                      Alert
                    </h4>
                    <CloseIcon
                      onClick={() => settoggleConfirmation(false)}
                      size={15}
                    />
                  </div>
                  <p style={{ textAlign: "center", margin: "30px 0" }}>
                    Are you want to edit the Maintenance history?
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => settoggleConfirmation(false)}
                      variant={"outlined"}
                    >
                      No
                    </Button>
                    <Button
                      onClick={() => {
                        settoggleConfirmation(false);
                        handleHistoryedit();
                      }}
                      variant={"contained"}
                    >
                      Yes
                    </Button>
                  </div>
                </Box>
              </Modal>
            </>
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  );
}

export default MaintenanceHistory;
