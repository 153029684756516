// import axios from 'axios';
// import { user_id, API_Url } from "../../configs/config";
import { APIUrl } from '../../../../helpers/constants';
import CallAPI from '../../../../services/conditionMonitoring.service';

// A mock function to mimic making an async request for data
export function viewCausualParameters(id) {
  const apiInfo = {
    url: `${APIUrl}/api/getCausualParameters?potentialCauseId=${id}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(`${APIUrl}/api/getCausualParameters?potentialCauseId=${id}`)
  //   .then((res) => res.data);
}

export function deleteCausualParameters(causualParameter) {
  const apiInfo = {
    url: `${APIUrl}/api/deleteCausualParameter?causualParameterId=${causualParameter.CausalParameter_ID}`,
    method: 'DELETE',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .delete(
  //     `${APIUrl}/api/deleteCausualParameter?causualParameterId=${causualParameter.CausalParameter_ID}`,
  //   )
  //   .then((res) => res.data);
}
