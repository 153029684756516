/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Drawer } from 'antd';
import { deleteCausualParametersAsync } from '../../../../redux/slices/causualParametersViewSlice';
import { CausualParameterAdd } from './CausualParametersAdd';
import { setEditCausalParameter } from '../../../../redux/slices/failureModeDetailsSlice';
import '../../../../styles/failureMode.css';
import { ReactComponent as Edit } from '../../../../images/editSpoc.svg';
import { ReactComponent as Delete } from '../../../../images/deleteIcon.svg';
// import Icon from '../../../Dashboard/Sidebar/images/Icon';

export const CausualParametersView = ({ potentialCause }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [deleteObject, setDeleteObject] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  // const [modalText, setModalText] = useState('Are you sure to delete this?');
  const modalText = 'Are you sure to delete this?';
  // const [editItem, setEditItem] = useState();
  const [editVisible, setEditVisible] = useState(false);

  const onDeleteParameterClick = (id, potentialId) => {
    setDeleteObject({ CausalParameter_ID: id, PotentialCause_ID: potentialId });
    setVisible(true);
  };

  const onEditParameterClick = (item) => {
    dispatch(setEditCausalParameter(item.CausalParameter_ID));
    setEditVisible(true);
  };

  const onEditParameterClose = () => {
    setEditVisible(false);
    dispatch(setEditCausalParameter());
  };

  const handleOk = () => {
    dispatch(deleteCausualParametersAsync(deleteObject));
    setDeleteObject(undefined);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const editFailure = useSelector(
    (state) => state.failureModeDetails.editFailureMode,
  );

  const causualParameterValues = potentialCause.causualparameters;

  return (
    <>
      <div className="recommended-action-list">
        {causualParameterValues && causualParameterValues.length > 0 && (
          <div className="sub-grid-label">FORMULA</div>)}
        <div>
          {causualParameterValues
            && causualParameterValues.map((item) => (
              <div key={item.CausalParameter_ID} className="card">

                <label>
                  {item.IndirectFormula}
                </label>
                {' '}

                <div className="pull-right edit">

                  <label
                    className="col-md-1"
                    onClick={() => {
                      onEditParameterClick(item);
                    }}

                  >
                    {/* <Icon name="EditIcon" /> */}
                    <Edit />
                  </label>

                  <label
                    className="col-md-1"
                    onClick={() => {
                      onDeleteParameterClick(
                        item.CausalParameter_ID,
                        item.PotentialCause_ID,
                      );

                      // showModal();
                    }}
                  >
                    {/* <Icon name="DeleteIcon" /> */}
                    <Delete />
                  </label>
                </div>
              </div>
            ))}
          {(causualParameterValues === undefined
            || causualParameterValues.length === 0) && (
            <div>
              <p>No Records Available</p>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Delete"
        visible={visible}
        width={300}
        onOk={handleOk}
        okText="Delete"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        className="failuremodulePage"
      >
        <p>{modalText}</p>
      </Modal>

      {editVisible && (
        <Drawer
          title="Add Causal Parameter"
          placement="right"
          height="100%"
          closable
          visible={editVisible}
          closeIcon={<div className="closepopup pointer"> &#x2715;</div>}
          onClose={onEditParameterClose}
          className="failuremodulePage"
          push={{ distance: 378 }}
        >
          <CausualParameterAdd
            modelID={editFailure.MachineModel_ID}
            sectionID={editFailure.MachineSection_ID}
            functionID={editFailure.MachineFunction_ID}
            onCausalParameterClose={onEditParameterClose}
          />
        </Drawer>
      )}
    </>
  );
};
