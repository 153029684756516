/* eslint no-unused-vars:0 */

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import CallAPI from '../../../../services/callAPI.service';
import APIS from '../../../../config/api.config.json';
import Select from './Select';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

// const today = format(new Date(), 'yyyy/MM/dd');
// import LineChart from '../graph/linechart';
/* eslint no-nested-ternary:0 */
/* eslint no-alert:0 */
// console.clear();
// console.log(today);
function limitDecimalPlaces(value, count = 2) {
  if (value.indexOf('.') === -1) { return; }
  const decimal = value.split('.')[1];
  // console.log(decimal);
  // console.log(value.indexOf('.'));
  if (decimal.length > count) {
    // value = parseFloat(value).toFixed(count);
    let splitValues = value.split('');
    splitValues[splitValues.length - 1] = 0;

    splitValues = splitValues.join('');
    // fixedValue[fixedValue.length - 1] = 0;
    return Number(splitValues).toFixed(count);
  }
}

const {
  GetECRData, ECRAddEntry, LoadPlant, GetECRFormSchema,
} = APIS;
const Close = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.88" d="M18.459 6.50098L12.459 12.501L6.45898 18.501" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
    <path opacity="0.88" d="M6.45898 6.50098L18.459 18.501" stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
/* eslint  react/prop-types:0 */

/* eslint react/prop-types:0 */
const defaults = {
  // BioDiesel: '',
  // HSD: '',
  // HWG: '',
  // DGSet: 0,
  date: '',
  PlantKey: '',
};

const DefaultSelect = {
  label: 'Select Plant',
  value: 'Select Plant',
};

const Loader = () => <div className="ECR__loader">Loading...</div>;

export default function ECRAddPopup({ setOpen, reportInfo }) {
  const LoggedUserKey = localStorage.getItem('UserKey');
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState(defaults);
  const [btnText, setBtnText] = useState('Save');
  const [plantOptions, setPlantOptions] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState(DefaultSelect);
  const [formSchema, setFormSchema] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const closePopup = () => {
    setOpen(false);
  };
  const handleDateChange = (e) => {
    // dates.compare(a,b)
    // console.log(e.target.value);
    const date = format(new Date(e), 'yyyy-MM-dd');
    setIsLoading(true);
    setSelectedDate(e);

    const request = {
      ...formData,
      LoggedUserKey,
      date,
    };

    CallAPI(GetECRData, request).then((res) => {
      const { status, data } = res;
      if (status === 200 && data.length > 0) {
        // const {
        //   HSD, DGSet, BioDiesel, HWG, MSEDL,
        // } = data[0];
        setFormData({
          ...formData,
          ...data[0],
          date: request.date,
        });
        setBtnText('Update');
        setIsLoading(false);
      } else {
        setFormData({
          ...defaults,
          date: request.date,
          PlantKey: request.PlantKey,
        });
        setBtnText('Save');
        setIsLoading(false);
      }
    }).catch((err) => {
      console.log(err);
    });
    // setFormData({
    //   ...formData,
    //   date,
    // });
  };

  const handleInputChange = (e) => {
    const { field } = e.target.dataset;
    let { value } = e.target;
    const floatValue = limitDecimalPlaces(value);
    value = floatValue === undefined ? value : floatValue;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const request = { ...formData, LoggedUserKey };
    if (Number(formData.PlantKey) === 5 && Number(formData.MSEDL) === 0) {
      alert('MPEB(kWh) must not be 0');
      return;
    }

    await CallAPI(ECRAddEntry, request).then((res) => {
      if (res.status === 200) {
        alert('Data uploaded Successfully');
        setFormData({ ...defaults });
        setSelectedPlant(DefaultSelect);
        setSelectedDate(null);
        setFormSchema([]);
      } else {
        alert('Data not uploaded, Something went wrong');
      }
    }).catch((err) => {
      alert('Data not uploaded, Something went wrong');
      console.log(err);
    });
  };

  const handleSelectChange = (value) => {
    setIsLoading(true);
    setSelectedPlant(value);
    setFormData({ ...defaults, PlantKey: value.value });
    setSelectedDate(null);

    const request = { LoggedUserKey, PlantKey: value.value };
    CallAPI(GetECRFormSchema, request).then((res) => {
      const { status, data } = res;
      if (status === 200) {
        console.log(res);
        setFormSchema(data);
        setIsLoading(false);
      } else {
        throw res;
      }
    }).catch((err) => {
      console.log(err);
      setFormSchema([]);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    const request = {
      LoggedUserKey,
    };
    CallAPI(LoadPlant, request).then((res) => {
      const options = [];
      const { status, data } = res;
      if (status === 200) {
        for (let i = 0; i < data.length; i += 1) {
          const { PlantKey, PlantName } = data[i];
          options.push({
            label: PlantName,
            value: PlantKey,
          });
        }
        setPlantOptions(options);
        console.log(options);
      } else {
        throw res;
      }
    }).catch((err) => {
      console.error(err);
    });
  }, []);
  return (
    <>
      <div className="ECR__popup">
        <div className="head">
          <span className="ECR__title">Manual Entry Form</span>
          <span className="ECR__close" onClick={closePopup}><Close /></span>
        </div>
        <div className="body">
          <div className="ECR__filter">
            <Select options={plantOptions} onChange={handleSelectChange} value={selectedPlant} />
            <div className="ECR__date_graph">
              <DatePicker
                selected={selectedDate}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select a date"
                maxDate={new Date()}
                onChange={handleDateChange}
              />
              {/* <input type="date" className="" onChange={handleDateChange} value={formData.date} /> */}
              {/* <input type="button" className="ECR__grahp_btn" value="Graph" /> */}
            </div>
          </div>
          <div className="ECR_Body_content">
            {isLoading === false ? (
              <form className="ECR__form" onSubmit={handleSubmit}>
                <div className="ECR_fields_container">
                  {formSchema.map((currentFiled) => {
                    const {
                      ColumntoMap, FieldName, PlaceHolder,
                    } = currentFiled;
                    return (
                      <div className="ECR__input_container">
                        <label>{FieldName}</label>
                        <input type="number" step="any" required placeholder={PlaceHolder} onChange={handleInputChange} data-field={ColumntoMap} value={formData[ColumntoMap] !== undefined ? formData[ColumntoMap] : ''} disabled={!reportInfo?.EditAccess} />
                      </div>
                    );
                  })}
                </div>
                {formData.date !== '' && formData.PlantKey !== '' && formSchema.length > 0
                  ? (
                    <div className="ECR__submit">
                      <input type="submit" value={btnText} className="ECR__submit_btn" disabled={!reportInfo?.EditAccess} />
                    </div>
                  )
                  : ''}

                {formSchema.length <= 0 ? (
                  <div className="ECR_NoFieldFound">
                    {selectedPlant === DefaultSelect ? 'Please Select Plant' : `No Fields Found for Plant ${selectedPlant.label}`}
                  </div>
                ) : ''}
              </form>
            ) : <Loader />}

          </div>
        </div>
      </div>
      <div className="ECR__layer" onClick={closePopup} />
    </>
  );
}
