/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";

import getBreakDownPareto from "../../../../API/getBreakDownPareto.js";

import "./ExpandPareto.css";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  zoomPlugin
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50%",
  // height: "500px",
  maxWidth: "90%",
  bgcolor: "#1f1f1f",
  border: "none",
  boxShadow: "-4px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "4px",
  paddingBottom: "10px",
};

function ExpandPareto(props) {
  const [paretoData, setParetoData] = useState([]);

  const getPareto = async (e) => {
    let temp = { ...e, TopNAll: "All" };
    let res = await getBreakDownPareto(temp);
    setParetoData(res?.data?.data);
  };

  useEffect(() => {
    if (props.datas.flag != "new") {
      getPareto(props.datas);
    }
  }, [props.datas]);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      props.setOpen(false);
    }
  };

  let labels = paretoData?.map((e, i) => {
    return e.DowntimeReason;
    // This code is for breaks in name
    // let str = e.DowntimeReason;
    // let parts = str.split(/(\S+\s*\s*\S+)/);
    // parts = parts.filter(function (str) {
    //   return /\S/.test(str);
    // });
    // return parts
  });
  console.log("paretoData", paretoData);
  const data = {
    labels,
    datasets: [
      {
        label: "Down Time Pareto",
        data: paretoData?.map((e) => {
          return e.Minutes;
        }),
        backgroundColor: "#4B84A5",
        pointRadius: 5,
        pointHoverRadius: 10,
      },
    ],
  };
  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },

          mode: "xy",
          // speed: 100,
        },
        // pan: {
        //   enabled: true,
        //   mode: "x",
        // },
      },
      datalabels: {
        display: true,
        color: "white",
        align: "end",
        anchor: "start",
        font: { size: "14" },
        formatter: function (value, context) {
          // console.log("ujjjaaa", value, context);
          return context.chart.data.labels[context.dataIndex];
        },
        rotation: "-90",
        // offset:20
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (e) {
            return paretoData?.[e[0].dataIndex].DowntimeReason;
          },
          label: function (text) {
            console.log("text", text);
            let label = " " + text.formattedValue + " mins";
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
          font: {
            size: 12,
            family: "Arial, Sans Serif",
          },
          color: "#FFFFFF",
        },
        grid: { display: false },
        title: {
          display: true,
          text: "Breakdown",
          color: "#FFFFFF",
          font: {
            size: 12,
            family: "Arial, Sans Serif",
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 12,
            family: "Arial, Sans Serif",
          },
          color: "#FFFFFF",
        },
        grid: {
          color: "rgba(255, 255, 255, 0.12)",
          borderWidth: 1,
          borderDash: [5, 2],
        },
        title: {
          display: true,
          text: "Time (mins)",
          color: "#FFFFFF",
          font: {
            size: 12,
            family: "Montserrat,Arial, Sans Serif",
          },
        },
      },
    },
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // sx={{
        //   backdropFilter: "blur(2px)",
        // }}
      >
        <Box sx={style}>
          <div
            className="cross-heading"
            style={{
              borderBottom: "0.2px solid #444444",
              background: "#565b60",
              borderRadius: "4px 4px 0px 0px",
            }}
          >
            <div id="heading">Downtime Pareto</div>
            <div
              onClick={handleClose}
              style={{
                //   paddingTop: "20px",
                //   paddingRight: "20px",
                cursor: "pointer",
              }}
            >
              <CloseIcon style={{ fontSize: "20px" }} />
            </div>
          </div>
          <Bar
            options={options}
            data={data}
            style={{ padding: "0 20px 0px 20px", marginTop: "35px" }}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default ExpandPareto;
